import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface GetDashBoardDataList {
  loading: boolean;
  data: [];
  status: number;
  error: null | string;
}

export const initialState: GetDashBoardDataList = {
  loading: false,
  data: [],
  status: 0,
  error: null,
};

const dashBoardDataListSlice = createSlice({
  name: "dashBoardDataList",
  initialState,
  reducers: {
    reset: () => initialState,
    dashBoardDataListRequest(
      state: GetDashBoardDataList,
      action: PayloadAction<string>,
    ) {
      state.loading = true;
    },
    dashBoardDataListSuccess(
      state: GetDashBoardDataList,
      action: PayloadAction<{ data: any; status: number }>,
    ) {
      state.loading = false;
      state.data = action.payload.data;
      state.status = action.payload.status;
    },
    dashBoardDataListFailure(
      state: GetDashBoardDataList,
      action: PayloadAction<{ error: any }>,
    ) {
      state.loading = false;
      state.error = action.payload.error;
    },
  },
});

export const dashBoardDataListAction = dashBoardDataListSlice.actions;
export const dashBoardDataListReducer = dashBoardDataListSlice.reducer;

export default dashBoardDataListReducer;
