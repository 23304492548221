import { ReactElement } from "react";
import styled from "styled-components";
import { Box, Text, Button } from "rebass";
import moment from "moment";
import { Form } from "react-bootstrap";
import icons from "../../../../icons";
import { useAppDispatch } from "../../../../../store/hooks";
import { assignOwnerAction } from "../../getAssignedUsersSlice";

const SelectUserWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 16px;
  overflow-x: scroll;
  padding-bottom: 6px;
  &::-webkit-scrollbar {
    width: 5px;
    height: 6px;
    cursor: pointer;
  }
  &::-webkit-scrollbar-track {
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
  .scrollbar {
    height: 100px !important;
  }
  .cardUser {
    background-color: ${({ theme }) => theme.colors.bg.white3};
    border: 1px solid ${({ theme }) => theme.colors.border.bluishCyan};
    border-radius: 6px;
    max-width: 263px;
    min-width: 263px;
    padding: 10px 0px;
    .titleName {
      font-weight: ${({ theme }) => theme.fontWeights.medium};
      font-size: ${({ theme }) => theme.fontSizes[16]};
      color: ${({ theme }) => theme.colors.text.black};
      line-height: 17px;
    }
    .wrapperRole {
      display: flex;
      align-items: center;
      padding-left: 16px;
      padding-bottom: 6px;
      .role {
        font-weight: ${({ theme }) => theme.fontWeights.regular};
        font-size: ${({ theme }) => theme.fontSizes[16]};
        color: ${({ theme }) => theme.colors.text.black};
        line-height: 20px;
        max-width: 160px;
      }
      .year {
        font-weight: ${({ theme }) => theme.fontWeights.regular};
        font-size: ${({ theme }) => theme.fontSizes[16]};
        color: ${({ theme }) => theme.colors.text.black};
        line-height: 20px;
      }
      .dot {
        background-color: ${({ theme }) => theme.colors.bg.black};
        width: 2px;
        height: 3px;
        border-radius: 50%;
        text-align: center;
        margin: 0 5px;
      }
    }
    .radiobuttonContainer {
      padding-left: 16px;
      display: flex;
      align-items: center;
      padding-left: 16px;
      border-bottom: 1px solid ${({ theme }) => theme.colors.border.bluishCyan};
      padding-bottom: 13px;
    }
    .durationWrapper {
      display: flex;
      align-items: center;
      padding: 10px 16px 0px 16px;
      .start,
      .end {
        font-weight: ${({ theme }) => theme.fontWeights.regular};
        font-size: ${({ theme }) => theme.fontSizes[16]};
        color: ${({ theme }) => theme.colors.text.black};
        line-height: 20px;
      }
      .arrow {
        margin: 0 10px;
        display: inline-block;
      }
    }
    .headerWrapper {
      display: flex;
      padding: 0 16px;
      .icon {
        margin-left: auto;
        background: transparent;
        padding: 0;
      }
    }
  }
`;
const RadioButtonHolder = styled.div`
  height: 19px;
  .form-check-inline {
    margin: 0;
  }
  .form-check-input {
    width: 19px;
    height: 19px;
    margin-right: 0;
    margin-top: unset;
    background-color: ${({ theme }) => theme.colors.bg.white3};
    border: 1px solid ${({ theme }) => theme.colors.border.mediumGrey1};
    cursor: pointer;
  }
  .form-check-input:checked {
    background-color: ${({ theme }) => theme.colors.bg.tealishBlue};
    border: unset;
  }
  .form-check-input:focus {
    box-shadow: unset;
  }
`;
const ButtonLabel = styled.p`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes[16]};
  color: ${({ theme }) => theme.colors.text.black};
  line-height: 17px;
  padding-left: 5px;
`;
function SelectUser({
  item,
  deleteUser,
  assignedUserList,
  setAssignedUserList,
}: any): ReactElement {
  const dispatch = useAppDispatch();
  const updateState = (profileId: string) => {
    const newState = assignedUserList.map((obj: any) => {
      if (obj?.profileId === profileId) {
        return { ...obj, isOwner: true };
      }
      return { ...obj, isOwner: false };
    });
    setAssignedUserList(newState);
    const dataSend: any = {
      data: { isOwner: true },
      userId: item?._id,
    };
    dispatch(assignOwnerAction.assignOwnerSubmitRequest(dataSend));
  };
  return (
    <SelectUserWrapper>
      <Box as="div" className="cardUser">
        <Box as="div" className="headerWrapper">
          <Text as="h6" className="titleName">
            {item?.fullName}
          </Text>
          <Button
            as="button"
            className="icon"
            onClick={() => deleteUser(item?.profileId)}
          >
            <icons.CloseIcon />
          </Button>
        </Box>
        <Box as="div" className="wrapperRole">
          <Text as="span" className="role">
            {item?.role}
          </Text>
          <Text as="span" className="dot" />
          {item?.experience && item?.experience !== "" ? (
            <Text as="span" className="year">
              {item?.experience} Years
            </Text>
          ) : null}
        </Box>
        <Box as="div" className="radiobuttonContainer">
          <RadioButtonHolder>
            <Form.Check
              inline
              type="radio"
              name={`${item?.profileId}_radio`}
              onChange={() => updateState(item?.profileId)}
              checked={item?.isOwner}
            />
          </RadioButtonHolder>
          <ButtonLabel>Select Owner</ButtonLabel>
        </Box>
        <Box as="div" className="durationWrapper">
          <Text as="span" className="start">
            {moment(item?.startDate).format("DD MMM YYYY")}
          </Text>
          <Text as="span" className="arrow">
            <icons.arrowRightIcon />
          </Text>
          <Text as="span" className="end">
            {moment(item?.endDate).format("DD MMM YYYY")}
          </Text>
        </Box>
      </Box>
    </SelectUserWrapper>
  );
}

export { SelectUser };
