import { ReactElement } from "react";
import styled, { css } from "styled-components";
import {
  Accordion,
  Dropdown,
  DropdownButton,
  ListGroup,
  Form,
} from "react-bootstrap";
import Icons from "../../icons";
import ThreeDots from "../../images/ThreeDotsImage/ThreeDots.svg";

const StyledProfileBuilderTable = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 12px;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
`;

type ImplementationTableContentHolderProps = {
  active?: boolean;
};
const Right = styled.div`
  display: flex;
  flex: 0 0 580px;
  align-items: center;
  .threeDotsButton {
    position: absolute;
    right: 14px;
    .dropdown-toggle::after {
      border: unset;
      content: url(${ThreeDots});
      position: absolute;
      top: 0;
      left: -2px;
    }
    .dropdown-menu {
      padding: 0px;
    }
    .dropdown-item {
      padding: 0px;
      &:hover,
      &:active,
      &:focus {
        background-color: ${({ theme }) => theme.colors.bg.tealishBlue};
      }
      svg {
        color: ${({ theme }) => theme.colors.text.lightGrey1};
      }
      &:hover svg,
      &:active svg,
      &:focus svg {
        color: ${({ theme }) => theme.colors.text.white};
      }
      &:hover h6,
      &:active h6,
      &:focus h6 {
        color: ${({ theme }) => theme.colors.text.white};
      }
    }
    .btn-primary.dropdown-toggle,
    .btn-primary.dropdown-toggle:focus {
      background-color: unset;
      border-color: unset;
      box-shadow: unset;
    }
    .btn {
      padding: unset;
    }
    .duration {
      position: relative;
      .threeDotsButton {
        position: absolute;
        right: 0;
        top: 15px;
      }
    }
  }
`;
const ImplementationTableContentHolder = styled.div<ImplementationTableContentHolderProps>`
  .accordion {
    border: 1px solid ${({ theme }) => theme.colors.border.default};
    border-radius: 0 8px 8px 0;
    cursor: pointer;
    transition: ease-in all 200ms;
    overflow: visible;
    border-left: 4px solid ${({ theme }) => theme.colors.border.default};
    &:hover {
      border-color: ${({ theme }) => theme.colors.bg.tealishBlue};
      box-shadow: 0px 4px 10px 0px rgba(31, 56, 98, 0.129);
    }
  }
  .accordion-item {
    border: none;
    display: flex;
    flex-direction: column;
    background: transparent;
    .accordion-header {
      order: -1;
      display: flex;
      .accordion-button {
        box-shadow: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0 10px 22px;
        min-height: 30px;
        border-radius: 0 8px 8px 0;

        &:after {
          position: absolute;
          right: 40px;
          background-size: 11px;
          background-position: center right;
          background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='9' height='5' viewBox='0 0 9 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fillRule='evenodd' clipRule='evenodd' d='M4.04543 4.3014C4.29648 4.51933 4.70352 4.51933 4.95457 4.3014L8.81171 0.953184C9.06276 0.735258 9.06276 0.381929 8.81171 0.164003C8.56066 -0.0539239 8.15363 -0.0539239 7.90257 0.164003L4.5 3.11763L1.09743 0.164003C0.846375 -0.0539239 0.43934 -0.0539239 0.188289 0.164003C-0.0627628 0.381929 -0.0627628 0.735258 0.188289 0.953184L4.04543 4.3014Z' fill='black'/%3e%3c/svg%3e ");
        }
      }
    }
  }
  .accordion-body {
    padding: 0;
    .list-group {
      border-radius: 8px;
      .list-group-item {
        border: none;
        display: flex;
        align-items: center;
        padding: 16px 22px;
        & + .list-group-item {
          border-top: 1px solid ${({ theme }) => theme.colors.bg.bluishCyan};
        }
      }
    }
  }
  .accordion-collapse.show {
    & + .accordion-header {
      button {
        background: ${({ theme }) => theme.colors.bg.white1};
        border-bottom: 1px solid ${({ theme }) => theme.colors.bg.bluishCyan};
        border-radius: 0 8px 0 0;
      }
    }
  }
  ${({ active }) =>
    !active &&
    css`
      .accordion {
        border-color: ${({ theme }) => theme.colors.bg.tealishBlue};
        box-shadow: 0px 4px 10px 0px rgba(31, 56, 98, 0.129);

        .accordion-button {
          border-bottom: 1px solid ${({ theme }) => theme.colors.border.default};
        }
        .collapse:not(.show) {
          display: block;
        }
        .accordion-button::after {
          transform: rotate(-180deg);
        }
      }
    `}
`;
const ActivityWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const Activity = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.medium};
  font-size: ${({ theme }) => theme.fontSizes[1]};
  line-height: ${({ theme }) => theme.lineHeight[1]};
  font-weight: normal;
  color: ${({ theme }) => theme.baseColors.black};
  padding-right: 20px;
  flex: 0 0 290px;
`;
const Owner = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.medium};
  font-size: ${({ theme }) => theme.fontSizes[1]};
  line-height: ${({ theme }) => theme.lineHeight[1]};
  font-weight: normal;
  color: ${({ theme }) => theme.baseColors.black};
  flex: 0 0 290px;
`;
const ActivityDetails = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.light};
  font-size: ${({ theme }) => theme.fontSizes[1]};
  line-height: ${({ theme }) => theme.lineHeight[1]};
  font-weight: normal;
  color: ${({ theme }) => theme.baseColors.black};
  padding-right: 20px;
  flex: 0 0 290px;
`;
const OwnerDetails = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.light};
  font-size: ${({ theme }) => theme.fontSizes[1]};
  line-height: ${({ theme }) => theme.lineHeight[1]};
  font-weight: normal;
  color: ${({ theme }) => theme.baseColors.black};
  flex: 0 0 290px;
`;
const Date = styled.div`
  width: 290px;
  flex: 0 0 290px;
  color: ${({ theme }) => theme.colors.text.black};
  font-family: ${({ theme }) => theme.fontFamily.medium};
  font-size: ${({ theme }) => theme.fontSizes[1]};
  line-height: ${({ theme }) => theme.lineHeight[1]};
  font-weight: normal;
`;
const PrioritySelect = styled.div`
  flex: 0 0 290px;
  color: ${({ theme }) => theme.colors.text.black};
  font-family: ${({ theme }) => theme.fontFamily.bold};
  font-size: ${({ theme }) => theme.fontSizes[1]};
  line-height: ${({ theme }) => theme.lineHeight[1]};
  font-weight: normal;
`;
const SelectGrp = styled.div`
  max-width: 96px;
  position: relative;
  flex: 0 0 96px;
  display: flex;
  align-items: center;
  margin-left: -13px;
  span {
    position: absolute;
    left: 11px;
  }
  select {
    font-family: ${({ theme }) => theme.fontFamily.bold};
    font-size: ${({ theme }) => theme.fontSizes[15]};
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='8' height='5' viewBox='0 0 8 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fillRule='evenodd' clipRule='evenodd' d='M3.59594 4.81694C3.8191 5.06102 4.1809 5.06102 4.40406 4.81694L7.83263 1.06694C8.05579 0.822864 8.05579 0.427135 7.83263 0.183058C7.60948 -0.0610194 7.24767 -0.0610194 7.02451 0.183058L4 3.49112L0.97549 0.183058C0.752333 -0.0610194 0.390524 -0.0610194 0.167368 0.183058C-0.0557892 0.427135 -0.0557892 0.822864 0.167368 1.06694L3.59594 4.81694Z' fill='black'/%3e%3c/svg%3e ");
    background-size: auto;
    background-position: center right 11px;
    padding-right: 23px;
    padding-left: 26px;
    max-width: 96px;
    flex: 0 0 96px;
    &:focus {
      box-shadow: none;
      outline: none;
    }
  }
`;
const ButtonDetails = styled.div`
  display: flex;
  align-items: center;
  padding: 14px 18px 12px 18px;
`;
const IconContainer = styled.div`
  margin-right: 6px;
`;
const DropdownText = styled.h6`
  font-family: ${({ theme }) => theme.fontFamily.light};
  font-style: normal;

  font-size: ${({ theme }) => theme.fontSizes[16]};
  color: ${({ theme }) => theme.colors.text.lightGrey1};
  line-height: ${({ theme }) => theme.lineHeight[14]};
  margin-bottom: 0px;
  @media screen and (max-width: 1200px) {
    font-size: ${({ theme }) => theme.fontSizes[0]};
  }
`;

type ImplementationTableContentProps = {
  implementationTableContent: Array<List>;
};
type List = {
  id?: string | number;
  activity: string;
  activityDetails: string;
  activityDetails02: string;
  owner: string;
  ownerDetails: string;
  ownerDetails02: string;

  date: string;
  active?: boolean;
};

function ImplementationTableContent({
  implementationTableContent,
}: ImplementationTableContentProps): ReactElement {
  return (
    <StyledProfileBuilderTable>
      {implementationTableContent
        ? implementationTableContent.map((item) => (
            <ImplementationTableContentHolder
              key={item.id}
              active={item.active}
            >
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Body>
                    <ListGroup>
                      <ListGroup.Item>
                        <ActivityDetails>
                          {item.activityDetails}
                        </ActivityDetails>
                        <OwnerDetails>{item.ownerDetails}</OwnerDetails>
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <ActivityDetails>
                          {item.activityDetails02}
                        </ActivityDetails>
                        <OwnerDetails>{item.ownerDetails02}</OwnerDetails>
                      </ListGroup.Item>
                    </ListGroup>
                  </Accordion.Body>
                  <Accordion.Header>
                    <ActivityWrapper>
                      <Activity>Activity - {item.activity}</Activity>
                      <Owner>Owner - {item.owner}</Owner>
                    </ActivityWrapper>
                    <Right>
                      <PrioritySelect>
                        {/* <Select options={data} /> */}
                        <SelectGrp>
                          <Icons.HighIcon />

                          <Form.Select aria-label="Default select example">
                            <option>Height</option>
                            <option value="1">Medium</option>
                            <option value="2">Low</option>
                          </Form.Select>
                        </SelectGrp>
                      </PrioritySelect>

                      <Date> {item.date}</Date>
                      <DropdownButton
                        title=""
                        align="end"
                        className="threeDotsButton"
                      >
                        <Dropdown.Item eventKey="1" className="dropDownText">
                          <ButtonDetails>
                            <IconContainer>
                              <Icons.EditIcon />
                            </IconContainer>
                            <DropdownText>Edit</DropdownText>
                          </ButtonDetails>
                        </Dropdown.Item>
                        <Dropdown.Item eventKey="1" className="dropDownText">
                          <ButtonDetails>
                            <IconContainer>
                              <Icons.DeleteIcon />
                            </IconContainer>
                            <DropdownText>Delete</DropdownText>
                          </ButtonDetails>
                        </Dropdown.Item>
                      </DropdownButton>
                    </Right>
                  </Accordion.Header>
                </Accordion.Item>
              </Accordion>
            </ImplementationTableContentHolder>
          ))
        : null}
    </StyledProfileBuilderTable>
  );
}

export { ImplementationTableContent };
