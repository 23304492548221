import { takeLatest, put, fork, call } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import http from "../../../../utils/http.service";
import config from "../../../../utils/request.config";
import { tomOrgRoadmapUrl } from "../../../../utils/serviceurl";
import { APIResponse, ErrorType } from "../../../../model/common";
import { emporgFetchAction } from "./emporgSlice";

export const getemporgDetails = async () =>
  http.GET(config.getConfig("", tomOrgRoadmapUrl, ""));

function* onroadMapgetDetailsAsync() {
  try {
    const response: AxiosResponse<APIResponse> = yield call(getemporgDetails);
    if (response.status === 200) {
      yield put(
        emporgFetchAction.emporgFetchSuccess({
          data: response.data.data,
          status: response.status,
        }),
      );
    }
  } catch (error) {
    yield put(
      emporgFetchAction.emporgFetchFailure({
        error: error as ErrorType,
      }),
    );
  }
}

function* onempOrgGetList() {
  yield takeLatest(
    emporgFetchAction.emporgFetchRequest,
    onroadMapgetDetailsAsync,
  );
}

export const emporgSaga = [fork(onempOrgGetList)];
