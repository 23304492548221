import { withAuthenticationRequired } from "@auth0/auth0-react";
import LoaderPage from "../pages/UtilPages/LoaderPage";

export function ProtectedRoute({ component }: any) {
  const Component = withAuthenticationRequired(component, {
    // eslint-disable-next-line react/no-unstable-nested-components
    onRedirecting: () => <LoaderPage />,
    returnTo: window.location.pathname,
  });

  return <Component />;
}
