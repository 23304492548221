import { takeLatest, put, fork, call } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { PayloadAction } from "@reduxjs/toolkit";
import http from "../../../../utils/http.service";
import config from "../../../../utils/request.config";
import { customDashboardFetchAction } from "./customDashboardSlice";
import { getCustomDashBoardData } from "../../../../utils/serviceurl";

import { APIResponse, ErrorType } from "../../../../model/common";

export const fetchCustomDashboardData = async (assessmentId: string) =>
  http.GET(
    config.getConfig("", `${getCustomDashBoardData}/${assessmentId}`, ""),
  );

function* onCustomDashboardDataAsync(action: PayloadAction<string>) {
  try {
    const response: AxiosResponse<APIResponse> = yield call(
      fetchCustomDashboardData,
      action.payload,
    );
    if (response.status === 200) {
      yield put(
        customDashboardFetchAction.getCustomDashboardDataSuccess({
          data: response.data.data,
          status: response.status,
        }),
      );
    }
  } catch (error) {
    yield put(
      customDashboardFetchAction.getCustomDashboardDataFailure({
        error: error as ErrorType,
      }),
    );
  }
}

function* onCustomDashboardDataList() {
  yield takeLatest(
    customDashboardFetchAction.getCustomDashboardDataRequest,
    onCustomDashboardDataAsync,
  );
}

export const customDashboardSaga = [fork(onCustomDashboardDataList)];
