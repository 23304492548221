import { ReactElement, useState, useEffect } from "react";
import styled from "styled-components";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { JobDescriptionList } from "./JobDescriptionList";
import { OrgStructure } from "./orgStructure";
import {
  orgChartUserListAction,
  orgMatchedProfileListAction,
  workStreamJobDescriptionAction,
} from "./workStreamJobDescriptionDetailsSlice";
import { useAppDispatch } from "../../../store/hooks";
import { JobDesc } from "../../../model/common";
import { RootState } from "../../../store/store";
import LoaderPage from "../../../pages/UtilPages/LoaderPage";
import { SelectUser } from "./orgStructure/selectUser";
import noData from "../../images/noData.png";
import { assignOrUnAssignResourceAction } from "./workStreamJobDescriptionSubmitSlice";
import { WsAssignedUser } from "../../../model/wsAddUser";
import { assignedUsersFetchAction } from "./getAssignedUsersSlice";
import errorHandling from "../../../utils/errorHandling";

const StyledWorkStreamTechnology = styled.div`
  display: grid;
  grid-template-columns: 42% 58%;
  height: 100%;
`;
const JobDescription = styled.div`
  padding: 28px;
  border-right: 1px solid ${({ theme }) => theme.baseColors.greyLight};
  overflow: hidden;
  .scrollbar {
    max-height: calc(100% - 40px);
  }
`;
const OrgStructureWrapper = styled.div`
  padding: 28px 28px 0;
  overflow: hidden;
  .scrollbar {
    max-height: calc(100% - 40px);
  }
`;

const Heading = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.bold};
  font-size: ${({ theme }) => theme.fontSizes[2]};
  line-height: ${({ theme }) => theme.lineHeight[3]};
  font-weight: normal;
  color: ${({ theme }) => theme.colors.text.black};
  padding-bottom: 21px;
`;
const SubHeading = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.bold};
  font-size: ${({ theme }) => theme.fontSizes[2]};
  line-height: ${({ theme }) => theme.lineHeight[3]};
  font-weight: normal;
  color: ${({ theme }) => theme.colors.text.black};
  padding: 16px 0;
`;
const OrgStructureHolder = styled.div`
  max-height: 500px;
  overflow: auto;
  border: 1px solid ${({ theme }) => theme.colors.border.bluishCyan};
  border-radius: 8px;
  background: ${({ theme }) => theme.baseColors.white};
  position: relative;
  padding: 20px;
  pre {
    z-index: 9;
    position: absolute;
    right: 0;
    bottom: 0;
    background: "rgba(0, 0, 0, .5)";
    padding: 20px;
    color: "white";
    display: flex;
    align-items: center;
    .btn-group {
      height: 48px;
      border-radius: 10px;
      filter: drop-shadow(0px 4px 10px rgba(31, 56, 98, 0.13));

      button {
        min-width: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: ${({ theme }) => theme.colors.bg.white};
        padding: 6px 11px 6px 9px;
        transition: 0.5s;
        border-radius: 10px 0 0 10px;
        & + button {
          border-radius: 0 10px 10px 0;
        }
        svg {
          color: ${({ theme }) => theme.colors.bg.tealishBlue}!important;
        }
        &:active,
        &:focus,
        &:hover {
          background-color: ${({ theme }) => theme.colors.bg.tealishBlue};
          box-shadow: none;
        }
      }
      button:focus svg,
      button:hover svg,
      button:active svg {
        color: ${({ theme }) => theme.colors.bg.white} !important;
      }
    }
    .scrollbar {
      max-height: 100%;
    }
  }
  .scrollbar {
    max-height: 100%;

    div {
      overflow: initial;
    }
  }
  .react-transform-wrapper {
    width: 100% !important;
    overflow: unset;
  }
  .react-transform-component {
    width: 100% !important;
  }
`;
const SelectedUsersHolder = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.border.bluishCyan};
  border-radius: 8px;
  background: ${({ theme }) => theme.baseColors.white};
  margin-bottom: 20px;
  padding: 16px;
  padding-bottom: 8px;
  overflow: hidden;
  &::-webkit-scrollbar {
    width: 5px;
    height: 6px;
    cursor: pointer;
  }
  &::-webkit-scrollbar-track {
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
  .scrollbar {
    height: 100px !important;
  }
  display: flex;

  gap: 16px;
  flex-wrap: wrap;
`;

type SelectOrganizationProps = {
  assessmentId: string | null;
  setSelectedJob: any;
  selectedJob: string;
  workstreamid: string;
  assignedUserList: any;
  setAssignedUserList: any;
  wrkStreamStartDate: any;
  wrkStreamEndDate: any;
  setDetailsSubmitDisable: any;
};

function SelectOrganization({
  assessmentId,
  selectedJob,
  setSelectedJob,
  workstreamid,
  assignedUserList,
  setAssignedUserList,
  wrkStreamStartDate,
  wrkStreamEndDate,
  setDetailsSubmitDisable,
}: SelectOrganizationProps): ReactElement {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const jobAttributes: JobDesc[] = useSelector(
    (state: RootState) => state.workStreamJobDescriptionDetails.data,
  );
  const dataFromStoreJobDescription = useSelector(
    (state: RootState) => state.workStreamJobDescriptionDetails,
  );

  // ...... commented only for mvp .....
  // const empOrgListStore = useSelector((state: RootState) => state.empOrgList);
  // useEffect(() => {
  //   dispatch(emporgFetchAction.emporgFetchRequest());
  //   return () => {
  //     dispatch(emporgFetchAction.reset());
  //   };
  // }, []);

  const orgProfiles = useSelector((state: RootState) => state.orgProfiles);
  const orgMatchedProfiles = useSelector(
    (state: RootState) => state.orgMatchedProfiles,
  );
  const [jobDescriptionList, setJobDescriptionList] = useState<any | []>();
  const [profileList, setProfileList] = useState<any | []>();
  const [itemClicked, setItemClicked] = useState<boolean>(false);
  const [resourceAssignApiStatus, setAssignApiStatus] =
    useState<boolean>(false);
  const [updatedAssignedArray, setBeforeApiAssignedList] =
    useState<WsAssignedUser[]>();
  const [assignUserUpdated, setAssignUserUpdate] = useState<boolean>(true);

  // Modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const assignOrUnassignDataUpdate = useSelector(
    (state: RootState) => state.assignOrUnAssignResources,
  );

  useEffect(() => {
    return () => {
      dispatch(orgMatchedProfileListAction.reset());
      dispatch(assignedUsersFetchAction.reset());
    };
  }, []);

  useEffect(() => {
    if (assessmentId !== null) {
      const jobRequestData = {
        workStreamId: workstreamid,
        assessmentId,
      };
      dispatch(
        workStreamJobDescriptionAction.workStreamJobDescriptionRequest(
          jobRequestData,
        ),
      );
    }
    dispatch(orgChartUserListAction.getProfilesRequest());
  }, []);

  useEffect(() => {
    if (assessmentId !== null) {
      const jobRequestData = {
        workStreamId: workstreamid,
        assessmentId,
      };
      dispatch(
        assignedUsersFetchAction.assignedUsersFetchRequest(jobRequestData),
      );
    }
  }, [!assignUserUpdated]);

  const getAssignedUserList = useSelector(
    (state: RootState) => state.getAssignedUserList,
  );

  const assignOwnerUpdates = useSelector(
    (state: RootState) => state.assignOwner,
  );

  useEffect(() => {
    if (getAssignedUserList?.error) {
      errorHandling(getAssignedUserList?.status, navigate);
    }
    if (assignOwnerUpdates?.error) {
      errorHandling(assignOwnerUpdates?.status, navigate);
    }
    if (orgMatchedProfiles?.error) {
      errorHandling(orgMatchedProfiles?.status, navigate);
    }
    if (dataFromStoreJobDescription?.error) {
      errorHandling(dataFromStoreJobDescription?.status, navigate);
    }
  }, [
    getAssignedUserList?.error,
    assignOwnerUpdates?.error,
    orgMatchedProfiles?.error,
    dataFromStoreJobDescription?.error,
  ]);

  useEffect(() => {
    if (jobAttributes?.length > 0) {
      setJobDescriptionList(jobAttributes);
    }
  }, [jobAttributes]);

  useEffect(() => {
    if (orgProfiles?.data?.length > 0) {
      setProfileList(orgProfiles.data);
    }
  }, [orgProfiles]);

  useEffect(() => {
    if (Object.keys(selectedJob).length !== 0) {
      dispatch(orgMatchedProfileListAction.reset());
      const currentlySelected: any = selectedJob;
      if (
        currentlySelected?.roleId !== undefined &&
        currentlySelected?.roleId !== ""
      ) {
        dispatch(
          orgMatchedProfileListAction.getMatchedProfilesRequest({
            experience: currentlySelected?.experience,
            roleId: currentlySelected?.roleId,
            workStreamId: workstreamid,
            assessmentId,
          }),
        );
      }
    }
  }, [selectedJob]);

  useEffect(() => {
    if (itemClicked && Object.entries(selectedJob)?.length !== 0) {
      if (
        orgMatchedProfiles?.status === 200 &&
        orgMatchedProfiles?.data?.length === 0
      ) {
        setShow(true);
      } else {
        setShow(false);
      }
    }
  }, [itemClicked, orgMatchedProfiles?.loading]);

  useEffect(() => {
    if (assignOrUnassignDataUpdate.status === 200) {
      setAssignApiStatus(true);
      setAssignUserUpdate(false);
    }
  }, [assignOrUnassignDataUpdate.loading]);

  useEffect(() => {
    if (resourceAssignApiStatus && updatedAssignedArray) {
      const updatedArray = [...updatedAssignedArray];
      setAssignedUserList(updatedArray);
    }
  }, [resourceAssignApiStatus, updatedAssignedArray]);

  useEffect(() => {
    if (getAssignedUserList.status === 200) {
      setAssignedUserList(getAssignedUserList?.data);
    }
  }, [getAssignedUserList.loading]);

  useEffect(() => {
    if (assignedUserList?.length > 0) {
      const ownerStatusCheck = assignedUserList?.find(
        (obj: any) => obj.isOwner,
      );
      if (
        ownerStatusCheck !== undefined &&
        Object.keys(ownerStatusCheck)?.length !== 0
      ) {
        setDetailsSubmitDisable(false);
      } else {
        setDetailsSubmitDisable(true);
      }
    } else {
      setDetailsSubmitDisable(false);
    }
  }, [assignedUserList]);

  const assignUnassignResourceHandler = (
    resorceArray: WsAssignedUser[],
    type: any,
  ) => {
    setAssignUserUpdate(true);
    const requestData: any = {
      type,
      assessmentId,
      workStreamId: workstreamid,
      assignedUsers: resorceArray,
    };
    dispatch(
      assignOrUnAssignResourceAction.assignOrUnAssignResourceRequest(
        requestData,
      ),
    );
  };

  const deleteUserHandle = (id: string) => {
    dispatch(assignOrUnAssignResourceAction.reset());
    const userArrayClone: WsAssignedUser[] = [...assignedUserList];
    const newUserData: WsAssignedUser[] = userArrayClone.filter(
      (item: WsAssignedUser) => item.profileId !== id,
    );
    setBeforeApiAssignedList(newUserData);
    const unassignedResource: any = userArrayClone.find(
      (item: WsAssignedUser) => item.profileId === id,
    );
    assignUnassignResourceHandler(unassignedResource, "unassign");
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {dataFromStoreJobDescription?.loading || orgProfiles?.loading ? (
        <LoaderPage />
      ) : (
        <StyledWorkStreamTechnology>
          {jobDescriptionList !== undefined ? (
            <JobDescription>
              <Heading>Job description</Heading>
              <Scrollbars autoHide className="scrollbar">
                <JobDescriptionList
                  jobDescriptionList={jobDescriptionList}
                  setSelectedJob={setSelectedJob}
                  setItemClicked={setItemClicked}
                />
              </Scrollbars>
            </JobDescription>
          ) : null}

          <OrgStructureWrapper>
            <Heading>Org Structure</Heading>
            <OrgStructureHolder>
              {/* // ...... commented only for mvp start.....
           {empOrgListStore.loading || empOrgListStore.data.length === 0 ? (
              <LoaderPage />
             ) : (
            <OrgStructure empOrgListStore={empOrgListStore.data[0]} />}

            // ...... commented for mvp end..... */}

              {orgProfiles.loading ? (
                <LoaderPage />
              ) : (
                <OrgStructure
                  profileList={profileList}
                  orgMatchedProfiles={orgMatchedProfiles?.data}
                  assignedUserList={assignedUserList}
                  setAssignedUserList={setAssignedUserList}
                  wrkStreamStartDate={wrkStreamStartDate}
                  wrkStreamEndDate={wrkStreamEndDate}
                  assignUnassignResourceHandler={assignUnassignResourceHandler}
                  jobDescriptionList={jobDescriptionList}
                />
              )}
            </OrgStructureHolder>
            <SubHeading>Selected Resources</SubHeading>
            {/* Selected Users */}
            <SelectedUsersHolder>
              {assignedUserList.length !== 0 ? (
                assignedUserList?.map((item: any) => {
                  return (
                    <SelectUser
                      key={item?.pr}
                      item={item}
                      deleteUser={deleteUserHandle}
                      assignedUserList={assignedUserList}
                      setAssignedUserList={setAssignedUserList}
                    />
                  );
                })
              ) : (
                <p> Assigned User is not available</p>
              )}
            </SelectedUsersHolder>
          </OrgStructureWrapper>
          {/* No matches popup */}
          <Modal
            show={show}
            onHide={handleClose}
            centered
            className="noDataMatch"
          >
            {/* Popup close */}
            <Modal.Header closeButton />
            <Modal.Body>
              <img src={noData} alt="map" />
              No matches available
            </Modal.Body>
          </Modal>
        </StyledWorkStreamTechnology>
      )}
    </>
  );
}

export { SelectOrganization };
