import { takeLatest, put, fork, call } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { PayloadAction } from "@reduxjs/toolkit";
import http from "../../../utils/http.service";
import config from "../../../utils/request.config";
import { responsibilitiesListAction } from "./responsibilitiesSlice";
import { getAllResponsibilites } from "../../../utils/serviceurl";

export const fetchResponsibilitiesList = async (data: any) =>
  http.GET(
    config.getConfig(
      "",
      `${getAllResponsibilites}?designationId=${data.roleId.join(
        "&designationId=",
      )}&searchKey=${data.searchKey}`,
    ),
  );

function* onResponsibilitiesListAsync(action: PayloadAction<any>) {
  try {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const response: AxiosResponse<any> = yield call(
      fetchResponsibilitiesList,
      action.payload,
    );
    if (response.status === 200) {
      yield put(
        responsibilitiesListAction.responsibilitiesListSuccess({
          data: response.data.data,
          status: response.status,
        }),
      );
    }
  } catch (error) {
    // console.log(error);
  }
}

function* getResponsibilitiesList() {
  yield takeLatest(
    responsibilitiesListAction.responsibilitiesListRequest,
    onResponsibilitiesListAsync,
  );
}

export const responsibilitiesSaga = [fork(getResponsibilitiesList)];
