import { takeLatest, put, fork, call } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { PayloadAction } from "@reduxjs/toolkit";
import http from "../../../utils/http.service";
import config from "../../../utils/request.config";
import { skillsListAction } from "./skillsSlice";
import { getAllSkills } from "../../../utils/serviceurl";

export const fetchSkillsList = async (data: any) =>
  http.GET(
    config.getConfig(
      "",
      `${getAllSkills}?designationId=${data.roleId.join(
        "&designationId=",
      )}&searchKey=${data.searchKey}`,
    ),
  );

function* onskillsListAsync(action: PayloadAction<any>) {
  try {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const response: AxiosResponse<any> = yield call(
      fetchSkillsList,
      action.payload,
    );
    if (response.status === 200) {
      yield put(
        skillsListAction.skillsListSuccess({
          data: response.data.data,
          status: response.status,
        }),
      );
    }
  } catch (error) {
    // console.log(error);
  }
}

function* getSkillsList() {
  yield takeLatest(skillsListAction.skillsListRequest, onskillsListAsync);
}

export const skillsSaga = [fork(getSkillsList)];
