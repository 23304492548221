import React, { ReactElement } from "react";
import styled from "styled-components";

const StyledSeparator = styled.div`
  display: flex;
  padding-left: 0;
  list-style: none;
  margin: 42px 0;
  justify-content: center;
  position: relative;
  width: 70%;
  line-height: normal;
  height: 1px;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
  }
`;

function Separator(): ReactElement {
  return <StyledSeparator />;
}

export { Separator };
