import React, { ReactElement, useEffect, useState } from "react";
import { Label, Checkbox } from "@rebass/forms";
import styled from "styled-components";

const StyledPageHeader = styled.thead`
  display: flex;
  padding: 18px 18px 14px;
  justify-content: space-between;
`;
const Heading = styled.th`
  font-size: ${({ theme }) => theme.fontSizes[1]};
  font-family: ${({ theme }) => theme.fontFamily.bold};
  color: ${({ theme }) => theme.colors.text.tealishBlue};
  display: flex;
  align-items: center;
  text-transform: uppercase;
  &:nth-child(2n) {
    width: 118px;
    flex: 0 0 118px;
    margin-left: auto;
    margin-right: 72px;
  }
  &:nth-child(3n) {
    width: 118px;
    flex: 0 0 118px;
  }
  label {
    display: flex;
    align-items: center;
    white-space: nowrap;
    cursor: pointer;
    div {
      margin-right: 18px;
    }
  }
  input:focus ~ svg {
    background-color: transparent;
  }
  svg {
    width: 18px;
    height: 18px;
    margin: 0;
  }
  svg {
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect x='0.375' y='0.375' width='17.25' height='17.25' rx='2.625' fill='%23F5F6FD' stroke='%23ADB1B7' stroke-width='0.75'/%3e%3c/svg%3e ");
    path {
      opacity: 0;
    }
  }
  input:checked ~ svg {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='3' fill='%234182F1'/%3e%3cpath d='M5.625 9.56263L7.7142 11.8125L12.9375 6.1875' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e ");
  }
`;
const defaultProps = {
  title: "",
  title2: "",
  title3: "",
  dataList: [],
  tab: "",
};
export interface DataListProps {
  active?: boolean;
}
export interface PageHeaderProps {
  title?: string;
  title2?: string;
  title3?: string;
  formik?: any;
  dataList?: Array<DataListProps>;
  tab?: string;
  allChecked: boolean;
  setAllchecked: any;
  setArray: any;
}

function ProfileBuilderTableHead({
  title,
  title2,
  title3,
  formik,
  dataList,
  tab,
  allChecked,
  setAllchecked,
  setArray,
}: PageHeaderProps): ReactElement {
  const [newArray, setNewArray] = useState([]);
  useEffect(() => {
    const listKnow: any = dataList;
    setNewArray(listKnow);
  }, [dataList]);

  useEffect(() => {
    if (newArray !== undefined && newArray?.length > 0) {
      const matchedData = newArray?.find((currentlySelected: any) => {
        if (!currentlySelected.active) {
          return true;
        }
        return false;
      });
      if (matchedData && Object.keys(matchedData).length !== 0) {
        setAllchecked(false);
      } else {
        setAllchecked(true);
      }
    } else {
      setAllchecked(false);
    }
  }, [newArray]);

  useEffect(() => {
    if (allChecked && dataList !== undefined && dataList?.length > 0) {
      const setToCheckedAll: any = dataList.map((topping: any) => ({
        ...topping,
        active: true,
      }));
      setNewArray(setToCheckedAll);
    } else if (!allChecked && dataList !== undefined && dataList?.length > 0) {
      const allSelectCheck = dataList.find(({ active }: any) => !active);
      if (allSelectCheck === undefined) {
        const setToUnCheckedAll: any = dataList.map((topping: any) => ({
          ...topping,
          active: false,
        }));
        setNewArray(setToUnCheckedAll);
      }
    }
  }, [allChecked]);

  useEffect(() => {
    if (newArray?.length > 0) {
      if (tab === "knowledge") {
        formik.setFieldValue("knowledgeList", newArray);
        setArray(newArray);
      } else if (tab === "skills") {
        formik.setFieldValue("skillsList", newArray);
        setArray(newArray);
      } else if (tab === "abliities") {
        formik.setFieldValue("abliities", newArray);
        setArray(newArray);
      } else if (tab === "responsiblities") {
        formik.setFieldValue("responsiblities", newArray);
        setArray(newArray);
      }
    }
  }, [newArray]);

  const checkAll = () => {
    setAllchecked(!allChecked);
  };
  return (
    <StyledPageHeader>
      <Heading>{title}</Heading>
      <Heading>{title2}</Heading>
      <Heading>
        <Label>
          <Checkbox
            id="selectAll"
            name="selectAll"
            onChange={() => checkAll()}
            checked={allChecked}
            disabled={dataList?.length === 0}
          />
          {title3}
        </Label>
      </Heading>
    </StyledPageHeader>
  );
}
ProfileBuilderTableHead.defaultProps = defaultProps;
export { ProfileBuilderTableHead };
