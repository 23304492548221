import { ReactElement, useEffect, useState } from "react";
import styled from "styled-components";
import { IPreviewProps } from "react-dropzone-uploader";
import { Button, ProgressBar } from "react-bootstrap";
import { useSelector } from "react-redux";
import icons from "../../../icons";
import { niceBytes } from "../../../utils/bytesConversion";
import { percentageCalc } from "../../../utils/uploading";
import ExcelIcon from "../../../images/ExcelIcon.svg";
import { useAppDispatch } from "../../../../store/hooks";
import { workSteramTecAction } from "../workStreamTechnologySlice";
import { RootState } from "../../../../store/store";

const InfoNoteWrapper = styled.div`
  margin-top: 10px;
  min-height: 15px;
  width: 100%;
`;
const InfoNote = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.text.lightGrey1};
  font-size: ${({ theme }) => theme.fontSizes[0]};
  line-height: ${({ theme }) => theme.lineHeight[0]};
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  &.successFully {
    color: ${({ theme }) => theme.colors.text.black};
  }
  svg {
    margin-right: 6px;
  }
`;
const ActionButtons = styled.div`
  border: none;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  button {
    margin: 0;
  }
  button {
    font-family: ${({ theme }) => theme.fontFamily.bold};
    font-size: ${({ theme }) => theme.fontSizes[1]};
    line-height: ${({ theme }) => theme.lineHeight[3]};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    text-transform: none;
    min-height: 45px;
    padding: 12px 24px;
    transition: background-color 1s;
    &[disabled] {
      background: ${({ theme }) => theme.baseColors.greyLight};
      border-color: ${({ theme }) => theme.baseColors.greyLight};
      color: ${({ theme }) => theme.colors.text.darkGrey};
      opacity: 1;
    }

    &.btn-secondary {
      background: ${({ theme }) => theme.baseColors.greyLight};
      border-color: ${({ theme }) => theme.baseColors.greyLight};
      color: ${({ theme }) => theme.colors.text.darkGrey};
      &:hover {
        background: ${({ theme }) => theme.colors.bg.darkGrey};
        border-color: ${({ theme }) => theme.colors.bg.darkGrey};
        color: ${({ theme }) => theme.colors.text.white};
      }
    }
    & + button {
      margin-left: 16px;
    }
  }
`;
const FileDetailsPreview = styled.div`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.border.default};
  display: flex;
  border-radius: 8px;
  padding: 19px 20px;
`;
const ProgressBarHolder = styled.div`
  width: 100%;
  padding: 0 0 0 16px;
  z-index: 1;
  overflow: hidden;
  .progress {
    height: 11px;
    border-radius: 50px;
    .progress-bar-striped {
      border-radius: 50px;
    }
  }
`;
const FileINfo = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const FileName = styled.div`
  width: 100%;
  text-align: left;
  color: ${({ theme }) => theme.baseColors.black};
  font-size: ${({ theme }) => theme.fontSizes[0]};
  line-height: ${({ theme }) => theme.lineHeight[0]};
  font-family: ${({ theme }) => theme.fontFamily.medium};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;
const FileSize = styled.div`
  width: 100%;
  text-align: right;
  color: ${({ theme }) => theme.baseColors.black};
  font-size: ${({ theme }) => theme.fontSizes[0]};
  line-height: ${({ theme }) => theme.lineHeight[2]};
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
`;

interface Props extends IPreviewProps {
  assessmentId: string;
  setFileUploading: (val: boolean) => any;
  setRecommendationList?: (val: []) => any;
  recommendationDataList?: any;
  workstreamid?: any;
}

function PreviewComponent(props: Props): ReactElement {
  const {
    assessmentId,
    setFileUploading,
    setRecommendationList,
    recommendationDataList,
    workstreamid,
    meta,
    fileWithMeta,
    files,
  } = props;

  const dispatch = useAppDispatch();
  // const [upload, setUpload] = useState(false);
  const TechSubmit = useSelector((state: RootState) => state.workStreamTech);
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    if (TechSubmit.loading === true) {
      setDisable(true);
    }
  }, [TechSubmit]);

  const removeUploaded = () => {
    fileWithMeta.remove();
    if (setRecommendationList) {
      setRecommendationList([]);
    }
    setFileUploading(false);
    dispatch(workSteramTecAction.reset());
  };

  const UploadFile = () => {
    const formData = new FormData();
    formData.append("workStreamId", workstreamid);
    formData.append("file", files?.[0]?.file);
    formData.append("assessmentId", assessmentId);
    dispatch(workSteramTecAction.workSteramTecRequest(formData));
    // setUpload(true);
  };

  return (
    <>
      <FileDetailsPreview>
        <img src={ExcelIcon} alt="Logo" />

        <ProgressBarHolder>
          <FileINfo>
            <FileName> {meta.name}</FileName>
            <FileSize> {niceBytes(meta.size)}</FileSize>
          </FileINfo>
          <ProgressBar animated now={percentageCalc(meta.status)} />
        </ProgressBarHolder>
      </FileDetailsPreview>
      <InfoNoteWrapper>
        {meta.status === "done" && TechSubmit?.status === 200 && (
          /* Upload successfully info */
          <InfoNote className="successFully">
            <icons.tick1 />
            Uploaded Successfully..!!
          </InfoNote>
        )}
      </InfoNoteWrapper>
      <ActionButtons>
        {recommendationDataList?.length > 0 ? (
          // dont use the classname enableRemoveButton and dont remove
          <Button
            className="enableRemoveButton"
            variant="outline-primary"
            onClick={removeUploaded}
          >
            Remove File
          </Button>
        ) : (
          <>
            {" "}
            <Button
              variant="outline-primary"
              onClick={removeUploaded}
              className="enableRemoveButton"
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={UploadFile}
              disabled={meta.status !== "done" || disable}
            >
              Upload
            </Button>
          </>
        )}
      </ActionButtons>
    </>
  );
}

export { PreviewComponent };
