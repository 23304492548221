import { takeLatest, put, fork, call } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { PayloadAction } from "@reduxjs/toolkit";
import http from "../../../../utils/http.service";
import config from "../../../../utils/request.config";
import { ErrorType } from "../../../../model/common";
import { getImplementaionList } from "../../../../utils/serviceurl";
import { implementationFetchAction } from "./ImplementationListSlice";

export const fetchImplementaionDetails = async (assesmentid: string) =>
  http.GET(config.getConfig("", `${getImplementaionList}/${assesmentid}`, ""));

function* onImplementaiongetDetailsAsync(
  action: PayloadAction<{
    assesmentid: string;
  }>,
) {
  try {
    const response: AxiosResponse<any> = yield call(
      fetchImplementaionDetails,
      action.payload.assesmentid,
    );
    if (response.status === 200) {
      yield put(
        implementationFetchAction.implemenationFetchSuccess({
          data: response.data.data,
          status: response.status,
        }),
      );
    }
  } catch (error) {
    yield put(
      implementationFetchAction.implemenationFetchFailure({
        error: error as ErrorType,
      }),
    );
  }
}

function* onImplementationGetList() {
  yield takeLatest(
    implementationFetchAction.implementaionFetchRequest,
    onImplementaiongetDetailsAsync,
  );
}

export const implementationSaga = [fork(onImplementationGetList)];
