import { ReactElement, useState } from "react";
import { Accordion } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { StyledSidebar, InnerContainer, IconButtons } from "./styles";
import Icons from "../../icons";
import { SidebarAccordion } from "./sidebarAccordion";

const MainContainer = styled.div`
  .accordion-item {
    border: none;
  }
  .title {
    display: flex;
    align-items: center;
    .icons-container {
      padding-right: 14px;
    }
    svg {
      opacity: 0.5;
      color: ${({ theme }) => theme.colors.bg.black6};
    }
    .title-name {
      font-family: ${({ theme }) => theme.fontFamily.medium};
      font-style: normal;
      font-weight: normal;
      font-size: ${({ theme }) => theme.fontSizes[16]};
      line-height: ${({ theme }) => theme.lineHeight[14]};
      color: #000000;
      opacity: 0.7;
    }
  }
  .accordion-button:not(.collapsed) {
    color: ${({ theme }) => theme.colors.bg.white};
    background-color: ${({ theme }) => theme.colors.bg.blue};
    .title-name {
      color: ${({ theme }) => theme.colors.bg.white};
      opacity: 1;
    }
    svg {
      color: ${({ theme }) => theme.colors.bg.white};
      opacity: 1;
    }
  }
  button {
    padding: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 46px;
    margin-bottom: 10px;

    &:focus,
    &:hover,
    &:active {
      border: none;
      box-shadow: none;
      background-color: ${({ theme }) => theme.colors.bg.blue};
      svg {
        color: ${({ theme }) => theme.colors.bg.white};
        opacity: 1;
      }
      .title-name {
        color: ${({ theme }) => theme.colors.bg.white};
        opacity: 1;
      }
    }
    &:after {
      background-image: none !important;
    }
  }
  .accordion-innercontainer {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    ul {
      list-style-type: none;
      width: 100%;
      padding-left: 0;
    }
    li {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px;
      border-radius: 5px;
      cursor: pointer;
      p {
        font-family: ${({ theme }) => theme.fontFamily.medium};
        font-style: normal;
        font-weight: normal;
        font-size: ${({ theme }) => theme.fontSizes[16]};
        line-height: ${({ theme }) => theme.lineHeight[14]};
        color: #000000;
        opacity: 0.7;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      svg {
        color: ${({ theme }) => theme.colors.bg.black6};
        margin-right: 10px;
        width: 10px;
      }
      &:focus,
      &:hover,
      &:active {
        border: none;
        box-shadow: none;
        background-color: ${({ theme }) => theme.colors.bg.blue};

        svg {
          color: ${({ theme }) => theme.colors.bg.white};
        }
        p {
          color: ${({ theme }) => theme.colors.bg.white};
          opacity: 1;
        }
      }
    }
  }
`;

type SidebarProps = {
  Navkey: string;
};
function Sidebar({ Navkey }: SidebarProps): ReactElement {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const permissionData = useSelector(
    (state: RootState) => state.getTokenPermission,
  );

  const Menus = [
    {
      id: "Home",
      title: "Dashboard",
      icon: <Icons.DashboardIcon />,
      subMenu: false,
      roles: [
        "Admin",
        "Auditor",
        "IS Team",
        "Editor",
        "HR",
        "Reviewer",
        "Technology",
      ], // Add roles that should see this menu
    },
    {
      id: "Assessment",
      title: "Assessment",
      icon: <Icons.AssessmentIcon />,
      subMenu: false,
      roles: ["IS Team", "Admin", "Editor", "HR", "Reviewer", "Technology"], // Only IS Team and Admin should see this menu
    },
    {
      id: "Audit",
      title: "Audit",
      icon: <Icons.AssessmentIcon />,
      subMenu: false,
      roles: ["Auditor", "Admin", "Editor", "HR", "Reviewer", "Technology"], // Only Auditor and Admin should see this menu
    },
    {
      id: "Strategy",
      title: "Strategy",
      icon: <Icons.StrategyIcon />,
      subMenu: false,
      roles: [
        "Admin",
        "Auditor",
        "IS Team",
        "Editor",
        "HR",
        "Reviewer",
        "Technology",
      ],
    },
    {
      id: "Roadmap",
      title: "Roadmap",
      icon: <Icons.RoadMapIcon />,
      subMenu: false,
      roles: [
        "Admin",
        "Auditor",
        "IS Team",
        "Editor",
        "HR",
        "Reviewer",
        "Technology",
      ],
    },
    {
      id: "TOM",
      title: "TOM ",
      icon: <Icons.TOMIcon />,
      subMenu: false,
      roles: [
        "Admin",
        "Auditor",
        "IS Team",
        "Editor",
        "HR",
        "Reviewer",
        "Technology",
      ],
    },
    {
      id: "Team",
      title: "Teams",
      icon: <Icons.TeamsIcon />,
      subMenu: false,
      roles: [
        "Admin",
        "Auditor",
        "IS Team",
        "Editor",
        "HR",
        "Reviewer",
        "Technology",
      ],
    },
    {
      id: "Implementation",
      title: "Implementation",
      icon: <Icons.ImplementationIcon />,
      subMenu: false,
      roles: [
        "Admin",
        "Auditor",
        "IS Team",
        "Editor",
        "HR",
        "Reviewer",
        "Technology",
      ],
    },
    {
      id: "Organization",
      title: "Organization",
      icon: <Icons.OrganizationIcon />,
      subMenu: false,
      roles: [
        "Admin",
        "Auditor",
        "IS Team",
        "Editor",
        "HR",
        "Reviewer",
        "Technology",
      ],
    },
  ];

  function filterMenusByRole(role: string) {
    return Menus.filter((menu) => menu.roles.includes(role));
  }

  const filteredMenus = filterMenusByRole(permissionData?.role);

  function ReturnKey(title: string, Navkey: string) {
    if (title === Navkey) {
      return true;
    }
    return false;
  }

  const ButtonClickNavigation = (Id: string) => {
    if (Id === "Home") {
      navigate("/Dashboard/Home");
    } else if (Id === "Assessment") {
      navigate("/Dashboard/Assessment");
    } else if (Id === "Audit") {
      navigate("/Dashboard/Audit");
    } else if (Id === "Strategy") {
      navigate("/Dashboard/Strategy");
    } else if (Id === "Roadmap") {
      navigate("/Dashboard/Roadmap");
    } else if (Id === "TOM") {
      navigate("/Dashboard/TOM");
    } else if (Id === "Team") {
      navigate("/Dashboard/Team");
    } else if (Id === "Organization") {
      navigate("/Dashboard/Organization");
    } else if (Id === "Implementation") {
      navigate("/Dashboard/Implementation");
    }
  };

  return (
    <StyledSidebar>
      <InnerContainer className={`${open ? "opened" : "not-opened"}`}>
        <button
          type="button"
          className={`${open && "rotate"} sidebar-navigation-button`}
          onClick={() => setOpen(!open)}
        >
          <Icons.SideBarIcon />
        </button>
        {open ? (
          <>
            <MainContainer>
              <Accordion activeKey={Navkey}>
                {filteredMenus.map((menu) => {
                  return (
                    <SidebarAccordion
                      key={menu.id}
                      item={menu}
                      ButtonClickNavigation={ButtonClickNavigation}
                    />
                  );
                })}
              </Accordion>
            </MainContainer>
          </>
        ) : (
          <div>
            {filteredMenus.map((menu) => (
              <IconButtons
                type="button"
                key={menu.id}
                title={menu.title}
                active={ReturnKey(menu.id, Navkey)}
                onClick={() => {
                  ButtonClickNavigation(menu.id);
                }}
              >
                {menu.icon}
              </IconButtons>
            ))}
          </div>
        )}
      </InnerContainer>
    </StyledSidebar>
  );
}

export { Sidebar };
