import { takeLatest, put, fork, call } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { PayloadAction } from "@reduxjs/toolkit";
import { draftReviewAssessDetailViewAction } from "./maturityAssessDetailsSlice";
import { APIResponse, ErrorType } from "../../../../model/common";
import { draftReviewAssessDetails } from "../../../../utils/serviceurl";
import config from "../../../../utils/request.config";
import http from "../../../../utils/http.service";

export const fetchAssessmenDetailsResult = async (assesmentId: string) =>
  http.GET(
    config.getConfig("", `${draftReviewAssessDetails}/${assesmentId}`, ""),
  );

function* onAssementDetailViewAsync(action: PayloadAction<string>) {
  try {
    const response: AxiosResponse<APIResponse> = yield call(
      fetchAssessmenDetailsResult,
      action.payload,
    );
    if (response.status === 200) {
      yield put(
        draftReviewAssessDetailViewAction.draftReviewAssessDetailViewSuccess({
          data: response.data.data,
          status: response.status,
        }),
      );
    }
  } catch (error) {
    yield put(
      draftReviewAssessDetailViewAction.draftReviewAssessDetailViewFailure({
        error: error as ErrorType,
      }),
    );
  }
}

function* onAssessmentDetailsGet() {
  yield takeLatest(
    draftReviewAssessDetailViewAction.draftReviewAssessDetailViewRequest,
    onAssementDetailViewAsync,
  );
}

export const draftReviewAssessDetailViewSaga = [fork(onAssessmentDetailsGet)];
