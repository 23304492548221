import { ReactElement, DragEvent, MouseEvent } from "react";
import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import { Tooltip } from "react-tippy";
import {
  AtrributesDataTalent,
  AttributesTalent,
  TalentsAddedProps,
} from "../../../../../model/wSTalent";
import { DropButton } from "../../../../components/dropButton";
import { DragAndDropInstructions } from "../DragAndDropInstruction";
import {
  RolesHeader,
  RolesContainer,
  RolesHeading,
  HeaderContent,
  ExperienceDetails,
  DetailsContainer,
  AttributeHeading,
  AttributeDataDetailsContainer,
  DatasDetails,
  RepeatTalentsSection,
  ButtonDetailsContainer,
} from "./styles";

type RolesDetailsProps = {
  id: string;
  item: TalentsAddedProps;
  dropAttributes: (ev: DragEvent, id: string) => void;
  dublicateRoles: (Talentid: string) => void;
  deleteRoles: (Talentid: string) => void;
  deleteAttribute: (
    ev: MouseEvent,
    Talentid: string,
    attribtueid: string,
    attributeName: string,
    attributeitemid: string,
  ) => void;
  allowed: (ev: DragEvent) => void;
  UpdateNoOfRolesPlus: (_id: string) => void;
  UpdateNoOfRolesMinus: (_id: string) => void;
};
function RolesDetailsContainer({
  item,
  dropAttributes,
  id,
  allowed,
  deleteAttribute,
  dublicateRoles,
  deleteRoles,
  UpdateNoOfRolesPlus,
  UpdateNoOfRolesMinus,
}: RolesDetailsProps): ReactElement {
  return (
    <RolesContainer
      onDrop={(ev: DragEvent) => {
        dropAttributes(ev, id);
      }}
      onDragOver={(ev) => {
        allowed(ev);
      }}
      key={id}
    >
      {Object.prototype.hasOwnProperty.call(item, "attributes") ? (
        <>
          <RolesHeader>
            <HeaderContent>
              {item.no_of_talents === undefined ? (
                <RolesHeading>{item.roleName}</RolesHeading>
              ) : (
                <RolesHeading>
                  {`${item.roleName} (${item.no_of_talents}) `}
                </RolesHeading>
              )}

              {item.attributes.map((attribute: AttributesTalent) => {
                if (
                  attribute.name === "Experience" ||
                  attribute.name === "Acquisition"
                ) {
                  return (
                    <ExperienceDetails key={attribute._id}>
                      <DropButton
                        id={attribute.data[0]._id}
                        attribtueid={attribute._id}
                        Talentid={id}
                        attributeName={attribute.name}
                        title={attribute.data[0].ksaId as string}
                        mainAttribute
                        experience={attribute.name === "Experience"}
                        twosideMust={attribute.data[0].mustHave}
                        twosideGood={attribute.data[0].goodToHave}
                        deleteAttribute={deleteAttribute}
                        key={attribute._id}
                      />
                    </ExperienceDetails>
                  );
                }
                return null;
              })}
            </HeaderContent>
            <DatasDetails>
              <RepeatTalentsSection>
                <ButtonDetailsContainer>
                  <Tooltip
                    title="Remove member"
                    position="top"
                    trigger="mouseenter"
                    theme="light"
                    className="tippy"
                  >
                    <Button
                      className="buttons decrement-button"
                      onClick={() => UpdateNoOfRolesMinus(id)}
                    >
                      -
                    </Button>
                  </Tooltip>
                  <Tooltip
                    title="Add member"
                    position="top"
                    trigger="mouseenter"
                    theme="light"
                    className="tippy"
                  >
                    <Button
                      className="buttons  increment-button"
                      onClick={() => UpdateNoOfRolesPlus(id)}
                    >
                      +
                    </Button>
                  </Tooltip>
                </ButtonDetailsContainer>
              </RepeatTalentsSection>

              <DropdownButton title="" align="end" className="threeDotsButton">
                <Dropdown.Item
                  eventKey="1"
                  onClick={() => dublicateRoles(id)}
                  className="dropDownText"
                >
                  Duplicate
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="1"
                  onClick={() => deleteRoles(id)}
                  className="dropDownText"
                >
                  Delete
                </Dropdown.Item>
              </DropdownButton>
            </DatasDetails>
          </RolesHeader>
          <DetailsContainer>
            {item.attributes.length !== 0 ? (
              <>
                {item.attributes.map((attribute: AttributesTalent) => {
                  if (
                    attribute.name !== "Experience" &&
                    attribute.name !== "Acquisition"
                  ) {
                    return (
                      <div key={attribute._id}>
                        {attribute.data.length > 0 ? (
                          <AttributeHeading>{attribute.name}</AttributeHeading>
                        ) : null}

                        <AttributeDataDetailsContainer>
                          {attribute.data.map((item: AtrributesDataTalent) => {
                            return (
                              <DatasDetails key={attribute._id}>
                                <DropButton
                                  id={item._id}
                                  attribtueid={attribute._id}
                                  Talentid={id}
                                  attributeName={attribute.name}
                                  title={item.ksaId as string}
                                  mainAttribute
                                  deleteAttribute={deleteAttribute}
                                  experience={false}
                                  twosideMust={item.mustHave}
                                  twosideGood={item.goodToHave}
                                  key={attribute._id}
                                />
                              </DatasDetails>
                            );
                          })}
                        </AttributeDataDetailsContainer>
                      </div>
                    );
                  }
                  return null;
                })}
              </>
            ) : (
              <DragAndDropInstructions
                id={id}
                drop={dropAttributes}
                allowed={allowed}
                isFullHeight
                text="Drag and drop  the required attributes for this profile"
              />
            )}
          </DetailsContainer>
        </>
      ) : null}
    </RolesContainer>
  );
}

export { RolesDetailsContainer };
