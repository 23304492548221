import { takeLatest, put, fork, call } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { PayloadAction } from "@reduxjs/toolkit";
import http from "../../../utils/http.service";
import config from "../../../utils/request.config";
import {
  domainListTechAction,
  saveCustomTechAction,
  workSteramTecAction,
} from "./workStreamTechnologySlice";
import { workSteramSubmitAction } from "./workStreamSubmitSlice";
import { recommendationListAction } from "./recommedationListSlice";
import {
  wsTechnologyExcelUpload,
  wsResoucePlanningSubmit,
  getRecommdendationList,
  getDomainNames,
  saveCustomTechnology,
} from "../../../utils/serviceurl";
import { TechnologySubmitProps } from "../../../model/wsTechnology";
import { APIResponse, ErrorType } from "../../../model/common";

export const fetchworkStreamTech = async (data: any) =>
  http.POST(config.postConfig(data, "", wsTechnologyExcelUpload, ""));

function* onworkStreamUploadAsync(action: PayloadAction<FormData>) {
  try {
    const response: AxiosResponse<any> = yield call(
      fetchworkStreamTech,
      action.payload,
    );
    if (response.status === 200) {
      yield put(
        workSteramTecAction.workSteramTecSuccess({
          data: response.data.data,
          status: response.status,
        }),
      );
    }
  } catch (error) {
    yield put(
      workSteramTecAction.workSteramTecFailure({
        error: error as ErrorType,
      }),
    );
  }
}

export const fetchworkSubmitAsync = async (data: TechnologySubmitProps) =>
  http.POST(config.postConfig(data, "", wsResoucePlanningSubmit, ""));

function* onworkStreamSubmitAsync(
  action: PayloadAction<TechnologySubmitProps>,
) {
  try {
    const response: AxiosResponse<any> = yield call(
      fetchworkSubmitAsync,
      action.payload,
    );
    if (response.status === 200) {
      yield put(
        workSteramSubmitAction.workSteramSubmitSuccess({
          data: response.data.data,
          status: response.status,
        }),
      );
      // yield put(workSteramTecAction.workSteramTecSuccess(response.data.data));
    }
  } catch (error) {
    // console.log(error);
  }
}

export const fetchRecommendationList = async (data: any) =>
  http.GET(
    config.getConfig(
      "",
      `${getRecommdendationList}?workStreamId=${data.workStreamId}&assessmentId=${data.assessmentId}`,
      "",
    ),
  );

function* onRecommendationListGetAsync(action: PayloadAction<any>) {
  try {
    const response: AxiosResponse<any> = yield call(
      fetchRecommendationList,
      action.payload,
    );
    if (response.status === 200) {
      yield put(
        recommendationListAction.recommendationListSuccess({
          data: response.data.data,
          status: response.status,
        }),
      );
    }
  } catch (error) {
    // console.log(error);
  }
}

export const fetchDomainList = async () =>
  http.GET(config.getConfig("", getDomainNames, ""));

function* onDomainListGetAsync(action: PayloadAction<any>) {
  try {
    const response: AxiosResponse<any> = yield call(fetchDomainList);
    if (response.status === 200) {
      yield put(
        domainListTechAction.domainListSuccess({
          data: response.data.data,
          status: response.status,
        }),
      );
    }
  } catch (error) {
    yield put(
      domainListTechAction.getAllDomainFailure({
        error: error as ErrorType,
      }),
    );
  }
}

export const saveCustomTechology = async (data: any, domain_id: string) =>
  http.PUT(config.postConfig(data, "", `${saveCustomTechnology}/${domain_id}`));

function* onCustomTechAsync(action: PayloadAction<any>) {
  try {
    const response: AxiosResponse<APIResponse> = yield call(
      saveCustomTechology,
      action.payload.data,
      action.payload.domain_id,
    );
    if (response.status === 200) {
      yield put(
        saveCustomTechAction.saveCustomTechSuccess({
          data: response.data.data,
          status: response.status,
        }),
      );
    }
  } catch (error) {
    yield put(
      saveCustomTechAction.saveCustomTechFailure({
        error: error as ErrorType,
      }),
    );
  }
}

function* onAddCustomTechnology() {
  yield takeLatest(
    saveCustomTechAction.saveCustomTechRequest,
    onCustomTechAsync,
  );
}

function* getAllDomains() {
  yield takeLatest(
    domainListTechAction.getAllDomainRequest,
    onDomainListGetAsync,
  );
}

function* onworkStreamUpload() {
  yield takeLatest(
    workSteramTecAction.workSteramTecRequest,
    onworkStreamUploadAsync,
  );
}

function* onworkStreamSave() {
  yield takeLatest(
    workSteramSubmitAction.workSteramSubmitRequest,
    onworkStreamSubmitAsync,
  );
}

function* onRecommendationList() {
  yield takeLatest(
    recommendationListAction.recommendationListRequest,
    onRecommendationListGetAsync,
  );
}

export const workStreamTechSaga = [
  fork(onworkStreamUpload),
  fork(onworkStreamSave),
  fork(onRecommendationList),
  fork(getAllDomains),
  fork(onAddCustomTechnology),
];
