import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorType } from "../../../../../model/common";

export interface NewBucketFuncCreation {
  loading: boolean;
  status: number;
  error: boolean;
}

export const initialState: NewBucketFuncCreation = {
  loading: false,
  status: 0,
  error: false,
};

const newBucketFuncCreationSlice = createSlice({
  name: "newBucketFuncCreationSlice",
  initialState,
  reducers: {
    reset: () => initialState,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    newBucketFuncCreationRequest(
      state: NewBucketFuncCreation,
      action: PayloadAction<{ orgFunctionName: string }>,
    ) {
      state.loading = true;
    },
    newBucketFuncCreationSuccess(
      state: NewBucketFuncCreation,
      action: PayloadAction<{ status: number }>,
    ) {
      state.loading = false;
      state.status = action.payload.status;
    },
    newBucketFuncCreationFailure(
      state: NewBucketFuncCreation,
      action: PayloadAction<{ error: ErrorType }>,
    ) {
      state.loading = false;
      state.error = true;
      state.status = action.payload.error.response.status;
    },
  },
});

export const newBucketFuncCreationAction = newBucketFuncCreationSlice.actions;
export const newBucketFuncCreationReducer = newBucketFuncCreationSlice.reducer;

export default newBucketFuncCreationReducer;
