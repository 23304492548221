import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorType } from "../../../../model/common";
import { GetTasksList } from "../../../../model/tasks";

export const initialState: GetTasksList = {
  loading: false,
  taskDetails: [],
  status: 0,
  error: false,
  data: [] || undefined,
};

const taskFetchSlice = createSlice({
  name: "taskFetchSlice",
  initialState,
  reducers: {
    reset: () => initialState,
    taskFetchRequest(state: GetTasksList) {
      state.loading = true;
    },
    taskFetchSuccess(
      state: GetTasksList,
      action: PayloadAction<{ data: any; status: number }>,
    ) {
      state.loading = false;
      state.taskDetails = action.payload.data;
      state.status = action.payload.status;
    },
    taskFetchFailure(
      state: GetTasksList,
      action: PayloadAction<{ error: ErrorType }>,
    ) {
      state.loading = false;
      state.error = true;
      state.status = action.payload.error.response.status;
    },
  },
});

const assessmentDetailsByIdSlice = createSlice({
  name: "getAssessmentDetails",
  initialState,
  reducers: {
    reset: () => initialState,
    getAssessmentDetailsRequest(
      state: GetTasksList,
      action: PayloadAction<string>,
    ) {
      state.loading = true;
    },
    getAssessmentDetailsSuccess(
      state: GetTasksList,
      action: PayloadAction<{ data: any; status: number }>,
    ) {
      state.loading = false;
      state.data = action.payload.data;
      state.status = action.payload.status;
    },
    getAssessmentDetailsFailure(
      state: GetTasksList,
      action: PayloadAction<{ error: any }>,
    ) {
      state.loading = false;
      state.error = action.payload.error;
    },
  },
});

const assessmentReviewSubmitSlice = createSlice({
  name: "assessmentReviewSubmit",
  initialState,
  reducers: {
    reset: () => initialState,
    reviewSubmitRequest(
      state: GetTasksList,
      action: PayloadAction<{ data: any }>,
    ) {
      state.loading = true;
    },
    reviewSubmitSuccess(
      state: GetTasksList,
      action: PayloadAction<{ data: any; status: number }>,
    ) {
      state.loading = false;
      state.data = action.payload.data;
      state.status = action.payload.status;
    },
    reviewSubmitFailure(
      state: GetTasksList,
      action: PayloadAction<{ error: ErrorType }>,
    ) {
      state.loading = false;
      state.error = true;
      state.status = action.payload.error.response.status;
    },
  },
});

const assessmentRejectionSlice = createSlice({
  name: "assessmentRejection",
  initialState,
  reducers: {
    reset: () => initialState,
    rejectionRequest(
      state: GetTasksList,
      action: PayloadAction<{ data: any }>,
    ) {
      state.loading = true;
    },
    rejectionSuccess(
      state: GetTasksList,
      action: PayloadAction<{ data: any; status: number }>,
    ) {
      state.loading = false;
      state.data = action.payload.data;
      state.status = action.payload.status;
    },
    rejectionFailure(
      state: GetTasksList,
      action: PayloadAction<{ error: ErrorType }>,
    ) {
      state.loading = false;
      state.error = true;
      state.status = action.payload.error.response.status;
    },
  },
});

export const assessmentRejectAction = assessmentRejectionSlice.actions;
export const assessmentRejectionReducer = assessmentRejectionSlice.reducer;
export const assessmentReviewSubmitAction = assessmentReviewSubmitSlice.actions;
export const assessmentReviewSubmitReducer =
  assessmentReviewSubmitSlice.reducer;
export const assessmentDetailsByIdAction = assessmentDetailsByIdSlice.actions;
export const assessmentDetailsByIdReducer = assessmentDetailsByIdSlice.reducer;
export const taskFetchAction = taskFetchSlice.actions;
export const taskFetchReducer = taskFetchSlice.reducer;

export default {
  taskFetchReducer,
  assessmentDetailsByIdReducer,
  assessmentReviewSubmitReducer,
  assessmentRejectionReducer,
};
