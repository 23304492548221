import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorType } from "../../../model/common";
import { HrUploadList } from "../../../model/tom";

export interface GetTomUpload {
  loading: boolean;
  data: any;
  status: number;
  error: boolean;
}

export const initialState: GetTomUpload = {
  loading: false,
  data: [],
  status: 0,
  error: false,
};

const tomUploadSlice = createSlice({
  name: "tomUplaodSlice",
  initialState,
  reducers: {
    reset: () => initialState,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    tomUploadRequest(state: GetTomUpload, action: PayloadAction<any>) {
      state.loading = true;
    },
    tomUploadSuccess(
      state: GetTomUpload,
      action: PayloadAction<{ data: HrUploadList; status: number }>,
    ) {
      state.loading = false;
      state.status = action.payload.status;
      state.data = action.payload.data;
    },
    tomUploadFailure(
      state: GetTomUpload,
      action: PayloadAction<{ error: ErrorType }>,
    ) {
      state.loading = false;
      state.error = true;
      state.status = action.payload.error.response.status;
    },
  },
});

export const tomUploadAction = tomUploadSlice.actions;
export const tomUploadReducer = tomUploadSlice.reducer;

export default tomUploadReducer;
