import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorType } from "../../../model/common";
import { WsKSAListGet } from "../../../model/wSTalent";

export interface SetKSA {
  loading: boolean;
  data: any | undefined;
  status: number;
  error: boolean;
}

export const initialState: SetKSA = {
  loading: false,
  data: undefined,
  status: 0,
  error: false,
};

const ksaTalentDetailsSliceSubmitSlice = createSlice({
  name: "ksaTalentDetailsSlice",
  initialState,
  reducers: {
    reset: () => initialState,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    ksaTalentDetailsFindRequest(state: SetKSA, action: PayloadAction<string>) {
      state.loading = true;
    },
    ksaTalentDetailsSubmitSuccess(
      state: SetKSA,
      action: PayloadAction<{ status: number; data: { data: WsKSAListGet[] } }>,
    ) {
      state.loading = false;
      state.status = action.payload.status;
      state.data = action.payload.data.data;
    },
    ksaTalentDetailsSubmitFailure(
      state: SetKSA,
      action: PayloadAction<{ error: ErrorType }>,
    ) {
      state.loading = false;
      state.error = true;
      state.status = action.payload.error.response.status;
    },
  },
});

export const ksaTalentDetailsSubmitAction =
  ksaTalentDetailsSliceSubmitSlice.actions;
export const ksaTalentDetailsSubmitReducer =
  ksaTalentDetailsSliceSubmitSlice.reducer;

export default ksaTalentDetailsSubmitReducer;
