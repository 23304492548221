import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorType } from "../../../model/common";
import { WsTalentAtrributes } from "../../../model/wSTalent";

export interface GetTalentDetails {
  loading: boolean;
  data: WsTalentAtrributes[];
  status: number;
  error: boolean;
}

export const initialState: GetTalentDetails = {
  loading: false,
  data: [],
  status: 0,
  error: false,
};

const workStreamTalentDetailsSlice = createSlice({
  name: "workStreamTalentSlice",
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    workStreamTalentDetailsRequest(
      state: GetTalentDetails,
      action: PayloadAction<string>,
    ) {
      state.loading = true;
    },
    workStreamTalentDetailsSuccess(
      state: GetTalentDetails,
      action: PayloadAction<{ data: WsTalentAtrributes[]; status: number }>,
    ) {
      state.loading = false;
      state.data = action.payload.data;
      state.status = action.payload.status;
    },
    workStreamTalentDetailsFailure(
      state: GetTalentDetails,
      action: PayloadAction<{ error: ErrorType }>,
    ) {
      state.loading = false;
      state.error = true;
      state.status = action.payload.error.response.status;
    },
  },
});

export const workStreamTalentDetailsAction =
  workStreamTalentDetailsSlice.actions;
export const workStreamTalentDetailsReducer =
  workStreamTalentDetailsSlice.reducer;

export default workStreamTalentDetailsReducer;
