import Spinner from "react-bootstrap/Spinner";
import styled from "styled-components";

const LoaderContainer = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
  margin: auto;
  padding-top: 10px;
`;
function LoaderPage() {
  return (
    <LoaderContainer>
      <Spinner animation="grow" variant="primary" />
    </LoaderContainer>
  );
}

export default LoaderPage;
