import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorType } from "../../../model/common";
import { TechnologyWSModel } from "../../../model/wsTechnology";

export interface GetAbout {
  loading: boolean;
  data: TechnologyWSModel[];
  status: number | null;
  error: boolean;
}

export const initialState: GetAbout = {
  loading: false,
  data: [],
  status: 0,
  error: false,
};

const workSteramTecSlice = createSlice({
  name: "workSteramTec",
  initialState,
  reducers: {
    reset: () => initialState,
    workSteramTecRequest(state: GetAbout, action: PayloadAction<FormData>) {
      state.loading = true;
      //   state.posts = action;
    },
    workSteramTecSuccess(
      state: GetAbout,
      action: PayloadAction<{ data: TechnologyWSModel[]; status: number }>,
    ) {
      state.loading = false;
      state.data = action.payload.data;
      state.status = action.payload.status;
    },
    workSteramTecFailure(
      state: GetAbout,
      action: PayloadAction<{ error: ErrorType }>,
    ) {
      state.loading = false;
      state.status = action.payload.error.response.status;
      state.error = true;
      // state.error = action.payload;
    },
  },
});

export interface GetDomainList {
  loading: boolean;
  data: [];
  status: number | null;
  error: boolean;
}

export const initialStateDomainList: GetDomainList = {
  loading: false,
  data: [],
  status: 0,
  error: false,
};

const domainListTechSlice = createSlice({
  name: "domainNamesTech",
  initialState: initialStateDomainList,
  reducers: {
    reset: () => initialStateDomainList,
    getAllDomainRequest(state: GetDomainList) {
      state.loading = true;
    },
    domainListSuccess(
      state: GetDomainList,
      action: PayloadAction<{ data: any; status: number }>,
    ) {
      state.loading = false;
      state.data = action.payload.data;
      state.status = action.payload.status;
    },
    getAllDomainFailure(
      state: GetDomainList,
      action: PayloadAction<{ error: ErrorType }>,
    ) {
      state.loading = false;
      state.status = action.payload.error.response.status;
      state.error = true;
    },
  },
});

export interface SaveCustomTech {
  loading: boolean;
  data: [];
  status: number | null;
  error: boolean;
}

export const initialSaveCustomTech: SaveCustomTech = {
  loading: false,
  data: [],
  status: 0,
  error: false,
};

const saveCustomTechSlice = createSlice({
  name: "customTechAdd",
  initialState: initialSaveCustomTech,
  reducers: {
    reset: () => initialSaveCustomTech,
    saveCustomTechRequest(
      state: SaveCustomTech,
      action: PayloadAction<{ data: any; domain_id: string }>,
    ) {
      state.loading = true;
    },
    saveCustomTechSuccess(
      state: SaveCustomTech,
      action: PayloadAction<{ data: any; status: number }>,
    ) {
      state.loading = false;
      state.data = action.payload.data;
      state.status = action.payload.status;
    },
    saveCustomTechFailure(
      state: SaveCustomTech,
      action: PayloadAction<{ error: ErrorType }>,
    ) {
      state.loading = false;
      state.status = action.payload.error.response.status;
      state.error = true;
    },
  },
});

export const saveCustomTechAction = saveCustomTechSlice.actions;
export const saveCustomTechReducer = saveCustomTechSlice.reducer;
export const workSteramTecAction = workSteramTecSlice.actions;
export const domainListTechAction = domainListTechSlice.actions;
export const workSteramTecReducer = workSteramTecSlice.reducer;
export const domainListTechReducer = domainListTechSlice.reducer;

export default { workSteramTecReducer, domainListTechReducer };
