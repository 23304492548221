import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorType } from "../../../../../model/common";
import {
  FunctionCard,
  FunctionCardUdpateUnassigned,
} from "../../../../../model/functionViewModel";

export interface GetFunctionBucketDataList {
  loading: boolean;
  data: FunctionCard[] | undefined;
  status: number;
  error: boolean;
}

export const initialState: GetFunctionBucketDataList = {
  loading: false,
  data: undefined,
  status: 0,
  error: false,
};

const funcUdpateBucketFunctionDataSlice = createSlice({
  name: "funcUdpateBucketFunctionDataSlice",
  initialState,
  reducers: {
    reset: () => initialState,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    funcBuncketUpdateRequest(
      state: GetFunctionBucketDataList,
      action: PayloadAction<{ data: FunctionCardUdpateUnassigned[] }>,
    ) {
      state.loading = true;
    },
    funcBuncketUpdateSuccess(
      state: GetFunctionBucketDataList,
      action: PayloadAction<{ data: FunctionCard[]; status: number }>,
    ) {
      state.loading = false;
      state.data = action.payload.data;
      state.status = action.payload.status;
    },
    funcBuncketUpdateFailure(
      state: GetFunctionBucketDataList,
      action: PayloadAction<{ error: ErrorType }>,
    ) {
      state.loading = false;
      state.error = true;
      state.status = action.payload.error.response.status;
    },
    funBuncketDeleteRequest(
      state: GetFunctionBucketDataList,
      action: PayloadAction<string>,
    ) {
      state.loading = true;
    },
  },
});

export const funcBucketUdpateDataAction =
  funcUdpateBucketFunctionDataSlice.actions;
export const funcBucketUpdateDataReducer =
  funcUdpateBucketFunctionDataSlice.reducer;

export default funcBucketUpdateDataReducer;
