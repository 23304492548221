import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorType } from "../../../model/common";

export interface JobDescProp {
  loading: boolean;
  data: string;
  status: number;
  error: boolean;
}

export const initialState: JobDescProp = {
  loading: false,
  data: "",
  status: 0,
  error: false,
};

const workStreamJobDescriptionSubmitSlice = createSlice({
  name: "workStreamJobDescriptionSubmit",
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    workStreamJobDescriptionSubmitRequest(
      state: JobDescProp,
      action: PayloadAction<any>,
    ) {
      state.loading = true;
      //   state.posts = action;
    },
    workStreamJobDescriptionSubmitSuccess(
      state: JobDescProp,
      action: PayloadAction<any>,
    ) {
      state.loading = false;
      state.data = action.payload.data;
      state.status = action.payload.status;
    },
    workStreamJobDescriptionSubmitFailure(state: JobDescProp) {
      state.loading = false;
      // state.error = action.payload;
    },
  },
});

const assignOrUnAssignResourceSlice = createSlice({
  name: "assignOrUnAssignResources",
  initialState,
  reducers: {
    reset: () => initialState,
    assignOrUnAssignResourceRequest(
      state: JobDescProp,
      action: PayloadAction<any>,
    ) {
      state.loading = true;
    },
    assignOrUnAssignResourceSuccess(
      state: JobDescProp,
      action: PayloadAction<{ status: number }>,
    ) {
      state.loading = false;
      state.status = action.payload.status;
    },
    assignOrUnAssignResourceFailure(
      state: JobDescProp,
      action: PayloadAction<{ error: ErrorType }>,
    ) {
      state.loading = false;
      state.error = true;
      state.status = action.payload.error.response.status;
    },
  },
});

export const workStreamJobDescriptionSubmitAction =
  workStreamJobDescriptionSubmitSlice.actions;
export const assignOrUnAssignResourceAction =
  assignOrUnAssignResourceSlice.actions;
export const workStreamJobDescriptionSubmitReducer =
  workStreamJobDescriptionSubmitSlice.reducer;
export const assignOrUnAssignResourceReducer =
  assignOrUnAssignResourceSlice.reducer;
export default {
  workStreamJobDescriptionSubmitReducer,
  assignOrUnAssignResourceReducer,
};
