import React, { ReactElement, useEffect, useState } from "react";
import styled from "styled-components";
import { Form, Row, Col, Button } from "react-bootstrap";
import Select from "react-select";
import _, { get as _get, map, isEqual } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { useAppDispatch } from "../../../../store/hooks";
import { saveCustomTechAction } from "../workStreamTechnologySlice";

import { FormGroup } from "../../../components";

const StyledWorkStreamDetails = styled.div`
  width: 100%;
  padding: 23px 0 60px;

  form {
    .form-select {
      height: 60px;
    }
    .formGrp {
      padding-bottom: 16px;
    }
    select {
      cursor: pointer;
    }
    .btn {
      font-family: ${({ theme }) => theme.fontFamily.bold};
      font-size: ${({ theme }) => theme.fontSizes[1]};
      font-weight: normal;
      width: 95px;
      height: 45px;
      padding: 0;
      float: right;
    }
  }
`;

const Separator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${({ theme }) => theme.fontFamily.light};
  font-size: ${({ theme }) => theme.fontSizes[1]};
  line-height: ${({ theme }) => theme.lineHeight[3]};
  font-weight: normal;
  color: ${({ theme }) => theme.colors.text.black};
  padding-bottom: 21px;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    height: 1px;
    width: 100%;
    background: ${({ theme }) => theme.colors.border.default};
  }
  span {
    position: relative;
    z-index: 1;
    background: ${({ theme }) => theme.colors.bg.white1};
    padding: 0 8px;
  }
`;

const CustomSelect = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.text.black};
  font-size: ${({ theme }) => theme.fontSizes[1]};
  [class*="-control"] {
    min-height: 60px;
    padding: 10px 15px;
    padding-right: 10px;
    font-family: ${({ theme }) => theme.fontFamily.medium};
    font-size: ${({ theme }) => theme.fontSizes[16]};
    line-height: ${({ theme }) => theme.lineHeight[14]};
    color: ${({ theme }) => theme.colors.text.black};
    border-color: ${({ theme }) => theme.colors.border.mediumGrey1};
    border-radius: 6px;
    box-shadow: none !important;
    &:hover {
      border-color: ${({ theme }) => theme.baseColors.primary};
    }
    [class*="-ValueContainer"] {
      padding-left: 0;
    }
    [class*="-indicatorSeparator"] {
      display: none;
    }
    @media screen and (max-width: 1400px) {
      min-height: 45px !important;
      padding: 10px 10px;
    }
    @media screen and (max-width: 1200px) {
      font-size: ${({ theme }) => theme.fontSizes[16]};
    }
    @media screen and (max-width: 1100px) {
      font-size: ${({ theme }) => theme.fontSizes[0]};
    }
    div {
      padding: 0 !important;
      line-height: normal !important;
    }
  }
  [class*="-ValueContainer"] {
    padding: 0;
    div {
      padding: 0;
      margin: 0;
      @media screen and (max-width: 1400px) {
        font-size: ${({ theme }) => theme.fontSizes[16]};
      }
      @media screen and (max-width: 1100px) {
        font-size: ${({ theme }) => theme.fontSizes[0]};
      }
    }
  }
  [class*="-Input"] {
    position: absolute;
  }
  [class*="-indicatorContainer"] {
    padding: 0;
    svg {
      width: 18px;
      height: 18px;
      path {
        fill: ${({ theme }) => theme.colors.text.black};
      }
    }
  }
  .css-b62m3t-container {
    display: grid !important;
    width: 100%;
    .css-1okebmr-indicatorSeparator {
      display: none;
    }
  }
`;
function SelectDomainVendor({
  domain,
  setDomain,
  domainTechnology,
  setDomainTechnology,
  domainList,
  setCustomTechnologyArray,
  setIsExcelUpload,
  setIsDropdown,
  customTechName,
  setCustomName,
  recommendationDataList,
}: any): ReactElement {
  const dispatch = useAppDispatch();
  const [technologyArray, setTechnologyList] = useState([]);
  const [msspList, setMsspList] = useState([]);
  const [msspName, setMsspName] = useState<any>(null);
  const [buttondisable, setButtonDisable] = useState(true);

  useEffect(() => {
    if (recommendationDataList && recommendationDataList?.length === 1) {
      const selectedData = recommendationDataList?.[0];
      const findTechnology = domainList.find(
        (currentlySelected: any) =>
          currentlySelected._id === selectedData?.domain_id,
      );
      if (findTechnology !== undefined) {
        setDomain({
          _id: selectedData?.domain_id,
          domain: selectedData?.domain,
          technologies: findTechnology?.technologies,
          mssp: findTechnology?.mssp,
        });
        setDomainTechnology({
          _id: selectedData?.technology_id,
          name: selectedData?.technologyName,
        });
        setCustomName(
          selectedData?.otherTechnology !== ""
            ? selectedData?.otherTechnology
            : "",
        );
        setMsspName(
          selectedData?.otherTechnology !== ""
            ? selectedData?.otherTechnology
            : "",
        );
      }
    }
  }, [domainList, recommendationDataList === undefined]);

  useEffect(() => {
    if (Object.entries(domain)?.length > 0) {
      setIsDropdown(true);
      setIsExcelUpload(false);
      const vendors: any = map(
        _get(domain, "technologies", []),
        (item: any) => {
          if (item?.isActive) {
            return {
              name: item?.technologyName,
              _id: item?._id,
            };
          }
        },
      );
      setTechnologyList(_.compact(vendors));
      const mssp: any = map(_get(domain, "mssp", []), (item: any) => {
        return {
          name: item?.msspName,
          _id: item?._id,
        };
      });

      setMsspList(_.compact(mssp));
    } else {
      setIsDropdown(false);
      setIsExcelUpload(true);
    }
  }, [domain]);

  const [flag, setFlag] = useState<number>(0);

  useEffect(() => {
    if (domainTechnology !== null) {
      setCustomTechnologyArray([]);
      if (
        flag === 1 &&
        (domainTechnology?.name === "Other" ||
          domainTechnology?.name === "Others" ||
          domainTechnology?.name === "mssp")
      ) {
        const temArray = {
          _id: uuidv4(),
          isSelected: true,
          domain_id: domain?._id,
          domain: domain?.domain,
          technologyName: domainTechnology?.name,
          otherTechnology:
            domainTechnology?.name === "mssp" ? msspName : customTechName,
          technology_id: domainTechnology?._id,
        };
        setCustomTechnologyArray([temArray]);
      } else if (
        domainTechnology?.name !== "Other" &&
        domainTechnology?.name !== "Others" &&
        domainTechnology?.name !== "mssp"
      ) {
        const temArray = {
          _id: uuidv4(),
          isSelected: true,
          domain_id: domain?._id,
          domain: domain?.domain,
          technologyName: domainTechnology?.name,
          otherTechnology: "",
          technology_id: domainTechnology?._id,
        };
        setCustomTechnologyArray([temArray]);
      }
    }
  }, [domainTechnology, customTechName, flag, msspName]);

  const customTechSubmitHandle = () => {
    setFlag(1);
    if (domainTechnology?.name === "mssp") {
      const dataSend = {
        data: {
          optionName:
            domainTechnology?.name === "mssp" ? msspName : customTechName,
          isActive: "false",
          isMSFP: true,
        },
        domain_id: domain?._id,
      };
      dispatch(saveCustomTechAction.saveCustomTechRequest(dataSend));
    } else {
      const dataSend = {
        data: {
          optionName:
            domainTechnology?.name === "mssp" ? msspName : customTechName,
          isActive: "false",
          isMSFP: false,
        },
        domain_id: domain?._id,
      };
      dispatch(saveCustomTechAction.saveCustomTechRequest(dataSend));
    }
    // const dataSend = {
    //   data: {
    //     optionName:
    //       domainTechnology?.name === "mssp" ? msspName : customTechName,
    //     isActive: "false",
    //   },
    //   domain_id: domain?._id,
    // };
    // dispatch(saveCustomTechAction.saveCustomTechRequest(dataSend));
  };

  useEffect(() => {
    if (domainTechnology?.name === "mssp" && msspName !== null) {
      setButtonDisable(false);
    } else if (
      (domainTechnology?.name === "Other" ||
        domainTechnology?.name === "Others") &&
      customTechName !== ""
    ) {
      setButtonDisable(false);
    } else setButtonDisable(true);
  }, [domainTechnology?.name, msspName, customTechName]);

  // console.log("data", msspName);
  return (
    <StyledWorkStreamDetails>
      <Separator>
        <span>or</span>
      </Separator>
      <Form>
        <Row>
          <Col sm>
            <FormGroup formGroupBlock className="formGrp">
              <Form.Label>Select Domain</Form.Label>
              {domainList && domainList?.length > 0 && (
                <CustomSelect>
                  <Select
                    options={domainList}
                    value={
                      domainList.find((item: any) =>
                        isEqual(item?._id, domain?._id),
                      ) || null
                    }
                    onChange={(e: any) => {
                      setDomain(e);
                      setDomainTechnology(null);
                      setCustomName("");
                      setMsspName(null);
                      setFlag(0);
                      const enableVal: any = document.querySelector(
                        ".enableRemoveButton",
                      );
                      if (enableVal) {
                        enableVal?.click();
                      }
                    }}
                    placeholder="Please Select"
                    getOptionLabel={(e: any) => (e ? e.domain : "")}
                    getOptionValue={(e: any) => (e ? e._id : "")}
                  />
                </CustomSelect>
              )}
            </FormGroup>
          </Col>
          <Col sm>
            <FormGroup formGroupBlock className="formGrp">
              <Form.Label>Select Technology</Form.Label>
              <CustomSelect>
                <Select
                  options={technologyArray}
                  value={
                    technologyArray.find(
                      (item: any) => item?._id === domainTechnology?._id,
                    ) || null
                  }
                  onChange={(e: any) => {
                    setDomainTechnology(e);
                    setCustomName("");
                    setMsspName(null);
                    setFlag(0);
                  }}
                  placeholder="Please Select"
                  getOptionLabel={(e: any) => (e ? e.name : "")}
                  getOptionValue={(e: any) => (e ? e._id : "")}
                />
              </CustomSelect>
            </FormGroup>
          </Col>
        </Row>
        {(domainTechnology !== null && domainTechnology?.name === "Others") ||
        domainTechnology?.name === "Other" ||
        domainTechnology?.name === "mssp" ? (
          <>
            {domainTechnology?.name === "Others" ||
              (domainTechnology?.name === "Other" && (
                <FormGroup formGroupBlock>
                  <Form.Label>Technology Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter technology name"
                    value={customTechName}
                    onChange={(ev: any) => setCustomName(ev.target.value)}
                  />
                </FormGroup>
              ))}
            {domainTechnology?.name === "mssp" && (
              <CustomSelect style={{ marginBottom: "20px" }}>
                <Select
                  options={msspList}
                  // value={
                  //   msspList.find(
                  //     (item: any) => item?.name === domainTechnology?.name,
                  //   ) || null
                  // }
                  onChange={(e: any) => {
                    setMsspName(e.name);
                  }}
                  placeholder={msspName !== null ? msspName : "Select option"}
                  getOptionLabel={(e: any) => (e ? e.name : "")}
                  getOptionValue={(e: any) => (e ? e.name : "")}
                />
              </CustomSelect>
            )}

            <Button
              variant="primary"
              disabled={buttondisable}
              onClick={customTechSubmitHandle}
            >
              Submit
            </Button>
          </>
        ) : null}
      </Form>
    </StyledWorkStreamDetails>
  );
}

export { SelectDomainVendor };
