import { combineReducers, configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "@redux-saga/core";
import {
  workSteramTecReducer,
  domainListTechReducer,
} from "../ui/patterns/workStreamTechnology/workStreamTechnologySlice";
import {
  workStreamDetailsReducer,
  getTimeLineFormDetailsReducer,
  RiskToleranceReducer,
} from "../ui/patterns/workStreamDetails/workStreamDetailsSlice";
import rootSaga from "./rootSaga";
import { workSteramSubmitReducer } from "../ui/patterns/workStreamTechnology/workStreamSubmitSlice";
import { workStreamTalentDetailsReducer } from "../ui/patterns/talentsDetails/workStreamTalentDetailsSlice";
import { workStreamTalentDetailsSubmitReducer } from "../ui/patterns/talentsDetails/workStreamTalentDetailsSubmitSlice ";
import {
  workStreamJobDescriptionDetailsReducer,
  orgProfilesReducer,
  orgMatchedProfileReducer,
} from "../ui/patterns/selectOrganization/workStreamJobDescriptionDetailsSlice";
import {
  assignOrUnAssignResourceReducer,
  workStreamJobDescriptionSubmitReducer,
} from "../ui/patterns/selectOrganization/workStreamJobDescriptionSubmitSlice";
import { roadMapReducer } from "../pages/MainPage/RoadMap/roadMapSlice";
import { maturityAssessmentTypeReducer } from "../pages/MaturityAssessment/maturityAssessmentTypeSlice";
import { roadMapFetchReducer } from "../pages/MainPage/RoadMap/roadMapFetchSlice";
import { recommendationListReducer } from "../ui/patterns/workStreamTechnology/recommedationListSlice";
import { dashBoardDataListReducer } from "../pages/MainPage/AssessmentResult/dashboardSlice";
import { dashBoardLineChartReducer } from "../pages/MainPage/AssessmentResult/dashboardLineChartSlice";
import { getTokenPermissionReducer } from "../pages/LoginPage/getTokenPermissionSlice";
import { getAssessmentIdReducer } from "../pages/MainPage/getAssessmentIdSlice";
import {
  designationListReducer,
  departmentListReducer,
  managerListReducer,
  resumeBuilderReducer,
  profileImageUploadReducer,
  profileBuilderGetProfileDetailsReducer,
} from "../pages/ProfileBuilder/profileBuilderStep1/basicDetailsSlice";
import { degreeListReducer } from "../pages/ProfileBuilder/profileBuilderStep2/educationGetSlice";
import { domainListReducer } from "../pages/ProfileBuilder/profileBuilderStep4/technologySlice";
import { skillsListReducer } from "../pages/ProfileBuilder/profileBuilderStep6/skillsSlice";
import { strategyFetchReducer } from "../pages/MainPage/Strategy/strategyFetchSlice";
import { strategyUpdateReducer } from "../pages/MainPage/Strategy/statergyUpdateSlice";
import { strategyUnActivityFetchReducer } from "../pages/MainPage/Strategy/strategyUnActivityFetchSlice";
import { knowledgeListReducer } from "../pages/ProfileBuilder/profileBuilderStep5/knowledgeSlice";
import { abilitiesListReducer } from "../pages/ProfileBuilder/profileBuilderStep7/abilitiesSlice";
import { responsibilitiesListReducer } from "../pages/ProfileBuilder/profileBuilderStep8/responsibilitiesSlice";
import {
  maturityAssessmentSubmitReducer,
  maturityAssessmentFileUploadReducer,
} from "../pages/MaturityAssessment/maturityAssessmentSubmitSlice";
import { ksaTalentDetailsSubmitReducer } from "../ui/patterns/talentsDetails/ksaTalentDetailsSlice";
import { workStreamTalentDetailsSearchReducer } from "../ui/patterns/talentsDetails/wsTalentSearchSlice";
import { tomUploadReducer } from "../pages/MainPage/Organization/tomUploadFetchSlice";
import { demographicsReducer } from "../pages/MainPage/Demographics/demographicsSlice";
import { emporgFetchReducer } from "../ui/patterns/selectOrganization/orgStructure/emporgSlice";
import {
  hrOrgFetchReducer,
  hrPreviewSaveReducer,
} from "../pages/MainPage/Organization/TOMView/HRView/hrOrgSlice";
import { strategyUnActivityUpdateReducer } from "../pages/MainPage/Strategy/strategyUnActivityFetchUpdateSlice";
import { funcUnassignedFetchReducer } from "../pages/MainPage/Organization/TOMView/FunctionalView/FunctionUnassignedProfilesSlice";
import { funcBucketGetDataReducer } from "../pages/MainPage/Organization/TOMView/FunctionalView/BucketFunctionProfilesGetSlice ";
import { funcBucketUpdateDataReducer } from "../pages/MainPage/Organization/TOMView/FunctionalView/UpdateBucketFuncProfiles";
import { newBucketFuncCreationReducer } from "../pages/MainPage/Organization/TOMView/FunctionalView/NewBucketFuncCreation";
import { FunctionUpdateNameReducer } from "../pages/MainPage/Organization/TOMView/FunctionalView/FunctionUpdateName";
import { funcprofileGetDataReducer } from "../pages/MainPage/Organization/TOMView/FunctionalView/FunctionViewProfileFetchSlice";
import { assessmentDetialsReducer } from "../ui/patterns/assessmentPriority/AssesmentDetailsSlice";
import { roadMapUpdateDateReducer } from "../pages/MainPage/RoadMap/Components/TimelineCalender/roadMapFetchSlice";
import { assessmentTypeFetchReducer } from "../ui/patterns/reassessDetails/assesmenttypeslice";
import { customDashboardDataReducer } from "../pages/MainPage/DashBoardTab/Dashboard/customDashboardSlice";
import {
  assessmentDetailsByIdReducer,
  assessmentRejectionReducer,
  assessmentReviewSubmitReducer,
  taskFetchReducer,
} from "../pages/MainPage/DashBoardTab/MyTask/taskListSlice";
import {
  activateUserReducer,
  assignUserRoleReducer,
  deActivateUserReducer,
  getAdminUsersReducer,
} from "../pages/Admin/ManageUsers/manageUserSlice";
import { getRolesReducer } from "../pages/Admin/ManageRoles/manageRolesSlice";
import {
  budgetDetailsSubmitReducer,
  getBudgetDetailsReducer,
} from "../ui/patterns/BudgetDetails/budgetDetailsSlice";
import {
  assignedUsersFetchReducer,
  assignOwnerReducer,
} from "../ui/patterns/selectOrganization/getAssignedUsersSlice";
import { implementationFetchReducer } from "../pages/MainPage/Implementation/ImplementationOverview/ImplementationListSlice";
import { getDraftReviewAssessDetailReducer } from "../pages/MainPage/DashBoardTab/MyTask/maturityAssessDetailsSlice";
import { getReviewAssessmentDataReducer } from "../pages/AuditReview/getReviewAssessmentDataSlice";

// mention Reducers here
const rootReducer = combineReducers({
  workStreamTech: workSteramTecReducer,
  workStreamDetails: workStreamDetailsReducer,
  workStreamTechSubmit: workSteramSubmitReducer,
  workStreamTalentDetails: workStreamTalentDetailsReducer,
  workStreamTalentDetailsSubmit: workStreamTalentDetailsSubmitReducer,
  workStreamJobDescriptionDetails: workStreamJobDescriptionDetailsReducer,
  workStreamJobDescriptionSubmit: workStreamJobDescriptionSubmitReducer,
  roadMapData: roadMapReducer,
  roadMapDate: roadMapUpdateDateReducer,
  maturityAssessmentType: maturityAssessmentTypeReducer,
  maturityAssessmentSubmit: maturityAssessmentSubmitReducer,
  roadMapFetchList: roadMapFetchReducer,
  recommendationList: recommendationListReducer,
  dashBoardDataList: dashBoardDataListReducer,
  dashBoardLineChart: dashBoardLineChartReducer,
  designationList: designationListReducer,
  departmentList: departmentListReducer,
  degreeList: degreeListReducer,
  domainList: domainListReducer,
  resumeBuilder: resumeBuilderReducer,
  skillsList: skillsListReducer,
  getTokenPermission: getTokenPermissionReducer,
  getAssessmentId: getAssessmentIdReducer,
  strategyList: strategyFetchReducer,
  strategyUpdateList: strategyUpdateReducer,
  strategyUnActivityList: strategyUnActivityFetchReducer,
  strategyUnActivityUpdateList: strategyUnActivityUpdateReducer,
  profileImageUpload: profileImageUploadReducer,
  knowledgeList: knowledgeListReducer,
  abilitiesList: abilitiesListReducer,
  responsibilitiesList: responsibilitiesListReducer,
  getProfileBuilderDetails: profileBuilderGetProfileDetailsReducer,
  maturityAssessmentFileUpload: maturityAssessmentFileUploadReducer,
  ksaTalentAttributeList: ksaTalentDetailsSubmitReducer,
  orgProfiles: orgProfilesReducer,
  orgMatchedProfiles: orgMatchedProfileReducer,
  wsSearchAction: workStreamTalentDetailsSearchReducer,
  tomUpload: tomUploadReducer,
  demographics: demographicsReducer,
  empOrgList: emporgFetchReducer,
  hrOrgList: hrOrgFetchReducer,
  domainNamesTech: domainListTechReducer,
  funcViewUnassignedList: funcUnassignedFetchReducer,
  funcBucketDataList: funcBucketGetDataReducer,
  funcBucketUpdateDataList: funcBucketUpdateDataReducer,
  newBucketData: newBucketFuncCreationReducer,
  functionUpdateData: FunctionUpdateNameReducer,
  ProfileFuncData: funcprofileGetDataReducer,
  // Gettting Assesment Details for viewing in Table
  assessmetDetailsListView: assessmentDetialsReducer,
  // Getting AssessmentType & Framework to view in Dashboard
  assessmetTypeFrameworkList: assessmentTypeFetchReducer,
  assignOrUnAssignResources: assignOrUnAssignResourceReducer,
  customDashboardData: customDashboardDataReducer,
  getTimeLineFormDetails: getTimeLineFormDetailsReducer,
  hrPreviewSave: hrPreviewSaveReducer,
  getAdminUsers: getAdminUsersReducer,
  getManageRoles: getRolesReducer,
  assignUserRole: assignUserRoleReducer,
  budgetDetailsSubmit: budgetDetailsSubmitReducer,
  getBudgetDetails: getBudgetDetailsReducer,
  riskToleranceData: RiskToleranceReducer,
  getAssignedUserList: assignedUsersFetchReducer,
  assignOwner: assignOwnerReducer,
  activateAdminUser: activateUserReducer,
  deActivateAdminUser: deActivateUserReducer,
  managerList: managerListReducer,
  taskList: taskFetchReducer,
  assessmentDetailsById: assessmentDetailsByIdReducer,
  assessmentReviewSubmit: assessmentReviewSubmitReducer,
  implementationList: implementationFetchReducer,
  draftReviewAssessmentDetails: getDraftReviewAssessDetailReducer,
  assessmentRejection: assessmentRejectionReducer,
  getReviewAssessmentData: getReviewAssessmentDataReducer,
});

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      thunk: false,
      serializableCheck: false,
    }).prepend(sagaMiddleware);
  },
});

sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
