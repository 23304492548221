import { ReactElement, useEffect, useState } from "react";
import { Image } from "rebass";
import { Button, Toast, ToastContainer } from "react-bootstrap";
import jwt_decode from "jwt-decode";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import {
  MainContainer,
  InnerContainer,
  InnerTextContainer,
  InnerTextHeading1,
  InnerTextHeading2,
  InnerTextParagraph,
  HomePageButtonContainer,
  InnerTextAccount,
  ButtonText,
} from "./styles";
import cybrizeLogo from "../../ui/images/CybrizeLogoFinal.svg";
import LoaderPage from "../UtilPages/LoaderPage";
import { TokenType } from "../../model/common";
import { useAppDispatch } from "../../store/hooks";
import { getTokenPermissionAction } from "./getTokenPermissionSlice";
import { navigateToPages } from "../../utils/navigation-sessionTimeOut";
import SessionExpiredModal from "../../ui/patterns/SessionExpiredModal";

function HomePage(): ReactElement {
  window.onpopstate = () => {};
  const pageStatus: any = sessionStorage.getItem("page");
  const sessionExpiryStatus: any = sessionStorage.getItem("modalStatus");
  const [isWarningModalOpen, setWarningModalOpen] = useState(
    !!(sessionExpiryStatus !== undefined && sessionExpiryStatus === "visible"),
  );
  const dispatch = useAppDispatch();
  const [Rolepopup, SetRolePopup] = useState(false);
  const navigate = useNavigate();
  const {
    loginWithRedirect,
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    logout,
    user,
  } = useAuth0();
  const win: Window = window;
  const signUp = () => {
    win.location = "mailto:xyz@yourapplicationdomain.com";
  };
  const contactUs = () => {
    win.location = "mailto:xyz@yourapplicationdomain.com";
  };

  const signinClick = () => {
    loginWithRedirect({ appState: { returnTo: "" } });
  };

  const navRoles = async () => {
    const token = await getAccessTokenSilently();
    // console.log(token, "token");
    const decoded: TokenType = jwt_decode(token);
    const Roles: string[] = decoded["https://cybrizehorizon/roles"];
    console.log("Role object", Roles);
    console.log("user-role", Roles[0]);
    if (
      Roles[0] === "Admin" ||
      Roles[0] === "Auditor" ||
      Roles[0] === "HR" ||
      Roles[0] === "IS Team" ||
      Roles[0] === "Technology" ||
      Roles[0] === "Reviewer"
    ) {
      dispatch(getTokenPermissionAction.setRole(Roles[0]));
      dispatch(getTokenPermissionAction.setUserRolesArray(Roles));
      navigateToPages(navigate, pageStatus);
    } else if (Roles[0] === undefined) {
      SetRolePopup(true);
      console.log(Roles, "undefined state");
      setTimeout(() => {
        logout();
        SetRolePopup(false);
      }, 2000);
    } else {
      console.log(Roles[0], "else state");
      SetRolePopup(true);
      setTimeout(() => {
        logout();
        SetRolePopup(false);
      }, 2000);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      navRoles();
      console.log(user, "user");
    }
  }, [isAuthenticated]);

  return isLoading ? (
    <LoaderPage />
  ) : (
    <>
      {isWarningModalOpen && (
        <SessionExpiredModal
          show={isWarningModalOpen}
          handleClose={() => {
            sessionStorage.removeItem("modalStatus");
            setWarningModalOpen(false);
          }}
        />
      )}
      <MainContainer>
        <InnerContainer>
          <Image
            src={cybrizeLogo}
            className="cybrize-logo"
            alt="cybrize logo"
          />
          <InnerTextContainer>
            <InnerTextHeading1>
              <span>What happens</span>
            </InnerTextHeading1>
            <InnerTextHeading2>After the assessment?</InnerTextHeading2>
            <InnerTextParagraph>
              Take control of your operating models by turning assessment
              findings into implementation
            </InnerTextParagraph>
            <HomePageButtonContainer>
              <Button variant="primary" onClick={contactUs}>
                Contact Us
              </Button>
              <Button
                variant="outline-primary"
                className="signup-button"
                onClick={signUp}
              >
                Sign Up
              </Button>
            </HomePageButtonContainer>
            <InnerTextAccount>
              Already have an account?
              <ButtonText onClick={signinClick}>Sign In</ButtonText>
            </InnerTextAccount>
          </InnerTextContainer>
        </InnerContainer>

        <ToastContainer className="m-3" position="top-center">
          <Toast show={Rolepopup}>
            <Toast.Header closeButton={false}>Cybrize</Toast.Header>
            <Toast.Body>401 Unauthorised Access</Toast.Body>
          </Toast>
        </ToastContainer>
      </MainContainer>
    </>
  );
}

export default HomePage;
