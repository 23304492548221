import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Image } from "rebass";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "react-bootstrap";
import errorLogo from "../../ui/images/ErrorImage/errorImage.svg";
import { errorLogOut } from "../../utils/navigation-sessionTimeOut";

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
`;
const InnerContainer = styled.div`
  width: 100%;
  height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    margin-bottom: 42px;
  }
`;
const ErrorMessageHeading = styled.h1`
  font-family: ${({ theme }) => theme.fontFamily.bold};
  font-style: normal;
  font-weight: normal;
  font-size: ${({ theme }) => theme.fontSizes[18]};
  line-height: ${({ theme }) => theme.lineHeight[5]};
  color: ${({ theme }) => theme.colors.text.black};
  text-transform: uppercase;
  margin-bottom: 10px;
`;
const ErrorMessageContent = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.light};
  font-style: normal;
  font-weight: normal;
  font-size: ${({ theme }) => theme.fontSizes[16]};
  line-height: ${({ theme }) => theme.lineHeight[3]};
  color: ${({ theme }) => theme.colors.text.black};
  margin-bottom: 40px;
`;
const ButtonsDetails = styled.div`
  display: flex;
  align-items: center;
  button {
    font-family: ${({ theme }) => theme.fontFamily.bold};
    font-style: normal;
    font-weight: normal;
    font-size: ${({ theme }) => theme.fontSizes[3]};
    line-height: ${({ theme }) => theme.lineHeight[14]};
    min-width: 204px;
    height: 59px;
    border-radius: 6px !important;
    margin-right: 20px;
    &:hover,
    &:focus,
    &:active {
      box-shadow: none !important;
      outline: none !important;
    }
  }
`;

type ErrorPageType = {
  msg?: String;
};

function ErrorPage({ msg }: ErrorPageType) {
  const location = useLocation();
  const { logout } = useAuth0();
  const navigate = useNavigate();
  console.log(location, "location");

  return (
    <MainContainer>
      <InnerContainer>
        <Image src={errorLogo} alt="Error-Information" />
        {location.state === 404 ? (
          <ErrorMessageHeading>Sorry, Page Not found</ErrorMessageHeading>
        ) : location.state === 500 ? (
          <ErrorMessageHeading>
            Sorry, Internal Server Error
          </ErrorMessageHeading>
        ) : location.state === 400 ? (
          <ErrorMessageHeading>Sorry, Bad Request</ErrorMessageHeading>
        ) : msg === "NF" ? (
          <ErrorMessageHeading>Sorry, Page Not found</ErrorMessageHeading>
        ) : (
          <ErrorMessageHeading>Sorry, Error Page</ErrorMessageHeading>
        )}
        <ErrorMessageContent>
          The page you are looking for might have been removed, had its name
          changed or is temporarily unavailable
        </ErrorMessageContent>
        <ButtonsDetails>
          <Button
            variant="outline-primary"
            onClick={() => {
              navigate(-1);
            }}
          >
            Go Back
          </Button>
          <Button
            variant="primary"
            className="btn-primary"
            onClick={() => errorLogOut(logout)}
          >
            Back to Login
          </Button>
        </ButtonsDetails>
      </InnerContainer>
    </MainContainer>
  );
}
export function Error401Page() {
  const { logout } = useAuth0();
  const navigate = useNavigate();

  return (
    <MainContainer>
      <InnerContainer>
        <Image src={errorLogo} alt="Error-Information" />
        <ErrorMessageHeading>Sorry, page not found...!!</ErrorMessageHeading>
        <ErrorMessageContent>
          401 Unauthorised Access / Roles not Defined
        </ErrorMessageContent>
        <ButtonsDetails>
          <Button
            variant="outline-primary"
            onClick={() => {
              navigate(-1);
            }}
          >
            Go Back
          </Button>
          <Button
            variant="primary"
            className="btn-primary"
            onClick={() => errorLogOut(logout)}
          >
            Back to Login
          </Button>
        </ButtonsDetails>
      </InnerContainer>
    </MainContainer>
  );
}
export default ErrorPage;
