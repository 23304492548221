import { NavigateFunction } from "react-router-dom";

const errorHandling = (status: number | null, navigate: NavigateFunction) => {
  if (status === 0) {
    navigate("../../ErrorPage", {
      replace: true,
      state: 503,
    });
  } else if (status === 400 || status === 404 || status === 500) {
    navigate("../../ErrorPage", {
      replace: true,
      state: status,
    });
  }
};
export default errorHandling;
