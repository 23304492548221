import { takeLatest, put, fork, call } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { PayloadAction } from "@reduxjs/toolkit";
import http from "../../utils/http.service";
import config from "../../utils/request.config";
import { getAssessmentIdAction } from "./getAssessmentIdSlice";
import { assementIdGetApi, getAssessment } from "../../utils/serviceurl";
import { ErrorType } from "../../model/common";

export const fetchAssessmentId = async () =>
  http.GET(config.getConfig("", getAssessment, ""));

function* onAssessmentIdAsync() {
  try {
    const response: AxiosResponse<any> = yield call(fetchAssessmentId);
    if (response.status === 200) {
      yield put(
        getAssessmentIdAction.getAssessmentIdSuccess({
          data: response.data.data,
          status: response.status,
        }),
      );
    }
  } catch (error) {
    yield put(
      getAssessmentIdAction.getAssessmentIdFailure({
        error: error as ErrorType,
      }),
    );
  }
}

function* onAssessmentIdGet() {
  yield takeLatest(
    getAssessmentIdAction.getAssessmentIdRequest,
    onAssessmentIdAsync,
  );
}

/// ////------------------------Fetching assementID based on selected framework-------------------//////////////////

export const fetchingAssessementIdbyFramework = async (frameworkName: string) =>
  http.GET(config.getConfig({ frameworkName }, `${assementIdGetApi}/`, ""));

function* onFetchAssessementIdbyFrameworkistAsync(
  action: PayloadAction<string>,
) {
  try {
    const response: AxiosResponse<any> = yield call(
      fetchingAssessementIdbyFramework,
      action.payload,
    );
    if (response.status === 200) {
      if (response.data !== undefined) {
        yield put(
          getAssessmentIdAction.getAssessmentIdBasedFrameworkSuccess({
            data: response.data,
            status: response.status,
          }),
        );
      }
    } else {
      yield put(
        getAssessmentIdAction.getAssessmentIdBasedFrameworkSuccesswithoutAssessmentId(),
      );
    }
  } catch (error) {
    yield put(
      getAssessmentIdAction.getAssessmentIdBasedFrameworkFailure({
        error: error as ErrorType,
      }),
    );
  }
}

function* onFetchAssessementIdList() {
  yield takeLatest(
    getAssessmentIdAction.getAssessmentIdBasedFrameworkRequest,
    onFetchAssessementIdbyFrameworkistAsync,
  );
}

export const getAssessmentIdSaga = [
  fork(onAssessmentIdGet),
  fork(onFetchAssessementIdList),
];
