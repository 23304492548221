import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorType } from "../../../model/common";
import { TalentRequestType } from "../../../model/wSTalent";

export interface SetTalent {
  loading: boolean;
  data: [];
  status: number;
  error: boolean;
}

export const initialState: SetTalent = {
  loading: false,
  data: [],
  status: 0,
  error: false,
};

const workStreamTalentDetailsSubmitSlice = createSlice({
  name: "workStreamTalentSubmitSlice",
  initialState,
  reducers: {
    reset: () => initialState,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    workStreamTalentDetailsSubmitRequest(
      state: SetTalent,
      action: PayloadAction<TalentRequestType>,
    ) {
      state.loading = true;
    },
    workStreamTalentDetailsSubmitSuccess(
      state: SetTalent,
      action: PayloadAction<{ status: number; data: [] }>,
    ) {
      state.loading = false;
      state.status = action.payload.status;
      state.data = action.payload.data;
    },
    workStreamTalentDetailsSubmitFailure(
      state: SetTalent,
      action: PayloadAction<{ error: ErrorType }>,
    ) {
      state.loading = false;
      state.error = true;
      state.status = action.payload.error.response.status;
    },
  },
});

export const workStreamTalentDetailsSubmitAction =
  workStreamTalentDetailsSubmitSlice.actions;
export const workStreamTalentDetailsSubmitReducer =
  workStreamTalentDetailsSubmitSlice.reducer;

export default workStreamTalentDetailsSubmitReducer;
