import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { addEventListeners, removeEventListeners } from "./timeOutEventHandler";

export function SessionExpiryCheck() {
  const [isWarningModalOpen, setWarningModalOpen] = useState(false);
  const { logout } = useAuth0();
  useEffect(() => {
    const createTimeout1 = () =>
      setTimeout(() => {
        setWarningModalOpen(true);
      }, 1800000); // 30 min

    const createTimeout2 = () =>
      setTimeout(() => {
        // console.log("logout implementation");
      }, 2400000);

    let timeout = isWarningModalOpen ? createTimeout2() : createTimeout1();

    const listener = () => {
      if (!isWarningModalOpen) {
        clearTimeout(timeout);
        timeout = createTimeout1();
      }
    };
    addEventListeners(listener);
    if (isWarningModalOpen) {
      sessionStorage.setItem("modalStatus", "visible");
      logout({ returnTo: window.location.origin });
    }
    return () => {
      removeEventListeners(listener);
      clearTimeout(timeout);
    };
  }, [isWarningModalOpen]);
}
