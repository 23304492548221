import { ReactElement, useEffect, useRef, useState } from "react";
import { Nav } from "react-bootstrap";
import styled from "styled-components";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Header } from "../../ui/patterns";
import { RootState } from "../../store/store";
import { getAssessmentIdAction } from "./getAssessmentIdSlice";
import { useAppDispatch } from "../../store/hooks";
import Icons from "../../ui/icons";
import LoaderPage from "../UtilPages/LoaderPage";
import errorHandling from "../../utils/errorHandling";
import { Sidebar } from "../../ui/patterns/sidebar";
import { SessionExpiryCheck } from "../../utils/SessionExpiryCheck";

const MainContainer = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  padding-top: 112px;
`;

const InnerContainer = styled.div`
  display: flex;
`;
const HeaderMenu = styled.div`
  .nav {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 8;
    border-bottom: unset;
    height: 48px;
    margin-top: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.bg.white3};
    border-bottom: 1px solid ${({ theme }) => theme.colors.border.default};
    .nav-link {
      width: 140px;
      margin-bottom: unset;
      height: 100%;
      padding: 15px 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: ${({ theme }) => theme.fontFamily.medium};
      font-style: normal;
      font-weight: normal;
      font-size: ${({ theme }) => theme.fontSizes[1]};
      line-height: ${({ theme }) => theme.lineHeight[14]};
      color: #000000 !important;
      border-left: unset;
      border-right: unset;
      border-top: unset;
      border-radius: unset;
      transition: color 0.25s;
      transition: all 0.25s ease;
      @media (max-width: 960px) {
        padding: 15px 20px;
      }
      &:hover {
        color: ${({ theme }) => theme.colors.text.tealishBlue} !important;
      }
      &.active {
        color: ${({ theme }) => theme.colors.text.tealishBlue} !important;
        background: unset;
        border-radius: unset;
        position: relative;
        font-family: ${({ theme }) => theme.fontFamily.bold};
        font-weight: normal;
        &:after {
          content: "";
          height: 4px;
          width: 100%;
          position: absolute;
          bottom: -1px;
          background: ${({ theme }) => theme.colors.border.tealishBlue};
          box-shadow: 0px 2px 5px 0px #5c97fba3;
        }
      }
      .disabled {
        color: #6c757d !important;
      }
    }
    .disablednav {
      color: #6c757d !important;
    }
  }
`;
const NavigationMenuContainer = styled.div`
  display: flex;
  align-items: center;
  .navigation-buttons {
    padding: 0;
    background: none;
    border: none;
    height: 48px;
  }
  .navigation-buttons-hidden {
    visibility: hidden;
    padding: 0;
    background: none;
    border: none;
    height: 48px;
  }
`;
const NavigationItemsWrapper = styled.div`
  margin: 0 30px;
  display: flex;
  align-items: center;
  width: 560px;
  overflow-y: hidden;
  overflow-x: auto;
  scroll-behavior: smooth;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0px;
    height: 0;
    background: transparent;
  }
`;
function MainPage(): ReactElement {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [key, setKey] = useState<string>("");
  useEffect(() => {
    dispatch(getAssessmentIdAction.getAssessmentIdRequest());
  }, []);

  useEffect(() => {
    if (window.location.pathname === "/Dashboard") {
      navigate("/Dashboard/Home");
      setKey("Home");
    } else if (window.location.pathname === "/Dashboard/Home") {
      setKey("Home");
    } else if (window.location.pathname === "/Dashboard/Assessment") {
      setKey("Assessment");
    } else if (window.location.pathname === "/Dashboard/Audit") {
      setKey("Audit");
    } else if (window.location.pathname === "/Dashboard/Strategy") {
      setKey("Strategy");
    } else if (window.location.pathname === "/Dashboard/Roadmap") {
      setKey("Roadmap");
    } else if (window.location.pathname === "/Dashboard/TOM") {
      setKey("TOM");
    } else if (window.location.pathname === "/Dashboard/Team") {
      setKey("Team");
    } else if (window.location.pathname === "/Dashboard/Organization") {
      setKey("Organization");
    } else if (window.location.pathname === "/Dashboard/Implementation") {
      setKey("Implementation");
    }
  }, [window.location.pathname]);

  const permissionData = useSelector(
    (state: RootState) => state.getTokenPermission,
  );
  const assessmentId = useSelector((state: RootState) => state.getAssessmentId);
  useEffect(() => {
    // console.log(permissionData, "permissionData.permissions");
    if (
      assessmentId.data.assessment === null &&
      !permissionData.loader &&
      !assessmentId.loading &&
      assessmentId.status === 200
    ) {
      if (permissionData.permissions?.includes("edit:MaturityAssessment")) {
        navigate("../MaturityAssessment");
      } else {
        navigate("ErrorPage");
      }
    }
  }, [assessmentId]);

  const onSlectedNavigation = (k: string | null) => {
    if (k === "" && k === null) {
      navigate("Dashboard/Home");
    } else {
      setKey(k as string);
    }
  };
  /// ////// ---------------- Error Handling-------------//////////
  useEffect(() => {
    if (assessmentId.error) {
      errorHandling(assessmentId.status, navigate);
    }
  }, [assessmentId.error]);
  const scrl: any = useRef(null);
  const [scrollX, setscrollX] = useState(0);
  const [scrolEnd, setscrolEnd] = useState(false);
  const slide = (shift: any) => {
    scrl.current.scrollLeft += shift;
    setscrollX(scrollX + shift);

    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };
  const scrollCheck = () => {
    setscrollX(scrl.current.scrollLeft);
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };
  SessionExpiryCheck();
  console.log(permissionData.permissions, "ads");

  return (
    <>
      {permissionData.loader || assessmentId.status !== 200 ? (
        <LoaderPage />
      ) : (
        <>
          <Header />
          <MainContainer>
            <HeaderMenu>
              <Nav
                defaultActiveKey="Home"
                activeKey={key}
                onSelect={(k) => {
                  onSlectedNavigation(k);
                }}
              >
                {" "}
                <NavigationMenuContainer>
                  <button
                    type="button"
                    className={
                      scrollX !== 0
                        ? "navigation-buttons "
                        : "navigation-buttons-hidden"
                    }
                    onClick={() => slide(-140)}
                  >
                    <Icons.LeftButtonIcon />
                  </button>

                  {/* ****************** COMMENT BACK ****************** */}

                  <NavigationItemsWrapper ref={scrl} onScroll={scrollCheck}>
                    <Nav.Item>
                      <Nav.Link as={NavLink} to="Home" eventKey="Home">
                        Dashboard
                      </Nav.Link>
                    </Nav.Item>
                    {permissionData.permissions?.includes(
                      "view:MaturityAssessment",
                    ) ||
                    permissionData.permissions?.includes(
                      "edit:MaturityAssessment",
                    ) ||
                    permissionData.permissions?.includes(
                      "view:OrganizationChart",
                    ) ||
                    permissionData.permissions?.includes(
                      "edit:OrganizationChart",
                    ) ? (
                      <Nav.Item>
                        <Nav.Link
                          as={NavLink}
                          to="Assessment"
                          eventKey="Assessment"
                        >
                          Assessment
                        </Nav.Link>
                      </Nav.Item>
                    ) : null}
                    {permissionData?.role === "Auditor" ? (
                      <Nav.Item>
                        <Nav.Link as={NavLink} to="Audit">
                          Audit
                        </Nav.Link>
                      </Nav.Item>
                    ) : null}
                    {permissionData.permissions?.includes("view:Strategy") ||
                    permissionData.permissions?.includes("edit:Strategy") ? (
                      <Nav.Item>
                        <Nav.Link
                          as={NavLink}
                          to="Strategy"
                          eventKey="Strategy"
                        >
                          Strategy
                        </Nav.Link>
                      </Nav.Item>
                    ) : null}

                    {permissionData.permissions?.includes("view:RoadMap") ||
                    permissionData.permissions?.includes("edit:RoadMap") ? (
                      <Nav.Item>
                        <Nav.Link as={NavLink} to="Roadmap" eventKey="Roadmap">
                          Roadmap
                        </Nav.Link>
                      </Nav.Item>
                    ) : null}

                    <Nav.Item>
                      <Nav.Link as={NavLink} to="Implementation">
                        Implementation
                      </Nav.Link>
                    </Nav.Item>
                    {permissionData.permissions?.includes(
                      "view:Demographics",
                    ) ||
                    permissionData.permissions?.includes(
                      "edit:Demographics",
                    ) ? (
                      <Nav.Item>
                        <Nav.Link as={NavLink} to="Team" eventKey="Team">
                          Team
                        </Nav.Link>
                      </Nav.Item>
                    ) : null}
                    {permissionData.permissions?.includes("view:TOM") ||
                    permissionData.permissions?.includes("edit:TOM") ? (
                      <Nav.Item>
                        <Nav.Link as={NavLink} to="TOM" eventKey="TOM">
                          TOM
                        </Nav.Link>
                      </Nav.Item>
                    ) : null}
                    {permissionData.permissions?.includes("view:TOM") ||
                    permissionData.permissions?.includes("edit:TOM") ? (
                      <Nav.Item>
                        <Nav.Link
                          as={NavLink}
                          to="Organization"
                          eventKey="Organization"
                        >
                          Organization
                        </Nav.Link>
                      </Nav.Item>
                    ) : null}
                  </NavigationItemsWrapper>

                  {/* <NavigationItemsWrapper ref={scrl} onScroll={scrollCheck}>
                    <Nav.Item>
                      <Nav.Link as={NavLink} to="Home" eventKey="Home">
                        Dashboard
                      </Nav.Link>
                    </Nav.Item>
                    {true ? (
                      <Nav.Item>
                        <Nav.Link
                          as={NavLink}
                          to="Assessment"
                          eventKey="Assessment"
                        >
                          Assessment
                        </Nav.Link>
                      </Nav.Item>
                    ) : null}

                    {true ? (
                      <Nav.Item>
                        <Nav.Link
                          as={NavLink}
                          to="Strategy"
                          eventKey="Strategy"
                        >
                          Strategy
                        </Nav.Link>
                      </Nav.Item>
                    ) : null}

                    {true ? (
                      <Nav.Item>
                        <Nav.Link as={NavLink} to="Roadmap" eventKey="Roadmap">
                          Roadmap
                        </Nav.Link>
                      </Nav.Item>
                    ) : null}

                    <Nav.Item>
                      <Nav.Link as={NavLink} to="Implementation">
                        Implementation
                      </Nav.Link>
                    </Nav.Item>
                    {true ? (
                      <Nav.Item>
                        <Nav.Link as={NavLink} to="Team" eventKey="Team">
                          Team
                        </Nav.Link>
                      </Nav.Item>
                    ) : null}
                    {true ? (
                      <Nav.Item>
                        <Nav.Link as={NavLink} to="TOM" eventKey="TOM">
                          TOM
                        </Nav.Link>
                      </Nav.Item>
                    ) : null}
                    {true ? (
                      <Nav.Item>
                        <Nav.Link
                          as={NavLink}
                          to="Organization"
                          eventKey="Organization"
                        >
                          Organization
                        </Nav.Link>
                      </Nav.Item>
                    ) : null}
                  </NavigationItemsWrapper> */}
                  <button
                    type="button"
                    className={
                      !scrolEnd
                        ? "navigation-buttons "
                        : "navigation-buttons-hidden"
                    }
                    onClick={() => slide(+140)}
                  >
                    <Icons.RightButtonIcon />
                  </button>
                </NavigationMenuContainer>
              </Nav>
            </HeaderMenu>
            <InnerContainer>
              <Sidebar Navkey={key} />
              <Outlet />
            </InnerContainer>
          </MainContainer>
        </>
      )}
    </>
  );
}

export { MainPage };
