import { ReactElement, useEffect, useState } from "react";
import { Image } from "rebass";
import { Dropdown } from "react-bootstrap";
import { useAuth0, User } from "@auth0/auth0-react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  MainContainer,
  StyledSidebar,
  ProfileDetails,
  ImageContainer,
  DropdownContainer,
  LogosContainer,
} from "./style";
import logo from "../../images/headerLogo.svg";
import profilePicture from "../../images/ProfileImage/profilePicture.svg";
import { getTokenPermissionAction } from "../../../pages/LoginPage/getTokenPermissionSlice";
import { useAppDispatch } from "../../../store/hooks";
import { RootState } from "../../../store/store";

interface UserInfo {
  image: object;
  nickname: string;
  name: string;
  picture: string;
  updated_at: string;
  email: string;
  email_verified: boolean;
  sub: string;
  org_id: string;
}

function isUserInfo(user: User | undefined): user is UserInfo {
  return user !== undefined && "org_id" in user && "sub" in user;
}

function Header(): ReactElement {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getTokenPermissionAction.getProfile());
  }, []);
  const profileNameData = useSelector(
    (state: RootState) => state.getTokenPermission,
  );
  const { user, logout } = useAuth0();

  const [Name, SetName] = useState("");
  const navigate = useNavigate();
  const permissionData = useSelector(
    (state: RootState) => state.getTokenPermission,
  );
  useEffect(() => {
    if (isUserInfo(user)) {
      dispatch(getTokenPermissionAction.setUserInfoArray(user));
    }
    if (
      profileNameData.profileName !== undefined ||
      profileNameData.profileName !== "" ||
      profileNameData.profileName !== null ||
      profileNameData.error !== true
    ) {
      SetName(profileNameData.profileName as string);
    }
    if (
      profileNameData.error === true ||
      profileNameData.profileName === undefined ||
      profileNameData.profileName === ""
    ) {
      SetName(user?.name as string);
    }
  }, [profileNameData]);

  const logOutHandle = () => {
    // Remove saved data from sessionStorage
    sessionStorage.clear();
    logout({ returnTo: window.location.origin });
  };

  return (
    <MainContainer>
      <StyledSidebar>
        <LogosContainer>
          {user === undefined || user.image.image === undefined ? (
            <img src={logo} alt="website-logo" />
          ) : (
            <img src={user?.image.image} alt="website-logo" />
          )}
        </LogosContainer>
        <ProfileDetails>
          <ImageContainer>
            <Image
              src={user !== undefined ? user.picture : profilePicture}
              alt="profile image"
              variant="avatar"
              style={{ borderRadius: "50%" }}
            />
          </ImageContainer>
          <DropdownContainer>
            <Dropdown drop="end">
              <Dropdown.Toggle variant="transparent" id="dropdown-basic">
                {Name}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {window.location.pathname === "/Admin/Users" ||
                window.location.pathname === "/Admin/Roles" ? (
                  <Dropdown.Item onClick={() => navigate("../Dashboard/Home")}>
                    Dashboard
                  </Dropdown.Item>
                ) : permissionData.role === "Admin" ? (
                  <Dropdown.Item onClick={() => navigate("../Admin/Users")}>
                    Admin
                  </Dropdown.Item>
                ) : null}
                {window.location.pathname !== "/ProfileBuilder" ? (
                  <Dropdown.Item
                    onClick={() => {
                      navigate("../ProfileBuilder");
                      window.location.reload();
                    }}
                  >
                    Profile
                  </Dropdown.Item>
                ) : (
                  <Dropdown.Item onClick={() => navigate("../Dashboard/Home")}>
                    Dashboard
                  </Dropdown.Item>
                )}
                <Dropdown.Item onClick={logOutHandle}>Log Out</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </DropdownContainer>
        </ProfileDetails>
      </StyledSidebar>
    </MainContainer>
  );
}

export { Header };
