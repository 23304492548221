const fontSizes = {
  fzSmall: "0.75rem",
  fz10: "0.9375rem",
  fz11: "1rem",
  fz12: "1.125rem",
  fz13: "1.188rem",
  fz14: "1.438rem",
  fz15: "1.5rem",
  fz16: "1.625rem",
  fz17: "1.75rem",
  fz18: "1.875rem",
  fz19: "2rem",
  fz20: "2.125rem",
  fz21: "2.25rem",
  fz22: "2.375rem",
  fz23: "2.5rem",
  fzXSmall: "0.625rem",
  fz24: "0.875rem",
  fz25: "1rem",
  fz26: "1.25rem",
};

export default fontSizes;
