import styled from "styled-components";
import ThreeDots from "../../../../images/ThreeDotsImage/ThreeDots.svg";

const RolesHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.bluishCyan};
  padding: 19px 16px 5px 16px;
  flex-wrap: wrap;
  .button {
    padding: unset;
  }
  .btn-primary.dropdown-toggle,
  .btn-primary.dropdown-toggle:focus {
    background-color: unset;
    border-color: unset;
    box-shadow: unset;
  }
  .btn {
    padding: unset;
  }
  .btn-primary {
    background-color: unset;
    border-color: unset;
  }
  .dropdown-toggle::after {
    border: unset;
    content: url(${ThreeDots});
    position: absolute;
    top: 0;
    left: -2px;
  }
  .dropDownText {
    font-family: ${({ theme }) => theme.fontFamily.medium};
    font-style: normal;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: ${({ theme }) => theme.fontSizes[1]};
    color: ${({ theme }) => theme.colors.text.black};
    line-height: ${({ theme }) => theme.lineHeight[2]};
    @media screen and (max-width: 1200px) {
      font-size: ${({ theme }) => theme.fontSizes[0]};
    }
  }
`;

const RolesContainer = styled.div`
  background: ${({ theme }) => theme.baseColors.white};
  border: 1px solid ${({ theme }) => theme.colors.border.bluishCyan};
  border-radius: 8px;
  margin-bottom: 17px;
  min-height: 350px;
`;
const RolesHeading = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.medium};
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes[1]};
  color: ${({ theme }) => theme.colors.text.black};
  line-height: ${({ theme }) => theme.lineHeight[2]};
  margin-right: 8px;
  margin-bottom: 8px;
  @media screen and (max-width: 1200px) {
    font-size: ${({ theme }) => theme.fontSizes[0]};
  }
`;
const HeaderContent = styled.div`
  display: flex;
  align-items: center;
`;
const ExperienceDetails = styled.div`
  display: flex;
  align-items: center;
`;

const DetailsContainer = styled.div`
  padding: 0px 16px 22px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
`;
const AttributeHeading = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.medium};
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes[1]};
  color: ${({ theme }) => theme.colors.text.black};
  line-height: ${({ theme }) => theme.lineHeight[2]};
  margin-right: 8px;
  margin-top: 20px;
  margin-bottom: 8px;
  @media screen and (max-width: 1200px) {
    font-size: ${({ theme }) => theme.fontSizes[0]};
  }
`;
const AttributeDataDetailsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;
const DatasDetails = styled.div`
  display: flex;
  align-items: center;
`;
const RepeatTalentsSection = styled.div`
  margin-right: 20px;
  .repeat-button {
    background: unset;
    text-decoration: underline;
    font-size: ${({ theme }) => theme.fontSizes[16]};
    color: ${({ theme }) => theme.colors.text.tealishBlue};
    line-height: ${({ theme }) => theme.lineHeight[14]};
    font-family: ${({ theme }) => theme.fontFamily.semibold};
    border: unset;
    margin-bottom: 3px;
    &:hover,
    &:focus,
    &:active {
      border: unset;
      outline: none;
      box-shadow: unset;
      background-color: unset;
    }
  }
`;
const ButtonDetailsContainer = styled.div`
  display: flex;
  align-items: center;
  .buttons {
    border-radius: 4px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
    &:hover,
    &:focus,
    &:active {
      outline: none;
      box-shadow: unset;
      background-color: unset;
      border: unset;
    }
  }
  .decrement-button {
    background-color: ${({ theme }) => theme.colors.bg.white3};
    border: 1px solid ${({ theme }) => theme.colors.border.mediumGrey1};
    font-size: 35px;
    color: #505a65;
    margin-right: 14px;
    &:hover,
    &:focus,
    &:active {
      outline: none;
      box-shadow: unset;
      background-color: ${({ theme }) => theme.colors.bg.white3};
      border: 1px solid ${({ theme }) => theme.colors.border.mediumGrey1};
    }
  }
  .increment-button {
    color: #ffffff;
    background-color: ${({ theme }) => theme.colors.bg.tealishBlue};
    border: 1px solid ${({ theme }) => theme.colors.border.tealishBlue};
    font-size: 25px;
    &:hover,
    &:focus,
    &:active {
      outline: none;
      box-shadow: unset;
      background-color: ${({ theme }) => theme.colors.bg.tealishBlue};
      border: 1px solid ${({ theme }) => theme.colors.border.tealishBlue};
    }
  }
`;
export {
  RolesHeader,
  RolesContainer,
  RolesHeading,
  HeaderContent,
  ExperienceDetails,
  DetailsContainer,
  AttributeHeading,
  AttributeDataDetailsContainer,
  DatasDetails,
  RepeatTalentsSection,
  ButtonDetailsContainer,
};
