import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ManageRolesProp } from "../../../model/admin";
import { ErrorType } from "../../../model/common";

export interface ManageRoles {
  loading: boolean;
  data: ManageRolesProp[] | undefined;
  status: number;
  error: boolean;
}

export const initialState: ManageRoles = {
  loading: false,
  data: [],
  status: 0,
  error: false,
};

const getRolesSlice = createSlice({
  name: "getRolesList",
  initialState,
  reducers: {
    reset: () => initialState,
    getRolesRequest(state: ManageRoles) {
      state.loading = true;
    },
    getRolesSuccess(
      state: ManageRoles,
      action: PayloadAction<{ data: ManageRolesProp[]; status: number }>,
    ) {
      state.loading = false;
      state.data = action.payload.data;
      state.status = action.payload.status;
    },
    getRolesFailure(
      state: ManageRoles,
      action: PayloadAction<{ error: ErrorType }>,
    ) {
      state.loading = false;
      state.error = true;
      state.status = action.payload.error.response.status;
    },
  },
});

export const getRolesAction = getRolesSlice.actions;
export const getRolesReducer = getRolesSlice.reducer;

export default getRolesReducer;
