import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorType } from "../../../../model/common";
import { EmpDataListOrg } from "../../../../model/wsRoadMap";

type GetEmporgList = {
  loading: boolean;
  data: EmpDataListOrg[];
  status: number;
  error: boolean;
};

export const initialState: GetEmporgList = {
  loading: false,
  data: [],
  status: 0,
  error: false,
};

const emporgSlice = createSlice({
  name: "emporgSlice",
  initialState,
  reducers: {
    reset: () => initialState,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    emporgFetchRequest(state: GetEmporgList) {
      state.loading = true;
    },
    emporgFetchSuccess(
      state: GetEmporgList,
      action: PayloadAction<{ data: EmpDataListOrg[]; status: number }>,
    ) {
      state.loading = false;
      state.data = action.payload.data;
      state.status = action.payload.status;
    },
    emporgFetchFailure(
      state: GetEmporgList,
      action: PayloadAction<{ error: ErrorType }>,
    ) {
      state.loading = false;
      state.error = true;
      state.status = action.payload.error.response.status;
    },
  },
});

export const emporgFetchAction = emporgSlice.actions;
export const emporgFetchReducer = emporgSlice.reducer;

export default emporgFetchReducer;
