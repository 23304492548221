import { takeLatest, put, fork, call } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { PayloadAction } from "@reduxjs/toolkit";
import http from "../../../utils/http.service";
import config from "../../../utils/request.config";
import { abilitiesListAction } from "./abilitiesSlice";
import { getAllAbilities } from "../../../utils/serviceurl";

export const fetchAbilitiesList = async (data: any) =>
  http.GET(
    config.getConfig(
      "",
      `${getAllAbilities}?designationId=${data.roleId.join(
        "&designationId=",
      )}&searchKey=${data.searchKey}`,
    ),
  );

function* onAbilitiesListAsync(action: PayloadAction<any>) {
  try {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const response: AxiosResponse<any> = yield call(
      fetchAbilitiesList,
      action.payload,
    );
    if (response.status === 200) {
      yield put(
        abilitiesListAction.abilitiesListSuccess({
          data: response.data.data,
          status: response.status,
        }),
      );
    }
  } catch (error) {
    // console.log(error);
  }
}

function* getAbilitiesList() {
  yield takeLatest(
    abilitiesListAction.abilitiesListRequest,
    onAbilitiesListAsync,
  );
}

export const abilitiesSaga = [fork(getAbilitiesList)];
