import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorType } from "../../../../../model/common";
import {
  FunctionCard,
  FunctionNameUdpate,
} from "../../../../../model/functionViewModel";

export interface FunctionUdpateGet {
  loading: boolean;
  status: number;
  data: FunctionCard | undefined;
  error: boolean;
}

export const initialState: FunctionUdpateGet = {
  loading: false,
  status: 0,
  data: undefined,
  error: false,
};

const FunctionUpdatenameSlice = createSlice({
  name: "FunctionUpdatenameSlice",
  initialState,
  reducers: {
    reset: () => initialState,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    FunctionUpdatenameRequest(
      state: FunctionUdpateGet,
      action: PayloadAction<{ data: FunctionNameUdpate }>,
    ) {
      state.loading = true;
    },
    FunctionUpdatenameSuccess(
      state: FunctionUdpateGet,
      action: PayloadAction<{ data: FunctionCard; status: number }>,
    ) {
      state.loading = false;
      state.data = action.payload.data;
      state.status = action.payload.status;
    },
    FunctionUpdatenameFailure(
      state: FunctionUdpateGet,
      action: PayloadAction<{ error: ErrorType }>,
    ) {
      state.loading = false;
      state.error = true;
      state.status = action.payload.error.response.status;
    },
  },
});

export const FunctionUpdateNameAction = FunctionUpdatenameSlice.actions;
export const FunctionUpdateNameReducer = FunctionUpdatenameSlice.reducer;

export default FunctionUpdateNameReducer;
