import React, { ReactElement } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const RoadMapHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 11px;
  padding-top: 19px;
`;
const RoadMapHeading = styled.h1`
  font-family: ${({ theme }) => theme.fontFamily.bold};
  font-style: normal;
  font-weight: normal;
  font-size: ${({ theme }) => theme.fontSizes[4]};
  line-height: ${({ theme }) => theme.lineHeight[5]};
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.text.black};
`;
const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  button {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 24px;
    background: ${({ theme }) => theme.colors.bg.tealishBlue};
    border-radius: 6px;
    font-family: ${({ theme }) => theme.fontFamily.bold};
    font-style: normal;
    font-weight: normal;
    font-size: ${({ theme }) => theme.fontSizes[1]};
    line-height: ${({ theme }) => theme.lineHeight[17]};
    letter-spacing: 0.005em;
    color: ${({ theme }) => theme.colors.text.white};
    &:focus {
      box-shadow: none;
    }
  }
`;
type HeaderProps = {
  title: string;
  buttonText: string;
  RoadMapButton: boolean;
};
function HeaderInnerPagesStatergy({
  title,
  buttonText,
  RoadMapButton,
}: HeaderProps): ReactElement {
  const navigate = useNavigate();

  return (
    <RoadMapHeader>
      <RoadMapHeading>{title}</RoadMapHeading>
      <ButtonContainer>
        <Button
          variant="primary"
          type="submit"
          disabled={RoadMapButton}
          onClick={() => navigate("../Roadmap")}
        >
          {buttonText}
        </Button>
      </ButtonContainer>
    </RoadMapHeader>
  );
}

export { HeaderInnerPagesStatergy };
