import { takeLatest, put, fork, call } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { PayloadAction } from "@reduxjs/toolkit";
import http from "../../../../../utils/http.service";
import { hrOrgFetchAction, hrPreviewSaveAction } from "./hrOrgSlice";
import config from "../../../../../utils/request.config";
import {
  tomOrgRoadmapUrl,
  hrPreviewSaveUrl,
} from "../../../../../utils/serviceurl";
import { APIResponse, ErrorType } from "../../../../../model/common";

export const gethrOrgDetails = async (data: string) => {
  const requestUrl =
    data !== "old" ? `${tomOrgRoadmapUrl}?type=new` : tomOrgRoadmapUrl;
  return http.GET(config.getConfig("", requestUrl, ""));
};

function* onroadMapgetDetailsAsync(action: PayloadAction<string>) {
  try {
    const response: AxiosResponse<APIResponse> = yield call(
      gethrOrgDetails,
      action.payload,
    );
    if (response.status === 200) {
      yield put(
        hrOrgFetchAction.hrOrgFetchSuccess({
          data: response.data.data,
          status: response.status,
        }),
      );
    }
  } catch (error) {
    yield put(
      hrOrgFetchAction.hrOrgFetchFailure({
        error: error as ErrorType,
      }),
    );
  }
}

// Udpate date of HRoverview
export const hrPreviewSave = async (data: any) =>
  http.POST(config.postConfig(data, "", hrPreviewSaveUrl, ""));

function* hrPreviewSaveAsync(action: PayloadAction<any>) {
  try {
    const response: AxiosResponse<any> = yield call(
      hrPreviewSave,
      action.payload,
    );
    if (response.status === 200) {
      yield put(
        hrPreviewSaveAction.hrPreviewSaveSuccess({
          status: response.status,
          data: response.data.data,
        }),
      );
    }
  } catch (error) {
    yield put(
      hrPreviewSaveAction.hrPreviewSaveFailure({
        error: error as ErrorType,
      }),
    );
  }
}
function* onempOrgGetList() {
  yield takeLatest(
    hrOrgFetchAction.hrOrgFetchRequest,
    onroadMapgetDetailsAsync,
  );
}

function* hrPreviewSubmit() {
  yield takeLatest(
    hrPreviewSaveAction.hrPreviewSaveRequest,
    hrPreviewSaveAsync,
  );
}

export const hrOrgSaga = [fork(onempOrgGetList), fork(hrPreviewSubmit)];
