import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Responsibilities {
  loading: boolean;
  data: [];
  status: number;
  // error:null| string
}

export const initialState: Responsibilities = {
  loading: false,
  data: [],
  status: 0,
  // error:null
};

const responsibilitiesListSlice = createSlice({
  name: "responsibilitiesList",
  initialState,
  reducers: {
    reset: () => initialState,
    responsibilitiesListRequest(
      state: Responsibilities,
      action: PayloadAction<any>,
    ) {
      state.loading = true;
    },
    responsibilitiesListSuccess(
      state: Responsibilities,
      action: PayloadAction<{ data: any; status: number }>,
    ) {
      state.loading = false;
      state.data = action.payload.data;
      state.status = action.payload.status;
    },
    responsibilitiesListFailure(state: Responsibilities) {
      state.loading = false;
      // state.error = action.payload;
    },
  },
});

export const responsibilitiesListAction = responsibilitiesListSlice.actions;
export const responsibilitiesListReducer = responsibilitiesListSlice.reducer;

export default responsibilitiesListReducer;
