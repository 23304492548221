// Service Url we are currently using
export const getAssessment = "org/assessment";
// Assessment Type & Framework Get api Dashboard
export const assessmentTypeFrameworkGetApi = "maturityAssessmentDropdown";
export const assementIdGetApi = "latestAssessment";

export const wsTechnologyExcelUpload = "resourcePlanning/technologyExcelUpload";
export const wsTalentDetails = "resourcePlanning/talentDetails";
export const wsResoucePlanningSubmit = "resourcePlanning";
export const wsJobDescriptionList = "resourcePlanning/jobDescriptions";
export const maturityAssessmentType = "maturityAssessmentType";
export const maturityAssessmentSubmit = "maturityAssessmentSave";
export const wsRoadMapGet = "roadMaps";
export const getRecommdendationList =
  "resourcePlanning/technologyRecommendations";
export const dashBoardDataList = "dashboard";
export const dashBoardLineChartGet = "dashboard/graph/lineChart";
export const strategyWorkStream = "workStreams";
export const strategyungroupedWorkStream = "workStreams/ungroupedActivities/";
export const getAllDesignations = "getAllDesignations";
export const getAllDepartments = "getAllDepartments";
export const getAllManagers = "getAllManagers";
export const getAllDegrees = "getAllDegrees";
export const getAllDomains = "getAllDomains";
export const saveProfile = "profileBuilder/saveProfile";
export const getAllKnowledges = "getAllKnowledgeByWorkRoleId";
export const getAllSkills = "getAllSkillsByWorkRoleId";
export const getAllAbilities = "getAllAbilitiesByWorkRoleId";
export const getAllResponsibilites = "getAllTasksByWorkRoleId";
export const profileAvatarUpload = "profileAvatarUpload";
export const profileBuilderGetProfile = "profileBuilder/profile";
export const assessmentFileUpload = "assessment/file";
export const wsTalentKSADetails = "resourcePlanning/getAllKSA";
export const getOrgProfiles = "org/profiles";
export const matchingProfiles = "resourcePlanning/matchProfiles";
// Demo Graphics Url/////
export const demographics = "org/profile/demographics";
// Tom Urls////
export const tomuploadURL = "org/chart/upload";
export const tomOrgRoadmapUrl = "org/chart/view";
// Tom Function Veiw Urls///
export const functionView = "org/function_view";
export const functionViewbulkUpdate = "org/function_view_bulk";
export const functionViewProfileDetails = "org/function_view/profile";
export const functionViewDelete = "/org/function_view";
// Getiing Profile Data for showing in Navbar
export const userProfileGet = "ProfileName";
// ... tech-domains urls in roadmap-technology screen....
export const getDomainNames = "domain_names";
export const saveCustomTechnology = "save_other_technology";
// Getting Assessment Result Table
export const dashboardDetailview = "dashboard/detailView";
export const assignOrUnAssignResources = "assignOrUnAssignResources";
export const getCustomDashBoardData = "dashboard/latest";
export const getTimeLineDetails = "resourceplanning/details";
export const hrPreviewSaveUrl = "org/chart/store";
export const getAdminUsers = "admin/users";
export const getManageRolesList = "roles/list";
export const adminUserAssignRole = "roles/assign";
export const budgetDetailsGet = "resourceplanning/budgetDetails";
export const getRiskToleranceList = "risk_tolerance_values";
export const getAssignedUserList = "resourcePlanning/assignedUsers";
export const assignOwner = "resourcePlanning/assignOwner/";
export const userActivation = "admin/enableUser";
export const userDeActivation = "admin/disableUser";
// Get Task
export const getTasks = "mytasks";
export const getAssessmentDetailsById = "assessment";
export const assessmentReviewSubmit = "assessment/review";
// Get Implementation Data
export const getImplementaionList = "implementationOverview";
export const draftReviewAssessDetails = "assessment/excel";
export const assessmentReject = "assessment/reject";
