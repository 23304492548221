import { takeLatest, put, fork, call } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { PayloadAction } from "@reduxjs/toolkit";
import http from "../../../utils/http.service";
import config from "../../../utils/request.config";
import { workStreamTalentDetailsAction } from "./workStreamTalentDetailsSlice";
import {
  wsTalentDetails,
  wsResoucePlanningSubmit,
  wsTalentKSADetails,
} from "../../../utils/serviceurl";
import { workStreamTalentDetailsSubmitAction } from "./workStreamTalentDetailsSubmitSlice ";
import { TalentRequestType } from "../../../model/wSTalent";
import { ksaTalentDetailsSubmitAction } from "./ksaTalentDetailsSlice";
import { workStreamTalentDetailsSearchAction } from "./wsTalentSearchSlice";
import { APIResponse, ErrorType } from "../../../model/common";

/// ////////////////////// ----------------------------------------- For gettting Talent Roles,experience,acquisiation details saga ------------------////////////////
export const fetchworkStreamTalentDetails = async (workStreamId: string) =>
  http.GET(config.getConfig({ workStreamId }, wsTalentDetails, ""));

// for getting in first load
function* onworkStreaTalentDetailsGetAsync(action: PayloadAction<string>) {
  try {
    const response: AxiosResponse<APIResponse> = yield call(
      fetchworkStreamTalentDetails,
      action.payload,
    );
    if (response.status === 200) {
      yield put(
        workStreamTalentDetailsAction.workStreamTalentDetailsSuccess({
          data: response.data.data,
          status: response.status,
        }),
      );
    }
  } catch (error) {
    yield put(
      workStreamTalentDetailsAction.workStreamTalentDetailsFailure({
        error: error as ErrorType,
      }),
    );
  }
}

function* onworkStreamTalentDetailsget() {
  yield takeLatest(
    workStreamTalentDetailsAction.workStreamTalentDetailsRequest,
    onworkStreaTalentDetailsGetAsync,
  );
}

/// ///////////////  ---------- for getting ks Details -------------------------------//////////////////////
export const getKsaDetails = async (designationId: string) =>
  http.GET(
    config.getConfig(
      "",
      `${wsTalentKSADetails}?designationId=${designationId}`,
      "",
    ),
  );

// for Submitting Talents
function* ongetksaTalentDetailsSubmitAsync(action: PayloadAction<string>) {
  try {
    const response: AxiosResponse<APIResponse> = yield call(
      getKsaDetails,
      action.payload,
    );
    if (response.status === 200) {
      yield put(
        ksaTalentDetailsSubmitAction.ksaTalentDetailsSubmitSuccess({
          status: response.status,
          data: response.data,
        }),
      );
    }
  } catch (error) {
    yield put(
      ksaTalentDetailsSubmitAction.ksaTalentDetailsSubmitFailure({
        error: error as ErrorType,
      }),
    );
  }
}

function* onksaFindDetailsRequest() {
  yield takeLatest(
    ksaTalentDetailsSubmitAction.ksaTalentDetailsFindRequest,
    ongetksaTalentDetailsSubmitAsync,
  );
}

/// ////////////////////// ----------------------------------------- For searching sada ------------------////////////////
export const fetchworkStreamTalentSearchDetails = async (searchData: string) =>
  http.GET(config.getConfig({ searchKey: searchData }, wsTalentDetails, ""));

// for getting in search
function* onworkStreaTalentSearchDetailsSubmitAsync(
  action: PayloadAction<string>,
) {
  try {
    const response: AxiosResponse<APIResponse> = yield call(
      fetchworkStreamTalentSearchDetails,
      action.payload,
    );
    if (response.status === 200) {
      yield put(
        workStreamTalentDetailsSearchAction.workStreamTalentDetailsSearchSuccess(
          {
            data: response.data.data,
            status: response.status,
          },
        ),
      );
    }
  } catch (error) {
    yield put(
      workStreamTalentDetailsSearchAction.workStreamTalentDetailsSearchFailure({
        error: error as ErrorType,
      }),
    );
  }
}

function* onworkStreamTalentSearchDetailsget() {
  yield takeLatest(
    workStreamTalentDetailsSearchAction.workStreamTalentDetailsSearchRequest,
    onworkStreaTalentSearchDetailsSubmitAsync,
  );
}

/// ///////////////  ---------- saving the roles -------------------------------//////////////////////
export const submitWorkStreamTalentDetails = async (
  data: TalentRequestType,
) => {
  return http.POST(config.postConfig(data, "", wsResoucePlanningSubmit, ""));
};

// for Submitting Talents
function* onworkStreaTalentDetailsSubmitAsync(
  action: PayloadAction<TalentRequestType>,
) {
  try {
    const response: AxiosResponse<any> = yield call(
      submitWorkStreamTalentDetails,
      action.payload,
    );

    if (response.status === 200) {
      yield put(
        workStreamTalentDetailsSubmitAction.workStreamTalentDetailsSubmitSuccess(
          { status: response.status, data: response.data },
        ),
      );
    }
  } catch (error) {
    yield put(
      workStreamTalentDetailsSubmitAction.workStreamTalentDetailsSubmitFailure({
        error: error as ErrorType,
      }),
    );
  }
}

function* onworkStreamTalentDetailsSubmit() {
  yield takeLatest(
    workStreamTalentDetailsSubmitAction.workStreamTalentDetailsSubmitRequest,
    onworkStreaTalentDetailsSubmitAsync,
  );
}

export const workStreamTalentDetailsSaga = [
  fork(onworkStreamTalentDetailsget),
  fork(onworkStreamTalentSearchDetailsget),
  fork(onworkStreamTalentDetailsSubmit),
  fork(onksaFindDetailsRequest),
];
