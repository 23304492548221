import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Knowledge {
  loading: boolean;
  data: [];
  status: number;
  // error:null| string
}

export const initialState: Knowledge = {
  loading: false,
  data: [],
  status: 0,
  // error:null
};

const knowledgeListSlice = createSlice({
  name: "knowledgeList",
  initialState,
  reducers: {
    reset: () => initialState,
    knowledgeListRequest(state: Knowledge, action: PayloadAction<any>) {
      state.loading = true;
    },
    knowledgeListSuccess(
      state: Knowledge,
      action: PayloadAction<{ data: any; status: number }>,
    ) {
      state.loading = false;
      state.data = action.payload.data;
      state.status = action.payload.status;
    },
    knowledgeListFailure(state: Knowledge) {
      state.loading = false;
      // state.error = action.payload;
    },
  },
});

export const knowledgeListAction = knowledgeListSlice.actions;
export const knowledgeListReducer = knowledgeListSlice.reducer;

export default knowledgeListReducer;
