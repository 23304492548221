import { takeLatest, put, fork, call } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import http from "../../../utils/http.service";
import { getRolesAction } from "./manageRolesSlice";
import { getManageRolesList } from "../../../utils/serviceurl";
import config from "../../../utils/request.config";
import { ErrorType } from "../../../model/common";

export const fetchAdminUsers = async () =>
  http.GET(config.getConfig("", getManageRolesList, ""));

function* onFetchUserAsync() {
  try {
    const response: AxiosResponse<any> = yield call(fetchAdminUsers);
    if (response.status === 200) {
      yield put(
        getRolesAction.getRolesSuccess({
          data: response.data,
          status: response.status,
        }),
      );
    }
  } catch (error) {
    yield put(
      getRolesAction.getRolesFailure({
        error: error as ErrorType,
      }),
    );
  }
}

function* onFetchUserDataList() {
  yield takeLatest(getRolesAction.getRolesRequest, onFetchUserAsync);
}

export const manageRolesSaga = [fork(onFetchUserDataList)];
