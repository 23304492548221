import React, { ReactElement, MouseEvent } from "react";
import styled from "styled-components";
import icons from "../../icons";

const DropSelection = styled.div`
  padding: 7px 10px;
  background: ${({ theme }) => theme.colors.bg.tealishBlue};
  border-radius: 100px 100px 100px 100px;
  display: flex;
  align-items: center;
  height: 28px;
  margin-right: 8px;
  margin-bottom: 8px;
`;
const StyledDropButton = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.medium};
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes[0]};
  line-height: ${({ theme }) => theme.lineHeight[0]};
  letter-spacing: 0.005em;
  color: ${({ theme }) => theme.colors.text.white};
`;
const CloseIcon = styled.div`
  margin-left: 6px;
  cursor: pointer;
`;
const TwoSideButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
  margin-bottom: 8px;
`;
const FirstSide = styled.div`
  padding: 7px 10px;
  background: ${({ theme }) => theme.colors.bg.tealishBlue};
  border-radius: 100px 0px 0px 100px;
  display: flex;
  align-items: center;
  height: 28px;
`;
const SecondSide = styled.div`
  display: flex;
  align-items: center;
  padding: 7px 10px;
  background: ${({ theme }) => theme.colors.bg.blueShade};
  border-radius: 0px 100px 100px 0px;
  height: 28px;
`;
const GoodToHaveText = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.medium};
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes[0]};
  line-height: ${({ theme }) => theme.lineHeight[0]};
  letter-spacing: 0.005em;
  color: ${({ theme }) => theme.colors.text.green};
`;
const MustHaveText = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.medium};
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes[0]};
  line-height: ${({ theme }) => theme.lineHeight[0]};
  letter-spacing: 0.005em;
  color: ${({ theme }) => theme.colors.text.purple};
`;
type DropButtonProps = {
  id: string;
  Talentid: string;
  attribtueid: string;
  title: string;
  attributeName: string;
  mainAttribute: boolean;
  experience: boolean;
  twosideMust: boolean;
  twosideGood: boolean;
  deleteAttribute: (
    ev: MouseEvent,
    Talentid: string,
    attribtueid: string,
    attributeName: string,
    attributeitemid: string,
  ) => void;
};

function DropButton({
  id,
  Talentid,
  attribtueid,
  title,
  mainAttribute,
  experience,
  twosideMust,
  twosideGood,
  attributeName,
  deleteAttribute,
}: DropButtonProps): ReactElement {
  return (
    <>
      {twosideMust || twosideGood ? (
        <TwoSideButtonContainer>
          <FirstSide>
            {mainAttribute && experience ? (
              <StyledDropButton>{title} years</StyledDropButton>
            ) : (
              <StyledDropButton>{title}</StyledDropButton>
            )}
          </FirstSide>
          <SecondSide>
            {twosideMust ? (
              <>
                <MustHaveText>Must have</MustHaveText>
                <CloseIcon
                  onClick={(ev: MouseEvent) =>
                    deleteAttribute(
                      ev,
                      Talentid,
                      attribtueid,
                      attributeName,
                      id,
                    )
                  }
                >
                  <icons.whiteCloseButton fill="#9A4AFF" />
                </CloseIcon>
              </>
            ) : (
              <>
                <GoodToHaveText>Good to have</GoodToHaveText>
                <CloseIcon
                  onClick={(ev: MouseEvent) =>
                    deleteAttribute(
                      ev,
                      Talentid,
                      attribtueid,
                      attributeName,
                      id,
                    )
                  }
                >
                  <icons.whiteCloseButton fill="#0AB062" />
                </CloseIcon>
              </>
            )}
          </SecondSide>
        </TwoSideButtonContainer>
      ) : (
        <DropSelection>
          {mainAttribute && experience ? (
            <StyledDropButton>{title} years</StyledDropButton>
          ) : (
            <StyledDropButton>{title}</StyledDropButton>
          )}
          <CloseIcon
            onClick={(ev: MouseEvent) =>
              deleteAttribute(ev, Talentid, attribtueid, attributeName, id)
            }
          >
            <icons.whiteCloseButton fill="white" />
          </CloseIcon>
        </DropSelection>
      )}
    </>
  );
}

export { DropButton };
