const lineHeight = {
  lh10: "0.875rem",
  lh11: "1rem",
  lh12: "1.125rem",
  lh13: "1.25rem",
  lh14: "1.375rem",
  lh15: "1.5rem",
  lh16: "1.625rem",
  lh17: "1.75rem",
  lh18: "1.875rem",
  lh19: "2rem",
  lh20: "2.125rem",
  lh21: "2.25rem",
  lh22: "2.375rem",
  lh23: "2.5rem",
  lh24: "1.188",
  lh25: "1.313",
  lhMedium: "1.063rem",
  lhsmall: "0.75rem",
};

export default lineHeight;
