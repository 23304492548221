import styled from "styled-components";

const HRViewMainContainer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.bg.white};
`;
const HRViewHeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 33px;
  .btn-group {
    height: 29px;
    border-radius: 4px;
    button {
      min-width: 68px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: ${({ theme }) => theme.fontFamily.regular};
      font-style: normal;
      font-size: ${({ theme }) => theme.fontSizes[16]};
      line-height: ${({ theme }) => theme.lineHeight[14]};
      color: ${({ theme }) => theme.colors.text.tealishBlue};
      background: ${({ theme }) => theme.colors.bg.white};
      padding: 6px 11px 6px 9px;
      transition: 0.5s;
      &:active,
      &:focus {
        background-color: ${({ theme }) => theme.colors.bg.tealishBlue};
        box-shadow: none;
        color: ${({ theme }) => theme.colors.text.white};
        border-color: ${({ theme }) => theme.colors.bg.tealishBlue};
      }
    }
  }
  .btn-check:focus + .btn-primary {
    box-shadow: none !important;
  }
  .btn-check:checked + .btn-primary:focus {
    box-shadow: none !important;
  }
  label {
    min-width: 68px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: ${({ theme }) => theme.fontFamily.regular};
    font-style: normal;
    font-size: ${({ theme }) => theme.fontSizes[16]};
    line-height: ${({ theme }) => theme.lineHeight[14]};
    color: ${({ theme }) => theme.colors.text.tealishBlue};
    background: ${({ theme }) => theme.colors.bg.white};
    padding: 6px 11px 6px 9px;
  }
  label:active,
  input:active,
  label:focus {
    background-color: ${({ theme }) => theme.colors.bg.tealishBlue} !important;
    box-shadow: none !important;
    color: ${({ theme }) => theme.colors.text.white} !important;
    border-color: ${({ theme }) => theme.colors.bg.tealishBlue} !important;
  }
  .toast-container {
    top: 115px !important;
    margin-right: 20px;
    margin-top: 5px;
    .toast {
      box-shadow: none;
    }
    .toast-header {
      justify-content: center;
      padding: 0 10px 0px 0px;
    }
  }
`;
const HRViewHeading = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.bold};
  font-style: normal;
  font-size: ${({ theme }) => theme.fontSizes[2]};
  line-height: ${({ theme }) => theme.lineHeight[15]};
  color: ${({ theme }) => theme.colors.text.black};
`;
const ArchitectureContainer = styled.div`
  height: calc(100vh - 398px);
  width: 100%;
  position: relative;
  pre {
    z-index: 9;
    position: absolute;
    bottom: 0;
    background: "rgba(0, 0, 0, .5)";
    padding: 20;
    color: "white";
    display: flex;
    align-items: center;
    .btn-group {
      height: 48px;
      border-radius: 10px;
      filter: drop-shadow(0px 4px 10px rgba(31, 56, 98, 0.13));

      button {
        min-width: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: ${({ theme }) => theme.colors.bg.white};
        padding: 6px 11px 6px 9px;
        transition: 0.5s;
        border-radius: 10px 0 0 10px;
        & + button {
          border-radius: 0 10px 10px 0;
        }
        svg {
          color: ${({ theme }) => theme.colors.bg.tealishBlue}!important;
        }
        &:active,
        &:focus,
        &:hover {
          background-color: ${({ theme }) => theme.colors.bg.tealishBlue};
          box-shadow: none;
        }
      }
      button:focus svg,
      button:hover svg,
      button:active svg {
        color: ${({ theme }) => theme.colors.bg.white} !important;
      }
    }
    .scrollbar {
      max-height: 100%;
    }
  }
  .Canvas-module_container__22cN4 {
    width: 100%;
    overflow: visible;
    .profileContainer {
      height: 135px;
      fill: ${({ theme }) => theme.colors.bg.white1};
      stroke: ${({ theme }) => theme.colors.border.bluishCyan};
      rx: 10px;
      ry: 10px;
      stroke-width: 1px;
      padding: 16px;
    }
    .Edge-module_path__u3LT2 {
      stroke: ${({ theme }) => theme.colors.bg.tealishBlue};
    }
  }
  .react-transform-wrapper {
    width: 100% !important;
    overflow: unset;
  }
  .react-transform-component {
    width: 100% !important;
  }
  .track-vertical {
    background-color: ${({ theme }) => theme.colors.bg.bluishCyan};
    height: 100%;
    right: 0;
    width: 3px !important;
    border-radius: 3px !important;
    div {
      background-color: ${({ theme }) =>
        theme.colors.bg.tralishBlue1} !important;
      border-radius: 3px !important;
    }
  }
`;
const DesignationContainer = styled.div`
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
`;
const DesignationDetails = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.bold};
  font-style: normal;
  font-size: ${({ theme }) => theme.fontSizes[16]};
  line-height: ${({ theme }) => theme.lineHeight[14]};
  color: ${({ theme }) => theme.colors.text.black};
  text-align: center;
`;
const UploadSectionContainer = styled.div`
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  .buttonGroupPrimary {
    margin-top: 45px;
    display: flex;
    justify-content: flex-end;
    button {
      flex: unset !important;
    }
  }
`;
const UploadButtonWrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: inline-block;
  margin-left: 50px;

  .btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: ${({ theme }) => theme.fontFamily.bold};
    font-style: normal;
    font-weight: normal;
    font-size: ${({ theme }) => theme.fontSizes[16]};
    color: ${({ theme }) => theme.colors.text.white};
  }
  input[type="file"] {
    font-size: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
  }
`;
const UpdateElementsSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .textContainer {
    display: flex;
    align-items: center;
  }
  img {
    width: 50px;
    height: 53px;
  }
`;
const UpdateSectionContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 7px;
`;
const UpdateInfoHeading = styled.h3`
  font-family: ${({ theme }) => theme.fontFamily.bold};
  font-style: normal;
  font-size: ${({ theme }) => theme.fontSizes[16]};
  line-height: ${({ theme }) => theme.lineHeight[14]};
  color: ${({ theme }) => theme.colors.text.black};
  margin-bottom: 6px;
`;
const UpdateInfoSubHeading = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.medium};
  font-style: normal;
  font-size: ${({ theme }) => theme.fontSizes[0]};
  line-height: ${({ theme }) => theme.lineHeight[0]};
  color: ${({ theme }) => theme.colors.text.lightGrey1};
`;
const UploadedFilesContainer = styled.div`
  position: relative;
`;
const SuccessMessageContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  button {
    border: none;
    background: transparent;
    padding: 0;
    margin-left: auto;
    &:hover {
      path {
        fill: ${({ theme }) => theme.colors.bg.tralishBlue1};
      }
    }
  }
`;
const UploadDetails = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 14px;
`;
const SuccessMessage = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.medium};
  font-style: normal;
  font-weight: normal;
  font-size: ${({ theme }) => theme.fontSizes[0]};
  line-height: 0.875rem;
  color: ${({ theme }) => theme.colors.text.lightGrey1};
  margin-top: 4px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 160px;
`;
const UploadedFileName = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.bold};
  font-style: normal;
  font-weight: normal;
  font-size: ${({ theme }) => theme.fontSizes[16]};
  line-height: ${({ theme }) => theme.lineHeight[14]};
  color: ${({ theme }) => theme.colors.text.black};
`;
const MembersDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 16px;
  background: ${({ theme }) => theme.colors.bg.white};
  border: 1px solid ${({ theme }) => theme.colors.bg.bluishCyan};
  border-radius: 10px;
  min-height: 70px;
  min-width: 190px;
`;
const ProfileImageContainer = styled.div`
  margin-right: 11px;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background: #dee5eb;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 20px;
    height: 20px;
  }
`;
const MembersDetails = styled.h3`
  font-family: ${({ theme }) => theme.fontFamily.bold};
  font-style: normal;
  font-size: ${({ theme }) => theme.fontSizes[1]};
  line-height: ${({ theme }) => theme.lineHeight[14]};
  color: ${({ theme }) => theme.colors.text.black};
  font-weight: normal;
`;
const MembersDetailsYears = styled.h3`
  font-family: ${({ theme }) => theme.fontFamily.bold};
  font-style: normal;
  font-size: ${({ theme }) => theme.fontSizes[1]};
  line-height: ${({ theme }) => theme.lineHeight[14]};
  color: ${({ theme }) => theme.colors.text.black};
  font-weight: normal;
`;
const DownloadButtonContainer = styled.div`
  position: absolute;
  right: 20px;
  top: 0;
  cursor: pointer;
  z-index: 2;
  svg {
    path {
      fill: none;
    }
  }
  button {
    border: none;
    background: #ffffff;
    border-radius: 0px;
  }
  .upload-link {
    font-family: ${({ theme }) => theme.fontFamily.bold};
    font-style: normal;
    font-size: ${({ theme }) => theme.fontSizes[16]};
    line-height: ${({ theme }) => theme.lineHeight[14]};
    color: ${({ theme }) => theme.colors.text.tealishBlue};
  }
`;
export {
  HRViewMainContainer,
  HRViewHeaderSection,
  HRViewHeading,
  ArchitectureContainer,
  DesignationContainer,
  DesignationDetails,
  MembersDetailsContainer,
  ProfileImageContainer,
  MembersDetails,
  UploadSectionContainer,
  UploadButtonWrapper,
  UpdateElementsSection,
  UpdateSectionContent,
  UpdateInfoHeading,
  UpdateInfoSubHeading,
  UploadedFilesContainer,
  SuccessMessageContainer,
  UploadDetails,
  SuccessMessage,
  UploadedFileName,
  MembersDetailsYears,
  DownloadButtonContainer,
};
