import styled from "styled-components";

const MainContainer = styled.div`
  height: 100%;
  position: relative;
  .header {
    background: ${({ theme }) => theme.colors.bg.blue};
    height: 60px;
    border-radius: 10px 10px 0px 0px;
    align-items: center;
    padding-left: 19px;
  }
  .profieldetails {
    .title {
      font-weight: normal;
      font-family: ${({ theme }) => theme.fontFamily.bold};
      font-size: ${({ theme }) => theme.fontSizes[18]};
      color: ${({ theme }) => theme.colors.text.white};
      line-height: 24px;
      text-transform: uppercase;
    }
  }
  .main-wrapper {
    height: calc(100vh - 130px);
    border: 1px solid ${({ theme }) => theme.colors.border.bluishCyan};
    padding: 50px 24px 110px 21px;
    @media screen and (max-width: 1300px) {
      padding: 50px 24px 30px 21px;
    }
  }
`;
const SearchBarContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
  height: 48px;
  .searchContainer {
    display: flex;
    position: relative;
    height: 48px;
    float: right;
  }
  .searchBar {
    border: 1px solid ${({ theme }) => theme.colors.border.bluishCyan1};
    border-radius: 6px;
    border-top-right-radius: unset;
    border-bottom-right-radius: unset;
    height: 48px;
    padding-left: 20px;
    padding-top: 16px;
    padding-bottom: 15px;
    font-family: ${({ theme }) => theme.fontFamily.regular};
    font-style: normal;
    font-weight: normal;
    font-size: ${({ theme }) => theme.fontSizes[1]};
    line-height: ${({ theme }) => theme.lineHeight[14]};
    color: ${({ theme }) => theme.colors.text.black};
    width: 250px;
  }
  .searchBar:focus {
    box-shadow: unset;
  }
  .form-control::placeholder {
    color: ${({ theme }) => theme.colors.text.mediumGrey1};
  }
`;
const IconContainer = styled.div`
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.bg.tealishBlue};
  border-radius: 0px 6px 6px 0px;
  width: 48px;
  cursor: pointer;
`;
const TableContainer = styled.div`
  height: calc(100vh - 300px);
  border-bottom: 1px solid #dee5eb;
  overflow: auto;
  scrollbar-width: thin;
  @media screen and (max-width: 1300px) {
    height: calc(100vh - 230px);
  }
  .basic-table {
    /* width: 100%; */
    min-width: 1200px;
    border-collapse: separate;
    border-spacing: 0;
    thead {
      position: sticky;
      top: 0;
    }
    .cell {
      background-color: white;
      padding: 0px;
      border-bottom: 1px solid #dee5eb;
    }
    tbody tr td:first-child {
      border-left: 3px solid #dee5eb;
    }
    tbody tr td:last-child {
      border-right: 1px solid #dee5eb;
    }
    tbody td {
      border-bottom: 1px solid #dee5eb;
    }
    th {
      .heading {
        display: flex;
        align-items: center;
        /* justify-content: center; */
        border-bottom: 1px solid #dee5eb;
        height: 36px;
      }
      .headingFilter {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #dee5eb;
        height: 36px;
        padding-left: 1rem;
      }
      h2 {
        font-family: ${({ theme }) => theme.fontFamily.bold};
        font-style: normal;
        font-size: ${({ theme }) => theme.fontSizes[16]};
        line-height: ${({ theme }) => theme.lineHeight[14]};
        text-transform: uppercase;
        color: ${({ theme }) => theme.colors.text.tealishBlue};
        text-align: center;
      }
      .filter-details {
        padding-right: 10px;
        button {
          border: none;
          background: none;
          padding: 0px;
          margin-top: 5px;
        }
      }
      @media screen and (max-width: 1300px) {
        .filter-details {
          padding-right: 0px;
          button {
            border: none;
            background: none;
            padding: 0px;
            margin-top: 5px;
          }
        }
      }
    }
    tbody {
      background: #ffffff;
      border: 1px solid #dee5eb;
      border-left: 3px solid #dee5eb;
      border-radius: 3px;
    }
    tr {
      border-bottom: 1px solid #dee5eb;
    }
    td {
      padding: 16px 0px 16px 0px;
      font-family: ${({ theme }) => theme.fontFamily.medium};
      font-style: normal;
      font-size: ${({ theme }) => theme.fontSizes[16]};
      line-height: ${({ theme }) => theme.lineHeight[14]};
      color: ${({ theme }) => theme.colors.text.black};
      vertical-align: top;
    }
    ul {
      text-align: left;
    }
    li {
    }
    .recommendation {
      text-align: left;
    }
    .functionName {
      width: 10%;
      padding-left: 1rem;
    }
    .categoryName {
      width: 10%;
      padding-right: 1rem;
    }
    .control_id {
      width: 10%;
    }
    .subCategory {
      width: 20%;
      padding-right: 1rem;
    }
    .current {
      width: 10%;
      text-align: center;
    }
    .target {
      width: 10%;
      text-align: center;
    }
    .benchmar {
      width: 10%;
      text-align: center;
    }
    .priority {
      width: 10%;
      padding-left: 1rem;
    }
    .recommendation {
      width: 10%;
    }

    @media screen and (max-width: 1300px) {
      .recommendation {
        text-align: left;
      }
      .functionName {
        width: 10%;
      }
      .categoryName {
        width: 10%;
      }
      .control_id {
        width: 10%;
      }
      .subCategory {
        width: 20%;
      }
      .current {
        width: 10%;
        text-align: center;
      }
      .target {
        width: 10%;
        text-align: center;
      }
      .benchmar {
        width: 10%;
      }
      .priority {
        width: 10%;
      }
      .recommendation {
        width: 10%;
      }
    }
  }
`;

export { MainContainer, IconContainer, SearchBarContainer, TableContainer };
