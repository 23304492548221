function percentageCalc(status: string) {
  switch (status) {
    case "preparing":
      return 0;
    case "getting_upload_params":
      return 25;
    case "uploading":
      return 50;
    case "headers_received":
      return 75;
    case "done":
      return 100;
    default:
      return 0;
  }
}

export { percentageCalc };
