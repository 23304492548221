import { takeLatest, put, fork, call } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import http from "../../../utils/http.service";
import config from "../../../utils/request.config";
import { degreeListAction } from "./educationGetSlice";
import { getAllDegrees } from "../../../utils/serviceurl";

export const fetchDegreeList = async () =>
  http.GET(config.getConfig("", getAllDegrees, ""));

function* onDegreeListGetAsync() {
  try {
    const response: AxiosResponse<any> = yield call(fetchDegreeList);
    if (response.status === 200) {
      yield put(
        degreeListAction.degreeListSuccess({
          data: response.data.data,
          status: response.status,
        }),
      );
    }
  } catch (error) {
    // console.log(error);
  }
}

function* onDegreeListGet() {
  yield takeLatest(degreeListAction.degreeListRequest, onDegreeListGetAsync);
}

export const educationSaga = [fork(onDegreeListGet)];
