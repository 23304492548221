import React from "react";
import styled from "styled-components";
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle,
} from "chart.js";
import GraphPieChart from "../../../pages/MainPage/AssessmentResult/GraphPieChart";

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle,
);

const StyledRadarDetails = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.border.default};
  margin: 0;
  border-radius: 10px;
  overflow: hidden;
  padding: 28px;
  display: flex;
  min-height: 500px;
  text {
    font-size: 14px !important;
  }
`;

const Legends = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 20px;
  gap: 18px;
  align-items: center;
  font-family: ${({ theme }) => theme.fontFamily.medium};
  font-size: ${({ theme }) => theme.fontSizes[1]};
  line-height: ${({ theme }) => theme.lineHeight[1]};
  font-weight: normal;
  color: ${({ theme }) => theme.colors.text.black};
  span {
    display: flex;
    align-items: center;
    & + span {
      margin-left: 20px;
    }
    .pr-icon {
      margin-right: 10px;
      margin-top: -1px;
    }
  }
`;

function RadarChart({ PieData }: any) {
  return (
    <StyledRadarDetails>
      <div>
        <b>
          <p style={{ marginBottom: 20 }}>Completion</p>
        </b>
        <GraphPieChart PieData={PieData} />
        <Legends>
          <div style={{ display: "flex", gap: 10 }}>
            <span
              style={{
                height: 18,
                width: 18,
                backgroundColor: "#4182F1",
              }}
            />
            <p
              style={{
                marginBottom: "0px!important",
                fontSize: 17,
                fontWeight: "700!important",
              }}
            >
              No. of Questions answered: &nbsp; <b>{PieData?.[1]}</b>
            </p>
          </div>

          <div style={{ display: "flex", gap: 10 }}>
            <span
              style={{
                height: 18,
                width: 18,
                backgroundColor: "#144881",
              }}
            />
            <p
              style={{
                marginBottom: "0px!important",
                fontSize: 17,
                fontWeight: "700!important",
              }}
            >
              No. of Questions Unanswered: &nbsp; <b>{PieData?.[2]}</b>
            </p>
          </div>
          <div style={{ display: "flex", gap: 10 }}>
            <span
              style={{
                height: 18,
                width: 18,
                backgroundColor: "#afcbf9",
              }}
            />
            <p
              style={{
                marginBottom: "0px!important",
                fontSize: 17,
                fontWeight: "700!important",
              }}
            >
              Total No. of Questions: &nbsp; <b>{PieData?.[0]}</b>
            </p>
          </div>
        </Legends>
      </div>
    </StyledRadarDetails>
  );
}

export { RadarChart };
