import { ReactElement, useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { TechnologyRecommendationList } from "./technologyRecommendationList";
import { TechnologyAdd } from "./technologyAdd";
import { RootState } from "../../../store/store";
import { TechnologyWSModel } from "../../../model/wsTechnology";
import { useAppDispatch } from "../../../store/hooks";
import { domainListTechAction } from "./workStreamTechnologySlice";
import { recommendationListAction } from "./recommedationListSlice";

const StyledWorkStreamTechnology = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 50%);
  height: 100%;
`;
const AddTechnology = styled.div`
  padding: 28px;
  border-right: 1px solid ${({ theme }) => theme.baseColors.greyLight};
  .scrollbar {
    max-height: calc(100% - 40px);
  }
`;
const TechnologyRegimentation = styled.div`
  padding: 28px;
  overflow: hidden;
  min-height: calc(100vh - 160px);
  .scrollbar {
    max-height: 600px;
    .track-vertical {
      height: 100%;
      right: 0;
      background: ${({ theme }) => theme.colors.border.default};
      border-radius: 50px;
      width: 3px !important;
      div {
        background: ${({ theme }) => theme.colors.bg.tealishBlue1};
      }
    }
  }
`;
const Heading = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.bold};
  font-size: ${({ theme }) => theme.fontSizes[2]};
  line-height: ${({ theme }) => theme.lineHeight[3]};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.colors.text.black};
  padding-bottom: 21px;
`;
const TechnologyDetails = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.light};
  font-size: ${({ theme }) => theme.fontSizes[2]};
  line-height: ${({ theme }) => theme.lineHeight[3]};
  font-weight: ${({ theme }) => theme.fontWeights.light};
  color: ${({ theme }) => theme.colors.text.black};
`;
const ScrollWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin-right: 0;
`;

type WorkStreamTechnologyProps = {
  assessmentId: string | null;
  setSelectedId: any;
  selectedId: any;
  // setSelectedTec: any;
  workstreamid: string;
  recommendationDataList: TechnologyWSModel[] | undefined;
  setRecommendationList: (val: TechnologyWSModel[]) => void;
  domain: any;
  setDomain: any;
  domainTechnology: any;
  setDomainTechnology: any;
  isExcelUpload: boolean;
  setIsExcelUpload: any;
  isDropdown: boolean;
  setIsDropdown: any;
  customTechName: string;
  setCustomName: any;
};

function WorkStreamTechnology({
  assessmentId,
  setSelectedId,
  selectedId,
  // setSelectedTec,
  workstreamid,
  recommendationDataList,
  setRecommendationList,
  domain,
  setDomain,
  domainTechnology,
  setDomainTechnology,
  isExcelUpload,
  setIsExcelUpload,
  isDropdown,
  setIsDropdown,
  customTechName,
  setCustomName,
}: WorkStreamTechnologyProps): ReactElement {
  const [customTechnologyArray, setCustomTechnologyArray] = useState([]);
  const recommendationlist = useSelector(
    (state: RootState) => state.workStreamTech,
  );
  const technologyListFromStore: any = useSelector(
    (state: RootState) => state.recommendationList.data,
  );
  const dispatch = useAppDispatch();
  const dataFromStore: any = useSelector((state: any) => state);

  useEffect(() => {
    dispatch(
      recommendationListAction.recommendationListRequest({
        workStreamId: workstreamid,
        assessmentId,
      }),
    );
  }, []);

  useEffect(() => {
    setRecommendationList(technologyListFromStore);
  }, [technologyListFromStore]);

  useEffect(() => {
    setRecommendationList(recommendationlist.data);
  }, [recommendationlist.data]);

  const handleRecommendationList = () => {
    setRecommendationList(recommendationlist?.data);
  };

  useEffect(() => {
    if (recommendationlist?.data?.length > 0) {
      setRecommendationList(recommendationlist?.data);
    }
  }, [recommendationlist?.data?.length]);

  useEffect(() => {
    dispatch(domainListTechAction.getAllDomainRequest());
  }, []);

  useEffect(() => {
    if (customTechnologyArray?.length > 0) {
      setRecommendationList(customTechnologyArray);
    }
  }, [customTechnologyArray]);

  return (
    <StyledWorkStreamTechnology>
      <AddTechnology>
        <Heading>Add technology details</Heading>
        <TechnologyDetails>
          1. Plan the timeline for the workstream
          <br />
          2. Identify technology and people resources required for the
          implementation
          <br />
          3. Plan the resource model
        </TechnologyDetails>

        {/* Add technology details */}
        <TechnologyAdd
          assessmentId={assessmentId}
          workstreamid={workstreamid}
          setRecommendationList={setRecommendationList}
          recommendationDataList={recommendationDataList}
          handleRecommendationList={handleRecommendationList}
          domain={domain}
          setDomain={setDomain}
          domainTechnology={domainTechnology}
          setDomainTechnology={setDomainTechnology}
          domainList={dataFromStore.domainNamesTech.data}
          setCustomTechnologyArray={setCustomTechnologyArray}
          isExcelUpload={isExcelUpload}
          setIsExcelUpload={setIsExcelUpload}
          isDropdown={isDropdown}
          setIsDropdown={setIsDropdown}
          customTechName={customTechName}
          setCustomName={setCustomName}
        />
      </AddTechnology>

      <TechnologyRegimentation>
        <Heading>Technology Inventory</Heading>
        <ScrollWrapper>
          <TechnologyRecommendationList
            technologyRecommendationList={recommendationDataList}
            setRecommendationList={setRecommendationList}
            setSelectedId={setSelectedId}
            selectedId={selectedId}
            // setSelectedTec={setSelectedTec}
          />
        </ScrollWrapper>
      </TechnologyRegimentation>
    </StyledWorkStreamTechnology>
  );
}

export { WorkStreamTechnology };
