import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

function GraphPieChart(props: any) {
  const { PieData } = props;
  const [totalQuestions, answeredQuestions, notApplicableQuestions] =
    PieData || [0, 0, 0];

  // Ensure that totalQuestions is at least 1 to prevent the chart from collapsing
  const safeTotalQuestions = totalQuestions || 1;

  const data = {
    labels: [
      "No. of Questions Unanswered",
      "No. of Questions answered",
      "Unanswered",
    ],
    datasets: [
      {
        // Inner Dataset (Total)
        data: [safeTotalQuestions],
        backgroundColor: ["rgba(65, 130, 241, 0.42)"], // Slightly transparent to see the overlay
        hoverBackgroundColor: ["rgba(65, 130, 241, 0.42)"],
        borderWidth: 0,
        weight: 3,
        label: "Total No. of Questions",
      },
      {
        // Outer Dataset (Breakdown)
        data: [
          notApplicableQuestions,
          answeredQuestions,
          totalQuestions - (answeredQuestions + notApplicableQuestions),
        ],
        backgroundColor: ["#144881", "#4182F1", "rgba(65, 130, 241, 0.42)"],
        hoverBackgroundColor: [
          "#144881",
          "#4182F1",
          "rgba(65, 130, 241, 0.42)",
        ],
        borderWidth: 5,
        weight: 6, // Ensures the outer dataset appears on top
        label: "Question Breakdown",
      },
    ],
  };

  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 20, // Add space between label and chart
      },
    },
    plugins: {
      legend: {
        display: false,
        position: "bottom",
        align: "center",
        padding: 20,
        labels: {
          generateLabels: (chart: any) => {
            const { datasets } = chart.data;
            return datasets[1].data.map((data: any, index: any) => ({
              text: `${chart.data.labels[index]}`,
              fillStyle: datasets[1].backgroundColor[index],
              hidden: false,
              index,
            }));
          },
        },
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem: any) => {
            const { datasetIndex, label, raw: value } = tooltipItem;

            if (datasetIndex === 0) {
              return `Total Questions: ${value}`;
            }
            return `${label}: ${value}`;
          },
        },
      },
    },
    cutout: "50%", // Creates the doughnut effect
  };

  return (
    <div
      style={{
        position: "relative",
        width: "60%",
        height: "60%",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: 30,
        marginBottom: 30,
      }}
    >
      <Doughnut data={data} options={options} />
    </div>
  );
}

export default GraphPieChart;
