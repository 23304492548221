import React, { ReactElement } from "react";
import styled from "styled-components";

const StyledPageHeader = styled.div`
  display: flex;
  padding: 0 0 22px 0;
  justify-content: space-between;
`;
const Left = styled.div`
  display: flex;
`;
const Right = styled.div`
  display: flex;
  flex: 0 0 580px;
`;
const Heading = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[1]};
  font-family: ${({ theme }) => theme.fontFamily.bold};
  color: ${({ theme }) => theme.colors.text.tealishBlue};
  display: flex;
  align-items: center;
  text-transform: uppercase;
`;
const HeadingCount = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;
const Count = styled.div`
  background: ${({ theme }) => theme.colors.bg.blueShade};
  width: 22px;
  height: 22px;
  font-size: ${({ theme }) => theme.fontSizes[1]};
  font-family: ${({ theme }) => theme.fontFamily.bold};
  color: ${({ theme }) => theme.colors.text.tealishBlue};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
`;
const Priority = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[1]};
  font-family: ${({ theme }) => theme.fontFamily.bold};
  color: ${({ theme }) => theme.colors.text.tealishBlue};
  display: flex;
  align-items: center;
  text-transform: uppercase;
  flex: 0 0 290px;
`;
const TimeLine = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[1]};
  font-family: ${({ theme }) => theme.fontFamily.bold};
  color: ${({ theme }) => theme.colors.text.tealishBlue};
  display: flex;
  align-items: center;
  text-transform: uppercase;
  flex: 0 0 290px;
`;
const defaultProps = {
  title: "",
  title2: "",
  title3: "",
  count: "",
};
type PageHeaderProps = {
  title?: string;
  title2?: string;
  title3?: string;
  count?: string;
};

function ImplementationTableHeading({
  title,
  title2,
  title3,
  count,
}: PageHeaderProps): ReactElement {
  return (
    <StyledPageHeader>
      <Left>
        <HeadingCount>
          <Count>{count}</Count>
          <Heading>{title}</Heading>
        </HeadingCount>
      </Left>
      <Right>
        <Priority>{title2}</Priority>
        <TimeLine>{title3}</TimeLine>
      </Right>
    </StyledPageHeader>
  );
}

ImplementationTableHeading.defaultProps = defaultProps;
export { ImplementationTableHeading };
