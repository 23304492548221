import React from "react";
import { Button } from "rebass";
import { Link } from "react-router-dom";
import styled from "styled-components";

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h2 {
    text-align: center;
    font-family: ${({ theme }) => theme.fontFamily.regular};
    font-style: normal;
    font-weight: normal;
    font-size: ${({ theme }) => theme.fontSizes[16]};
    line-height: ${({ theme }) => theme.lineHeight[3]};
    color: #000000;
    margin-bottom: 40px;
  }
  span {
    font-family: ${({ theme }) => theme.fontFamily.bold};
  }
`;
const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  .createButton {
    padding: 16px 48px;
    height: 59px;
    border-radius: 6px;
    background: ${({ theme }) => theme.colors.text.tealishBlue};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 16px;
    font-family: ${({ theme }) => theme.fontFamily.bold};
    font-style: normal;
    font-size: ${({ theme }) => theme.fontSizes[3]};
    line-height: ${({ theme }) => theme.lineHeight[3]};
    color: ${({ theme }) => theme.colors.text.white};
    &:hover {
      background: ${({ theme }) => theme.colors.bg.tralishBlue1};
      border-color: ${({ theme }) => theme.colors.bg.tralishBlue1};
    }
    &:focus {
      background: ${({ theme }) => theme.colors.bg.blue};
      border-color: ${({ theme }) => theme.colors.bg.blue};
    }
  }
  .cancelButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 48px;
    height: 59px;
    border-radius: 7px;
    border: 1px solid ${({ theme }) => theme.colors.border.tealishBlue};
    border-radius: 6px;
    font-family: ${({ theme }) => theme.fontFamily.bold};
    font-style: normal;
    font-size: ${({ theme }) => theme.fontSizes[3]};
    line-height: ${({ theme }) => theme.lineHeight[3]};
    color: ${({ theme }) => theme.colors.text.tealishBlue};
    background: ${({ theme }) => theme.colors.bg.white};
    &:hover {
      background: transparent;
      border-color: ${({ theme }) => theme.colors.bg.tralishBlue1};
      color: ${({ theme }) => theme.colors.bg.tralishBlue1};
    }
    &:focus {
      background: transparent;
      border-color: ${({ theme }) => theme.colors.bg.blue};
      color: ${({ theme }) => theme.colors.bg.blue};
    }
  }
  button:focus {
    box-shadow: none;
  }
`;
function ReassessModal({
  handleClose,
  assessmentType,
  assessmentFramework,
  maturityAssessmentTarget,
}: any) {
  return (
    <MainContainer>
      <h2>
        You are running a <span>{assessmentType}</span> on
        <span> {assessmentFramework}</span> framework.Are you sure you want to
        reassess?
      </h2>
      <ButtonsContainer>
        <Button
          variant="outline-primary"
          className="cancelButton"
          onClick={handleClose}
        >
          Cancel
        </Button>
        {/* <Button variant="primary" className="createButton">
          Create
        </Button> */}
        <Link
          className="createButton"
          to="/MaturityAssessment"
          state={{
            currentActiveTab: 2,
            functionality: "reassess",
            assessmentType,
            assessmentFramework,
            maturityAssessmentTarget,
          }}
          style={{ textDecoration: "none" }}
        >
          Reassess
        </Link>
      </ButtonsContainer>
    </MainContainer>
  );
}

export default ReassessModal;
