import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AssessmentIdtype, ErrorType } from "../../model/common";

export interface GetAssessmentId {
  loading: boolean;
  loadingAssementChange: boolean;
  data: AssessmentIdtype;
  status: number | null;
  error: boolean;
  errorAssementChange: boolean;
  statusAssesmentChange: number;
}

export const initialState: GetAssessmentId = {
  loading: false,
  loadingAssementChange: false,
  data: { assessment: null },
  status: 0,
  error: false,
  errorAssementChange: false,
  statusAssesmentChange: 0,
};

const getAssessmentIdSlice = createSlice({
  name: "GetAssessmentId",
  initialState,
  reducers: {
    /// /////////////-------------------Gettting assessmentId on first loads and after reassessment---------------------///////////////
    reset: () => initialState,
    getAssessmentIdRequest(state: GetAssessmentId) {
      state.loading = true;
    },
    getAssessmentIdSuccess(
      state: GetAssessmentId,
      action: PayloadAction<{ data: AssessmentIdtype; status: number }>,
    ) {
      state.loading = false;
      state.data = action.payload.data;
      state.status = action.payload.status;
    },
    getAssessmentIdFailure(
      state: GetAssessmentId,
      action: PayloadAction<{ error: ErrorType }>,
    ) {
      state.loading = false;
      state.status = action.payload.error.response.status;
      state.error = true;
    },
    /// /////////////-------------------Gettting assessmentId based on framework type selecting in dropdown---------------------///////////////
    getAssessmentIdBasedFrameworkRequest(
      state: GetAssessmentId,
      action: PayloadAction<string>,
    ) {
      state.loadingAssementChange = true;
    },
    getAssessmentIdBasedFrameworkSuccess(
      state: GetAssessmentId,
      action: PayloadAction<{ data: AssessmentIdtype; status: number }>,
    ) {
      state.loadingAssementChange = false;
      state.data = action.payload.data;
      state.statusAssesmentChange = action.payload.status;
    },
    getAssessmentIdBasedFrameworkFailure(
      state: GetAssessmentId,
      action: PayloadAction<{ error: ErrorType }>,
    ) {
      state.loadingAssementChange = false;
      state.status = action.payload.error.response.status;
      state.errorAssementChange = true;
    },
    getAssessmentIdBasedFrameworkSuccesswithoutAssessmentId(
      state: GetAssessmentId,
    ) {
      state.errorAssementChange = true;
      state.statusAssesmentChange = 404;
    },
  },
});

export const getAssessmentIdAction = getAssessmentIdSlice.actions;
export const getAssessmentIdReducer = getAssessmentIdSlice.reducer;

export default getAssessmentIdReducer;
