import React, { ReactElement } from "react";
import styled from "styled-components";

const StyledTextWrapper = styled.p`
  padding: 6px 10px;
  background-color: ${({ theme }) => theme.colors.bg.white3};
  border: 1px solid ${({ theme }) => theme.colors.border.blueShade};
  border-radius: 100px;
  height: 28px;
  font-family: ${({ theme }) => theme.fontFamily.medium};
  line-height: ${({ theme }) => theme.lineHeight[0]};
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.Heading};
  font-size: ${({ theme }) => theme.fontSizes[0]};
  color: ${({ theme }) => theme.colors.text.blue};
  cursor: move;
  transition: background-color 1.2s;
  &:hover {
    background-color: ${({ theme }) => theme.baseColors.primary};
    color: ${({ theme }) => theme.colors.text.white};
  }
`;

type TextWrapperProps = {
  title: string;
};

function TextWrapper({ title }: TextWrapperProps): ReactElement {
  return <StyledTextWrapper className="pr-icon">{title}</StyledTextWrapper>;
}

export { TextWrapper };
