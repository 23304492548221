import { ReactElement, useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import styled, { css } from "styled-components";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import { AttriJob, JobDesc, DataAttri } from "../../../../model/common";

const StyledJobDescriptionList = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 20px;
  row-gap: 20px;
  width: 100%;
`;
type JobDescriptionListHolderProps = {
  active?: boolean;
};

type JobDesExperienceListType = {
  id: string;
  experience: string;
  active: boolean;
};
const JobDescriptionListHolder = styled.section<JobDescriptionListHolderProps>`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.border.bluishCyan};
  border-radius: 8px;
  cursor: pointer;
  transition: ease-in all 200ms;
  overflow: hidden;
  margin-bottom: 8px;
  &:hover,
  &:focus,
  &:active,
  &:focus-within {
    border-color: ${({ theme }) => theme.colors.border.tealishBlue};
  }

  .accordion-button {
    box-shadow: none;
    background: transparent;
    padding: 11px 16px;
    &:not(.collapsed) {
      border-bottom: 1px solid ${({ theme }) => theme.colors.border.default};
    }
  }
  .accordion-item {
    border: none;
  }
  .accordion-body {
    padding: 16px 16px 22px;
  }
  .accordion-button::after {
    width: 9px;
    height: 5px;
    background-size: 100%;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='9' height='5' viewBox='0 0 9 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fillRule='evenodd' clipRule='evenodd' d='M4.04543 4.81694C4.29648 5.06102 4.70352 5.06102 4.95457 4.81694L8.81171 1.06694C9.06276 0.822864 9.06276 0.427136 8.81171 0.183058C8.56066 -0.0610194 8.15363 -0.0610194 7.90257 0.183058L4.5 3.49112L1.09743 0.183058C0.846375 -0.0610194 0.43934 -0.0610194 0.188289 0.183058C-0.0627628 0.427136 -0.0627628 0.822864 0.188289 1.06694L4.04543 4.81694Z' fill='black'/%3e%3c/svg%3e ");
  }
  .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='9' height='5' viewBox='0 0 9 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fillRule='evenodd' clipRule='evenodd' d='M4.04543 4.81694C4.29648 5.06102 4.70352 5.06102 4.95457 4.81694L8.81171 1.06694C9.06276 0.822864 9.06276 0.427136 8.81171 0.183058C8.56066 -0.0610194 8.15363 -0.0610194 7.90257 0.183058L4.5 3.49112L1.09743 0.183058C0.846375 -0.0610194 0.43934 -0.0610194 0.188289 0.183058C-0.0627628 0.427136 -0.0627628 0.822864 0.188289 1.06694L4.04543 4.81694Z' fill='black'/%3e%3c/svg%3e ");
  }
  ${({ active }) =>
    !active &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.bg.tealishBlue};
      .accordion-button {
        border-bottom: 1px solid ${({ theme }) => theme.colors.border.default};
      }
      .collapse:not(.show) {
        display: block;
      }
      .accordion-button::after {
        transform: rotate(-180deg);
      }
    `}
`;
const JobHeadingIconView = styled.div`
  width: 38px;
  height: 38px;
  flex: 0 0 38px;
  border-radius: 38px;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.border.bluishCyan};
  display: flex;
  align-items: center;
  justify-content: Center;
  font-family: ${({ theme }) => theme.fontFamily.black};
  font-size: ${({ theme }) => theme.fontSizes[1]};
  font-weight: ${({ theme }) => theme.fontWeights.black};
  color: ${({ theme }) => theme.baseColors.black};
  margin-right: 16px;
  text-transform: uppercase;
`;
const HeadingWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
const JobHeading = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.medium};
  font-size: ${({ theme }) => theme.fontSizes[1]};
  line-height: ${({ theme }) => theme.lineHeight[1]};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.baseColors.black};
`;

const Years = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: ${({ theme }) => theme.fontSizes[1]};
  line-height: ${({ theme }) => theme.lineHeight[3]};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  color: ${({ theme }) => theme.colors.text.darkGrey2};
  display: flex;
  width: 100%;
  align-items: center;
`;
const JobExpertise = styled.div`
  width: 100%;
  margin-bottom: 16px;
`;
const JobAttributes = styled.div`
  width: 100%;
`;
const SubTitle = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.text.darkGrey2};
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: ${({ theme }) => theme.fontSizes[1]};
  line-height: ${({ theme }) => theme.lineHeight[3]};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  margin-bottom: 3px;
`;

const Details = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.text.black};
  font-family: ${({ theme }) => theme.fontFamily.medium};
  font-size: ${({ theme }) => theme.fontSizes[1]};
  line-height: ${({ theme }) => theme.lineHeight[1]};
  font-weight: normal;
  display: flex;
  flex-wrap: Wrap;
  p {
    &:after {
      content: ",";
      margin-left: 1px;
    }
    & + p {
      margin-left: 3px;
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
  }
`;
type JobDescriptionListProps = {
  jobDescriptionList: JobDesc[];
  setSelectedJob: any;
  setItemClicked: any;
};

function JobDescriptionList({
  jobDescriptionList,
  setSelectedJob,
  setItemClicked,
}: JobDescriptionListProps): ReactElement {
  const [jobDesExperienceList, setJobDesExperienceList] = useState<
    JobDesExperienceListType[]
  >([]);
  const [, setActiveKey] = useState("0");
  useEffect(() => {
    let newExperienceList: JobDesExperienceListType;
    const jobData: JobDesExperienceListType[] = jobDescriptionList.map(
      (item: JobDesc) => {
        const experience = item?.attributes.map((temp: AttriJob) => {
          if (temp.name === "Experience") {
            return temp;
          }
          return null;
        });
        const temp: any = experience?.filter(
          (tt) => tt !== undefined && tt !== null,
        );
        newExperienceList = {
          id: item.id,
          experience: temp[0]?.data[0]?.ksaId,
          active: false,
        };
        return newExperienceList;
      },
    );
    setJobDesExperienceList(jobData);
  }, [jobDescriptionList]);

  // eslint-disable-next-line react/no-unstable-nested-components
  function CustomToggle({ eventKey }: any) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      setActiveKey((prev) => (prev === eventKey ? "" : eventKey)),
    );
    return (
      <button
        type="button"
        style={{
          background: "red",
          top: 0,
          right: 0,
          position: "absolute",
          height: "100%",
          opacity: 0,
        }}
        onClick={(e: any) => {
          e.stopPropagation();
          decoratedOnClick(e);
        }}
      >
        Click me
      </button>
    );
  }

  return (
    <StyledJobDescriptionList>
      <Accordion>
        {jobDescriptionList.map((item: JobDesc, index: number) => (
          <JobDescriptionListHolder
            key={item.id}
            active
            onClick={() => {
              setItemClicked(true);
              setSelectedJob({
                roleId: encodeURI(item?.roleName),
                experience: jobDesExperienceList[index]?.experience
                  ? jobDesExperienceList[index]?.experience
                  : "",
              });
            }}
          >
            <Accordion.Item eventKey={index.toString()}>
              <Accordion.Header onClick={() => setActiveKey(index.toString())}>
                <JobHeadingIconView>
                  {item?.roleName?.substring(0, 2)}
                </JobHeadingIconView>
                <HeadingWrapper>
                  <JobHeading>{item?.roleName}</JobHeading>
                  {jobDesExperienceList?.length > 0 &&
                  jobDesExperienceList[index]?.experience !== undefined ? (
                    <Years>
                      {jobDesExperienceList[index]?.experience} Years
                    </Years>
                  ) : null}
                </HeadingWrapper>
                <CustomToggle eventKey={`${index}`} />
              </Accordion.Header>
              <Accordion.Collapse eventKey={`${index}`}>
                <Accordion.Body>
                  {item.attributes.map((subItem: AttriJob) => {
                    return (
                      // eslint-disable-next-line react/jsx-no-useless-fragment
                      <>
                        {subItem.name !== "Experience" ? (
                          <JobExpertise>
                            <JobAttributes>
                              <SubTitle>{subItem.name}</SubTitle>
                              <Details key={subItem._id}>
                                {subItem.data.map((tempData: DataAttri) => {
                                  return (
                                    <p key={tempData._id}>{tempData.ksaId}</p>
                                  );
                                })}
                              </Details>
                            </JobAttributes>
                          </JobExpertise>
                        ) : null}
                      </>
                    );
                  })}
                </Accordion.Body>
              </Accordion.Collapse>
            </Accordion.Item>
          </JobDescriptionListHolder>
        ))}
      </Accordion>
    </StyledJobDescriptionList>
  );
}

export { JobDescriptionList };
