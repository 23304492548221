import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorType } from "../../../../../model/common";
import { FunctionCard } from "../../../../../model/functionViewModel";

export interface GetFunctionBucketDataList {
  loading: boolean;
  data: FunctionCard[] | undefined;
  status: number;
  error: boolean;
}

export const initialState: GetFunctionBucketDataList = {
  loading: false,
  data: undefined,
  status: 0,
  error: false,
};

const funcGetBucketFunctionDataSlice = createSlice({
  name: "funcGetBucketFunctionDataSlice",
  initialState,
  reducers: {
    reset: () => initialState,
    funcBuncketFetchRequest(state: GetFunctionBucketDataList) {
      state.loading = true;
    },
    funcBuncketFetchSuccess(
      state: GetFunctionBucketDataList,
      action: PayloadAction<{ data: FunctionCard[]; status: number }>,
    ) {
      state.loading = false;
      state.data = action.payload.data;
      state.status = action.payload.status;
    },
    funcBuncketFetchFailure(
      state: GetFunctionBucketDataList,
      action: PayloadAction<{ error: ErrorType }>,
    ) {
      state.loading = false;
      state.error = true;
      state.status = action.payload.error.response.status;
    },
    funcionUpdateData(
      state: GetFunctionBucketDataList,
      action: PayloadAction<FunctionCard[]>,
    ) {
      state.data = action.payload;
    },
  },
});

export const funcBucketGetDataAction = funcGetBucketFunctionDataSlice.actions;
export const funcBucketGetDataReducer = funcGetBucketFunctionDataSlice.reducer;

export default funcBucketGetDataReducer;
