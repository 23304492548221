import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TechnologySubmitProps } from "../../../model/wsTechnology";

export interface GetAbout {
  loading: boolean;
  data: TechnologySubmitProps | undefined;
  status: number;
  // error:null| string
}

export const initialState: GetAbout = {
  loading: false,
  data: undefined,
  status: 0,
  // error:null
};

const workSteramSubmitSlice = createSlice({
  name: "workSteramSubmit",
  initialState,
  reducers: {
    workSteramSubmitRequest(
      state: GetAbout,
      action: PayloadAction<TechnologySubmitProps>,
    ) {
      state.loading = true;
      //   state.posts = action;
    },
    workSteramSubmitSuccess(
      state: GetAbout,
      action: PayloadAction<{ data: any; status: number }>,
    ) {
      state.loading = false;
      state.data = action.payload.data.x;
      state.status = action.payload.status;
    },
    workSteramSubmitFailure(state: GetAbout) {
      state.loading = false;
      // state.error = action.payload;
    },
  },
});

export const workSteramSubmitAction = workSteramSubmitSlice.actions;
export const workSteramSubmitReducer = workSteramSubmitSlice.reducer;

export default workSteramSubmitReducer;
