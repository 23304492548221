import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BudgetDetailsApiProps } from "../../../model/budget";
import { ErrorType } from "../../../model/common";

export interface BudgetType {
  loading: boolean;
  data: any;
  status: number | null;
  error: boolean;
}

export const initialState: BudgetType = {
  loading: false,
  data: [],
  status: 0,
  error: false,
};

const budgetDetailsSubmitSlice = createSlice({
  name: "budgetDetailsSubmit",
  initialState,
  reducers: {
    reset: () => initialState,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    detailsSubmitRequest(state: BudgetType, action: PayloadAction<any>) {
      state.loading = true;
    },
    detailsSubmitSuccess(
      state: BudgetType,
      action: PayloadAction<{ status: number; data: any }>,
    ) {
      state.loading = false;
      state.data = action.payload.data;
      state.status = action.payload.status;
    },
    detailsSubmitFailure(
      state: BudgetType,
      action: PayloadAction<{ error: ErrorType }>,
    ) {
      state.loading = false;
      state.status = action.payload.error.response.status;
      state.error = true;
    },
  },
});

const getBudgetDetailsSlice = createSlice({
  name: "getBudgetDetails",
  initialState,
  reducers: {
    reset: () => initialState,
    detailsRequest(state: BudgetType, action: PayloadAction<any>) {
      state.loading = true;
    },
    detailsSuccess(
      state: BudgetType,
      action: PayloadAction<{ status: number; data: BudgetDetailsApiProps[] }>,
    ) {
      state.loading = false;
      state.data = action.payload.data;
      state.status = action.payload.status;
    },
    detailsFailure(
      state: BudgetType,
      action: PayloadAction<{ error: ErrorType }>,
    ) {
      state.loading = false;
      state.status = action.payload.error.response.status;
      state.error = true;
    },
  },
});

export const budgetDetailsSubmitAction = budgetDetailsSubmitSlice.actions;
export const budgetDetailsSubmitReducer = budgetDetailsSubmitSlice.reducer;
export const getBudgetDetailsAction = getBudgetDetailsSlice.actions;
export const getBudgetDetailsReducer = getBudgetDetailsSlice.reducer;

export default { budgetDetailsSubmitReducer, getBudgetDetailsReducer };
