import React, { ReactElement, useState, useEffect } from "react";
import styled from "styled-components";
import { Button, Form } from "react-bootstrap";
import { FormGroup } from "../../../components";

const MainContainer = styled.div``;
const ContentsHeader = styled.div`
  display: flex;
  align-items: center;

  padding: 5px 5px 10px 5px;
  border-bottom: 1px solid #dee5eb;
`;
const ProfileDetailsContainer = styled.div``;
const ProfileName = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
`;
const Profession = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #505a65;
  text-align: initial !important;
  .experienceCount {
    margin-left: 10px;
  }
`;
const InnerContainer = styled.div`
  padding: 16px 5px 16px 5px;
  .calendarInput {
    text-align: initial !important;
    margin-bottom: 16px;
    display: flex;

    flex-direction: column;

    input {
      min-height: 40px;

      border-radius: 6px;

      border: 1px solid #adb1b7;

      font-size: 14px;

      line-height: 16.8px;

      font-weight: normal;

      color: black;

      padding: 0px 10px;

      cursor: pointer;
    }
  }
  .form-label {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #505a65;
    margin-bottom: 9px;
  }
  .react-date-picker {
    width: 100%;
    .react-calendar {
      padding: 10px;
      border: 1px solid #adb1b7;
      border-radius: 6px;
      abbr[data-bs-original-title],
      abbr[title] {
        text-decoration: none;
        cursor: default;
      }
      .react-calendar__tile--active {
        background: #4182f1;
      }
      .react-calendar__navigation button:enabled:hover,
      .react-calendar__navigation button:enabled:focus {
        background: #4182f1;
        color: white;
      }
      .react-calendar__month-view__days__day--weekend {
        opacity: 0.5;
      }
      .react-calendar__navigation button {
        min-width: 33px;
        background: none;
        padding: 0;
        height: 34px;
      }
      .react-calendar__tile {
        padding: 6px;
      }
    }
    .react-date-picker__wrapper {
      min-height: 40px;
      border-radius: 6px;
      border: 1px solid #adb1b7;

      font-size: 14px;
      line-height: 16.8px;
      font-weight: normal;
      color: black;
      padding: 0px 10px;
      cursor: pointer;

      .react-date-picker__clear-button {
        display: none;
      }
      input {
        &:focus {
          outline: none;
          box-shadow: none;
          border: none;
        }
      }

      .react-date-picker__calendar-button {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fillRule='evenodd' clipRule='evenodd' d='M7.08301 0.833984C7.54324 0.833984 7.91634 1.20708 7.91634 1.66732V2.50065H12.083V1.66732C12.083 1.20708 12.4561 0.833984 12.9163 0.833984C13.3766 0.833984 13.7497 1.20708 13.7497 1.66732V2.50065H17.4997C18.4201 2.50065 19.1663 3.24684 19.1663 4.16732V17.5006C19.1663 18.4211 18.4201 19.1673 17.4997 19.1673H2.49967C1.5792 19.1673 0.833008 18.4211 0.833008 17.5006V4.16732C0.833008 3.24684 1.5792 2.50065 2.49967 2.50065H6.24967V1.66732C6.24967 1.20708 6.62277 0.833984 7.08301 0.833984ZM6.24967 4.16732H2.49967V7.50065H17.4997V4.16732H13.7497V5.00065C13.7497 5.46089 13.3766 5.83398 12.9163 5.83398C12.4561 5.83398 12.083 5.46089 12.083 5.00065V4.16732H7.91634V5.00065C7.91634 5.46089 7.54324 5.83398 7.08301 5.83398C6.62277 5.83398 6.24967 5.46089 6.24967 5.00065V4.16732ZM17.4997 9.16732H13.7497V12.5006H17.4997V9.16732ZM17.4997 14.1673H13.7497V17.5006H17.4997V14.1673ZM12.083 17.5006V14.1673H7.91634V17.5006H12.083ZM6.24967 17.5006V14.1673H2.49967V17.5006H6.24967ZM2.49967 12.5006H6.24967V9.16732H2.49967V12.5006ZM7.91634 9.16732V12.5006H12.083V9.16732H7.91634Z' fill='%236B7588'/%3e%3c/svg%3e ");
        background-repeat: no-repeat;
        background-position: center center;
        padding: 0;
        svg {
          opacity: 0;
          visibility: hidden;
        }
      }
    }
  }
  button {
    font-size: 14px;
    line-height: 1.6875rem;
    text-transform: none;
    min-height: 45px;
    padding: 7px 24px;
    transition: background-color 1s;
  }
  .addButton {
    width: 100%;
  }

  .start-dateClass[type="date"]:not(.has-value):before {
    color: lightgray;
    content: attr(placeholder);
  }
`;

type Taskprop = {
  Name: string;
  ProfessionName: string;
  ExperienceCount: string;
  startDate: any;
  setStartDate: any;
  endDate: any;
  setEndDate: any;
  addUserHandler: any;
  wrkStreamStartDate: Date;
  wrkStreamEndDate: Date;
};
function TooltipContent({
  Name,
  ProfessionName,
  ExperienceCount,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  addUserHandler,
  wrkStreamStartDate,
  wrkStreamEndDate,
}: Taskprop): ReactElement {
  const [submitBtnDisable, setSubmitBtnDisable] = useState(true);

  useEffect(() => {
    if (startDate !== null && endDate !== null) {
      setSubmitBtnDisable(false);
    } else setSubmitBtnDisable(true);
  }, [startDate, endDate]);

  const disablePastDate = (date: any) => {
    const day = new Date(date);
    const dd = String(day.getDate() + 0).padStart(2, "0");
    const mm = String(day.getMonth() + 1).padStart(2, "0"); // January is 0!
    const yyyy = day.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  };

  const incrementDate = (dateInput: any) => {
    const dateFormatTotime = new Date(dateInput);
    const increasedDate = new Date(dateFormatTotime.getTime() + 1 * 86400000);
    const dd = String(increasedDate.getDate() + 0).padStart(2, "0");
    const mm = String(increasedDate.getMonth() + 1).padStart(2, "0"); // January is 0!
    const yyyy = increasedDate.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  };

  return (
    <MainContainer>
      <ContentsHeader>
        {/* <ProfilePicContainer>
          <Image src={ProfilePic} />
        </ProfilePicContainer> */}
        <ProfileDetailsContainer className="ProfileDetailsContainer">
          <ProfileName className="ProfileName">{Name}</ProfileName>
          <Profession>
            {ProfessionName}
            <span className="experienceCount">{ExperienceCount} Years</span>
          </Profession>
        </ProfileDetailsContainer>
      </ContentsHeader>
      <InnerContainer>
        <FormGroup className="calendarInput">
          <Form.Label>Start Date</Form.Label>
          <input
            type="date"
            required
            // placeholder="dd-mm-yyyy"
            //  pattern="Month dd, yyyy"
            className="start-dateClass"
            min={disablePastDate(wrkStreamStartDate)}
            max={disablePastDate(wrkStreamEndDate)}
            onChange={(e: any) => {
              setStartDate(e.target.value);
              setEndDate(null);
            }}
            value={startDate !== null && startDate}
          />
          {/* <DatePicker onChange={(e:any)=>{// console.log('eeeee')}} value={startDate} /> */}
        </FormGroup>
        <FormGroup className="calendarInput">
          <Form.Label>End Date</Form.Label>
          <input
            type="date"
            required
            pattern="Month dd, yyyy"
            onChange={(e: any) => setEndDate(e.target.value)}
            min={incrementDate(startDate)}
            max={disablePastDate(wrkStreamEndDate)}
            value={endDate !== null && endDate}
            disabled={startDate === null}
          />
          {/* <DatePicker onChange={setEndDate} value={endDate} /> */}
        </FormGroup>
        <Button
          variant="primary"
          className="addButton"
          onClick={addUserHandler}
          disabled={submitBtnDisable}
        >
          Add
        </Button>
      </InnerContainer>
    </MainContainer>
  );
}

export { TooltipContent };
