import { takeLatest, put, fork, call } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { PayloadAction } from "@reduxjs/toolkit";
import http from "../../../utils/http.service";
import config from "../../../utils/request.config";
import {
  getTimeLineDetailsAction,
  workStreamDetailsAction,
  getRiskToleranceAction,
} from "./workStreamDetailsSlice";
import {
  getTimeLineDetails,
  wsResoucePlanningSubmit,
  getRiskToleranceList,
} from "../../../utils/serviceurl";
import { DetailsFormProps } from "../../../model/wSDetailsForm";
import { ErrorType } from "../../../model/common";

export const fetchworkStreamDetails = async (data: any) =>
  http.POST(config.postConfig(data, "", wsResoucePlanningSubmit, ""));

function* onworkStreamDetailsSubmitAsync(
  action: PayloadAction<DetailsFormProps>,
) {
  try {
    const response: AxiosResponse<any> = yield call(
      fetchworkStreamDetails,
      action.payload,
    );
    if (response.status === 200) {
      yield put(
        workStreamDetailsAction.workStreamDetailsSuccess({
          data: response.data.data,
          status: response.status,
        }),
      );
    }
  } catch (error) {
    //  // console.log(error);
  }
}

function* onworkStreamDetailsSubmit() {
  yield takeLatest(
    workStreamDetailsAction.workStreamDetailsRequest,
    onworkStreamDetailsSubmitAsync,
  );
}

export const fetchTimeLineDetails = async (data: any) =>
  http.GET(
    config.getConfig(
      "",
      `${getTimeLineDetails}?workStreamId=${data.workStreamId}&assessmentId=${data.assessmentId}`,
    ),
  );

function* onAssessmentIdAsync(action: PayloadAction<any>) {
  try {
    const response: AxiosResponse<any> = yield call(
      fetchTimeLineDetails,
      action.payload,
    );
    if (response.status === 200) {
      yield put(
        getTimeLineDetailsAction.getTimeLineDetailsSuccess({
          data: response.data.data,
          status: response.status,
        }),
      );
    }
  } catch (error) {
    yield put(
      getTimeLineDetailsAction.getTimeLineDetailsFailure({
        error: error as ErrorType,
      }),
    );
  }
}

function* onTimeLineDetailsGet() {
  yield takeLatest(
    getTimeLineDetailsAction.getTimeLineDetailsRequest,
    onAssessmentIdAsync,
  );
}
export const fetchRiskToleranceList = async () =>
  http.GET(config.getConfig("", getRiskToleranceList, ""));

function* onFetchRiskToleranceAsync() {
  try {
    const response: AxiosResponse<any> = yield call(fetchRiskToleranceList);
    if (response.status === 200) {
      yield put(
        getRiskToleranceAction.getRiskToleranceSuccess({
          data: response.data,
          status: response.status,
        }),
      );
    }
  } catch (error) {
    //  // console.log(error);
  }
}
function* onFetchRiskToleranceList() {
  yield takeLatest(
    getRiskToleranceAction.getRiskToleranceRequest,
    onFetchRiskToleranceAsync,
  );
}

export const workStreamDetailsSaga = [
  fork(onworkStreamDetailsSubmit),
  fork(onTimeLineDetailsGet),
  fork(onFetchRiskToleranceList),
];
