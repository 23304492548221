/* eslint-disable import/order */
import "./App.css";
import { ThemeProvider } from "styled-components";
import RouterPage from "./router/routerIndex";
import GlobalStyles from "./ui/styles/globalStyles";
import theme from "./ui/styles/theme";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-dropzone-uploader/dist/styles.css";
import "react-tippy/dist/tippy.css";
import "rc-steps/assets/index.css";
// import { useAuth0 } from "@auth0/auth0-react";

function App() {
  if (
    process.env.REACT_APP_PLATFORM === "QA" ||
    process.env.REACT_APP_PLATFORM === "DEV" ||
    process.env.REACT_APP_PLATFORM === "PROD" ||
    process.env.REACT_APP_PLATFORM === "STAG"
  ) {
    // eslint-disable-next-line @typescript-eslint/no-empty-function, no-console
    // console.log = () => {};
    // eslint-disable-next-line @typescript-eslint/no-empty-function, no-console
    // console.error = () => {};
    // eslint-disable-next-line @typescript-eslint/no-empty-function, no-console
    // console.debug = () => {};
  }

  // const { logout } = useAuth0();
  // const count = 3000; // 3 seconds
  // let lastTime = Date.now();

  // function timerCheck() {
  //   let timer;
  //   if (Date.now() - lastTime >= count * 2) {
  //     // logout code
  //     sessionStorage.clear();
  //     // logout({ returnTo: window.location.origin });
  //     clearTimeout(timer);
  //   }
  //   lastTime = Date.now();
  //   timer = setTimeout(timerCheck, count);
  // }
  // timerCheck();

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <RouterPage />
    </ThemeProvider>
  );
}

export default App;
