import { DragEvent, useEffect, useState } from "react";
import {
  RolesDetails,
  RolesSection,
  ViewMoreButton,
  ViewMoreText,
} from "../../styles";
import { TextWrapper } from "../../../../components";
import icons from "../../../../icons";
import { WsAttributes } from "../../../../../model/wSTalent";

type RolesLeftCardtype = {
  Role: WsAttributes[] | undefined;
  updataKSA: (workRoleIds: string[] | undefined) => void;
  DragRole: (
    ev: DragEvent,
    _id: string,
    role: string,
    workRoleIds: string[],
  ) => void;
};

function RolesLeftCard({ Role, updataKSA, DragRole }: RolesLeftCardtype) {
  // Constants & States
  const [filterRoleView, setfilterRoleView] = useState<WsAttributes[]>();
  const [view, setView] = useState(true);
  useEffect(() => {
    if (Role !== undefined) {
      setfilterRoleView(Role?.slice(0, 3));
    }
  }, [Role]);

  const updateRolesView = () => {
    if (view) {
      setfilterRoleView(Role);
    } else {
      setfilterRoleView(Role?.slice(0, 3));
    }
    setView(!view);
  };

  return (
    <RolesDetails>
      <h4>Job Titles</h4>
      <RolesSection>
        {filterRoleView === undefined || filterRoleView.length === 0 ? (
          "No job titles"
        ) : (
          <>
            {filterRoleView.map((role: WsAttributes) => {
              return (
                <div
                  id={role._id}
                  draggable="true"
                  onClick={() => {
                    updataKSA(role.workRoleIds);
                  }}
                  onDragStart={(ev: DragEvent) => {
                    DragRole(
                      ev,
                      role._id,
                      role.attributeName as string,
                      role.workRoleIds as string[],
                    );
                  }}
                  role="presentation"
                  key={role._id}
                >
                  <TextWrapper title={role.attributeName as string} />
                </div>
              );
            })}

            {view ? (
              <ViewMoreButton
                onClick={() => {
                  updateRolesView();
                }}
              >
                <ViewMoreText>View More</ViewMoreText>
                <icons.downArrow />
              </ViewMoreButton>
            ) : (
              <ViewMoreButton
                onClick={() => {
                  updateRolesView();
                }}
              >
                <ViewMoreText>View less</ViewMoreText>

                <icons.AccordionUpArrow />
              </ViewMoreButton>
            )}
          </>
        )}
      </RolesSection>
    </RolesDetails>
  );
}

export default RolesLeftCard;
