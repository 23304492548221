import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorType } from "../../../../model/common";
import { CustomDashboardType } from "../../../../model/customDashboard";

export interface GetDashboard {
  loading: boolean;
  data: CustomDashboardType | undefined;
  status: number;
  error: boolean;
}

export const initialState: GetDashboard = {
  loading: false,
  data: undefined,
  status: 0,
  error: false,
};

const customDashboardDataSlice = createSlice({
  name: "customDashboardData",
  initialState,
  reducers: {
    reset: () => initialState,
    getCustomDashboardDataRequest(
      state: GetDashboard,
      action: PayloadAction<string>,
    ) {
      state.loading = true;
    },
    getCustomDashboardDataSuccess(
      state: GetDashboard,
      action: PayloadAction<{ data: CustomDashboardType; status: number }>,
    ) {
      state.loading = false;
      state.data = action.payload.data;
      state.status = action.payload.status;
    },
    getCustomDashboardDataFailure(
      state: GetDashboard,
      action: PayloadAction<{ error: ErrorType }>,
    ) {
      state.loading = false;
      state.error = true;
      state.status = action.payload.error.response.status;
    },
  },
});

export const customDashboardFetchAction = customDashboardDataSlice.actions;
export const customDashboardDataReducer = customDashboardDataSlice.reducer;

export default customDashboardDataReducer;
