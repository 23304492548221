import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface GetEducation {
  loading: boolean;
  data: [];
  status: number;
  // error:null| string
}

export const initialState: GetEducation = {
  loading: false,
  data: [],
  status: 0,
  // error:null
};

const degreeListSlice = createSlice({
  name: "degreeList",
  initialState,
  reducers: {
    degreeListRequest(state: GetEducation) {
      state.loading = true;
    },
    degreeListSuccess(
      state: GetEducation,
      action: PayloadAction<{ data: any; status: number }>,
    ) {
      state.loading = false;
      state.data = action.payload.data;
      state.status = action.payload.status;
    },
    degreeListFailure(state: GetEducation) {
      state.loading = false;
      // state.error = action.payload;
    },
  },
});

export const degreeListAction = degreeListSlice.actions;
export const degreeListReducer = degreeListSlice.reducer;

export default degreeListReducer;
