/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorType } from "../../../model/common";
import { WsSortedType } from "../../../model/statergyWS";

export interface GetwsStrategyList {
  loading: boolean;
  data: WsSortedType[] | null;
  status: number;
  error: boolean;
}

export const initialState: GetwsStrategyList = {
  loading: false,
  data: null,
  status: 0,
  error: false,
};

const statergyFetchSlice = createSlice({
  name: "statergyFetchSlice",
  initialState,
  reducers: {
    reset: () => initialState,
    strategyFetchRequest(
      state: GetwsStrategyList,
      action: PayloadAction<string>,
    ) {
      state.loading = true;
    },
    strategyFetchSuccess(
      state: GetwsStrategyList,
      action: PayloadAction<{ data: WsSortedType[]; status: number }>,
    ) {
      state.loading = false;
      state.data = action.payload.data;
      state.status = action.payload.status;
    },
    strategyFetchFailure(
      state: GetwsStrategyList,
      action: PayloadAction<{ error: ErrorType }>,
    ) {
      state.loading = false;
      state.error = true;
      state.status = action.payload.error.response.status;
    },
  },
});

export const strategyFetchAction = statergyFetchSlice.actions;
export const strategyFetchReducer = statergyFetchSlice.reducer;

export default strategyFetchReducer;
