import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AdminUserListProp, AssignRoleProp } from "../../../model/admin";
import { ErrorType } from "../../../model/common";

export interface ManageAdminUser {
  loading: boolean;
  data: any;
  status: number;
  error: boolean;
}

export const initialState: ManageAdminUser = {
  loading: false,
  data: [],
  status: 0,
  error: false,
};

const getAdminUsersSlice = createSlice({
  name: "getAdminUsers",
  initialState,
  reducers: {
    reset: () => initialState,
    getAdminUsers(state: ManageAdminUser) {
      state.loading = true;
    },
    getAdminUsersSuccess(state: ManageAdminUser, action: PayloadAction<any>) {
      state.loading = false;
      state.data = action.payload.data;
      state.status = action.payload.status;
    },
    getAdminUsersFailure(
      state: ManageAdminUser,
      action: PayloadAction<{ error: ErrorType }>,
    ) {
      state.loading = false;
      state.error = true;
      state.status = action.payload.error.response.status;
    },
  },
});

export const getAdminUsersAction = getAdminUsersSlice.actions;
export const getAdminUsersReducer = getAdminUsersSlice.reducer;
const assignUserRoleSlice = createSlice({
  name: "assignUserRole",
  initialState,
  reducers: {
    reset: () => initialState,
    roleAssignRequest(
      state: ManageAdminUser,
      action: PayloadAction<{ data: AssignRoleProp }>,
    ) {
      state.loading = true;
    },
    roleAssignSuccess(
      state: ManageAdminUser,
      action: PayloadAction<{ data: AdminUserListProp[]; status: number }>,
    ) {
      state.loading = false;
      state.data = action.payload.data;
      state.status = action.payload.status;
    },
    roleAssignFailure(
      state: ManageAdminUser,
      action: PayloadAction<{ error: ErrorType }>,
    ) {
      state.loading = false;
      state.error = true;
      state.status = action.payload.error.response.status;
    },
  },
});

export const assignUserRoleAction = assignUserRoleSlice.actions;
export const assignUserRoleReducer = assignUserRoleSlice.reducer;

const activateUserSlice = createSlice({
  name: "activateUser",
  initialState,
  reducers: {
    reset: () => initialState,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    activateRequest(state: ManageAdminUser, action: PayloadAction<any>) {
      state.loading = true;
    },
    activateSuccess(
      state: ManageAdminUser,
      action: PayloadAction<{ data: any; status: number }>,
    ) {
      state.loading = false;
      state.status = action.payload.status;
      state.data = action.payload.data;
    },
    activateFailure(
      state: ManageAdminUser,
      action: PayloadAction<{ error: ErrorType }>,
    ) {
      state.loading = false;
      state.error = true;
      state.status = action.payload.error.response.status;
    },
  },
});

export const activateUserAction = activateUserSlice.actions;
export const activateUserReducer = activateUserSlice.reducer;

const deActivateUserSlice = createSlice({
  name: "deActivateUser",
  initialState,
  reducers: {
    reset: () => initialState,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    deActivateRequest(state: ManageAdminUser, action: PayloadAction<any>) {
      state.loading = true;
    },
    deActivateSuccess(
      state: ManageAdminUser,
      action: PayloadAction<{ data: any; status: number }>,
    ) {
      state.loading = false;
      state.status = action.payload.status;
      state.data = action.payload.data;
    },
    deActivateFailure(
      state: ManageAdminUser,
      action: PayloadAction<{ error: ErrorType }>,
    ) {
      state.loading = false;
      state.error = true;
      state.status = action.payload.error.response.status;
    },
  },
});

export const deActivateUserAction = deActivateUserSlice.actions;
export const deActivateUserReducer = deActivateUserSlice.reducer;

export default {
  getAdminUsersReducer,
  assignUserRoleReducer,
  activateUserReducer,
  deActivateUserReducer,
};
