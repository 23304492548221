/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorType } from "../../../model/common";
import { AcitiviesType } from "../../../model/statergyWS";

export interface GetwsStrategyList {
  loading: boolean;
  data: AcitiviesType[] | null;
  status: number;
  error: boolean;
}

export const initialState: GetwsStrategyList = {
  loading: false,
  data: null,
  status: 0,
  error: false,
};

const strategyUnActivityFetchSlice = createSlice({
  name: "strategyUnActivityFetchSlice",
  initialState,
  reducers: {
    reset: () => initialState,
    strategyUnActivityFetchRequest(
      state: GetwsStrategyList,
      action: PayloadAction<string>,
    ) {
      state.loading = true;
    },
    strategyUnActivityFetchSuccess(
      state: GetwsStrategyList,
      action: PayloadAction<{ data: AcitiviesType[]; status: number }>,
    ) {
      state.loading = false;
      state.data = action.payload.data;
      state.status = action.payload.status;
    },
    strategyUnActivityFetchFailure(
      state: GetwsStrategyList,
      action: PayloadAction<{ error: ErrorType }>,
    ) {
      state.loading = false;
      state.error = true;
      state.status = action.payload.error.response.status;
    },
  },
});

export const strategyUnActivityFetchAction =
  strategyUnActivityFetchSlice.actions;
export const strategyUnActivityFetchReducer =
  strategyUnActivityFetchSlice.reducer;

export default strategyUnActivityFetchReducer;
