import styled from "styled-components";

type NavProps = {
  hide?: boolean;
  show?: boolean;
};
type IconProps = {
  active: boolean;
};

const StyledSidebar = styled.aside<NavProps>`
  display: flex;
  position: sticky;
  top: 112px;
  bottom: 0;
  height: calc(100vh - 112px);
  .opened {
    width: 264px;
    @media screen and (max-width: 1300px) {
      width: 200px;
    }
  }
  .not-opened {
    width: 64px;
  }
`;
const InnerContainer = styled.div`
  height: 100%;
  background: ${({ theme }) => theme.colors.bg.white};
  position: relative;
  transition-duration: 300ms;
  padding: 13px 8px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
  .sidebar-navigation-button {
    position: absolute;
    cursor: pointer;
    right: -19px;
    bottom: 75px;
    border: none;
    background: none;
    padding: 0;
    z-index: 2;
  }
  .rotate {
    transform: rotate(180deg);
  }
`;

const IconButtons = styled.button<IconProps>`
  padding: 0;
  border: none;
  background: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 46px;
  width: 46px;
  border-radius: 10px;
  margin-bottom: 10px;
  background: ${(props: IconProps) => {
    if (props.active) {
      return "#2B388F";
    }
    return "none";
  }};

  svg {
    opacity: ${(props: IconProps) => {
      if (props.active) {
        return "1";
      }
      return "0.5";
    }};
    color: ${(props: IconProps) => {
      if (props.active) {
        return "#FFF";
      }
      return "";
    }};
  }
  &:hover,
  &:active {
    background-color: ${({ theme }) => theme.colors.bg.blue};
    svg {
      color: ${({ theme }) => theme.colors.bg.white};
      opacity: 1;
    }
  }
`;

export { StyledSidebar, InnerContainer, IconButtons };
