import { takeLatest, put, fork, call } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { PayloadAction } from "@reduxjs/toolkit";
import http from "../../../utils/http.service";
import config from "../../../utils/request.config";
import {
  budgetDetailsGet,
  wsResoucePlanningSubmit,
} from "../../../utils/serviceurl";
import { DetailsFormProps } from "../../../model/wSDetailsForm";
import { ErrorType } from "../../../model/common";
import {
  budgetDetailsSubmitAction,
  getBudgetDetailsAction,
} from "./budgetDetailsSlice";

export const fetchBudgetDetails = async (data: any) =>
  http.POST(config.postConfig(data, "", wsResoucePlanningSubmit, ""));

function* onBudgetDetailsSubmitAsync(action: PayloadAction<DetailsFormProps>) {
  try {
    const response: AxiosResponse<any> = yield call(
      fetchBudgetDetails,
      action.payload,
    );
    if (response.status === 200) {
      yield put(
        budgetDetailsSubmitAction.detailsSubmitSuccess({
          data: response.data.data,
          status: response.status,
        }),
      );
    }
  } catch (error) {
    yield put(
      budgetDetailsSubmitAction.detailsSubmitFailure({
        error: error as ErrorType,
      }),
    );
  }
}

function* onBudgetDetailsSubmit() {
  yield takeLatest(
    budgetDetailsSubmitAction.detailsSubmitRequest,
    onBudgetDetailsSubmitAsync,
  );
}

export const getBudgetDetails = async (data: any) =>
  http.GET(
    config.getConfig(
      "",
      `${budgetDetailsGet}?workStreamId=${data.workStreamId}&assessmentId=${data.assessmentId}`,
    ),
  );

function* onBudgetDetailsGetAsync(action: PayloadAction<any>) {
  try {
    const response: AxiosResponse<any> = yield call(
      getBudgetDetails,
      action.payload,
    );
    if (response.status === 200) {
      yield put(
        getBudgetDetailsAction.detailsSuccess({
          data: response.data.data,
          status: response.status,
        }),
      );
    }
  } catch (error) {
    yield put(
      getBudgetDetailsAction.detailsFailure({
        error: error as ErrorType,
      }),
    );
  }
}

function* onBudgetDetailsGet() {
  yield takeLatest(
    getBudgetDetailsAction.detailsRequest,
    onBudgetDetailsGetAsync,
  );
}

export const budgetDetailsSaga = [
  fork(onBudgetDetailsSubmit),
  fork(onBudgetDetailsGet),
];
