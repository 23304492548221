import styled from "styled-components";

const StepperNav = styled.div`
  display: flex;
  flex-direction: column;
  .rc-steps-item-container {
    display: flex;
  }
`;
export { StepperNav };
