import { AppState, Auth0Provider } from "@auth0/auth0-react";
import { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import { auth0paraGet } from "../utils/constants";

export function Auth0ProviderWithHistory({
  children,
}: PropsWithChildren<any>): JSX.Element | null {
  const { domain, clientId, audience } = auth0paraGet();
  const navigate = useNavigate();

  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience={audience}
    >
      {children}
    </Auth0Provider>
  );
}

export default Auth0ProviderWithHistory;
