import { takeLatest, put, fork, call } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { PayloadAction } from "@reduxjs/toolkit";
import http from "../../../utils/http.service";
import config from "../../../utils/request.config";
import {
  designationListAction,
  departmentListAction,
  saveProfileAction,
  profileImageUploadAction,
  profileBuilderGetProfileDetailsAction,
  managerListAction,
} from "./basicDetailsSlice";
import {
  getAllDesignations,
  getAllDepartments,
  getAllManagers,
  saveProfile,
  profileAvatarUpload,
  profileBuilderGetProfile,
} from "../../../utils/serviceurl";

export const fetchDesignationList = async () =>
  http.GET(config.getConfig("", getAllDesignations, ""));

function* onDesignationGetListAsync() {
  try {
    const response: AxiosResponse<any> = yield call(fetchDesignationList);
    if (response.status === 200) {
      yield put(
        designationListAction.designationListSuccess({
          data: response.data.data,
          status: response.status,
        }),
      );
    }
  } catch (error) {
    // console.log(error);
  }
}

function* onDesignationGetList() {
  yield takeLatest(
    designationListAction.designationListRequest,
    onDesignationGetListAsync,
  );
}

export const fetchDepartmentList = async () =>
  http.GET(config.getConfig("", getAllDepartments, ""));

function* onDepartmentListGetAsync() {
  try {
    const response: AxiosResponse<any> = yield call(fetchDepartmentList);
    if (response.status === 200) {
      yield put(
        departmentListAction.departmentListSuccess({
          data: response.data.data,
          status: response.status,
        }),
      );
    }
  } catch (error) {
    // console.log(error);
  }
}

function* onDepartmentListGet() {
  yield takeLatest(
    departmentListAction.departmentListRequest,
    onDepartmentListGetAsync,
  );
}

export const fetchManagerList = async () =>
  http.GET(config.getConfig("", getAllManagers, ""));

function* onManagerGetListAsync() {
  try {
    const response: AxiosResponse<any> = yield call(fetchManagerList);
    if (response.status === 200) {
      yield put(
        managerListAction.managerListSuccess({
          data: response.data.data,
          status: response.status,
        }),
      );
    }
  } catch (error) {
    // console.log(error);
  }
}

function* onManagerGetList() {
  yield takeLatest(managerListAction.managerListRequest, onManagerGetListAsync);
}

export const profileSubmit = async (data: any) =>
  http.POST(config.postConfig(data, "", saveProfile, ""));

function* onSaveProfileActionAsync(action: PayloadAction<any>) {
  try {
    const response: AxiosResponse<any> = yield call(
      profileSubmit,
      action.payload,
    );
    if (response.status === 200) {
      yield put(
        saveProfileAction.saveProfileSuccess({
          data: response.data.data,
          status: response.status,
        }),
      );
    }
  } catch (error) {
    // console.log(error);
  }
}

function* onSaveProfile() {
  yield takeLatest(
    saveProfileAction.saveProfileRequest,
    onSaveProfileActionAsync,
  );
}

export const profileImageUpload = async (data: any) =>
  http.POST(config.postConfig(data, "", profileAvatarUpload, ""));

function* onProfileImageUploadAsync(action: PayloadAction<any>) {
  try {
    const response: AxiosResponse<any> = yield call(
      profileImageUpload,
      action.payload,
    );
    if (response.status === 200) {
      yield put(
        profileImageUploadAction.profileImageUploadSuccess({
          data: response.data.data,
          status: response.status,
        }),
      );
    }
  } catch (error) {
    // console.log(error);
  }
}

function* onProfileImagUpload() {
  yield takeLatest(
    profileImageUploadAction.profileImageUploadRequest,
    onProfileImageUploadAsync,
  );
}
export const fetchProfileDetails = async () =>
  http.GET(config.getConfig("", profileBuilderGetProfile, ""));

function* onProfileDetailsAsync() {
  try {
    const response: AxiosResponse<any> = yield call(fetchProfileDetails);
    if (response.status === 200) {
      yield put(
        profileBuilderGetProfileDetailsAction.profileDetailsSuccess({
          data: response.data.data,
          status: response.status,
        }),
      );
    }
  } catch (error) {
    // console.log(error);
  }
}

function* onGetProfileDetails() {
  yield takeLatest(
    profileBuilderGetProfileDetailsAction.profileDetailsRequest,
    onProfileDetailsAsync,
  );
}
export const basicDetailsSaga = [
  fork(onDesignationGetList),
  fork(onDepartmentListGet),
  fork(onSaveProfile),
  fork(onProfileImagUpload),
  fork(onGetProfileDetails),
  fork(onManagerGetList),
];
