/* eslint-disable react/prop-types */
import React, { ReactElement, useEffect } from "react";
import Select from "react-select";
import styled from "styled-components";
import { Form, Row, Col } from "react-bootstrap";
import DatePicker from "react-date-picker";
import { useSelector } from "react-redux";
import { isEqual } from "lodash";
import { FormGroup } from "../../components";
import { useAppDispatch } from "../../../store/hooks";
import {
  getTimeLineDetailsAction,
  getRiskToleranceAction,
} from "./workStreamDetailsSlice";
import { RootState } from "../../../store/store";

const StyledWorkStreamDetails = styled.div`
  padding: 25px 30px 3px;
  border: 1px solid ${({ theme }) => theme.colors.border.default};
  background: ${({ theme }) => theme.baseColors.white};
  border-radius: 10px;
  overflow: visible;
  margin: 53px 58px;
  .react-date-picker__inputGroup__input,
  select {
    min-width: auto !important;
  }
  .react-date-picker {
    width: 100%;
    .react-calendar {
      padding: 10px;
      border: 1px solid ${({ theme }) => theme.colors.border.default};
      border-radius: 6px;
      abbr[data-bs-original-title],
      abbr[title] {
        text-decoration: none;
        cursor: default;
      }
      .react-calendar__tile--active {
        background: ${({ theme }) => theme.baseColors.primary};
      }
      .react-calendar__navigation button:enabled:hover,
      .react-calendar__navigation button:enabled:focus {
        background: ${({ theme }) => theme.baseColors.primary};
        color: ${({ theme }) => theme.baseColors.white};
      }
    }
    .react-date-picker__wrapper {
      min-height: 60px;
      border-radius: 6px;
      border: 1px solid ${({ theme }) => theme.colors.border.bluishCyan1};
      font-family: ${({ theme }) => theme.fontFamily.medium};
      font-size: ${({ theme }) => theme.fontSizes[1]};
      line-height: 16.8px;
      font-weight: ${({ theme }) => theme.fontWeights.medium};
      color: ${({ theme }) => theme.colors.text.black};
      padding: 10px 20px;
      cursor: pointer;
      @media (max-width: 1400px) {
        min-height: 45px;
      }
      .react-date-picker__clear-button {
        display: none;
      }
      input {
        &:focus {
          outline: none;
          box-shadow: none;
          border: none;
        }
      }
      .react-date-picker__calendar-button {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fillRule='evenodd' clipRule='evenodd' d='M7.08301 0.833984C7.54324 0.833984 7.91634 1.20708 7.91634 1.66732V2.50065H12.083V1.66732C12.083 1.20708 12.4561 0.833984 12.9163 0.833984C13.3766 0.833984 13.7497 1.20708 13.7497 1.66732V2.50065H17.4997C18.4201 2.50065 19.1663 3.24684 19.1663 4.16732V17.5006C19.1663 18.4211 18.4201 19.1673 17.4997 19.1673H2.49967C1.5792 19.1673 0.833008 18.4211 0.833008 17.5006V4.16732C0.833008 3.24684 1.5792 2.50065 2.49967 2.50065H6.24967V1.66732C6.24967 1.20708 6.62277 0.833984 7.08301 0.833984ZM6.24967 4.16732H2.49967V7.50065H17.4997V4.16732H13.7497V5.00065C13.7497 5.46089 13.3766 5.83398 12.9163 5.83398C12.4561 5.83398 12.083 5.46089 12.083 5.00065V4.16732H7.91634V5.00065C7.91634 5.46089 7.54324 5.83398 7.08301 5.83398C6.62277 5.83398 6.24967 5.46089 6.24967 5.00065V4.16732ZM17.4997 9.16732H13.7497V12.5006H17.4997V9.16732ZM17.4997 14.1673H13.7497V17.5006H17.4997V14.1673ZM12.083 17.5006V14.1673H7.91634V17.5006H12.083ZM6.24967 17.5006V14.1673H2.49967V17.5006H6.24967ZM2.49967 12.5006H6.24967V9.16732H2.49967V12.5006ZM7.91634 9.16732V12.5006H12.083V9.16732H7.91634Z' fill='%236B7588'/%3e%3c/svg%3e ");
        background-repeat: no-repeat;
        background-position: center center;
        padding: 0;
        svg {
          opacity: 0;
          visibility: hidden;
        }
      }
    }
  }
  .react-calendar__tile:disabled {
    color: gray;
    opacity: 0.6;
  }
  .description-textarea {
    min-height: 110px;
  }
  .tolerance-selector {
    flex: 0 0 50%;
    max-width:50%;
    .select-placeholder{
      color:${({ theme }) => theme.colors.text.darkGrey1};
      font-size: ${({ theme }) => theme.fontSizes[1]};
      line-height: ${({ theme }) => theme.lineHeight[14]};
    }
    [class*="-control"] {
      min-height: 45px;
      padding: 10px 15px;
      padding-right:10px;
      font-family: ${({ theme }) => theme.fontFamily.medium};
      font-size: ${({ theme }) => theme.fontSizes[1]};
      line-height: ${({ theme }) => theme.lineHeight[14]};
      color: ${({ theme }) => theme.colors.text.black};
      border-color: ${({ theme }) => theme.colors.border.bluishCyan1};
      border-radius: 6px;
      box-shadow: none !important;
      [class*="-ValueContainer"]{
        padding-left:0;
      }
      [class*="-indicatorSeparator"]{
        display:none;
      }
      [class*="-indicatorContainer"]{
        padding:0;
      }
      svg{
        path{
          fill:${({ theme }) => theme.colors.text.black};
        }
      }
    .form-select {
  height: 60px;
    }
  }
`;
const Heading = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.bold};
  font-size: ${({ theme }) => theme.fontSizes[2]};
  line-height: ${({ theme }) => theme.lineHeight[3]};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.colors.text.black};
  padding-bottom: 21px;
`;
// const options = [
//   {
//     _id: "6327f20c5eaf3e2edcf51c28",
//     riskTolerance: "Demo risk case 1",
//     isSelected: false,
//   },
//   {
//     _id: "6327f20c5eaf3e2edcf51c27",
//     riskTolerance: "Demo risk case 2",
//     isSelected: false,
//   },
//   {
//     _id: "6327f20c5eaf3e2edcf51c26",
//     riskTolerance: "Demo risk case 3",
//     isSelected: true,
//   },
// ];

type WorkStreamDetailsProps = {
  props: any;
  workstreamid: string;
  assessmentId: string | null;
};

const exceptNumberTypeSymbols = ["e", "E", "+", "-", "."];
const day: number = 60 * 60 * 24 * 1000;

function WorkStreamDetails({
  props,
  workstreamid,
  assessmentId,
}: WorkStreamDetailsProps): ReactElement {
  const dispatch = useAppDispatch();

  const timeLineDatas: any = useSelector(
    (state: RootState) => state.getTimeLineFormDetails,
  );
  const riskToleranceList: any = useSelector(
    (state: RootState) => state.riskToleranceData,
  );
  const riskToleranceData = riskToleranceList.data.data;
  useEffect(() => {
    dispatch(getRiskToleranceAction.getRiskToleranceRequest());
  }, []);
  useEffect(() => {
    if (assessmentId !== null) {
      dispatch(
        getTimeLineDetailsAction.getTimeLineDetailsRequest({
          workStreamId: workstreamid,
          assessmentId,
        }),
      );
    }
    return () => {
      dispatch(getTimeLineDetailsAction.reset());
    };
  }, []);

  useEffect(() => {
    if (
      timeLineDatas.status === 200 &&
      Object.keys(timeLineDatas.data).length !== 0
    ) {
      props?.setStartDate(new Date(timeLineDatas?.data?.startDate));
      props?.setEndDate(new Date(timeLineDatas?.data?.endDate));
      props?.setEffort(timeLineDatas?.data?.estimatedEffort);
      props?.setActual(timeLineDatas?.data?.actualDays);
      props?.setWsDescription(timeLineDatas?.data?.description);
      props?.setRiskToleranceId(timeLineDatas?.data?.riskToleranceId);
    }
  }, [timeLineDatas]);

  function getDiffInDaysOrHours(dayOrHour: number) {
    const calcStartDate: any = new Date(props.startDate);
    const calcEndDate: any = new Date(props.endDate);
    const diffInMs = Math.abs(calcEndDate - calcStartDate);
    if (dayOrHour === 1) {
      return Math.round(diffInMs / (1000 * 60 * 60 * 24));
    }
    return diffInMs / (1000 * 60 * 60);
  }

  useEffect(() => {
    const calculatedActualDays = getDiffInDaysOrHours(1);
    props.setActual(calculatedActualDays);
    if (new Date(props?.startDate) > new Date(props?.endDate)) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      props?.setEndDate(new Date(props?.startDate.getTime() + day));
    }
  }, [props.startDate, props.endDate]);
  return (
    <StyledWorkStreamDetails>
      <Heading>Timeline</Heading>
      <Form>
        <Row>
          <Col sm>
            <FormGroup formGroupBlock>
              <Form.Label>Planned Start Date</Form.Label>
              <DatePicker
                format="MMM d, yyyy"
                minDate={new Date()}
                onChange={(value: any) => props?.setStartDate(value)}
                value={props.startDate}
              />
            </FormGroup>
          </Col>
          <Col sm>
            <FormGroup formGroupBlock>
              <Form.Label>Planned End Date</Form.Label>
              <DatePicker
                format="MMM d, yyyy"
                value={props.endDate}
                minDate={new Date(props.startDate.getTime() + day)}
                onChange={(value: any) => props?.setEndDate(value)}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm>
            <FormGroup formGroupBlock>
              <Form.Label>Estimated Effort (Hours)</Form.Label>
              <Form.Control
                type="number"
                onKeyDown={(e: any) =>
                  exceptNumberTypeSymbols.includes(e.key) && e.preventDefault()
                }
                min="1"
                id="effort"
                placeholder="Enter Estimated Effort In Hours"
                value={props.effort !== 0 && props.effort}
                onChange={(e: any) => props?.setEffort(e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col sm>
            <FormGroup formGroupBlock>
              <Form.Label>Estimated Duration (Days)</Form.Label>
              <Form.Control
                type="number"
                onKeyDown={(e: any) =>
                  exceptNumberTypeSymbols.includes(e.key) && e.preventDefault()
                }
                min="1"
                id="actual"
                placeholder="Enter Estimated Duration"
                value={props.actualDays !== 0 && props.actualDays}
                disabled
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm className="tolerance-selector">
            <FormGroup formGroupBlock>
              <Form.Label>Risk tolerance</Form.Label>
              <Select
                options={riskToleranceData}
                value={
                  riskToleranceData?.find((item: any) =>
                    isEqual(item?._id, props.riskToleranceId),
                  ) || null
                }
                onChange={(ev: any) => {
                  props.setRiskToleranceId(ev._id);
                }}
                placeholder={
                  <div className="select-placeholder">Enter tolerance</div>
                }
                getOptionLabel={(e: any) => (e ? e.riskTolerance : "")}
                getOptionValue={(e: any) => (e ? e._id : "")}
                isSearchable={false}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm>
            <FormGroup formGroupBlock>
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Description"
                className="description-textarea"
                onChange={(event) =>
                  props?.setWsDescription(event.target.value)
                }
                value={props?.description}
              />
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </StyledWorkStreamDetails>
  );
}
export { WorkStreamDetails };
