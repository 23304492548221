import styled from "styled-components";

const MainContainer = styled.header`
  background: ${({ theme }) => theme.baseColors.primary};
  width: 100%;
  position: fixed;
  margin: 0 0 0 0;
  height: 64px;
  z-index: 10;
`;

const StyledSidebar = styled.div`
  padding: 13px 25px 13px 25px;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  img {
    width: 116px;
  }
`;
const MenuSection = styled.div`
  margin-right: 10px;
  cursor: pointer;
  color: ${({ theme }) => theme.baseColors.white};
`;
const LogosContainer = styled.div`
  display: flex;
  align-items: center;
  .global-class-name {
    cursor: pointer;
  }
`;
const ProfileDetails = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;
const ImageContainer = styled.div`
  width: 38px;
  height: 38px;
`;
const DropdownContainer = styled.div`
  .btn {
    color: ${({ theme }) => theme.baseColors.white};
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    padding-left: 10px;
    padding-right: 0px;
  }
  .btn:after {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fillRule='evenodd' clipRule='evenodd' d='M9.82733 13.0893C10.1528 13.4147 10.6804 13.4147 11.0058 13.0893L16.0058 8.0893C16.3313 7.76386 16.3313 7.23622 16.0058 6.91079C15.6804 6.58535 15.1528 6.58535 14.8273 6.91079L10.4166 11.3215L6.00584 6.91079C5.6804 6.58535 5.15277 6.58535 4.82733 6.91079C4.50189 7.23622 4.50189 7.76386 4.82733 8.0893L9.82733 13.0893Z' fill='white'/%3e%3c/svg%3e ");
    height: 20px;
    width: 20px;
    vertical-align: middle;
    border: none;
  }
  .btn:active,
  .btn:hover,
  .btn:focus {
    border-color: none;
    box-shadow: none;
  }
  .dropdown-menu {
    min-width: 100px;
    /* margin: 0px 0px 0px 145px; */
    transform: none !important;
    top: 40px !important;
  }
  .dropdown-item {
    justify-content: center;
  }
  .btn:first-child:active {
    color: ${({ theme }) => theme.baseColors.white};
    font-style: normal;
    border: none;
  }
`;

export {
  MainContainer,
  StyledSidebar,
  MenuSection,
  LogosContainer,
  ProfileDetails,
  ImageContainer,
  DropdownContainer,
};
