import { takeLatest, put, fork, call } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import http from "../../../utils/http.service";
import config from "../../../utils/request.config";
import { demographicsListAction } from "./demographicsSlice";
import { demographics } from "../../../utils/serviceurl";

import { ErrorType } from "../../../model/common";

export const fetchDemographicsData = async () =>
  http.GET(config.getConfig("", demographics, ""));

function* onDemographicsListAsync() {
  try {
    const response: AxiosResponse<any> = yield call(fetchDemographicsData);
    if (response.status === 200) {
      yield put(
        demographicsListAction.getDemographicsListSuccess({
          data: response.data.data,
          status: response.status,
        }),
      );
    }
  } catch (error) {
    yield put(
      demographicsListAction.getDemographicsListFailure({
        error: error as ErrorType,
      }),
    );
  }
}

function* onDemographicDataList() {
  yield takeLatest(
    demographicsListAction.getDemographicsListRequest,
    onDemographicsListAsync,
  );
}

export const demographicsSaga = [fork(onDemographicDataList)];
