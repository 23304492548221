import { takeLatest, put, fork, call } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { PayloadAction } from "@reduxjs/toolkit";
import http from "../../../utils/http.service";
import config from "../../../utils/request.config";
import { APIResponse, ErrorType } from "../../../model/common";
import { dashboardDetailview } from "../../../utils/serviceurl";
import { assessmentDetialsAction } from "./AssesmentDetailsSlice";

export const fetchAssessmenDetailsResult = async (assesmentId: string) =>
  http.GET(config.getConfig("", `${dashboardDetailview}/${assesmentId}`, ""));

function* onAssementDetailViewAsync(action: PayloadAction<string>) {
  try {
    const response: AxiosResponse<APIResponse> = yield call(
      fetchAssessmenDetailsResult,
      action.payload,
    );
    if (response.status === 200) {
      yield put(
        assessmentDetialsAction.getAssessmentDetailViewSuccess({
          data: response.data.data,
          status: response.status,
        }),
      );
    }
  } catch (error) {
    yield put(
      assessmentDetialsAction.getAssessmentDetailViewFailure({
        error: error as ErrorType,
      }),
    );
  }
}

function* onAssessmentDetailsGet() {
  yield takeLatest(
    assessmentDetialsAction.getAssessmentDetailViewRequest,
    onAssementDetailViewAsync,
  );
}

export const assesmentDetailViewSaga = [fork(onAssessmentDetailsGet)];
