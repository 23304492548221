function niceBytes(x: any) {
  const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const l = 0;
  let n = parseInt(x, 10) || 0;
  while (n >= 1024 && l + 1) {
    n /= 1024;
  }
  return `${n.toFixed(n < 10 && l > 0 ? 1 : 0)} ${units[l]}`;
}

export { niceBytes };
