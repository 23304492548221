import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define the interface for the OrgArray elements
interface OrgInfo {
  _id: string;
  userId: string;
  organizationId: string;
  isExcelUpload: boolean;
  companyName: string;
  firstName: string;
  lastName: string;
}

// Interface for the state
export interface GetReviewAssessmentData {
  loading: boolean;
  data: any[];
  status: number;
  error: null | string;
  OrgArray: OrgInfo[];
}

// Initial state
export const initialState: GetReviewAssessmentData = {
  loading: false,
  data: [],
  status: 0,
  error: null,
  OrgArray: [],
};

// Create slice
const getReviewAssessmentDataSlice = createSlice({
  name: "getReviewAssessmentData",
  initialState,
  reducers: {
    reset: () => initialState,
    getReviewAssessmentOrgData(
      state: GetReviewAssessmentData,
      action: PayloadAction<OrgInfo[]>,
    ) {
      state.OrgArray = action.payload;
    },
    // Add other reducers if needed
  },
});

// Export actions and reducer
export const getReviewAssessmentDataAction =
  getReviewAssessmentDataSlice.actions;
export const getReviewAssessmentDataReducer =
  getReviewAssessmentDataSlice.reducer;

export default getReviewAssessmentDataReducer;
