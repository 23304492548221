import { takeLatest, put, fork, call } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { PayloadAction } from "@reduxjs/toolkit";
import http from "../../../utils/http.service";
import config from "../../../utils/request.config";
import { dashBoardDataListAction } from "./dashboardSlice";
import { dashBoardLineChartAction } from "./dashboardLineChartSlice";
import {
  dashBoardDataList,
  dashBoardLineChartGet,
  getAssessment,
} from "../../../utils/serviceurl";

import { getAssessmentIdAction } from "../getAssessmentIdSlice";
import { ErrorType } from "../../../model/common";

export const fetchDashBoardDataList = async (assessmentId: string) =>
  http.GET(config.getConfig("", `${dashBoardDataList}/${assessmentId}`, ""));

function* onDashBoardGetListAsync(action: PayloadAction<string>) {
  try {
    const response: AxiosResponse<any> = yield call(
      fetchDashBoardDataList,
      action.payload,
    );
    if (response.status === 200) {
      yield put(
        dashBoardDataListAction.dashBoardDataListSuccess({
          data: response.data.data,
          status: response.status,
        }),
      );
    }
  } catch (error) {
    yield put(
      dashBoardDataListAction.dashBoardDataListFailure({
        error,
      }),
    );
  }
}

function* onDashBoardGetList() {
  yield takeLatest(
    dashBoardDataListAction.dashBoardDataListRequest,
    onDashBoardGetListAsync,
  );
}

export const fetchDashBoardAssessTrend = async () =>
  http.GET(config.getConfig("", dashBoardLineChartGet, ""));

function* onDashBoardAssessTrendAsync() {
  try {
    const response: AxiosResponse<any> = yield call(fetchDashBoardAssessTrend);
    if (response.status === 200) {
      yield put(
        dashBoardLineChartAction.dashBoardLineChartSuccess({
          data: response.data.data,
          status: response.status,
        }),
      );
    }
  } catch (error) {
    // console.log(error);
  }
}

function* onDashBoardAssessTrendGetList() {
  yield takeLatest(
    dashBoardLineChartAction.dashBoardLineChartRequest,
    onDashBoardAssessTrendAsync,
  );
}

export const fetchAssessmentId = async () =>
  http.GET(config.getConfig("", getAssessment, ""));

function* onAssessmentIdAsync() {
  try {
    const response: AxiosResponse<any> = yield call(fetchAssessmentId);
    if (response.status === 200) {
      yield put(
        getAssessmentIdAction.getAssessmentIdSuccess({
          data: response.data.data,
          status: response.status,
        }),
      );
    }
  } catch (error) {
    yield put(
      getAssessmentIdAction.getAssessmentIdFailure({
        error: error as ErrorType,
      }),
    );
  }
}

function* onAssessmentIdGet() {
  yield takeLatest(
    getAssessmentIdAction.getAssessmentIdRequest,
    onAssessmentIdAsync,
  );
}

export const dashBoardDataListSaga = [
  fork(onDashBoardGetList),
  fork(onDashBoardAssessTrendGetList),
  fork(onAssessmentIdGet),
];
