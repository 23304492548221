import { Flex, Box } from "rebass";
import { Tooltip } from "react-tippy";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MainContainer, TableContainer } from "./styles";
import filter from "../../../../ui/images/Filter.svg";
import { FilterDropdown } from "./FilterDropdown";
import { RootState } from "../../../../store/store";
import LoaderPage from "../../../UtilPages/LoaderPage";
import { DashboardDetailViewProp } from "../../../../model/dashboardDetailView";
import errorHandling from "../../../../utils/errorHandling";

type ModalAssesmenttype = {
  functionName: string;
};

function ModalAssessment({ functionName }: ModalAssesmenttype) {
  const [FunctionName, setFunctionNames] = useState<string[] | undefined>();
  const [CategoryName, setCategoryNames] = useState<string[] | undefined>();

  // const [ControlId, setControlId] = useState<string[] | undefined>();
  const [Priority, setPriority] = useState<string[] | undefined>();
  const [filterDataFunName, setFilterDataFunName] = useState<string[]>([]);
  const [filterCategoryName, setfilterCategoryNames] = useState<string[]>([]);
  const filterControlId: string[] = [];
  const [filterPriority, setFilterPriority] = useState<string[]>([]);
  const [controlIdAvailable, setControlIdAvilabel] = useState(false);
  const [filtertipName, setFiltertipName] = useState(false);

  // const [filtertipControlId, setFiltertipControlId] = useState(false);
  const [filtertipControlName, setFiltertipControlName] = useState(false);
  const [filtertipPriority, setFiltertippriority] = useState(false);
  const DetailViewStore = useSelector(
    (state: RootState) => state.assessmetDetailsListView,
  );

  const [DetailViewData, setDetailviewData] = useState<
    DashboardDetailViewProp[] | undefined
  >(undefined);

  useEffect(() => {
    const Data = DetailViewStore?.data?.filter(
      (element) => element.functionName === functionName,
    );
    setDetailviewData(Data);
    setFilterDataFunName([functionName]);
  }, [functionName]);

  useEffect(() => {
    const Data = DetailViewStore?.data?.filter(
      (element) => element.functionName === functionName,
    );
    setDetailviewData(Data);
    // setting Function Name for Tooltip
    const fname = DetailViewStore?.data?.map((element) => element.functionName);
    const rmDupFname = fname?.filter(
      (item, index) => fname?.indexOf(item) === index,
    );
    setFunctionNames(rmDupFname);
    // setting CategoryName for Tooltip
    const categoryNam = DetailViewStore?.data?.map(
      (element) => element.categoryName,
    );
    const rmDupcategory = categoryNam?.filter(
      (item, index) => categoryNam?.indexOf(item) === index,
    );
    setCategoryNames(rmDupcategory);
    // setting ControlId for Tooltip

    const controlId = DetailViewStore?.data?.map(
      (element) => element.control_id,
    );
    const DataFiltered = controlId?.filter((item) => item !== undefined);
    if (
      controlId === undefined ||
      DataFiltered === undefined ||
      DataFiltered.length === 0
    ) {
      setControlIdAvilabel(false);
    } else {
      setControlIdAvilabel(true);
      // const rmDupcontrolId = DataFiltered?.filter(
      //   (item, index) => controlId?.indexOf(item) === index,
      // );

      // setControlId(rmDupcontrolId);
    }
    // setting Priority for Tooltip
    const PriorityList = DetailViewStore?.data?.map((element) =>
      element.priority.replace("Priority", ""),
    );
    const rmDuppriority = PriorityList?.filter(
      (item, index) => PriorityList?.indexOf(item) === index,
    );
    setPriority(rmDuppriority);
  }, [DetailViewStore.data]);

  /// //////////////////--------------------Filtering Functions------------------------/////////////////
  // Filtering using Control id
  const filterFunctionNameDataFunc = (
    DataUdpatedfilter: DashboardDetailViewProp[],
  ) => {
    let DataUpdated = DataUdpatedfilter;
    if (DetailViewStore.data !== undefined) {
      if (filterDataFunName !== undefined && filterDataFunName.length > 0) {
        const Data = DetailViewStore?.data.filter((element) => {
          if (filterDataFunName.includes(element.functionName)) {
            return element;
          }
        });
        DataUpdated = [...Data];
      }
    }
    return DataUpdated;
  };

  // Filtering using Category
  const filterCategoryDataFunc = (
    DataUdpatedfilter: DashboardDetailViewProp[],
  ) => {
    let DataUpdated = DataUdpatedfilter;
    if (DetailViewStore.data !== undefined) {
      if (filterCategoryName !== undefined && filterCategoryName.length > 0) {
        let Data: DashboardDetailViewProp[] = [];
        if (DataUpdated.length > 0) {
          Data = DataUpdated.filter((element) => {
            if (filterCategoryName.includes(element.categoryName)) {
              return element;
            }
          });
        } else if (DataUpdated.length === 0 && filterDataFunName.length === 0) {
          Data = DetailViewStore?.data.filter((element) => {
            if (filterCategoryName.includes(element.categoryName)) {
              return element;
            }
          });
        }
        DataUpdated = [...Data];
      }
    }
    return DataUpdated;
  };

  // Filtering using Control id
  const filterControlIdDataFunc = (
    DataUdpatedfilter: DashboardDetailViewProp[],
  ) => {
    let DataUpdated = DataUdpatedfilter;
    if (DetailViewStore.data !== undefined) {
      if (filterControlId !== undefined && filterControlId.length > 0) {
        let Data: DashboardDetailViewProp[] = [];
        if (DataUpdated.length > 0) {
          Data = DataUpdated.filter((element) => {
            if (filterControlId.includes(element.control_id)) {
              return element;
            }
          });
        } else if (
          DataUpdated.length === 0 &&
          filterCategoryName.length === 0 &&
          filterDataFunName.length === 0
        ) {
          Data = DetailViewStore?.data.filter((element) => {
            if (filterControlId.includes(element.control_id)) {
              return element;
            }
          });
        }

        DataUpdated = [...Data];
      }
    }
    return DataUpdated;
  };

  // Filtering using priority Function
  const filterPriorityDataFunc = (
    DataUdpatedfilter: DashboardDetailViewProp[],
  ) => {
    let DataUpdated = DataUdpatedfilter;
    if (DetailViewStore.data !== undefined) {
      if (filterPriority !== undefined && filterPriority.length > 0) {
        let Data: DashboardDetailViewProp[] = [];
        if (DataUpdated.length > 0) {
          Data = DataUpdated.filter((element) => {
            if (
              filterPriority.includes(element.priority.replace("Priority", ""))
            ) {
              return element;
            }
          });
        } else if (
          DataUpdated.length === 0 &&
          filterCategoryName.length === 0 &&
          filterDataFunName.length === 0 &&
          filterControlId.length === 0
        ) {
          Data = DetailViewStore?.data.filter((element) => {
            if (
              filterPriority.includes(element.priority.replace("Priority", ""))
            ) {
              return element;
            }
          });
        }

        DataUpdated = [...Data];
      }
    }
    return DataUpdated;
  };

  const FilterFunctionCLick = () => {
    setFiltertipName(false);
    // setFiltertipControlId(false);
    setFiltertippriority(false);
    setFiltertipControlName(false);
    const DataUpdated: DashboardDetailViewProp[] = [];
    if (DetailViewStore.data !== undefined) {
      /// /////Filtering using FunctionName
      const DataFilteredFuntcionName = filterFunctionNameDataFunc(DataUpdated);
      /// /////Filtering using Control Name
      const DataCategoryFilter = filterCategoryDataFunc(
        DataFilteredFuntcionName,
      );
      /// /////Filtering using Control Id
      const DataControlIdFilter = filterControlIdDataFunc(DataCategoryFilter);
      /// //////Filtering using priority
      const DataPriorityFilter = filterPriorityDataFunc(DataControlIdFilter);

      setDetailviewData(DataPriorityFilter);
    }
    if (
      filterDataFunName.length === 0 &&
      filterControlId.length === 0 &&
      filterPriority.length === 0 &&
      filterCategoryName.length === 0
    ) {
      setDetailviewData(DetailViewStore?.data);
    }
  };

  /// ////////////----------------- Clearing Filters-------------------///////////////////////
  const ClearClickFunctionName = () => {
    setFilterDataFunName([]);
    setFiltertipName(false);
    // setFiltertipControlId(false);
    setFiltertippriority(false);
    setFiltertipControlName(false);
    if (DetailViewStore.data !== undefined) {
      /// /////Filtering using Category
      const DataFilteredCategory = filterCategoryDataFunc(
        DetailViewStore?.data,
      );
      /// /////Filtering using Control Id
      const DataFilteredControlID =
        filterControlIdDataFunc(DataFilteredCategory);
      /// //////Filtering using priority
      const DataFilteredPriority = filterPriorityDataFunc(
        DataFilteredControlID,
      );
      setDetailviewData(DataFilteredPriority);
    }
    if (
      filterControlId.length === 0 &&
      filterPriority.length === 0 &&
      filterCategoryName.length === 0
    ) {
      setDetailviewData(DetailViewStore?.data);
    }
  };

  const ClearClickCategory = () => {
    setfilterCategoryNames([]);
    setFiltertipName(false);
    // setFiltertipControlId(false);
    setFiltertippriority(false);
    setFiltertipControlName(false);

    if (DetailViewStore.data !== undefined) {
      /// /////Filtering using FunctionName
      const DataFilteredFuntcionName = filterFunctionNameDataFunc(
        DetailViewStore?.data,
      );
      /// /////Filtering using Control Id
      const DataControlIdFilter = filterControlIdDataFunc(
        DataFilteredFuntcionName,
      );
      /// //////Filtering using priority
      const DataPriorityFilter = filterPriorityDataFunc(DataControlIdFilter);
      setDetailviewData(DataPriorityFilter);
    }
    if (
      filterDataFunName.length === 0 &&
      filterControlId.length === 0 &&
      filterPriority.length === 0
    ) {
      setDetailviewData(DetailViewStore?.data);
    }
  };
  // const ClearClickControlId = () => {
  //   setFilterControlId([]);
  //   setFiltertipName(false);
  //   setFiltertipControlId(false);
  //   setFiltertippriority(false);
  //   setFiltertipControlName(false);

  //   if (DetailViewStore.data !== undefined) {
  //     /// /////Filtering using FunctionName
  //     const DataFilteredFuntcionName = filterFunctionNameDataFunc(
  //       DetailViewStore?.data,
  //     );
  //     /// /////Filtering using Control Name
  //     const DataCategoryFilter = filterCategoryDataFunc(
  //       DataFilteredFuntcionName,
  //     );
  //     /// //////Filtering using priority
  //     const DataPriorityFilter = filterPriorityDataFunc(DataCategoryFilter);
  //     setDetailviewData(DataPriorityFilter);
  //   }
  //   if (
  //     filterDataFunName.length === 0 &&
  //     filterPriority.length === 0 &&
  //     filterCategoryName.length === 0
  //   ) {
  //     setDetailviewData(DetailViewStore?.data);
  //   }
  // };

  const ClearClickPriority = () => {
    setFilterPriority([]);
    setFiltertipName(false);
    // setFiltertipControlId(false);
    setFiltertippriority(false);
    setFiltertipControlName(false);

    if (DetailViewStore.data !== undefined) {
      /// /////Filtering using FunctionName
      const DataFilteredFuntcionName = filterFunctionNameDataFunc(
        DetailViewStore?.data,
      );
      /// /////Filtering using Control Name
      const DataCategoryFilter = filterCategoryDataFunc(
        DataFilteredFuntcionName,
      );
      /// /////Filtering using Control Id
      const DataControlIdFilter = filterControlIdDataFunc(DataCategoryFilter);

      setDetailviewData(DataControlIdFilter);
    }
    if (
      filterDataFunName.length === 0 &&
      filterControlId.length === 0 &&
      filterCategoryName.length === 0
    ) {
      setDetailviewData(DetailViewStore?.data);
    }
  };

  /// /---------------------Error Capturing----------------------------////////////
  const navigate = useNavigate();
  useEffect(() => {
    if (DetailViewStore.error) {
      errorHandling(DetailViewStore.status, navigate);
    }
  }, [DetailViewStore.error]);

  return (
    <MainContainer>
      <Flex className="header">
        <Box as="span" className="profieldetails">
          <Box className="title">Assessment details</Box>
        </Box>
      </Flex>
      <Box className="main-wrapper">
        {DetailViewStore.loading ? (
          <LoaderPage />
        ) : (
          <>
            {/* <SearchBarContainer>
              <Form className="searchContainer">
                <FormControl
                  type="search"
                  placeholder="Search for role or user."
                  className="searchBar"
                  aria-label="Search"
                />
                <IconContainer>
                  <Icons.searchIcon />
                </IconContainer>
              </Form>
            </SearchBarContainer> */}
            <TableContainer>
              <table className="basic-table">
                <thead>
                  <tr>
                    <th key="functionName" className="cell ">
                      <div className="headingFilter">
                        <h2> Function</h2>
                        <div
                          className="filter-details"
                          onClick={() => setFiltertipName(!filtertipName)}
                          role="presentation"
                        >
                          <Tooltip
                            // options
                            title="Function Name"
                            open={filtertipName}
                            position="top"
                            trigger="click"
                            arrow
                            interactive
                            multiple={false}
                            theme="light"
                            onRequestClose={() => {
                              setFiltertipName(false);
                            }}
                            html={
                              <FilterDropdown
                                data={FunctionName}
                                filterData={filterDataFunName}
                                setFilterData={setFilterDataFunName}
                                FilterFunctionClick={FilterFunctionCLick}
                                ClearData={ClearClickFunctionName}
                              />
                            }
                          >
                            <button type="button">
                              <img src={filter} />
                            </button>
                          </Tooltip>
                        </div>
                      </div>
                    </th>
                    <th key="categoryName" className="cell ">
                      <div className="headingFilter">
                        <h2> Category</h2>
                        <div
                          className="filter-details"
                          onClick={() =>
                            setFiltertipControlName(!filtertipControlName)
                          }
                          role="presentation"
                        >
                          <Tooltip
                            // options
                            title="Category Name"
                            open={filtertipControlName}
                            position="top"
                            trigger="click"
                            arrow
                            interactive
                            multiple={false}
                            theme="light"
                            onRequestClose={() => {
                              setFiltertipControlName(false);
                            }}
                            html={
                              <FilterDropdown
                                data={CategoryName}
                                filterData={filterCategoryName}
                                setFilterData={setfilterCategoryNames}
                                FilterFunctionClick={FilterFunctionCLick}
                                ClearData={ClearClickCategory}
                              />
                            }
                          >
                            <button type="button">
                              <img src={filter} />
                            </button>
                          </Tooltip>
                        </div>
                      </div>
                    </th>
                    {controlIdAvailable ? (
                      <th key="control_id" className="cell ">
                        <div className="heading">
                          <h2> Control ID</h2>
                          <div
                            className="filter-details"
                            // onClick={() => setFiltertipControlId(true)}
                          >
                            {/* <Tooltip
                              // options
                              title="Control ID"
                              position="top"
                              open={filtertipControlId}
                              trigger="click"
                              arrow
                              interactive
                              multiple={false}
                              theme="light"
                              onRequestClose={() => {
                                setFiltertipControlId(false);
                              }}
                              html={
                                <FilterDropdown
                                  data={ControlId}
                                  filterData={filterControlId}
                                  setFilterData={setFilterControlId}
                                  FilterFunctionClick={FilterFunctionCLick}
                                  ClearData={ClearClickControlId}
                                />
                              }
                            >
                              <button>
                                <img src={filter} />
                              </button>
                            </Tooltip> */}
                          </div>
                        </div>
                      </th>
                    ) : null}

                    <th key="SubCategory" className="cell  ">
                      <div className="heading">
                        <h2> SubCategory</h2>
                      </div>
                    </th>
                    <th key="current" className="cell ">
                      <div className="heading">
                        <h2> Current Maturity</h2>
                      </div>
                    </th>
                    <th key="target" className="cell ">
                      <div className="heading">
                        <h2> Target Maturity</h2>
                      </div>
                    </th>
                    <th key="benchmarkMaturity" className="cell ">
                      <div className="heading">
                        <h2> Benchmark Maturity</h2>
                      </div>
                    </th>
                    <th key="priority" className="cell ">
                      <div className="headingFilter">
                        <h2> Priority</h2>
                        <div
                          className="filter-details "
                          onClick={() =>
                            setFiltertippriority(!filtertipPriority)
                          }
                          role="presentation"
                        >
                          <Tooltip
                            // options
                            title="Priority"
                            position="top"
                            trigger="click"
                            open={filtertipPriority}
                            arrow
                            interactive
                            theme="light"
                            onRequestClose={() => {
                              setFiltertippriority(false);
                            }}
                            html={
                              <FilterDropdown
                                data={Priority}
                                filterData={filterPriority}
                                setFilterData={setFilterPriority}
                                FilterFunctionClick={FilterFunctionCLick}
                                ClearData={ClearClickPriority}
                              />
                            }
                          >
                            <button type="button">
                              <img src={filter} />
                            </button>
                          </Tooltip>
                        </div>
                      </div>
                    </th>
                    <th key="recommendation" className="cell ">
                      <div className="heading">
                        <h2> Recommendation</h2>
                      </div>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {DetailViewData === undefined ||
                  DetailViewData.length === 0 ? (
                    <tr>
                      <td>No Data Avilable</td>
                    </tr>
                  ) : (
                    DetailViewData?.map((elementData) => {
                      return (
                        <tr key={elementData._id}>
                          <td className="functionName">
                            {elementData.functionName}
                          </td>

                          <td className="categoryName">
                            {elementData.categoryName}
                          </td>
                          {controlIdAvailable ? (
                            <td className="control_id">
                              {elementData.control_id}
                            </td>
                          ) : null}
                          <td className="subCategory">
                            {elementData.subCategory}
                          </td>

                          <td className="current"> {elementData.current}</td>
                          <td className="target"> {elementData.target}</td>
                          <td className="benchmar">
                            {elementData.benchmarkMaturity}
                          </td>
                          <td className="priority">
                            {elementData.priority.replace("Priority", "")}
                          </td>
                          <td className="recommendation">
                            {elementData.recommendation}
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            </TableContainer>
          </>
        )}
      </Box>
    </MainContainer>
  );
}

export default ModalAssessment;
