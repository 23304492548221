import {
  DragEvent,
  FormEvent,
  ReactElement,
  useEffect,
  useState,
  MouseEvent,
} from "react";
import { Form, FormControl } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DragAndDropInstructions } from "./components/DragAndDropInstruction";
import { RolesDetailsContainer } from "./components/RolesDetailsContainer";
import icons from "../../icons";
import {
  TalentsContainer,
  TalentsAddedSection,
  TalentsCriteriaSection,
  TalentsAddedHeading,
  SelectedTalentsContainer,
  AttributesInfoSection,
  DragAndDropInstructionContainer,
  MoreDragandDropInstruction,
  IconContainer,
  AttributeMainContainer,
} from "./styles";
import { workStreamTalentDetailsAction } from "./workStreamTalentDetailsSlice";
import { useAppDispatch } from "../../../store/hooks";
import {
  AttributesTalent,
  TalentsAddedProps,
  WsTalentAtrributes,
  WsAttributes,
  WsKSAList,
  WsKSAAttributes,
  WsKSAListGet,
} from "../../../model/wSTalent";
import { RootState } from "../../../store/store";
import MainAtrributeCard from "./components/LeftCards/MainAtrributeCard";
import RolesLeftCard from "./components/LeftCards/RolesLeftCard";
import { ksaTalentDetailsSubmitAction } from "./ksaTalentDetailsSlice";
import KsaLeftCards from "./components/LeftCards/KsaLeftCards";
import LoaderPage from "../../../pages/UtilPages/LoaderPage";
import { TechnologyWSModel } from "../../../model/statergyWS";
import { workStreamTalentDetailsSearchAction } from "./wsTalentSearchSlice";
import errorHandling from "../../../utils/errorHandling";
import { workStreamJobDescriptionAction } from "../selectOrganization/workStreamJobDescriptionDetailsSlice";
import { JobDesc } from "../../../model/common";

export type WsTalentMod = {
  id: string;
  attributeName: string;
  attributes: [];
  mustHave: boolean;
  goodToHave: boolean;
};

type TalentDetailsProps = {
  SetTalents: (value: TalentsAddedProps[]) => void;
  TalentsData: TalentsAddedProps[];

  assessmentId: string | null;
  workStreamId: string;
};

function TalentsDetails({
  SetTalents,
  TalentsData,
  assessmentId,
  workStreamId,
}: TalentDetailsProps): ReactElement {
  const dispatch = useAppDispatch();
  const [Role, setRoles] = useState<WsAttributes[]>();
  const [MainAtrributes, setMainAttribute] = useState<WsTalentAtrributes[]>();
  const [ksaList, setKSAList] = useState<WsKSAList[]>();
  // for creating the new Talents array
  const [search, setSearch] = useState("");
  // dispatching the action and getting talent details from backend
  useEffect(() => {
    dispatch(
      workStreamTalentDetailsAction.workStreamTalentDetailsRequest(
        workStreamId,
      ),
    );
    if (assessmentId !== null) {
      const jobRequestData = {
        workStreamId,
        assessmentId,
      };
      dispatch(
        workStreamJobDescriptionAction.workStreamJobDescriptionRequest(
          jobRequestData,
        ),
      );
    }
  }, []);

  // Search functionality
  const handleSearch = () => {
    dispatch(
      workStreamTalentDetailsSearchAction.workStreamTalentDetailsSearchRequest(
        search,
      ),
    );
  };

  /// ///////// -------------- Selectors ------------------///////////////////
  // storing Talent Attirubts got
  const dataAttributes = useSelector(
    (state: RootState) => state.workStreamTalentDetails,
  );

  const ksaTalentAttribureList = useSelector(
    (state: RootState) => state.ksaTalentAttributeList,
  );

  // Getting Data for Which Technology is selected
  const dataTechSubmit = useSelector(
    (state: RootState) => state.workStreamTechSubmit,
  );

  // Getting Job attribut Description details if its already set
  const jobAttributes = useSelector(
    (state: RootState) => state.workStreamJobDescriptionDetails,
  );

  // Getting Data for Which Technology is selected
  const dataSearch = useSelector((state: RootState) => state.wsSearchAction);

  // Setting Data if its already saved
  useEffect(() => {
    if (jobAttributes.data !== undefined) {
      const JobDataSaved = jobAttributes.data.map((item: JobDesc) => {
        return { ...item, id: item._id };
      });
      SetTalents(JobDataSaved);
    }
  }, [jobAttributes.data]);

  const sortingRoles = (RolesData: WsAttributes[]) => {
    if (RolesData.length > 0) {
      setRoles(RolesData);
    }
  };

  useEffect(() => {
    if (dataSearch.data.length > 0) {
      // Sorting Roles from main data
      const tempdatRoles = dataSearch.data.filter(
        (temp: WsTalentAtrributes) => temp.attributeName === "Roles",
      );
      sortingRoles(tempdatRoles[0].attributes);

      // Filtering Experience Aquisation from main data
      const tempdatMainAttribute = dataSearch.data.filter(
        (temp: WsTalentAtrributes) => temp.attributeName !== "Roles",
      );
      const tempDataWithoutTech = tempdatMainAttribute.filter(
        (temp: WsTalentAtrributes) => temp.attributeName !== "Technology",
      );
      setMainAttribute(tempDataWithoutTech);
    }
  }, [dataSearch]);

  // const [AttributeTalent, setAttributeTalents] = useState<any | []>([]);

  const [selectedTech, setSelectedTech] = useState<string>();
  // Setting Data from the store of talents
  useEffect(() => {
    if (dataAttributes.data.length > 0) {
      // Sorting Roles from main data
      const tempdatRoles = dataAttributes.data.filter(
        (temp: WsTalentAtrributes) => temp.attributeName === "Job Titles",
      );
      sortingRoles(tempdatRoles[0].attributes);

      // Filtering Experience Aquisation from main data
      const tempdatMainAttribute = dataAttributes.data.filter(
        (temp: WsTalentAtrributes) => temp.attributeName !== "Job Titles",
      );
      const tempDataWithoutTech = tempdatMainAttribute.filter(
        (temp: WsTalentAtrributes) => temp.attributeName !== "Technology",
      );
      setMainAttribute(tempDataWithoutTech);
    }
    if (dataTechSubmit?.data?.technologies !== undefined) {
      const technology: TechnologyWSModel[] = dataTechSubmit.data.technologies;
      technology.forEach((recItem: TechnologyWSModel) => {
        if (recItem.isSelected === true) {
          setSelectedTech(recItem.technologyName);
        }
      });
    }
  }, [dataAttributes, dataTechSubmit]);

  // Updating KSA on click of roles
  const updateKSAonClickRole = (workRoleIds: string[] | undefined) => {
    if (workRoleIds !== undefined) {
      if (workRoleIds.length > 0) {
        const data = workRoleIds.join("&designationId=");
        dispatch(
          ksaTalentDetailsSubmitAction.ksaTalentDetailsFindRequest(data),
        );
      }
    }
  };

  useEffect(() => {
    const tempdattechAttribute = dataAttributes.data.filter(
      (temp: WsTalentAtrributes) => temp.attributeName === "Technology",
    );
    const techUpdated: WsKSAList[] = tempdattechAttribute.map(
      (temp: WsTalentAtrributes) => {
        const tempTechUdpated = temp.attributes.map((data: WsAttributes) => {
          return { _id: data._id, ksaId: data.domain as string };
        });
        return {
          _id: temp.id,
          attributeName: temp.attributeName,
          goodToHave: false,
          mustHave: false,
          attributes: tempTechUdpated,
        };
      },
    );

    if (ksaTalentAttribureList.data !== undefined) {
      const tempKsaDataTalent: WsKSAList[] = ksaTalentAttribureList.data.map(
        (ksaItem: WsKSAListGet) => {
          return {
            ...ksaItem,
            goodToHave: false,
            mustHave: false,
          };
        },
      );
      setKSAList([...tempKsaDataTalent, ...techUpdated]);
    }
  }, [ksaTalentAttribureList, dataAttributes]);

  // Changing Selecotr of attribute
  const changeSelector = (_id: string, ev: FormEvent) => {
    const Data = ksaList?.map((Ksaitem: WsKSAList) => {
      if (Ksaitem._id === _id && (ev.target as Element).id === "musthave") {
        return { ...Ksaitem, mustHave: !Ksaitem.mustHave };
      }
      if (Ksaitem._id === _id && (ev.target as Element).id === "goodtohave") {
        return { ...Ksaitem, goodToHave: !Ksaitem.goodToHave };
      }
      return Ksaitem;
    });

    setKSAList(Data);
  };

  // Dragging Function for roles
  const DragRole = (
    ev: DragEvent,
    _id: string,
    role: string,
    workRoleIds: string[],
  ) => {
    const dragData = {
      _id,
      role,
    };
    if (workRoleIds !== undefined) {
      if (workRoleIds.length > 0) {
        const data = workRoleIds.join("&designationId=");
        dispatch(
          ksaTalentDetailsSubmitAction.ksaTalentDetailsFindRequest(data),
        );
      }
    }
    ev.dataTransfer?.setData("DragRole", JSON.stringify(dragData));
  };

  // Dragging function for Mainattributes
  const DragMainAttributes = (
    ev: DragEvent,
    _id: string,
    attributeName: string,
    attribute: WsAttributes,
  ) => {
    const dragData = {
      _id,
      attributeName,
      attribute,
      item: "MainAttribute",
    };
    ev.dataTransfer?.setData("DragAttribute", JSON.stringify(dragData));
  };

  // Dragging function for attributes
  const DragAttributes = (
    ev: DragEvent,
    attribute: WsKSAAttributes,
    ksaItem: WsKSAList,
  ) => {
    const dragData = {
      _id: ksaItem._id,
      ksaItem,
      attribute,
      item: "KSAAttribute",
    };
    ev.dataTransfer?.setData("DragAttribute", JSON.stringify(dragData));
  };

  // Dropping Roles to the container
  const DropRole = (ev: DragEvent) => {
    if (ev.dataTransfer.getData("DragRole") !== "") {
      const trasferData = JSON.parse(ev.dataTransfer.getData("DragRole"));
      if (trasferData !== "") {
        const Data = {
          id: TalentsData.length.toString(),
          roleId: trasferData._id,
          no_of_talents: 1,
          roleName: trasferData.role,
          attributes: [
            {
              _id: "4",
              name: "Technology",
              data: [
                {
                  _id: "0",
                  ksaId: selectedTech,
                  mustHave: true,
                  goodToHave: false,
                },
              ],
            },
          ],
        };
        SetTalents([...TalentsData, Data]);
      }
    }
  };

  // Dropping Attirbutes to the Roles Container
  const DropAttributes = (ev: DragEvent, id: string) => {
    ev.stopPropagation();

    const dataTransfred = JSON.parse(
      ev.dataTransfer.getData("DragAttribute") as string,
    );

    if (dataTransfred.item === "MainAttribute") {
      const data = {
        _id: dataTransfred._id,
        name: dataTransfred.attributeName,
        data: [
          {
            _id: dataTransfred.attribute._id,
            ksaId: dataTransfred.attribute.attributeName,
            mustHave: false,
            goodToHave: false,
          },
        ],
      };

      const tempData = TalentsData.map((Talents: TalentsAddedProps) => {
        if (Talents.id === id) {
          let flagExist = 0;
          const DataTalentAttribute = Talents.attributes.map(
            (attribute: AttributesTalent) => {
              if (attribute._id === dataTransfred._id) {
                flagExist = 1;
                return data;
              }
              return attribute;
            },
          );

          if (flagExist === 1) {
            flagExist = 0;
            return { ...Talents, attributes: DataTalentAttribute };
          }
          return { ...Talents, attributes: [...DataTalentAttribute, data] };
        }
        return Talents;
      });
      SetTalents(tempData);
    } else if (dataTransfred.item === "KSAAttribute") {
      const KsaData: AttributesTalent = {
        _id: dataTransfred._id as string,
        name: dataTransfred.ksaItem.attributeName as string,
        data: [
          {
            _id: dataTransfred.attribute._id as string,
            ksaId: dataTransfred.attribute.ksaId as string,
            mustHave: dataTransfred.ksaItem.mustHave as boolean,
            goodToHave: dataTransfred.ksaItem.goodToHave as boolean,
          },
        ],
      };
      let alreadyexistingFlag = 0;
      const tempData = TalentsData.map((Talents: TalentsAddedProps) => {
        if (Talents.id === id) {
          let flagExist = 0;
          const DataTalentAttribute = Talents.attributes.map(
            (attribute: AttributesTalent) => {
              if (attribute._id === dataTransfred._id) {
                attribute.data.forEach((dat) => {
                  if (
                    dat._id === dataTransfred.attribute._id ||
                    dat.ksaId?.toString().replace(/\s/g, "") ===
                      dataTransfred.attribute.ksaId
                        .toString()
                        .replace(/\s/g, "")
                  ) {
                    alreadyexistingFlag = 1;
                  }
                });
                flagExist = 1;
                return {
                  ...attribute,
                  data: [...attribute.data, ...KsaData.data],
                };
              }
              return attribute;
            },
          );
          if (flagExist === 1) {
            return { ...Talents, attributes: DataTalentAttribute };
          }
          return {
            ...Talents,
            attributes: [...DataTalentAttribute, KsaData],
          };
        }
        return Talents;
      });
      if (alreadyexistingFlag === 0) {
        SetTalents(tempData);
      }
    }
  };

  const allowDrop = (ev: DragEvent) => {
    ev.preventDefault();
  };

  // Dublicating the roles
  const dublicateRoles = (Talentid: string) => {
    const newRole = TalentsData.filter(
      (Talent: TalentsAddedProps) => Talent.id === Talentid,
    );
    SetTalents([
      ...TalentsData,
      {
        id: TalentsData.length.toString(),
        roleId: newRole[0].roleId,
        no_of_talents: 1,
        roleName: newRole[0].roleName,
        attributes: newRole[0].attributes,
      },
    ]);
  };

  // Deleting the attributes that moved through drop down
  const deleteAttribute = (
    ev: MouseEvent,
    Talentid: string,
    attribtueid: string,
    attributeName: string,
    attributeitemid: string,
  ) => {
    const TalentDataFiltered = TalentsData.filter(
      (talent) => talent.id === Talentid,
    );
    const innerAttributes = [...TalentDataFiltered[0].attributes];
    const innerAttributeitem = innerAttributes.filter(
      (attribute) => attribute._id !== attribtueid,
    );
    let tempdata: AttributesTalent[];
    if (attributeName === "Experience") {
      tempdata = innerAttributeitem;
    } else if (attributeName === "Acquisition") {
      tempdata = innerAttributeitem;
    } else {
      const deleteInnerAtributes = innerAttributes.filter(
        (attribute) => attribute._id === attribtueid,
      );
      const innerDataArray = deleteInnerAtributes[0].data.filter(
        (dataitem) => dataitem._id !== attributeitemid,
      );
      tempdata = [
        ...innerAttributeitem,
        { ...deleteInnerAtributes[0], data: innerDataArray },
      ];
    }

    const newData = TalentsData.map((talent: TalentsAddedProps) => {
      if (talent.id === Talentid) {
        return { ...talent, attributes: tempdata };
      }
      return talent;
    });

    SetTalents(newData);
  };

  // Deleting Roles
  const deleteRoles = (Talentid: string) => {
    const newRole = TalentsData.filter(
      (Talent: TalentsAddedProps) => Talent.id !== Talentid,
    );
    const newTemp = newRole.map((item: TalentsAddedProps, index: number) => {
      return { ...item, id: index.toString() };
    });
    SetTalents(newTemp);
  };

  // Updating Number of Roles Plus
  const UpdateNoOfRolesPlus = (_id: string) => {
    const UpdatedTalents = TalentsData.map((Talents) => {
      if (Talents.id === _id && Talents.no_of_talents === undefined) {
        return { ...Talents, no_of_talents: 2 };
      }
      if (Talents.id === _id) {
        return { ...Talents, no_of_talents: Talents.no_of_talents + 1 };
      }
      return Talents;
    });
    SetTalents(UpdatedTalents);
  };

  // Updating Number of Roles Minus
  const UpdateNoOfRolesMinus = (_id: string) => {
    const UpdatedTalents = TalentsData.map((Talents) => {
      if (
        Talents.id === _id &&
        Talents.no_of_talents !== undefined &&
        Talents.no_of_talents > 1
      ) {
        return { ...Talents, no_of_talents: Talents.no_of_talents - 1 };
      }
      return Talents;
    });
    SetTalents(UpdatedTalents);
  };

  /// //----------------------------------Error Handling---------------------------//////////////////
  const navigate = useNavigate();
  useEffect(() => {
    if (dataAttributes.error) {
      errorHandling(dataAttributes.status, navigate);
    } else if (ksaTalentAttribureList.error) {
      errorHandling(ksaTalentAttribureList.status, navigate);
    } else if (dataSearch.error) {
      errorHandling(dataSearch.status, navigate);
    }
    // else if(dataTechSubmit.error){
    //   errorHandling(dataTechSubmit.status, navigate);
    // }
  }, [dataAttributes.error, ksaTalentAttribureList.error, dataSearch.error]);

  return dataAttributes.loading ? (
    <LoaderPage />
  ) : (
    // Talents Added section
    <TalentsContainer>
      {jobAttributes.loading ? (
        <LoaderPage />
      ) : (
        <TalentsAddedSection>
          <TalentsAddedHeading>
            <h3>Talents Added</h3>
          </TalentsAddedHeading>
          {TalentsData.length === 0 ? (
            <DragAndDropInstructionContainer>
              <DragAndDropInstructions
                id={TalentsData.length.toString()}
                drop={DropRole}
                allowed={allowDrop}
                isFullHeight={false}
                text="Drag and drop the required talent to this panel to build the required profile"
              />
            </DragAndDropInstructionContainer>
          ) : (
            // Droppable section of Talents Added
            <SelectedTalentsContainer
              onDrop={(ev) => {
                DropRole(ev);
              }}
              onDragOver={(ev) => {
                allowDrop(ev);
              }}
            >
              {/* Custom scrollbar Added */}

              <div
                onDrop={(ev) => {
                  DropRole(ev);
                }}
                onDragOver={(ev) => {
                  allowDrop(ev);
                }}
              >
                {TalentsData.map((item: TalentsAddedProps) => (
                  <RolesDetailsContainer
                    item={item}
                    deleteRoles={deleteRoles}
                    dropAttributes={DropAttributes}
                    id={item.id}
                    deleteAttribute={deleteAttribute}
                    dublicateRoles={dublicateRoles}
                    allowed={allowDrop}
                    key={item.id}
                    UpdateNoOfRolesPlus={UpdateNoOfRolesPlus}
                    UpdateNoOfRolesMinus={UpdateNoOfRolesMinus}
                  />
                ))}
              </div>
              {/* More Roles added section */}
              <MoreDragandDropInstruction>
                You can add more roles by dragging in roles
              </MoreDragandDropInstruction>
            </SelectedTalentsContainer>
          )}
        </TalentsAddedSection>
      )}

      {/* Talents Criteria section */}
      <TalentsCriteriaSection>
        <h3>Talent Criteria</h3>

        {/* Searchbar section */}
        <Form className="searchContainer">
          <FormControl
            type="search"
            placeholder="Search for role, expertise, acquisition, education.."
            className="searchBar"
            aria-label="Search"
            onChange={(ev: FormEvent) => {
              setSearch((ev.target as HTMLInputElement).value);
            }}
          />
          <IconContainer onClick={handleSearch}>
            <icons.SearchIcon />
          </IconContainer>
        </Form>
        <AttributeMainContainer>
          <AttributesInfoSection>
            {/* Different Roles section */}

            <RolesLeftCard
              Role={Role}
              updataKSA={updateKSAonClickRole}
              DragRole={DragRole}
            />
            {MainAtrributes !== undefined
              ? MainAtrributes.map((Attributitem) => {
                  return (
                    <MainAtrributeCard
                      Attributitem={Attributitem}
                      key={Attributitem.id}
                      DragMainAttributes={DragMainAttributes}
                    />
                  );
                })
              : null}

            {/* KSA attributes section */}
            {ksaTalentAttribureList.loading ? (
              <LoaderPage />
            ) : (
              ksaList?.map((ksaListItem) => {
                return (
                  <KsaLeftCards
                    ksaItem={ksaListItem}
                    key={ksaListItem.attributeName}
                    changeSelector={changeSelector}
                    DragAttributes={DragAttributes}
                  />
                );
              })
            )}
          </AttributesInfoSection>
        </AttributeMainContainer>
      </TalentsCriteriaSection>
    </TalentsContainer>
  );
}

export { TalentsDetails };
