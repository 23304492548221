import { takeLatest, put, fork, call } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import http from "../../../utils/http.service";
import config from "../../../utils/request.config";
import { domainListAction } from "./technologySlice";
import { getAllDomains } from "../../../utils/serviceurl";

export const fetchDomainList = async () =>
  http.GET(config.getConfig("", getAllDomains, ""));

function* onDomainGetListAsync() {
  try {
    const response: AxiosResponse<any> = yield call(fetchDomainList);
    if (response.status === 200) {
      yield put(
        domainListAction.domainListSuccess({
          data: response.data.data,
          status: response.status,
        }),
      );
    }
  } catch (error) {
    // console.log(error);
  }
}

function* onDomainGetList() {
  yield takeLatest(domainListAction.domainListRequest, onDomainGetListAsync);
}

export const technologySaga = [fork(onDomainGetList)];
