import { ReactElement } from "react";
import { ListGroup, ProgressBar, Tab, Tabs } from "react-bootstrap";
import styled from "styled-components";

import { Scrollbars } from "react-custom-scrollbars-2";
import _ from "lodash";
import icons from "../../icons";

const StyledAssessmentDetails = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.border.default};
  margin: 0;
  border-radius: 10px;
  overflow: hidden;
  min-height: 78px;
  display: flex;
  min-height: 500px;
  padding: 0;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.bg.white};

  .TabsDetails {
    height: auto;
    margin-top: 0 !important;
    justify-content: flex-start !important;
    background-color: ${({ theme }) => theme.colors.bg.white}!important;
    border-bottom: 1px solid ${({ theme }) => theme.colors.border.default};
    padding-left: 50px;
  }
  .tab-content {
    height: 100%;
    background: ${({ theme }) => theme.colors.bg.white}!important;
  }
  .tab-pane {
    height: 100%;
  }
  .nav-item {
    height: 100%;
  }
  .nav-tabs .nav-link {
    margin-bottom: unset;
    height: 100%;
    padding: 25px 8px 13px 8px;
    margin-right: 30px;
    width: auto;
    justify-content: center;
    font-family: ${({ theme }) => theme.fontFamily.light}!important;
    font-weight: normal;
    font-size: ${({ theme }) => theme.fontSizes[1]};
    line-height: ${({ theme }) => theme.lineHeight[2]};
    color: ${({ theme }) => theme.colors.text.lightGrey1};
    border-bottom: 3px solid transparent;
    border: none;

    &:hover {
      color: ${({ theme }) => theme.colors.text.tealishBlue} !important;
      font-family: ${({ theme }) => theme.fontFamily.light} !important;
      &:after {
        content: "";
        height: 3px;
      }
    }
    &.active {
      color: ${({ theme }) => theme.colors.text.tealishBlue} !important;
      font-family: ${({ theme }) => theme.fontFamily.bold}!important;
      font-weight: normal;
      border-bottom-left-radius: unset;
      border-bottom-right-radius: unset;
      border-bottom: 3px solid ${({ theme }) => theme.colors.text.tealishBlue};
      &:after {
        content: "";
        height: 3px;
      }
    }
  }
  .scrollbar {
    max-height: 100%;
  }
  .listGroup {
    li {
      border: 1px solid ${({ theme }) => theme.colors.bg.bluishCyan};
      box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.03);
      padding: 23px 20px;
      border-radius: 8px !important;
      display: flex;
      align-items: center;
      border-width: 1px !important;
      & + li {
        margin-top: 10px;
      }
    }
  }
`;
const TabsContent = styled.div`
  padding: 28px;
`;
const AssessmentName = styled.div`
  display: flex;
  align-items: center;
  max-width: 250px;
  flex: 0 0 250px;
  text-transform: capitalize;
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: ${({ theme }) => theme.fontSizes[1]};
  line-height: ${({ theme }) => theme.lineHeight[1]};
  font-weight: normal;
  color: ${({ theme }) => theme.colors.text.black};
  @media screen and (max-width: 1300px) {
    max-width: 180px;
    flex: 0 0 180px;
  }
  span {
    font-weight: normal;
  }
  .pr-icon {
    margin-right: 10px;
  }
`;
const ProgressBarWrapper = styled.div`
  width: 100%;
  position: relative;
  .progress {
    height: 10px;
    border-radius: 50px;
    .progress-bar {
      background-color: ${({ theme }) => theme.colors.bg.tealishBlue};
    }
  }
`;
const ProgressBreakPoint = styled.div`
  position: absolute;
  top: -11px;
  margin-left: -6px;
`;
const Heading = styled.div`
  display: flex;
  padding: 28px 0 0 28px;
  font-family: ${({ theme }) => theme.fontFamily.bold};
  font-size: ${({ theme }) => theme.fontSizes[2]};
  line-height: ${({ theme }) => theme.lineHeight[3]};
  font-weight: normal;
  color: ${({ theme }) => theme.colors.text.black};
`;
const Category = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: ${({ theme }) => theme.fontSizes[1]};
  line-height: ${({ theme }) => theme.lineHeight[1]};
  font-weight: normal;
  color: ${({ theme }) => theme.colors.text.black};
`;
const Legends = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${({ theme }) => theme.fontFamily.medium};
  font-size: ${({ theme }) => theme.fontSizes[1]};
  line-height: ${({ theme }) => theme.lineHeight[1]};
  font-weight: normal;
  color: ${({ theme }) => theme.colors.text.black};
  margin-bottom: 20px;
  span {
    display: flex;
    align-items: center;
    & + span {
      margin-left: 20px;
    }
    div {
      width: 24px;
      height: 8px;
      border-radius: 50px;
      margin-right: 10px;
      background-color: ${({ theme }) => theme.colors.bg.tealishBlue};
    }
    .pr-icon {
      margin-right: 6px;
    }
  }
`;
function AssessmentDetails({ assessmentDetails }: any): ReactElement {
  return (
    <StyledAssessmentDetails>
      <Heading>Assessment Details</Heading>
      {assessmentDetails?.length > 0 && (
        <Tabs
          defaultActiveKey={assessmentDetails[0].function}
          id="uncontrolled-tab-example"
          className="TabsDetails"
        >
          {assessmentDetails.map((item: any) => (
            <Tab
              eventKey={item.functionName}
              title={_.capitalize(item.functionName)}
              key={item?.function_id}
            >
              <Scrollbars autoHide className="scrollbar">
                <TabsContent>
                  <ListGroup as="ul" className="listGroup">
                    {item.category.map((tempItem: any) => (
                      <ListGroup.Item as="li" key={tempItem?.category_id}>
                        <AssessmentName>
                          {tempItem.current >= tempItem.target ? (
                            <icons.Tick3 />
                          ) : (
                            <icons.info1 />
                          )}
                          <Category>
                            {tempItem.category}
                            {/* {tempItem?.control_id !== "" &&
                            tempItem?.control_id !== null ? (
                              <span
                                style={{ fontWeight: "700", marginLeft: "3px" }}
                              >
                                ({tempItem?.control_id})
                              </span>
                            ) : null} */}
                          </Category>
                        </AssessmentName>
                        <ProgressBarWrapper style={{ marginLeft: "5px" }}>
                          <ProgressBreakPoint
                            style={{ left: `${(tempItem.target / 5) * 100}%` }}
                          >
                            <icons.ProgressStrokeIcon />
                          </ProgressBreakPoint>
                          <ProgressBreakPoint
                            style={{ left: `${(tempItem.current / 5) * 100}%` }}
                          >
                            <icons.ProgressBreakICon />
                          </ProgressBreakPoint>
                          <ProgressBar now={(tempItem.current / 5) * 100} />
                        </ProgressBarWrapper>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </TabsContent>
              </Scrollbars>
            </Tab>
          ))}
        </Tabs>
      )}
      <Legends>
        {/* <span>
          <div></div>Current
        </span> */}
        <span>
          {" "}
          <icons.ProgressBreakICon /> Current Level
        </span>
        <span>
          <icons.ProgressStrokeIcon /> Target Level
        </span>
      </Legends>
    </StyledAssessmentDetails>
  );
}

export { AssessmentDetails };
