import { takeLatest, put, fork, call } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { PayloadAction } from "@reduxjs/toolkit";
import http from "../../../utils/http.service";
import config from "../../../utils/request.config";
import { roadMapFetchAction } from "./roadMapFetchSlice";
import {
  wsResoucePlanningSubmit,
  wsRoadMapGet,
} from "../../../utils/serviceurl";
import { UpdateDateRoadMap } from "../../../model/wsRoadMap";
import { roadMapUpdateDateAction } from "./Components/TimelineCalender/roadMapFetchSlice";
import { ErrorType } from "../../../model/common";

export const fetchroadMapDetails = async (assessmentId: string) =>
  http.GET(
    config.getConfig("", `${wsRoadMapGet}?assessmentId=${assessmentId}`, ""),
  );

function* onroadMapgetDetailsAsync(action: PayloadAction<string>) {
  try {
    const response: AxiosResponse<any> = yield call(
      fetchroadMapDetails,
      action.payload,
    );
    if (response.status === 200) {
      yield put(
        roadMapFetchAction.roadMapFetchSuccess({
          data: response.data.data,
          status: response.status,
        }),
      );
    }
  } catch (error) {
    // console.log(error);
  }
}

function* onRoadMapGetList() {
  yield takeLatest(
    roadMapFetchAction.roadMapFetchRequest,
    onroadMapgetDetailsAsync,
  );
}

// Udpate date of workstream after completion
export const workStreamUpdateDateDetails = async (data: any) =>
  http.POST(config.postConfig(data, "", wsResoucePlanningSubmit, ""));

function* onworkStreamUdpateDateAsync(
  action: PayloadAction<UpdateDateRoadMap>,
) {
  try {
    const response: AxiosResponse<any> = yield call(
      workStreamUpdateDateDetails,
      action.payload,
    );
    if (response.status === 200) {
      yield put(
        roadMapUpdateDateAction.roadMapUdpateDateSuccess({
          status: response.status,
        }),
      );
    }
  } catch (error) {
    yield put(
      roadMapUpdateDateAction.roadMapUpdateDateFailure({
        error: error as ErrorType,
      }),
    );
  }
}

function* onworkStreamUpdateDateSubmit() {
  yield takeLatest(
    roadMapUpdateDateAction.roadMapUdpateDateRequest,
    onworkStreamUdpateDateAsync,
  );
}

export const roadMapGetSaga = [
  fork(onRoadMapGetList),
  fork(onworkStreamUpdateDateSubmit),
];
