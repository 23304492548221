import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DetailsFormProps } from "../../../model/wSDetailsForm";
import { ErrorType } from "../../../model/common";

export interface WsForm {
  loading: boolean;
  data: DetailsFormProps[];
  status: number | null;
  error: boolean;
}

export const initialState: WsForm = {
  loading: false,
  data: [],
  status: 0,
  error: false,
};

const workStreamDetailsSlice = createSlice({
  name: "workStreamDetails",
  initialState,
  reducers: {
    reset: () => initialState,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    workStreamDetailsRequest(state: WsForm, action: PayloadAction<any>) {
      state.loading = true;
    },
    workStreamDetailsSuccess(
      state: WsForm,
      action: PayloadAction<{ status: number; data: DetailsFormProps[] }>,
    ) {
      state.loading = false;
      state.data = action.payload.data;
      state.status = action.payload.status;
    },
    workStreamDetailsFailure(state: WsForm) {
      state.loading = false;
      // state.error = action.payload;
    },
  },
});

const getTimeLineDetailsSlice = createSlice({
  name: "getTimeLineFormDetails",
  initialState,
  reducers: {
    reset: () => initialState,
    getTimeLineDetailsRequest(state: any, action: PayloadAction<any>) {
      state.loading = true;
    },
    getTimeLineDetailsSuccess(
      state: WsForm,
      action: PayloadAction<{ status: number; data: DetailsFormProps[] }>,
    ) {
      state.loading = false;
      state.data = action.payload.data;
      state.status = action.payload.status;
    },
    getTimeLineDetailsFailure(
      state: WsForm,
      action: PayloadAction<{ error: ErrorType }>,
    ) {
      state.loading = false;
      state.status = action.payload.error.response.status;
      state.error = true;
    },
  },
});

const riskToleranceSlice = createSlice({
  name: "riskToleranceData",
  initialState,
  reducers: {
    reset: () => initialState,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    getRiskToleranceRequest(state: any) {
      state.loading = true;
    },
    getRiskToleranceSuccess(
      state: WsForm,
      action: PayloadAction<{ status: number; data: any }>,
    ) {
      state.loading = false;
      state.data = action.payload.data;
      state.status = action.payload.status;
    },
    getRiskToleranceFailure(state: any) {
      state.loading = false;
      // state.error = action.payload;
    },
  },
});

export const workStreamDetailsAction = workStreamDetailsSlice.actions;
export const workStreamDetailsReducer = workStreamDetailsSlice.reducer;
export const getTimeLineDetailsAction = getTimeLineDetailsSlice.actions;
export const getTimeLineFormDetailsReducer = getTimeLineDetailsSlice.reducer;
export const getRiskToleranceAction = riskToleranceSlice.actions;
export const RiskToleranceReducer = riskToleranceSlice.reducer;

export default {
  workStreamDetailsReducer,
  getTimeLineFormDetailsReducer,
  RiskToleranceReducer,
};
