const fontWeights = {
  black: 900,
  bold: 700,
  medium: 600,
  regular: 400,
  light: 300,
  thin: 100,
};

export default fontWeights;
