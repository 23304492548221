import React, { ReactElement } from "react";
import { Accordion } from "react-bootstrap";

type SidebarAccordionprops = {
  item: any;
  ButtonClickNavigation: (Id: string) => void;
};

function SidebarAccordion({
  item,
  ButtonClickNavigation,
}: SidebarAccordionprops): ReactElement {
  return (
    <Accordion.Item
      eventKey={item.id}
      onClick={() => ButtonClickNavigation(item.id)}
    >
      <Accordion.Header className="title">
        <div className="icons-container">{item.icon}</div>
        <h2 className="title-name">{item.title}</h2>
      </Accordion.Header>
      <Accordion.Body className="accordion-innercontainer">
        {/* <ul>
              <li>
                <icons.RoundIcon />
                <p>Activity A</p>
              </li>
              <li>
                <icons.RoundIcon />
                <p>Activity B</p>
              </li>
              <li>
                <icons.RoundIcon />
                <p>Activity C</p>
              </li>
              <li>
                <icons.RoundIcon />
                <p>Activity D</p>
              </li>
            </ul> */}
      </Accordion.Body>
    </Accordion.Item>
  );
}

export { SidebarAccordion };
