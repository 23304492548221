import { ReactElement } from "react";
import styled from "styled-components";
import dragAndDrop from "../../../../images/DragAndDropICon/dragAndDrop.svg";

const DragAndDropInstruction = styled.div`
  height: 234px;
  width: 100%;
  background: ${({ theme }) => theme.baseColors.white};
  border: 1px dashed ${({ theme }) => theme.colors.border.purpleShade};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1200px) {
    .dropLogo {
      width: 50px;
      height: 50px;
    }
  }
`;
const InstructionText = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-size: ${({ theme }) => theme.fontSizes[2]};
  line-height: ${({ theme }) => theme.lineHeight[4]};
  color: ${({ theme }) => theme.colors.text.lightGrey1};
  font-style: normal;
  margin-top: 20px;
  width: 285px;
  text-align: center;
  @media screen and (max-width: 1200px) {
    font-size: ${({ theme }) => theme.fontSizes[1]};
  }
`;
type Props = {
  id: string;
  drop: any;
  allowed: any;
  isFullHeight: boolean;
  text: string;
};
function DragAndDropInstructions({
  id,
  drop,
  allowed,
  isFullHeight,
  text,
}: Props): ReactElement {
  return (
    <DragAndDropInstruction
      onDrop={(ev) => {
        drop(ev, id);
      }}
      onDragOver={(ev) => {
        allowed(ev);
      }}
      style={
        isFullHeight
          ? { height: "300px", border: "unset" }
          : {
              height: "100%",
              border: "1px dashed",
              borderColor: "#B5C2D5",
            }
      }
    >
      <img src={dragAndDrop} alt="Drag and Drop Icon" className="dropLogo" />
      <InstructionText>{text}</InstructionText>
    </DragAndDropInstruction>
  );
}

export { DragAndDropInstructions };
