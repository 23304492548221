import React, { ReactNode, ReactElement } from "react";
import styled from "styled-components";

const StyledIconWrapper = styled.span`
  line-height: 1;
  svg {
    display: block;
    position: relative;
    /* top: 0.185em; */
    min-height: auto;
    min-width: auto;
    fill: currentColor;
  }

  svg.pr-icon-fa {
    position: relative;
    top: 0.185em;
    height: 1em;
    width: 1em;
    fill: currentColor;
  }
  svg.fb-icon-fa {
    width: 54px;
    height: 54px;
  }
  svg.calendar {
    float: left;
  }
  svg.ratingIcon,
  svg.foodItem {
    float: left;
  }
  svg.InfoIcon,
  svg.modalClose {
    float: left;
    cursor: pointer;
  }
  svg.hamburgerMenu {
    float: left;
  }
`;

type IconWrapperProps = {
  children: ReactNode;
};

function IconWrapper({ children }: IconWrapperProps): ReactElement {
  return <StyledIconWrapper className="pr-icon">{children}</StyledIconWrapper>;
}

export { IconWrapper };
