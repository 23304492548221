import { takeLatest, put, fork, call } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { PayloadAction } from "@reduxjs/toolkit";
import config from "../../../utils/request.config";
import http from "../../../utils/http.service";
import { tomUploadAction } from "./tomUploadFetchSlice";
import { ErrorType } from "../../../model/common";
import { tomuploadURL } from "../../../utils/serviceurl";

/// //////////////// --------------------------- Uploading Tom Model Excel --------------///
export const tomUploadDetails = async (data: any) =>
  http.POST(config.postConfig(data, "", tomuploadURL, ""));

function* ontomUploadDetailsAsync(action: PayloadAction<any>) {
  try {
    const response: AxiosResponse<any> = yield call(
      tomUploadDetails,
      action.payload,
    );
    if (response.status === 200) {
      yield put(
        tomUploadAction.tomUploadSuccess({
          status: response.status,
          data: response.data.data,
        }),
      );
    }
  } catch (error) {
    yield put(
      tomUploadAction.tomUploadFailure({
        error: error as ErrorType,
      }),
    );
  }
}

function* ontomUploadGetDetails() {
  yield takeLatest(tomUploadAction.tomUploadRequest, ontomUploadDetailsAsync);
}

export const tomSaga = [fork(ontomUploadGetDetails)];
