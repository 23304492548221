import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RecommendationListProps } from "../../../model/wsTechnology";

export interface RecommendationListProp {
  loading: boolean;
  data: RecommendationListProps[];
  status: number;
  // error:null| string
}

export const initialState: RecommendationListProp = {
  loading: false,
  data: [],
  status: 0,
  // error:null
};

const recommendationListSlice = createSlice({
  name: "recommendationList",
  initialState,
  reducers: {
    reset: () => initialState,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    recommendationListRequest(
      state: RecommendationListProp,
      action: PayloadAction<any>,
    ) {
      state.loading = true;
      //   state.posts = action;
    },
    recommendationListSuccess(
      state: RecommendationListProp,
      action: PayloadAction<{
        data: RecommendationListProps[];
        status: number;
      }>,
    ) {
      state.loading = false;
      state.data = action.payload.data;
      state.status = action.payload.status;
    },
    recommendationListFailure(state: RecommendationListProp) {
      state.loading = false;
      // state.error = action.payload;
    },
  },
});

export const recommendationListAction = recommendationListSlice.actions;
export const recommendationListReducer = recommendationListSlice.reducer;

export default recommendationListReducer;
