/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorType } from "../../../model/common";
import { AssessmentType } from "../../../model/dashTypeFramework";

export interface GetAssessmentType {
  loading: boolean;
  data: AssessmentType[];
  status: number;
  error: boolean;
}

export const initialState: GetAssessmentType = {
  loading: false,
  data: [],
  status: 0,
  error: false,
};

const assesmentFetchSlice = createSlice({
  name: "assesmentSlice",
  initialState,
  reducers: {
    reset: () => initialState,
    assessmentTypeFetchRequest(state: GetAssessmentType) {
      state.loading = true;
    },
    assessmentTypeFetchSuccess(
      state: GetAssessmentType,
      action: PayloadAction<{ data: AssessmentType[]; status: number }>,
    ) {
      state.loading = false;
      state.data = action.payload.data;
      state.status = action.payload.status;
    },
    assessmentTypeFetchFailure(
      state: GetAssessmentType,
      action: PayloadAction<{ error: ErrorType }>,
    ) {
      state.loading = false;
      state.error = true;
      state.status = action.payload.error.response.status;
    },
  },
});

export const assessmentTypeFetchAction = assesmentFetchSlice.actions;
export const assessmentTypeFetchReducer = assesmentFetchSlice.reducer;

export default assessmentTypeFetchReducer;
