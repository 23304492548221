import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface GetDashBoardLineChart {
  loading: boolean;
  data: [];
  status: number;
  error: null | string;
}

export const initialState: GetDashBoardLineChart = {
  loading: false,
  data: [],
  status: 0,
  error: null,
};

const dashBoardLineChartSlice = createSlice({
  name: "dashBoardAssessTrend",
  initialState,
  reducers: {
    reset: () => initialState,
    dashBoardLineChartRequest(state: GetDashBoardLineChart) {
      state.loading = true;
    },
    dashBoardLineChartSuccess(
      state: GetDashBoardLineChart,
      action: PayloadAction<{ data: any; status: number }>,
    ) {
      state.loading = false;
      state.data = action.payload.data;
      state.status = action.payload.status;
    },
    dashBoardLineChartFailure(
      state: GetDashBoardLineChart,
      action: PayloadAction<{ error: any }>,
    ) {
      state.loading = false;
      state.error = action.payload.error;
    },
  },
});

export const dashBoardLineChartAction = dashBoardLineChartSlice.actions;
export const dashBoardLineChartReducer = dashBoardLineChartSlice.reducer;

export default dashBoardLineChartReducer;
