import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorType } from "../../../../../model/common";
import { ProfileDataFunc } from "../../../../../model/functionViewModel";

export interface GetProfileData {
  loading: boolean;
  data: ProfileDataFunc[] | undefined;
  status: number;
  error: boolean;
}

export const initialState: GetProfileData = {
  loading: false,
  data: undefined,
  status: 0,
  error: false,
};

const funcGetProfileDataSlice = createSlice({
  name: "funcGetProfileDataSlice",
  initialState,
  reducers: {
    reset: () => initialState,
    funcProfileFetchRequest(
      state: GetProfileData,
      action: PayloadAction<string>,
    ) {
      state.loading = true;
    },
    funcProfileFetchSuccess(
      state: GetProfileData,
      action: PayloadAction<{ data: ProfileDataFunc[]; status: number }>,
    ) {
      state.loading = false;
      state.data = action.payload.data;
      state.status = action.payload.status;
    },
    funcProfileFetchFailure(
      state: GetProfileData,
      action: PayloadAction<{ error: ErrorType }>,
    ) {
      state.loading = false;
      state.error = true;
      state.status = action.payload.error.response.status;
    },
  },
});

export const funcProfileGetDataAction = funcGetProfileDataSlice.actions;
export const funcprofileGetDataReducer = funcGetProfileDataSlice.reducer;

export default funcprofileGetDataReducer;
