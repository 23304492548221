import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WsRoadMap } from "../../../model/wsRoadMap";

export interface RoadMap {
  workStreamData: WsRoadMap[];
}

export const initialState: RoadMap = {
  workStreamData: [],
};

const roadMapSlice = createSlice({
  name: "roadMapSlice",
  initialState,
  reducers: {
    setRoadMap(state: RoadMap, action: PayloadAction<WsRoadMap[]>) {
      state.workStreamData = action.payload;
    },
  },
});

export const roadMapAction = roadMapSlice.actions;
export const roadMapReducer = roadMapSlice.reducer;

export default roadMapReducer;
