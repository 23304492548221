import styled from "styled-components";

const TalentsContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  h3 {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-family: ${({ theme }) => theme.fontFamily.bold};
    font-size: ${({ theme }) => theme.fontSizes[2]};
    margin-bottom: 16px;
  }
`;
const TalentsAddedSection = styled.div`
  width: 50%;
  border-right: 1px solid;
  border-color: ${({ theme }) => theme.colors.border.bluishCyan};
  height: 100%;
  padding: 26px 28px 28px 28px;
`;
const TalentsAddedHeading = styled.div``;
const TalentsCriteriaSection = styled.div`
  width: 50%;
  padding-left: 28px;

  padding-top: 26px;
  .searchContainer {
    display: flex;
    position: relative;
    margin-bottom: 16px;
    height: 48px;
    margin-right: 28px;
  }
  .searchBar {
    border: 1px solid ${({ theme }) => theme.colors.border.bluishCyan1};
    border-radius: 6px;
    border-top-right-radius: unset;
    border-bottom-right-radius: unset;
    height: 48px;
    padding-left: 20px;
    padding-top: 16px;
    padding-bottom: 15px;
    font-family: ${({ theme }) => theme.fontFamily.regular};
    font-style: normal;
    font-weight: ${({ theme }) => theme.fontWeights.regular};
    font-size: ${({ theme }) => theme.fontSizes[1]};
    line-height: ${({ theme }) => theme.lineHeight[14]};
    color: ${({ theme }) => theme.colors.text.black};
  }
  .searchBar:focus {
    box-shadow: unset;
  }
  .form-control::placeholder {
    color: ${({ theme }) => theme.colors.text.mediumGrey1};
  }
`;
const SelectedTalentsContainer = styled.div`
  height: 100%;
  width: 100%;
  .track-vertical {
    background-color: ${({ theme }) => theme.colors.bg.bluishCyan};
    height: 100%;
    right: 0;
    width: 3px !important;
    border-radius: 3px !important;
    div {
      background-color: ${({ theme }) =>
        theme.colors.bg.tralishBlue1} !important;
      border-radius: 3px !important;
    }
  }
`;
const AttributeMainContainer = styled.div`
  height: calc(100% - 96px);
  padding-right: 8px;
  .track-vertical {
    background-color: ${({ theme }) => theme.colors.bg.bluishCyan};
    height: 100%;
    right: 0;
    width: 3px !important;
    border-radius: 3px !important;
    div {
      background-color: ${({ theme }) =>
        theme.colors.bg.tralishBlue1} !important;
      border-radius: 3px !important;
    }
  }
`;
const AttributesInfoSection = styled.div`
  width: 100%;
  padding-right: 20px;
`;
const RolesDetails = styled.div`
  background: ${({ theme }) => theme.colors.bg.white};
  border: 1px solid ${({ theme }) => theme.colors.border.bluishCyan};
  border-radius: 8px;
  padding: 12px 16px 8px 16px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h4 {
    font-family: ${({ theme }) => theme.fontFamily.medium};
    font-style: normal;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: ${({ theme }) => theme.fontSizes[1]};
    line-height: ${({ theme }) => theme.lineHeight[2]};
    color: ${({ theme }) => theme.baseColors.black};
    padding-bottom: 12px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.border.bluishCyan};
    margin-bottom: 12px;
    @media screen and (max-width: 1200px) {
      font-size: ${({ theme }) => theme.fontSizes[0]};
    }
  }
`;
const RolesSection = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  p {
    margin-right: 8px;
    margin-bottom: 8px;
  }
`;
const AttributesDetails = styled.div`
  background: ${({ theme }) => theme.colors.bg.white};
  border: 1px solid ${({ theme }) => theme.colors.border.bluishCyan};
  border-radius: 8px;
  padding: 12px 16px 8px 16px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  h4 {
    font-family: ${({ theme }) => theme.fontFamily.medium};
    font-style: normal;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: ${({ theme }) => theme.fontSizes[1]};
    line-height: ${({ theme }) => theme.lineHeight[2]};
    color: ${({ theme }) => theme.baseColors.black};

    @media screen and (max-width: 1200px) {
      font-size: ${({ theme }) => theme.fontSizes[0]};
      margin-top: 3px;
    }
  }
`;
const AttributesHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.bluishCyan};
`;
const AttributesDetailsSection = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  p {
    margin-right: 8px;
    margin-bottom: 8px;
  }
`;
const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
`;
const CheckboxSection = styled.div`
  display: flex;
  justify-content: center;
  .form-check-input {
    margin-top: unset;
  }
  .checkBox {
    background-color: ${({ theme }) => theme.colors.bg.white3};
    border: 0.75px solid ${({ theme }) => theme.colors.border.mediumGrey1};
    border-radius: 3px;
    width: 16px;
    height: 16px;
    margin-left: 18px;
    margin-right: 8px;
  }
  .checkBox:checked {
    background-color: ${({ theme }) => theme.colors.bg.tealishBlue};
    border-radius: 3px;
    width: 16px;
    height: 16px;
    border-color: ${({ theme }) => theme.colors.border.tealishBlue};
  }
  .checkboxLabel {
    font-family: ${({ theme }) => theme.fontFamily.medium};
    font-style: normal;
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: ${({ theme }) => theme.fontSizes[0]};
    line-height: ${({ theme }) => theme.lineHeight[0]};
    display: flex;
    align-items: center;
    margin-top: 2px;
    letter-spacing: 0.005em;
    color: ${({ theme }) => theme.colors.text.black};
    .form-check-input {
      margin-top: unset;
    }
    @media screen and (max-width: 1200px) {
      font-size: 10px;
    }
  }
  @media screen and (max-width: 1200px) {
    .checkBox {
      margin-right: 4px;
    }
    .checkboxLabel {
      font-size: 10px;
    }
  }
`;
const DragAndDropInstructionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100% - 48px);
`;
const MoreDragandDropInstruction = styled.p`
  text-align: center;
  margin-top: 50px;
  margin-bottom: 68px;
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-size: ${({ theme }) => theme.fontSizes[2]};
  line-height: ${({ theme }) => theme.lineHeight[5]};
  color: ${({ theme }) => theme.colors.text.lightGrey1};
`;
const IconContainer = styled.div`
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.bg.tealishBlue};
  border-radius: 0px 6px 6px 0px;
  width: 48px;
  cursor: pointer;
`;
const ViewMoreButton = styled.div`
  display: flex;
  align-items: center;
  height: 27px;
  margin-bottom: 8px;
  cursor: pointer;
`;
const ViewMoreText = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.medium};
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes[1]};
  line-height: ${({ theme }) => theme.lineHeight[14]};
  color: ${({ theme }) => theme.colors.text.tealishBlue};
  margin-right: 5px;
  @media screen and (max-width: 1200px) {
    font-size: ${({ theme }) => theme.fontSizes[0]};
  }
`;
export {
  TalentsContainer,
  TalentsAddedSection,
  TalentsCriteriaSection,
  TalentsAddedHeading,
  SelectedTalentsContainer,
  RolesDetails,
  RolesSection,
  AttributesDetails,
  AttributesHeader,
  AttributesDetailsSection,
  CheckboxSection,
  DragAndDropInstructionContainer,
  MoreDragandDropInstruction,
  IconContainer,
  AttributesInfoSection,
  ViewMoreButton,
  ViewMoreText,
  CheckBoxContainer,
  AttributeMainContainer,
};
