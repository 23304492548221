import { transparentize } from "polished";
import tokenFontSizes from "../utils/designtokens/fontSizes";
import tokenFontWeights from "../utils/designtokens/fontWeights";
import tokenColors from "../utils/designtokens/colors";
import tokenFontFamily from "../utils/designtokens/fontFamilies";
import tokenLineHeight from "../utils/designtokens/lineHeight";

const fonts = {
  body: "'AileronRegular', sans-serif",
  heading: "AileronSemiBold",
};

// Font size
const fontSizes = [
  tokenFontSizes.fzSmall,
  tokenFontSizes.fz10,
  tokenFontSizes.fz11,
  tokenFontSizes.fz12,
  tokenFontSizes.fz13,
  tokenFontSizes.fz14,
  tokenFontSizes.fz15,
  tokenFontSizes.fz16,
  tokenFontSizes.fz17,
  tokenFontSizes.fz18,
  tokenFontSizes.fz19,
  tokenFontSizes.fz20,
  tokenFontSizes.fz21,
  tokenFontSizes.fz22,
  tokenFontSizes.fz23,
  tokenFontSizes.fzXSmall,
  tokenFontSizes.fz24,
  tokenFontSizes.fz25,
  tokenFontSizes.fz26,
];

// Line height
const lineHeight = [
  tokenLineHeight.lh10,
  tokenLineHeight.lh11,
  tokenLineHeight.lh12,
  tokenLineHeight.lh13,
  tokenLineHeight.lh14,
  tokenLineHeight.lh15,
  tokenLineHeight.lh16,
  tokenLineHeight.lh17,
  tokenLineHeight.lh18,
  tokenLineHeight.lh19,
  tokenLineHeight.lh20,
  tokenLineHeight.lh21,
  tokenLineHeight.lh22,
  tokenLineHeight.lh23,
  tokenLineHeight.lhMedium,
  tokenLineHeight.lh24,
  tokenLineHeight.lhsmall,
  tokenLineHeight.lh25,
];

// Font weight
const fontWeights = {
  thin: tokenFontWeights.thin,
  light: tokenFontWeights.light,
  regular: tokenFontWeights.regular,
  medium: tokenFontWeights.medium,
  bold: tokenFontWeights.bold,
  black: tokenFontWeights.black,

  heading: "600",
};

// Font family
const fontFamily = {
  light: tokenFontFamily.light,
  medium: tokenFontFamily.medium,
  regular: tokenFontFamily.regular,
  bold: tokenFontFamily.bold,
  black: tokenFontFamily.black,
};

// Colors
const baseColors = {
  black: tokenColors.black,
  primary: tokenColors.blue,
  white: tokenColors.white,
  red: tokenColors.red,
  greyLight: tokenColors.lightGrey,
  green: tokenColors.green,
};

const colors = {
  text: {
    black: tokenColors.black,
    white: tokenColors.white,
    lightGrey1: tokenColors.lightGrey1,
    darkGrey: tokenColors.darkGrey,
    darkGrey1: tokenColors.darkGrey1,
    blue: tokenColors.blue,
    mediumGrey: tokenColors.mediumGrey,
    tealishBlue: tokenColors.tealishBlue,
    darkGrey2: tokenColors.darkGrey2,
    mediumGrey1: tokenColors.mediumGrey1,
    purple: tokenColors.purple,
    green: tokenColors.green01,
    black2: tokenColors.black2,
    black3: tokenColors.black3,
    red3: tokenColors.red3,
    blue5: tokenColors.blue5,
    grey3: tokenColors.grey3,
    grey4: tokenColors.grey4,
  },
  bg: {
    white: tokenColors.white,
    tealishBlue: tokenColors.tealishBlue,
    tealishBlue1: tokenColors.tealishBlue,
    white1: tokenColors.white1,
    darkGrey: tokenColors.darkGrey,
    ghostWhite: tokenColors.ghostWhite,
    white2: tokenColors.white2,
    blue: tokenColors.blue,
    blue2: tokenColors.blue2,
    blue3: tokenColors.blue3,
    tralishBlue2: tokenColors.tralishBlue2,
    white3: tokenColors.white3,
    blueShade: tokenColors.blueShade,
    bluishCyan: tokenColors.bluishCyan,
    tralishBlue1: tokenColors.tralishBlue1,
    purple1: tokenColors.purple1,
    green2: tokenColors.green2,
    white4: tokenColors.white4,
    red3: tokenColors.red3,
    red4: tokenColors.red4,
    white5: tokenColors.white5,
    white6: tokenColors.white6,
    blue5: tokenColors.blue5,
    blue6: tokenColors.blue6,
    white7: tokenColors.white7,
    lightGrey2: tokenColors.lightGrey2,
    lightGrey1: tokenColors.lightGrey1,
  },

  border: {
    default: tokenColors.bluishCyan,
    white: tokenColors.white,
    black: tokenColors.black,
    bluishCyan: tokenColors.bluishCyan,
    bluishCyan1: tokenColors.bluishCyan1,
    blueShade: tokenColors.blueShade,
    purpleShade: tokenColors.purpleShade,
    darkGreyShade: tokenColors.darkGreyShade,
    green01: tokenColors.green01,
    tralishBlue1: tokenColors.tralishBlue1,
    mediumGrey1: tokenColors.mediumGrey1,
    tealishBlue: tokenColors.tealishBlue,
    red2: tokenColors.red2,
    blue4: tokenColors.blue4,
    purple1: tokenColors.purple1,
    black4: tokenColors.black4,
  },

  transparent: {
    black15: transparentize(0.15, tokenColors.black),
    black20: transparentize(0.2, tokenColors.black),
    black30: transparentize(0.3, tokenColors.black),
    black35: transparentize(0.35, tokenColors.black),
    black50: transparentize(0.5, tokenColors.black),
    white15: transparentize(0.15, tokenColors.white),
    white50: transparentize(0.5, tokenColors.white),
    white70: transparentize(0.7, tokenColors.white),
  },

  state: {
    default: tokenColors.white,
    error: tokenColors.red,
    fbButton: "#465898",
    googleButton: "#518EF8",
  },

  focusOutline: {
    error: transparentize(0.15, baseColors.red),
  },
  context: {
    danger: {
      light: "#FFEEF0",
      medium: "#D73A49",
      dark: "#9E1C23",
    },
  },
};

export default {
  fonts,
  fontSizes,
  fontWeights,
  fontFamily,
  baseColors,
  colors,
  lineHeight,
};
