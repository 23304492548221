import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorType } from "../../../../../model/common";
import { EmpDataListOrg } from "../../../../../model/wsRoadMap";

type GetAbout = {
  loading: boolean;
  data: EmpDataListOrg[] | any;
  status: number;
  error: boolean;
};

export const initialState: GetAbout = {
  loading: false,
  data: [],
  status: 0,
  error: false,
};

const hrOrgSlice = createSlice({
  name: "hrOrgList",
  initialState,
  reducers: {
    reset: () => initialState,
    hrOrgFetchRequest(state: GetAbout, action: PayloadAction<string>) {
      state.loading = true;
    },
    hrOrgFetchSuccess(
      state: GetAbout,
      action: PayloadAction<{ data: EmpDataListOrg[]; status: number }>,
    ) {
      state.loading = false;
      state.data = action.payload.data;
      state.status = action.payload.status;
    },
    hrOrgFetchFailure(
      state: GetAbout,
      action: PayloadAction<{ error: ErrorType }>,
    ) {
      state.loading = false;
      state.error = true;
      state.status = action.payload.error.response.status;
    },
  },
});

const hrPreviewSlice = createSlice({
  name: "hrPreviewSave",
  initialState,
  reducers: {
    reset: () => initialState,
    hrPreviewSaveRequest(state: GetAbout, action: PayloadAction<string>) {
      state.loading = true;
    },
    hrPreviewSaveSuccess(
      state: GetAbout,
      action: PayloadAction<{ data: any; status: number }>,
    ) {
      state.loading = false;
      state.data = action.payload.data;
      state.status = action.payload.status;
    },
    hrPreviewSaveFailure(
      state: GetAbout,
      action: PayloadAction<{ error: ErrorType }>,
    ) {
      state.loading = false;
      state.error = true;
      state.status = action.payload.error.response.status;
    },
  },
});

export const hrOrgFetchAction = hrOrgSlice.actions;
export const hrOrgFetchReducer = hrOrgSlice.reducer;
export const hrPreviewSaveAction = hrPreviewSlice.actions;
export const hrPreviewSaveReducer = hrPreviewSlice.reducer;

export default { hrOrgFetchReducer, hrPreviewSaveReducer };
