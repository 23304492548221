export function navigateToPages(navigate: any, pageStatus: any) {
  switch (pageStatus) {
    case "Home":
      navigate("/Dashboard/Home");
      break;
    case "Assessment":
      navigate("/Dashboard/Assessment");
      break;
    case "Strategy":
      navigate("/Dashboard/Strategy");
      break;
    case "Roadmap":
      navigate("/Dashboard/Roadmap");
      break;
    case "TOM":
      navigate("/Dashboard/TOM");
      break;
    case "Organization":
      navigate("/Dashboard/Organization");
      break;
    case "Team":
      navigate("/Dashboard/Team");
      break;
    case "AdminUsers":
      navigate("/Admin/Users");
      break;
    case "AdminRoles":
      navigate("/Admin/Roles");
      break;
    case "ProfileBuilder":
      navigate("/ProfileBuilder");
      break;
    case "Maturityassessment":
      navigate("/MaturityAssessment");
      break;

    default:
      navigate("Dashboard");
      break;
  }
}

export const errorLogOut = (logout: any) => {
  // Remove saved data from sessionStorage
  sessionStorage.clear();
  logout({ returnTo: window.location.origin });
};
