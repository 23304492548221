import { takeLatest, put, fork, call } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { PayloadAction } from "@reduxjs/toolkit";
import http from "../../../utils/http.service";
import config from "../../../utils/request.config";
import {
  orgChartUserListAction,
  orgMatchedProfileListAction,
  workStreamJobDescriptionAction,
} from "./workStreamJobDescriptionDetailsSlice";
import {
  assignOrUnAssignResourceAction,
  workStreamJobDescriptionSubmitAction,
} from "./workStreamJobDescriptionSubmitSlice";
import {
  wsJobDescriptionList,
  wsResoucePlanningSubmit,
  getOrgProfiles,
  matchingProfiles,
  assignOrUnAssignResources,
  getAssignedUserList,
  assignOwner,
} from "../../../utils/serviceurl";
import { GetJobDesc } from "../../../model/wSTalent";
import { ErrorType } from "../../../model/common";
import {
  assignedUsersFetchAction,
  assignOwnerAction,
} from "./getAssignedUsersSlice";

export const fetchworkStreamJobDetails = async (data: GetJobDesc) =>
  http.GET(config.getConfig(data, wsJobDescriptionList, ""));

function* onJobDescriptionGetAsync(action: PayloadAction<GetJobDesc>) {
  try {
    const response: AxiosResponse<any> = yield call(
      fetchworkStreamJobDetails,
      action.payload,
    );

    if (response.status === 200) {
      yield put(
        workStreamJobDescriptionAction.workStreamJobDescriptionSuccess({
          data: response.data.data,
          status: response.status,
        }),
      );
    }
  } catch (error) {
    // console.log(error);
  }
}

function* onJobDescriptionGet() {
  yield takeLatest(
    workStreamJobDescriptionAction.workStreamJobDescriptionRequest,
    onJobDescriptionGetAsync,
  );
}

export const fetchworkSubmitAsync = async (data: any) =>
  http.POST(config.postConfig(data, "", wsResoucePlanningSubmit, ""));

function* onJobDescriptionSubmitAsync(action: PayloadAction<any>) {
  try {
    const response: AxiosResponse<any> = yield call(
      fetchworkSubmitAsync,
      action.payload,
    );
    if (response.status === 200) {
      yield put(
        workStreamJobDescriptionSubmitAction.workStreamJobDescriptionSubmitSuccess(
          {
            data: response.data.data,
            status: response.status,
          },
        ),
      );
    }
  } catch (error) {
    // console.log(error);
  }
}

function* onJobDescriptionSubmit() {
  yield takeLatest(
    workStreamJobDescriptionSubmitAction.workStreamJobDescriptionSubmitRequest,
    onJobDescriptionSubmitAsync,
  );
}

export const fetchOrgProfilesAsync = async () =>
  http.GET(config.getConfig("", getOrgProfiles, ""));

function* onAssessmentIdAsync() {
  try {
    const response: AxiosResponse<any> = yield call(fetchOrgProfilesAsync);
    if (response.status === 200) {
      yield put(
        orgChartUserListAction.orgProfileListSuccess({
          data: response.data.data,
          status: response.status,
        }),
      );
    }
  } catch (error) {
    // console.log(error);
  }
}

function* getOrgProfileList() {
  yield takeLatest(
    orgChartUserListAction.getProfilesRequest,
    onAssessmentIdAsync,
  );
}
export const fetchMatchedProfiles = async (data: any) =>
  http.GET(
    config.getConfig(
      "",
      `${matchingProfiles}?role=${data.roleId}&experience=${data.experience}&assessmentId=${data.assessmentId}&workStreamId=${data.workStreamId}`,
      "",
    ),
  );

function* onMatchedProfileListAsync(action: PayloadAction<any>) {
  try {
    const response: AxiosResponse<any> = yield call(
      fetchMatchedProfiles,
      action.payload,
    );
    // console.log("response....", response);
    if (response.status === 200) {
      yield put(
        orgMatchedProfileListAction.orgMatchedProfileListSuccess({
          data: response.data.data,
          status: response.status,
        }),
      );
    }
  } catch (error) {
    // console.log(error);
  }
}

function* getMatchedProfileList() {
  yield takeLatest(
    orgMatchedProfileListAction.getMatchedProfilesRequest,
    onMatchedProfileListAsync,
  );
}

export const assignOrUnAssignResourceFetch = async (data: any) =>
  http.POST(config.postConfig(data, "", assignOrUnAssignResources, ""));

function* onAssignOrUnAssignResourceAsync(action: PayloadAction<any>) {
  try {
    const response: AxiosResponse<any> = yield call(
      assignOrUnAssignResourceFetch,
      action.payload,
    );
    if (response.status === 200) {
      yield put(
        assignOrUnAssignResourceAction.assignOrUnAssignResourceSuccess({
          status: response.status,
        }),
      );
    }
  } catch (error) {
    yield put(
      assignOrUnAssignResourceAction.assignOrUnAssignResourceFailure({
        error: error as ErrorType,
      }),
    );
  }
}

function* assignOrUnAssignResourceSubmit() {
  yield takeLatest(
    assignOrUnAssignResourceAction.assignOrUnAssignResourceRequest,
    onAssignOrUnAssignResourceAsync,
  );
}

export const fetchAssignedUserList = async (data: any) =>
  http.GET(
    config.getConfig(
      "",
      `${getAssignedUserList}?workStreamId=${data.workStreamId}&assessmentId=${data.assessmentId}`,
      "",
    ),
  );

function* onAssignedUserListAsync(action: PayloadAction<any>) {
  try {
    const response: AxiosResponse<any> = yield call(
      fetchAssignedUserList,
      action.payload,
    );
    if (response.status === 200) {
      yield put(
        assignedUsersFetchAction.assignedUsersFetchSuccess({
          data: response.data.data,
          status: response.status,
        }),
      );
    }
  } catch (error) {
    // console.log(error);
  }
}

function* getAssignUserList() {
  yield takeLatest(
    assignedUsersFetchAction.assignedUsersFetchRequest,
    onAssignedUserListAsync,
  );
}

export const assignOwnerHandle = async (
  data: { isOwner: boolean },
  userId: string,
) => http.PUT(config.postConfig(data, "", assignOwner + userId));

function* onAssignOwnerAsync(action: PayloadAction<any>) {
  try {
    const response: AxiosResponse<any> = yield call(
      assignOwnerHandle,
      action.payload.data,
      action.payload.userId,
    );
    if (response.status === 200) {
      yield put(
        assignOwnerAction.assignOwnerSubmitSuccess({
          status: response.status,
        }),
      );
    }
  } catch (error) {
    yield put(
      assignOwnerAction.assignOwnerSubmitFailure({
        error: error as ErrorType,
      }),
    );
  }
}
function* assignOwnerSubmit() {
  yield takeLatest(
    assignOwnerAction.assignOwnerSubmitRequest,
    onAssignOwnerAsync,
  );
}
export const jobDescriptionSaga = [
  fork(onJobDescriptionGet),
  fork(onJobDescriptionSubmit),
  fork(getOrgProfileList),
  fork(getMatchedProfileList),
  fork(assignOrUnAssignResourceSubmit),
  fork(getAssignUserList),
  fork(assignOwnerSubmit),
];
