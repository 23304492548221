import { Form } from "react-bootstrap";
import { DragEvent, FormEvent, useEffect, useState } from "react";
import {
  AttributesDetails,
  AttributesHeader,
  AttributesDetailsSection,
  CheckboxSection,
  CheckBoxContainer,
  ViewMoreButton,
  ViewMoreText,
} from "../../styles";
import icons from "../../../../icons";
import { TextWrapper } from "../../../../components";
import { WsKSAAttributes, WsKSAList } from "../../../../../model/wSTalent";

type KsaLeftCardstype = {
  ksaItem: WsKSAList;
  changeSelector: (_id: string, ev: FormEvent) => void;
  DragAttributes: (
    ev: DragEvent,
    attribute: WsKSAAttributes,
    ksaItem: WsKSAList,
  ) => void;
};
function KsaLeftCards({
  ksaItem,
  changeSelector,
  DragAttributes,
}: KsaLeftCardstype) {
  const [filterKSAview, setfilterKSAView] = useState<WsKSAAttributes[]>();
  const [view, setView] = useState(true);
  useEffect(() => {
    if (ksaItem.attributes !== undefined) {
      setfilterKSAView(ksaItem.attributes?.slice(0, 9));
    }
  }, [ksaItem]);

  const updateRolesView = () => {
    if (view) {
      setfilterKSAView(ksaItem.attributes);
    } else {
      setfilterKSAView(ksaItem.attributes?.slice(0, 9));
    }
    setView(!view);
  };
  return (
    <AttributesDetails>
      <AttributesHeader>
        <h4>{ksaItem.attributeName}</h4>
        <CheckboxSection>
          <CheckBoxContainer>
            <Form.Check.Input
              type="checkbox"
              id="musthave"
              checked={ksaItem.mustHave}
              onChange={(ev: FormEvent) => changeSelector(ksaItem._id, ev)}
              className="checkBox"
              disabled={ksaItem.goodToHave}
            />
            <Form.Check.Label className="checkboxLabel">
              Must have
            </Form.Check.Label>
          </CheckBoxContainer>
          <CheckBoxContainer>
            <Form.Check.Input
              type="checkbox"
              id="goodtohave"
              checked={ksaItem.goodToHave}
              onChange={(ev: FormEvent) => changeSelector(ksaItem._id, ev)}
              className="checkBox"
              disabled={ksaItem.mustHave}
            />
            <Form.Check.Label className="checkboxLabel">
              Good to have
            </Form.Check.Label>
          </CheckBoxContainer>
        </CheckboxSection>
      </AttributesHeader>
      <AttributesDetailsSection>
        {filterKSAview === undefined || filterKSAview.length <= 0 ? (
          <TextWrapper title="No Data Available" />
        ) : (
          filterKSAview.map((item: WsKSAAttributes) => {
            return (
              <div
                draggable="true"
                onDragStart={(ev: DragEvent) =>
                  DragAttributes(ev, item, ksaItem)
                }
                key={item.ksaId}
              >
                <TextWrapper title={item.ksaId as string} />
              </div>
            );
          })
        )}
        {view ? (
          <ViewMoreButton
            onClick={() => {
              updateRolesView();
            }}
          >
            <ViewMoreText>View More</ViewMoreText>
            <icons.downArrow />
          </ViewMoreButton>
        ) : (
          <ViewMoreButton
            onClick={() => {
              updateRolesView();
            }}
          >
            <ViewMoreText>View less</ViewMoreText>

            <icons.AccordionUpArrow />
          </ViewMoreButton>
        )}
      </AttributesDetailsSection>
    </AttributesDetails>
  );
}

export default KsaLeftCards;
