import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorType } from "../../../model/common";
import { WsTalentAtrributes } from "../../../model/wSTalent";

export interface SetTalent {
  loading: boolean;
  data: WsTalentAtrributes[];
  status: number;
  error: boolean;
}

export const initialState: SetTalent = {
  loading: false,
  data: [],
  status: 0,
  error: false,
};

const workStreamTalentDetailsSearchSlice = createSlice({
  name: "workStreamTalentSubmitSlice",
  initialState,
  reducers: {
    reset: () => initialState,
    workStreamTalentDetailsSearchRequest(
      state: SetTalent,
      action: PayloadAction<string>,
    ) {
      state.loading = true;
    },
    workStreamTalentDetailsSearchSuccess(
      state: SetTalent,
      action: PayloadAction<{ status: number; data: WsTalentAtrributes[] }>,
    ) {
      state.loading = false;
      state.status = action.payload.status;
      state.data = action.payload.data;
    },
    workStreamTalentDetailsSearchFailure(
      state: SetTalent,
      action: PayloadAction<{ error: ErrorType }>,
    ) {
      state.loading = false;
      state.error = true;
      state.status = action.payload.error.response.status;
    },
  },
});

export const workStreamTalentDetailsSearchAction =
  workStreamTalentDetailsSearchSlice.actions;
export const workStreamTalentDetailsSearchReducer =
  workStreamTalentDetailsSearchSlice.reducer;

export default workStreamTalentDetailsSearchReducer;
