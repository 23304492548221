import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorType } from "../../../../../model/common";
import { UnassignedfuncProfile } from "../../../../../model/functionViewModel";

export interface GetunAssignedProfileList {
  loading: boolean;
  data: UnassignedfuncProfile[] | undefined;
  status: number;
  error: boolean;
}

export const initialState: GetunAssignedProfileList = {
  loading: false,
  data: undefined,
  status: 0,
  error: false,
};

const functionUnassignedProfileFetchSlice = createSlice({
  name: "functionUnassignedProfileFetchSlice",
  initialState,
  reducers: {
    reset: () => initialState,
    funcUnassignedProfileFetchRequest(state: GetunAssignedProfileList) {
      state.loading = true;
    },
    funcUnassignedProfileFetchSuccess(
      state: GetunAssignedProfileList,
      action: PayloadAction<{ data: UnassignedfuncProfile[]; status: number }>,
    ) {
      state.loading = false;
      state.data = action.payload.data;
      state.status = action.payload.status;
    },
    funcUnassignedProfileFetchFailure(
      state: GetunAssignedProfileList,
      action: PayloadAction<{ error: ErrorType }>,
    ) {
      state.loading = false;
      state.error = true;
      state.status = action.payload.error.response.status;
    },
  },
});

export const funcUnassignedFetchAction =
  functionUnassignedProfileFetchSlice.actions;
export const funcUnassignedFetchReducer =
  functionUnassignedProfileFetchSlice.reducer;

export default funcUnassignedFetchReducer;
