import { DragEvent } from "react";
import {
  AttributesDetails,
  AttributesHeader,
  AttributesDetailsSection,
} from "../../styles";
import { TextWrapper } from "../../../../components";
import {
  WsAttributes,
  WsTalentAtrributes,
} from "../../../../../model/wSTalent";

type MainAtrributeCardProps = {
  Attributitem: WsTalentAtrributes;
  DragMainAttributes: (
    ev: DragEvent,
    _id: string,
    attributeName: string,
    attribute: WsAttributes,
  ) => void;
};
function MainAtrributeCard({
  Attributitem,
  DragMainAttributes,
}: MainAtrributeCardProps) {
  return (
    <AttributesDetails>
      <AttributesHeader>
        <h4>{Attributitem.attributeName}</h4>
      </AttributesHeader>
      <AttributesDetailsSection>
        {Attributitem.attributes.map((item) => {
          return (
            <div
              id={item._id}
              draggable="true"
              onDragStart={(ev: DragEvent) =>
                DragMainAttributes(
                  ev,
                  Attributitem.id,
                  Attributitem.attributeName,
                  item,
                )
              }
              key={item._id}
            >
              {item.attributeName !== "" ? (
                <TextWrapper title={item.attributeName as string} />
              ) : null}
            </div>
          );
        })}
      </AttributesDetailsSection>
    </AttributesDetails>
  );
}

export default MainAtrributeCard;
