import { takeLatest, put, fork, call } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { PayloadAction } from "@reduxjs/toolkit";
import http from "../../../utils/http.service";
import config from "../../../utils/request.config";
import { strategyFetchAction } from "./strategyFetchSlice";
import {
  strategyungroupedWorkStream,
  strategyWorkStream,
} from "../../../utils/serviceurl";
import { strategyUpdateAction } from "./statergyUpdateSlice";
import {
  AcitiviesType,
  WsNewrequestCreation,
  WsSortedType,
} from "../../../model/statergyWS";
import { strategyUnActivityFetchAction } from "./strategyUnActivityFetchSlice";
import { APIResponse, ErrorType } from "../../../model/common";
import { strategyUnActivityUpdateAction } from "./strategyUnActivityFetchUpdateSlice";

/// /////////////Get Statergy Saga
export const fetchStrategyWsDetails = async (assesmentid: string) =>
  http.GET(
    config.getConfig({ assessmentId: assesmentid }, strategyWorkStream, ""),
  );

function* onStrategyDetailsAsync(action: PayloadAction<string>) {
  try {
    const response: AxiosResponse<APIResponse> = yield call(
      fetchStrategyWsDetails,
      action.payload,
    );

    if (response.status === 200) {
      yield put(
        strategyFetchAction.strategyFetchSuccess({
          data: response.data.data,
          status: response.status,
        }),
      );
    }
  } catch (error) {
    yield put(
      strategyFetchAction.strategyFetchFailure({
        error: error as ErrorType,
      }),
    );
  }
}

function* onStrategyGetList() {
  yield takeLatest(
    strategyFetchAction.strategyFetchRequest,
    onStrategyDetailsAsync,
  );
}

/// ////////////////Update Statergy Saga
export const updateStrategyWsDetails = async (
  data: { workStreams: WsSortedType[] },
  assesmentid: string,
) =>
  http.PUT(config.postConfig(data, "", `${strategyWorkStream}/${assesmentid}`));

function* onStrategyUdpateDetailsAsync(
  action: PayloadAction<{
    data: { workStreams: WsSortedType[] };
    assesmentid: string;
  }>,
) {
  try {
    const response: AxiosResponse<APIResponse> = yield call(
      updateStrategyWsDetails,
      action.payload.data,
      action.payload.assesmentid,
    );
    if (response.status === 200) {
      yield put(
        strategyUpdateAction.strategyUpdateSuccess({
          data: response.data.data,
          status: response.status,
        }),
      );
    }
  } catch (error) {
    yield put(
      strategyUpdateAction.strategyUpdateFailure({
        error: error as ErrorType,
      }),
    );
  }
}

function* onStrategyUpdateList() {
  yield takeLatest(
    strategyUpdateAction.strategyUpdateRequest,
    onStrategyUdpateDetailsAsync,
  );
}

// For Creating workstream
export const createWorkStream = async (newData: WsNewrequestCreation) =>
  http.POST(config.postConfig({ ...newData }, "", strategyWorkStream, ""));

function* onCreateWorkStreamAsync(
  action: PayloadAction<{ newData: WsNewrequestCreation }>,
) {
  try {
    const response: AxiosResponse<APIResponse> = yield call(
      createWorkStream,
      action.payload.newData,
    );
    if (response.status === 200) {
      yield put(
        strategyUpdateAction.strategyUpdateSuccess({
          data: response.data.data,
          status: response.status,
        }),
      );
    }
  } catch (error) {
    yield put(
      strategyUpdateAction.strategyUpdateFailure({
        error: error as ErrorType,
      }),
    );
  }
}

function* onWorkStreamCreate() {
  yield takeLatest(
    strategyUpdateAction.workStreamCreateRequest,
    onCreateWorkStreamAsync,
  );
}

/// ///////////////////For Getting UnGrouped innerActivities
export const getUngroupedActivity = async (assesmentid: string) =>
  http.GET(
    config.getConfig(
      { assessmentId: assesmentid },
      strategyungroupedWorkStream,
      "",
    ),
  );

function* onUngroupedActivity(action: PayloadAction<string>) {
  try {
    const response: AxiosResponse<APIResponse> = yield call(
      getUngroupedActivity,
      action.payload,
    );
    if (response.status === 200) {
      yield put(
        strategyUnActivityFetchAction.strategyUnActivityFetchSuccess({
          data: response.data.data,
          status: response.status,
        }),
      );
    }
  } catch (error) {
    yield put(
      strategyUnActivityFetchAction.strategyUnActivityFetchFailure({
        error: error as ErrorType,
      }),
    );
  }
}

function* onUngroupedActivityGet() {
  yield takeLatest(
    strategyUnActivityFetchAction.strategyUnActivityFetchRequest,
    onUngroupedActivity,
  );
}

/// ///////////////////For Updateing UnGrouped innerActivities
export const updateUngroupedActivity = async (
  data: { ungroupedActivities: AcitiviesType[] },
  assesmentid: string,
) =>
  http.PUT(
    config.postConfig(data, "", strategyungroupedWorkStream + assesmentid),
  );

function* onUpdateUngroupedActivity(
  action: PayloadAction<{
    data: { ungroupedActivities: AcitiviesType[] };
    assesmentid: string;
  }>,
) {
  try {
    const response: AxiosResponse<APIResponse> = yield call(
      updateUngroupedActivity,
      action.payload.data,
      action.payload.assesmentid,
    );
    if (response.status === 200) {
      yield put(
        strategyUnActivityUpdateAction.strategyUnActivityUpdateSuccess({
          data: response.data.data,
          status: response.status,
        }),
      );
    }
  } catch (error) {
    yield put(
      strategyUnActivityUpdateAction.strategyUnActivityUpdateFailure({
        error: error as ErrorType,
      }),
    );
  }
}

function* onUngroupedActivityUpdate() {
  yield takeLatest(
    strategyUnActivityUpdateAction.strategyUnActivityUpdateRequest,
    onUpdateUngroupedActivity,
  );
}

export const strategySaga = [
  fork(onStrategyGetList),
  fork(onStrategyUpdateList),
  fork(onWorkStreamCreate),
  fork(onUngroupedActivityGet),
  fork(onUngroupedActivityUpdate),
];
