import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Questionnare } from "../../model/common";

export interface AssessmentProp {
  loading: boolean;
  data: Questionnare[];
  status: number;
  error: null | string;
}

export const initialState: AssessmentProp = {
  loading: false,
  data: [],
  status: 0,
  error: null,
};

const maturityAssessmentSlice = createSlice({
  name: "maturityAssessmentType",
  initialState,
  reducers: {
    reset: () => initialState,
    maturityAssessmentRequest(
      state: AssessmentProp,
      action: PayloadAction<any>,
    ) {
      state.loading = true;
    },
    maturityAssessmentSuccess(
      state: AssessmentProp,
      action: PayloadAction<any>,
    ) {
      state.loading = false;
      state.data = action.payload.data;
      state.status = action.payload.status;
    },
    maturityAssessmentFailure(
      state: AssessmentProp,
      action: PayloadAction<{ error: any }>,
    ) {
      state.loading = false;
      state.error = action.payload.error;
    },
  },
});

export const maturityAssessmentTypeAction = maturityAssessmentSlice.actions;
export const maturityAssessmentTypeReducer = maturityAssessmentSlice.reducer;

export default maturityAssessmentTypeReducer;
