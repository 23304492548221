import { all } from "redux-saga/effects";
import { workStreamTechSaga } from "../ui/patterns/workStreamTechnology/workStreamTechnologySaga";
import { workStreamDetailsSaga } from "../ui/patterns/workStreamDetails/workStreamDetailsSaga";
import { workStreamTalentDetailsSaga } from "../ui/patterns/talentsDetails/workStreamTalentDetailsSaga";
import { jobDescriptionSaga } from "../ui/patterns/selectOrganization/workStreamJobDescriptionSaga";
import { maturityAssessmentSaga } from "../pages/MaturityAssessment/maturityAssessmentSaga";
import { roadMapGetSaga } from "../pages/MainPage/RoadMap/roadMapListSaga";
import { dashBoardDataListSaga } from "../pages/MainPage/AssessmentResult/dashboardSaga";
import { basicDetailsSaga } from "../pages/ProfileBuilder/profileBuilderStep1/basicDetailsSaga";
import { educationSaga } from "../pages/ProfileBuilder/profileBuilderStep2/educationSaga";
import { technologySaga } from "../pages/ProfileBuilder/profileBuilderStep4/technologySaga";
import { skillsSaga } from "../pages/ProfileBuilder/profileBuilderStep6/skillsSaga";
import { knowledgeSaga } from "../pages/ProfileBuilder/profileBuilderStep5/knowledgeSaga";
import { abilitiesSaga } from "../pages/ProfileBuilder/profileBuilderStep7/abilitiesSaga";
import { responsibilitiesSaga } from "../pages/ProfileBuilder/profileBuilderStep8/responsibilitiesSaga";
import { strategySaga } from "../pages/MainPage/Strategy/strategySaga";
import { tomSaga } from "../pages/MainPage/Organization/tomMapSaga";
import { demographicsSaga } from "../pages/MainPage/Demographics/demographicsSaga";
import { emporgSaga } from "../ui/patterns/selectOrganization/orgStructure/emporgSaga";
import { hrOrgSaga } from "../pages/MainPage/Organization/TOMView/HRView/hrOrgSaga";
import { functionViewSaga } from "../pages/MainPage/Organization/TOMView/FunctionalView/FunctionViewSaga";
import { profileUserNameSaga } from "../pages/LoginPage/getProfileUserSaga";
import { assesmentDetailViewSaga } from "../ui/patterns/assessmentPriority/AssessmentResultSaga";
import { assessmentTypeFrameworkSaga } from "../ui/patterns/reassessDetails/assessmentTypeSaga";
import { getAssessmentIdSaga } from "../pages/MainPage/getAssessmentIdSaga";
import { customDashboardSaga } from "../pages/MainPage/DashBoardTab/Dashboard/customDashboardSaga";
import { manageAdminUsersSaga } from "../pages/Admin/ManageUsers/manageUserSaga";
import { manageRolesSaga } from "../pages/Admin/ManageRoles/manageRolesSaga";
import { budgetDetailsSaga } from "../ui/patterns/BudgetDetails/budgetDetailsSaga";
import { taskSaga } from "../pages/MainPage/DashBoardTab/MyTask/taskListSaga";
import { implementationSaga } from "../pages/MainPage/Implementation/ImplementationOverview/ImplementationListSaga";
import { draftReviewAssessDetailViewSaga } from "../pages/MainPage/DashBoardTab/MyTask/maturityAssessDetailsSaga";
// mention all saga to here
export default function* rootSaga() {
  yield all([
    ...getAssessmentIdSaga,
    ...workStreamTechSaga,
    ...workStreamDetailsSaga,
    ...workStreamTalentDetailsSaga,
    ...jobDescriptionSaga,
    ...maturityAssessmentSaga,
    ...roadMapGetSaga,
    ...dashBoardDataListSaga,
    ...basicDetailsSaga,
    ...educationSaga,
    ...technologySaga,
    ...skillsSaga,
    ...strategySaga,
    ...knowledgeSaga,
    ...abilitiesSaga,
    ...responsibilitiesSaga,
    ...tomSaga,
    ...demographicsSaga,
    ...emporgSaga,
    ...hrOrgSaga,
    ...functionViewSaga,
    ...profileUserNameSaga,
    ...assesmentDetailViewSaga,
    ...assessmentTypeFrameworkSaga,
    ...customDashboardSaga,
    ...manageAdminUsersSaga,
    ...manageRolesSaga,
    ...budgetDetailsSaga,
    ...taskSaga,
    ...implementationSaga,
    ...draftReviewAssessDetailViewSaga,
  ]);
}
