import { takeLatest, put, fork, call } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { PayloadAction } from "@reduxjs/toolkit";
import http from "../../../../utils/http.service";
import config from "../../../../utils/request.config";
import { APIResponse, ErrorType } from "../../../../model/common";
import {
  assessmentReject,
  getAssessmentDetailsById,
  getTasks,
} from "../../../../utils/serviceurl";
import {
  assessmentDetailsByIdAction,
  assessmentRejectAction,
  assessmentReviewSubmitAction,
  taskFetchAction,
} from "./taskListSlice";

export const fetchTaskDetails = async () =>
  http.GET(config.getConfig("", getTasks, ""));

function* onTaskgetDetailsAsync() {
  try {
    const response: AxiosResponse<any> = yield call(fetchTaskDetails);
    if (response.status === 200) {
      yield put(
        taskFetchAction.taskFetchSuccess({
          data: response.data.data,
          status: response.status,
        }),
      );
    }
  } catch (error) {
    yield put(
      taskFetchAction.taskFetchFailure({
        error: error as ErrorType,
      }),
    );
  }
}

function* onTaskGetList() {
  yield takeLatest(taskFetchAction.taskFetchRequest, onTaskgetDetailsAsync);
}

export const fetchAssessmentById = async (assessmentId: string) =>
  http.GET(
    config.getConfig("", `${getAssessmentDetailsById}/${assessmentId}`, ""),
  );

function* onGetAssessmentDetailsAsync(action: PayloadAction<string>) {
  try {
    const response: AxiosResponse<any> = yield call(
      fetchAssessmentById,
      action.payload,
    );
    if (response.status === 200) {
      yield put(
        assessmentDetailsByIdAction.getAssessmentDetailsSuccess({
          data: response.data.data,
          status: response.status,
        }),
      );
    }
  } catch (error) {
    yield put(
      assessmentDetailsByIdAction.getAssessmentDetailsFailure({
        error,
      }),
    );
  }
}

function* onAssessmentDetails() {
  yield takeLatest(
    assessmentDetailsByIdAction.getAssessmentDetailsRequest,
    onGetAssessmentDetailsAsync,
  );
}

export const reviewSubmit = async (
  data: any,
  assessment_id: string,
  apiUrl: string,
) => http.PUT(config.postConfig(data, "", `${apiUrl}/${assessment_id}`));

function* onReviewSubmitAsync(action: PayloadAction<any>) {
  try {
    const response: AxiosResponse<APIResponse> = yield call(
      reviewSubmit,
      action.payload.data,
      action.payload.assessment_id,
      action.payload.apiUrl,
    );
    if (response.status === 200) {
      yield put(
        assessmentReviewSubmitAction.reviewSubmitSuccess({
          data: response.data.data,
          status: response.status,
        }),
      );
    }
  } catch (error) {
    yield put(
      assessmentReviewSubmitAction.reviewSubmitFailure({
        error: error as ErrorType,
      }),
    );
  }
}

function* onAssessmentReviewSubmit() {
  yield takeLatest(
    assessmentReviewSubmitAction.reviewSubmitRequest,
    onReviewSubmitAsync,
  );
}

export const assessmentRejection = async (data: any, assessment_id: string) =>
  http.PUT(config.postConfig(data, "", `${assessmentReject}/${assessment_id}`));

function* onAssessmentRejectionAsync(action: PayloadAction<any>) {
  try {
    const response: AxiosResponse<APIResponse> = yield call(
      assessmentRejection,
      action.payload.data,
      action.payload.assessment_id,
    );
    if (response.status === 200) {
      yield put(
        assessmentRejectAction.rejectionSuccess({
          data: response.data.data,
          status: response.status,
        }),
      );
    }
  } catch (error) {
    yield put(
      assessmentRejectAction.rejectionFailure({
        error: error as ErrorType,
      }),
    );
  }
}

function* onAssessmentRejection() {
  yield takeLatest(
    assessmentRejectAction.rejectionRequest,
    onAssessmentRejectionAsync,
  );
}
export const taskSaga = [
  fork(onTaskGetList),
  fork(onAssessmentDetails),
  fork(onAssessmentReviewSubmit),
  fork(onAssessmentRejection),
];
