import { FormEvent, useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Form, FormCheckProps } from "react-bootstrap";

const MainContainer = styled.div`
  padding: 10px;
  button {
    font-size: 14px;
    line-height: 1.6875rem;
    text-transform: none;
    min-height: 45px;
    padding: 7px 24px;
    transition: background-color 1s;
  }
  .addButton {
    width: 100%;
  }
`;
const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 200px;
  overflow-y: auto;
  min-width: 180px;
  input {
    width: 24px;
    height: 24px;
    margin-right: 12px;
  }
  .form-check {
    text-align: left;
    margin-bottom: 14px;
    min-height: auto;
  }
  .form-check-label {
    margin-top: 7px;
    display: block;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;

    color: #505a65;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  .clearButton {
    margin-right: 16px;
  }
`;
type FilterProp = {
  data: string[] | undefined;
  filterData: string[];
  setFilterData: (data: string[]) => void;
  FilterFunctionClick: () => void;
  ClearData: () => void;
};

function FilterDropdown({
  data,
  filterData,
  setFilterData,
  FilterFunctionClick,
  ClearData,
}: FilterProp) {
  const [checkArray, setCheckArray] = useState(
    new Array(data?.length).fill(false),
  );

  useEffect(() => {
    const arraCheck = [...checkArray];
    data?.forEach((ele, index) => {
      if (filterData.includes(ele)) {
        arraCheck[index] = true;
      }
    });
    setCheckArray(arraCheck);
  }, [data]);

  const handleChange = (ev: FormEvent, element: string, index: number) => {
    const arraCheck = [...checkArray];
    arraCheck[index] = !arraCheck[index];
    setCheckArray(arraCheck);
    if ((ev?.target as FormCheckProps).checked) {
      setFilterData([...filterData, element]);
    } else {
      const dataFil = [...filterData];
      const dataFilmn = dataFil.filter((ele) => ele !== element);
      setFilterData(dataFilmn);
    }
  };

  const ClearFilter = () => {
    setCheckArray(new Array(data?.length).fill(false));
    ClearData();
  };

  return checkArray !== undefined ? (
    <MainContainer>
      <CheckboxContainer>
        {data?.map((element, index) => {
          return (
            <Form.Check
              inline
              label={element}
              name="group1"
              type="checkbox"
              checked={checkArray[index]}
              onChange={(ev) => {
                handleChange(ev, element, index);
              }}
              id={index.toString()}
              key={element}
            />
          );
        })}
      </CheckboxContainer>
      <ButtonContainer>
        <Button
          variant="outline-primary"
          className="clearButton"
          onClick={ClearFilter}
        >
          Clear
        </Button>
        <Button
          variant="primary"
          className="addButton"
          onClick={FilterFunctionClick}
        >
          Apply
        </Button>
      </ButtonContainer>
    </MainContainer>
  ) : null;
}

export { FilterDropdown };
