import { takeLatest, put, fork, call } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { PayloadAction } from "@reduxjs/toolkit";
import http from "../../../utils/http.service";
import {
  activateUserAction,
  assignUserRoleAction,
  deActivateUserAction,
  getAdminUsersAction,
} from "./manageUserSlice";
import {
  userActivation,
  adminUserAssignRole,
  getAdminUsers,
  userDeActivation,
} from "../../../utils/serviceurl";
import config from "../../../utils/request.config";
import { APIResponse, ErrorType } from "../../../model/common";
import { AssignRoleProp } from "../../../model/admin";

export const fetchAdminUsers = async () =>
  http.GET(config.getConfig("", getAdminUsers, ""));

function* onFetchUserAsync() {
  try {
    const response: AxiosResponse<APIResponse> = yield call(fetchAdminUsers);
    if (response.status === 200) {
      yield put(
        getAdminUsersAction.getAdminUsersSuccess({
          data: response.data.data,
          status: response.status,
        }),
      );
    }
  } catch (error) {
    yield put(
      getAdminUsersAction.getAdminUsersFailure({
        error: error as ErrorType,
      }),
    );
  }
}

function* onFetchUserDataList() {
  yield takeLatest(getAdminUsersAction.getAdminUsers, onFetchUserAsync);
}

export const assignRole = async (data: AssignRoleProp) =>
  http.PUT(config.postConfig(data, "", adminUserAssignRole));

function* onRoleAssignAsync(action: PayloadAction<{ data: AssignRoleProp }>) {
  try {
    const response: AxiosResponse<APIResponse> = yield call(
      assignRole,
      action.payload.data,
    );
    if (response.status === 200) {
      yield put(
        assignUserRoleAction.roleAssignSuccess({
          data: response.data.data,
          status: response.status,
        }),
      );
    }
  } catch (error) {
    yield put(
      assignUserRoleAction.roleAssignFailure({
        error: error as ErrorType,
      }),
    );
  }
}

function* onUpdateUserRole() {
  yield takeLatest(assignUserRoleAction.roleAssignRequest, onRoleAssignAsync);
}

export const enableAdminUser = async (data: any) =>
  http.POST(config.postConfig(data, "", userActivation, ""));

function* onAdminUserActivationAsync(action: PayloadAction<any>) {
  try {
    const response: AxiosResponse<any> = yield call(
      enableAdminUser,
      action.payload,
    );
    if (response.status === 200) {
      yield put(
        activateUserAction.activateSuccess({
          status: response.status,
          data: response.data.data,
        }),
      );
    }
  } catch (error) {
    yield put(
      activateUserAction.activateFailure({
        error: error as ErrorType,
      }),
    );
  }
}

function* onAdminUserActivation() {
  yield takeLatest(
    activateUserAction.activateRequest,
    onAdminUserActivationAsync,
  );
}
export const disableAdminUser = async (data: any) =>
  http.POST(config.postConfig(data, "", userDeActivation, ""));

function* onAdminUserDiActivationAsync(action: PayloadAction<any>) {
  try {
    const response: AxiosResponse<any> = yield call(
      disableAdminUser,
      action.payload,
    );
    if (response.status === 200) {
      yield put(
        deActivateUserAction.deActivateSuccess({
          status: response.status,
          data: response.data.data,
        }),
      );
    }
  } catch (error) {
    yield put(
      deActivateUserAction.deActivateFailure({
        error: error as ErrorType,
      }),
    );
  }
}

function* onAdminUserDiActivation() {
  yield takeLatest(
    deActivateUserAction.deActivateRequest,
    onAdminUserDiActivationAsync,
  );
}
export const manageAdminUsersSaga = [
  fork(onFetchUserDataList),
  fork(onUpdateUserRole),
  fork(onAdminUserActivation),
  fork(onAdminUserDiActivation),
];
