import { ReactElement, useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import styled from "styled-components";
import { useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Select from "react-select";
import moment from "moment";
import exportFromJSON from "export-from-json";
import icons from "../../icons";
import { RootState } from "../../../store/store";
import { assessmentTypeFetchAction } from "./assesmenttypeslice";
import { useAppDispatch } from "../../../store/hooks";
import { AssessmentFrameworks } from "../../../model/dashTypeFramework";
import { getAssessmentIdAction } from "../../../pages/MainPage/getAssessmentIdSlice";
import errorHandling from "../../../utils/errorHandling";
import { assessmentDetialsAction } from "../assessmentPriority/AssesmentDetailsSlice";
import ReassessModal from "../../../pages/MainPage/AssessmentResult/ReassessModal";

const StyledReassessDetails = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.border.default};
  margin: 20px 0 0 0;
  border-radius: 10px;
  min-height: 80px;
  display: flex;
  background: ${({ theme }) => theme.colors.bg.white};

  button {
    padding: 0 0 0 0;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    min-width: 192px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: ${({ theme }) => theme.fontFamily.bold};
    font-weight: normal;
    font-size: ${({ theme }) => theme.fontSizes[2]};
    line-height: ${({ theme }) => theme.lineHeight[3]};
    svg {
      margin-right: 10px;
    }
  }
  .reassess-details {
    display: flex;
    align-items: center;
  }
`;

const ReassessDetailsWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  padding: 0 16px;
`;

const ReassessDetailsList = styled.div`
  width: 100%;
  margin-right: 10px;
  max-width: 230px;
  label,
  p {
    font-family: ${({ theme }) => theme.fontFamily.regular};
    font-size: ${({ theme }) => theme.fontSizes[1]};
    line-height: ${({ theme }) => theme.lineHeight[2]};
    font-weight: normal;
    color: ${({ theme }) => theme.colors.text.lightGrey1};
  }
  p {
    font-family: ${({ theme }) => theme.fontFamily.medium};
    font-weight: normal;
    color: ${({ theme }) => theme.colors.text.black};
    margin-top: 4px;
  }
`;
const TextReassess = styled.div`
  height: 38px;
  align-items: center;
  display: flex;
  text-align: left;
`;
const CustomSelect1 = styled.div`
  width: 170px;
  color: ${({ theme }) => theme.colors.text.black};
  font-size: ${({ theme }) => theme.fontSizes[1]};

  [class*="-control"] {
    font-family: ${({ theme }) => theme.fontFamily.bold};
    font-style: normal;
    font-size: ${({ theme }) => theme.fontSizes[0]};
    line-height: ${({ theme }) => theme.lineHeight[0]};
    color: ${({ theme }) => theme.colors.text.black};
    cursor: pointer;
    box-shadow: none !important;
    border: unset;
    &:hover {
      border-color: ${({ theme }) => theme.baseColors.primary};
      border: unset;
    }
    div:first-child {
      padding: 0px;
    }
  }
  [class*="-ValueContainer"] {
    padding: 0;
    font-family: ${({ theme }) => theme.fontFamily.medium};
    color: ${({ theme }) => theme.colors.text.black};
    font-size: ${({ theme }) => theme.fontSizes[1]};
    line-height: ${({ theme }) => theme.lineHeight[2]};
    font-weight: normal;
    div {
      padding: 0;
      margin: 0;
    }
  }
  [class*="-placeholder"] {
    font-family: ${({ theme }) => theme.fontFamily.medium};
    color: ${({ theme }) => theme.colors.text.black};
    font-size: ${({ theme }) => theme.fontSizes[1]};
    line-height: ${({ theme }) => theme.lineHeight[2]};
    font-weight: normal;
    padding: 0px;
    margin: 0px;
  }
  [class*="-Input"] {
    position: absolute;
  }
  [class*="-indicatorContainer"] {
    padding: 0;
    svg {
      width: 18px;
      height: 18px;
      path {
        fill: ${({ theme }) => theme.baseColors.black};
      }
    }
  }
  .css-b62m3t-container {
    display: grid !important;
    width: 100%;
    .css-1okebmr-indicatorSeparator {
      display: none;
    }
  }
  .css-26l3qy-menu {
    z-index: 5;
    font-family: ${({ theme }) => theme.fontFamily.medium};
    color: ${({ theme }) => theme.colors.text.black};
    font-size: ${({ theme }) => theme.fontSizes[1]};
    line-height: ${({ theme }) => theme.lineHeight[2]};
    margin: none;
    padding: 0px;
  }
`;
const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 15px;
  button {
    height: 50px;
    padding: 0 0 0 0;
    min-width: 132px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: ${({ theme }) => theme.fontFamily.bold};
    font-weight: normal;
    font-size: ${({ theme }) => theme.fontSizes[2]};
    line-height: ${({ theme }) => theme.lineHeight[3]};
    border-radius: 6px;
    svg {
      margin-right: 10px;
    }
  }
  .download-button {
    font-family: ${({ theme }) => theme.fontFamily.medium};
    margin-right: 16px;
    min-width: 184px;

    /* background-color: ${({ theme }) => theme.colors.bg.white}; */
    /* color: ${({ theme }) => theme.colors.text.tealishBlue}; */
  }
  .download-button:hover {
    /* background-color: ${({ theme }) => theme.colors.bg.white};
    color: ${({ theme }) => theme.colors.text.tealishBlue};
    svg {
      path {
        fill: #ffffff;
      }
    }
    &:hover {
      background: ${({ theme }) => theme.colors.bg.tralishBlue1};
      border-color: ${({ theme }) => theme.colors.bg.tralishBlue1};
      svg {
        path {
          fill: ${({ theme }) => theme.colors.bg.tralishBlue1};
        }
      }
    }
    &:focus {
      background: ${({ theme }) => theme.colors.bg.blue};
      border-color: ${({ theme }) => theme.colors.bg.blue};
      color: ${({ theme }) => theme.colors.text.white};
      svg {
        path {
          fill: ${({ theme }) => theme.colors.bg.blue};
        }
      }
    }
    border-color: #0a53be; */
    .pr-icon {
      color: #9197c0;
    }
  }
  /* .download-button:active {
    background-color: ${({ theme }) => theme.colors.bg.white};
    color: ${({ theme }) => theme.colors.text.tealishBlue};
    border-color: #0a53be;
    box-shadow: 0 0 0 0.25rem rgb(49 132 253 / 50%);
  }  */
`;
const CustomSelect2 = styled.div`
  width: 120px;
  color: ${({ theme }) => theme.colors.text.black};
  font-size: ${({ theme }) => theme.fontSizes[1]};

  [class*="-control"] {
    font-family: ${({ theme }) => theme.fontFamily.bold};
    font-style: normal;
    font-size: ${({ theme }) => theme.fontSizes[0]};
    line-height: ${({ theme }) => theme.lineHeight[0]};
    color: ${({ theme }) => theme.colors.text.black};
    cursor: pointer;
    box-shadow: none !important;
    border: unset;
    &:hover {
      border-color: ${({ theme }) => theme.baseColors.primary};
      border: unset;
    }
    div:first-child {
      padding: 0px;
    }
  }

  [class*="-ValueContainer"] {
    padding: 0;
    font-family: ${({ theme }) => theme.fontFamily.medium};
    color: ${({ theme }) => theme.colors.text.black};
    font-size: ${({ theme }) => theme.fontSizes[1]};
    line-height: ${({ theme }) => theme.lineHeight[2]};
    font-weight: normal;
    div {
      padding: 0;
      margin: 0;
    }
  }
  [class*="-placeholder"] {
    font-family: ${({ theme }) => theme.fontFamily.medium};
    color: ${({ theme }) => theme.colors.text.black};
    font-size: ${({ theme }) => theme.fontSizes[1]};
    line-height: ${({ theme }) => theme.lineHeight[2]};
    font-weight: normal;
    margin: 0px;
  }
  [class*="-Input"] {
    position: absolute;
  }
  [class*="-indicatorContainer"] {
    padding: 0;
    svg {
      width: 18px;
      height: 18px;
      path {
        fill: ${({ theme }) => theme.baseColors.black};
      }
    }
  }
  .css-b62m3t-container {
    display: grid !important;
    width: 100%;
    .css-1okebmr-indicatorSeparator {
      display: none;
    }
  }
  .css-26l3qy-menu {
    z-index: 5;
    font-family: ${({ theme }) => theme.fontFamily.medium};
    color: ${({ theme }) => theme.colors.text.black};
    font-size: ${({ theme }) => theme.fontSizes[1]};
    line-height: ${({ theme }) => theme.lineHeight[2]};
    margin: none;
    padding: 0px;
  }
`;
const ButtonDetails = styled.div`
  display: flex;
  align-items: center;
  a {
    text-decoration: none;
    border: 1px solid ${({ theme }) => theme.colors.bg.tealishBlue};
    font-family: ${({ theme }) => theme.fontFamily.medium};
    font-style: normal;
    font-weight: normal;
    color: ${({ theme }) => theme.colors.text.tealishBlue};
    height: 50px;
    padding: 0 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    &:hover {
      background: ${({ theme }) => theme.colors.bg.tralishBlue1};
      border-color: ${({ theme }) => theme.colors.bg.tralishBlue1};
      color: ${({ theme }) => theme.colors.text.white};
    }
    &:active,
    &:focus {
      background: ${({ theme }) => theme.colors.bg.blue};
      border-color: ${({ theme }) => theme.colors.bg.blue};
      color: ${({ theme }) => theme.colors.text.white};
      svg {
        fill: ${({ theme }) => theme.colors.bg.blue};
      }
    }
  }
  .new-button {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    min-width: 92px;
  }
  .reassess-details {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    min-width: 132px;
    background: ${({ theme }) => theme.colors.bg.tealishBlue};
    color: ${({ theme }) => theme.colors.text.white};
    svg {
      margin-right: 10px;
      color: ${({ theme }) => theme.colors.text.white};
    }
  }
`;

export interface Selecttype {
  value: string;
  label: string;
  isdisabled: boolean;
}

function ReassessDetails({
  reassessmentData,
  GetUserAssessmentInfoAPI,
  setSelectedFrameWorkType,
  isExcelStatus,
}: any): ReactElement {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [AssesmentType, setAssessmentType] = useState<Selecttype[]>();
  const [frameWorkType, setFrameWorkType] = useState<Selecttype[]>();
  const [DataListDownload, setDataListDownload] = useState(false);

  // First load getting AssessmentTypes
  const dataAssessmentTypeGetting = useSelector((state: RootState) => {
    return state.assessmetTypeFrameworkList;
  });

  const assessmentIdSelectionData = useSelector(
    (state: RootState) => state.getAssessmentId,
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [PermissionGranted, setPermissionGreanted] = useState(true);
  const [show, setShow] = useState(false);

  // const [unAssignedUser, setUnAssignedUser] = useState(false);
  const handleClose = () => {
    setShow(false);
  };

  // ----------Getting assessment details type and framework--------------------------//////////
  useEffect(() => {
    if (dataAssessmentTypeGetting.data.length === 0) {
      dispatch(assessmentTypeFetchAction.assessmentTypeFetchRequest());
    }
    if (dataAssessmentTypeGetting.data.length !== 0) {
      const data = dataAssessmentTypeGetting.data.map((element) => {
        if (element.assessmentType === "Product Assessment") {
          return {
            value: element._id,
            label: element.assessmentType,
            isdisabled: true,
          };
        }
        return {
          value: element._id,
          label: element.assessmentType,
          isdisabled: false,
        };
      });
      setAssessmentType(data);
    }
  }, [dataAssessmentTypeGetting.data]);

  // For setting assement Frameworktype dropdown according to previouse assement
  useEffect(() => {
    // console.log("dataAssessmentTypeGetting", dataAssessmentTypeGetting);
    if (
      reassessmentData?.assessmentType !== undefined &&
      dataAssessmentTypeGetting.data !== undefined
    ) {
      const frameWorkTypesList = dataAssessmentTypeGetting.data.filter(
        (element) =>
          element.assessmentType === reassessmentData?.assessmentType,
      );
      // console.log("frameWorkTypesList", frameWorkTypesList);
      if (frameWorkTypesList.length > 0) {
        const frameworkData = frameWorkTypesList[0].assessmentFrameworks.map(
          (data: AssessmentFrameworks) => {
            return {
              value: data.frameworkName,
              label: data.frameworkName,
              isdisabled: !data.isActive,
            };
          },
        );
        setFrameWorkType(frameworkData);
      }
    }
  }, [reassessmentData?.assessmentType, dataAssessmentTypeGetting.data]);

  // --------------------- Premission processing-----------------------//
  const permissionData = useSelector(
    (state: RootState) => state.getTokenPermission,
  );

  useEffect(() => {
    if (permissionData.permissions?.includes("edit:MaturityAssessment")) {
      setPermissionGreanted(false);
    } else {
      setPermissionGreanted(true);
    }
  }, [permissionData]);

  // console.log(permissionData, "get permisuiion");
  // Updating Framework Type on selecting Assessment Type
  const udpateFrameworkTypeonSelect = (id: string) => {
    const frameWorkTypesList = dataAssessmentTypeGetting.data.filter(
      (element) => element._id === id,
    );
    const frameworkData = frameWorkTypesList[0].assessmentFrameworks.map(
      (data: AssessmentFrameworks) => {
        return {
          value: data.frameworkName,
          label: data.frameworkName,
          isdisabled: !data.isActive,
        };
      },
    );
    setFrameWorkType(frameworkData);
  };

  // Getting Assement id based on FrameworkType Selected
  const UpdateAssessmentIdDataDispatch = (FrameWorkName: string) => {
    setSelectedFrameWorkType(FrameWorkName);
    if (FrameWorkName === "Custom Framework") {
      GetUserAssessmentInfoAPI();
    } else {
      dispatch(
        getAssessmentIdAction.getAssessmentIdBasedFrameworkRequest(
          FrameWorkName,
        ),
      );
    }
  };

  /// //////Download Report
  const DetailViewStore = useSelector(
    (state: RootState) => state.assessmetDetailsListView,
  );

  useEffect(() => {
    if (DataListDownload) {
      if (assessmentIdSelectionData.data.assessment !== null) {
        dispatch(
          assessmentDetialsAction.getAssessmentDetailViewRequest(
            assessmentIdSelectionData.data.assessment,
          ),
        );
      }
    }
  }, [DataListDownload]);

  useEffect(() => {
    if (DataListDownload === true && DetailViewStore.loading === false) {
      if (DetailViewStore.data !== undefined) {
        const removingNull = DetailViewStore.data.map((element) => {
          if (element.functionName === null) {
            return { ...element, functionName: "" };
          }
          if (element.control_id === null) {
            return { ...element, control_id: "" };
          }
          if (element.subCategory === null) {
            return { ...element, subCategory: "" };
          }
          if (element.current === null) {
            return { ...element, current: "" };
          }
          if (element.benchmarkMaturity === null) {
            return { ...element, benchmarkMaturity: "" };
          }
          if (element.target === null) {
            return { ...element, target: "" };
          }
          if (element.priority === null) {
            return { ...element, priority: "" };
          }
          return { ...element };
        });
        const DataFormated = removingNull.map((element) => {
          return {
            "Function Name": element.functionName,
            "Control Id": element.control_id,
            "Category Name": element.categoryName,
            "Sub Category": element.subCategory,
            Recommendation: element.recommendation,
            Priority: element.priority.replace("Priority", ""),
            Target: element.target,
            Current: element.current,
            "Benchmark Maturity": element.benchmarkMaturity,
          };
        });
        const data = DataFormated;
        const fileName = "AssessmentReport";
        const exportType = exportFromJSON.types.csv;

        exportFromJSON({ data, fileName, exportType });
      }
      dispatch(assessmentDetialsAction.reset());
      setDataListDownload(false);
    }
  }, [DetailViewStore.loading]);

  const DownloadResult = () => {
    setDataListDownload(true);
  };

  /// ///////////////-----------------------------------Error Capturing when changing select framework--------------------///////////////////////////
  useEffect(() => {
    if (dataAssessmentTypeGetting.error) {
      errorHandling(dataAssessmentTypeGetting.status, navigate);
    } else if (assessmentIdSelectionData.errorAssementChange) {
      if (assessmentIdSelectionData.statusAssesmentChange === 404) {
        // console.log("Not Data Available");
      } else {
        errorHandling(
          assessmentIdSelectionData.statusAssesmentChange,
          navigate,
        );
      }
    }
  }, [
    dataAssessmentTypeGetting.error,
    assessmentIdSelectionData.errorAssementChange,
  ]);

  return (
    <StyledReassessDetails>
      {reassessmentData !== null && (
        <ReassessDetailsWrapper>
          <ReassessDetailsList>
            <Form.Label>Last Run</Form.Label>
            <TextReassess>
              <p>
                {moment(reassessmentData?.lastRun).format("DD MMMM, h:mm a")}
              </p>
            </TextReassess>
          </ReassessDetailsList>
          <ReassessDetailsList>
            <Form.Label>Intake type </Form.Label>
            <CustomSelect1>
              <Select
                onChange={(ev: any) => {
                  udpateFrameworkTypeonSelect(ev.value);
                }}
                options={AssesmentType}
                getOptionLabel={(e: any) => (e ? e.label : "")}
                placeholder={reassessmentData?.assessmentType}
                isOptionDisabled={(option) => option.isdisabled}
              />
            </CustomSelect1>
          </ReassessDetailsList>
          <ReassessDetailsList>
            <Form.Label>Framework type </Form.Label>
            <CustomSelect2>
              <Select
                onChange={(ev: any) => {
                  UpdateAssessmentIdDataDispatch(ev.value);
                }}
                options={frameWorkType}
                getOptionLabel={(e: any) => (e ? e.label : "")}
                placeholder={reassessmentData?.assessmentFramework}
                isOptionDisabled={(option) => option.isdisabled}
              />
            </CustomSelect2>
          </ReassessDetailsList>
        </ReassessDetailsWrapper>
      )}

      <ButtonsContainer>
        <Button
          variant="outline-primary"
          className="download-button"
          disabled={DataListDownload}
          onClick={DownloadResult}
        >
          <icons.DownloadIcon />
          Download Report
        </Button>
        <ButtonDetails>
          {permissionData.role !== "Auditor" ? (
            <Link
              to="/MaturityAssessment"
              className="new-button"
              state={{ currentActiveTab: 0, functionality: "new" }}
            >
              New
            </Link>
          ) : (
            <>
              <Link
                to={
                  isExcelStatus === true
                    ? "/MaturityAssessment/audit/custom-framework/upload-excel"
                    : "/MaturityAssessment/audit/custom-framework/take-assessment"
                }
                className="new-button"
                state={{ currentActiveTab: 0, functionality: "new" }}
              >
                Audit
              </Link>
            </>
          )}
          {/* <Link to={} className="reassess-button" disabled={PermissionGranted}>
            <icons.refreshIcon />
            Reassess
          </Link> */}
          {/* <Link
           className="reassess-details"
            onClick={() => {
              setShow(true);
            }}
            // state={{
            //   currentActiveTab: 1,
            //   functionality: "reassess",
            //   assessmentType: reassessmentData?.assessmentType,
            //   assessmentFramework: reassessmentData?.assessmentFramework,
            // }}
          >
            <icons.refreshIcon />
            Reassess
          </Link> */}
          <Button
            className="reassess-details"
            onClick={() => {
              setShow(true);
            }}
          >
            <icons.refreshIcon />
            Reassess
          </Button>
        </ButtonDetails>
      </ButtonsContainer>
      <Modal
        show={show}
        closeTimeoutMS={500}
        onHide={handleClose}
        centered
        className="centered-modal"
      >
        {/* Popup close */}
        <Modal.Header closeButton />
        <Modal.Body>
          <ReassessModal
            handleClose={handleClose}
            assessmentType={reassessmentData?.assessmentType}
            assessmentFramework={reassessmentData?.assessmentFramework}
            maturityAssessmentTarget={
              reassessmentData?.maturityAssessmentTarget
            }
          />
        </Modal.Body>
      </Modal>
    </StyledReassessDetails>
  );
}

export { ReassessDetails };
