const config = {
  // get configuration
  getConfig: (params: any, url: any, headers = {}) => {
    return {
      url,
      params,
      headers,
    };
  },
  // config for post request
  postConfig: (data: any, params: any, url: any, headers = {}) => {
    return {
      url,
      params,
      headers,
      data,
    };
  },
};

export default config;
