import { ReactElement, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Tooltip } from "react-tippy";
import styled from "styled-components";
import { WsAssignedUser } from "../../../../model/wsAddUser";
import {
  DesignationDetails,
  MembersDetailsContainer,
  MembersDetails,
} from "../../../../pages/MainPage/Organization/TOMView/HRView/styles";
import { useAppDispatch } from "../../../../store/hooks";
import { RootState } from "../../../../store/store";
import { assignOrUnAssignResourceAction } from "../workStreamJobDescriptionSubmitSlice";
import { TooltipContent } from "./addPopup";

const UploadHolder = styled.div``;
const StyledNode = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px;
  border-radius: 10px;
  margin-bottom: 10px;
  border: 1px solid ${({ theme }) => theme.colors.bg.bluishCyan};
  background-color: ${({ theme }) => theme.colors.bg.white1};
  position: relative;
  &:hover,
  &:active,
  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.bg.tealishBlue};
    background-color: ${({ theme }) => theme.colors.bg.white3};
  }
`;
const DesignationContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;

function OrgStructure({
  profileList,
  orgMatchedProfiles,
  assignedUserList,
  setAssignedUserList,
  wrkStreamStartDate,
  wrkStreamEndDate,
  assignUnassignResourceHandler,
  jobDescriptionList,
}: any): ReactElement {
  const dispatch = useAppDispatch();
  const [matchProfileId, setMatchProfile] = useState<string[]>([""]);
  const [open, setOpen] = useState<boolean>(false);
  const [clickedUser, setClickedUser] = useState<any>(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [toolTipClickEnable, setToolTipClickEnable] = useState<boolean>(false);
  const [resourceAssignApiStatus, setAssignApiStatus] =
    useState<boolean>(false);
  const [updatedAssignedArray, setBeforeApiAssignedList] =
    useState<WsAssignedUser[]>();

  const assignOrUnassignDataUpdate = useSelector(
    (state: RootState) => state.assignOrUnAssignResources,
  );

  useEffect(() => {
    const data = orgMatchedProfiles?.map((item: any) => {
      return item._id;
    });
    setMatchProfile(data);
  }, [orgMatchedProfiles]);

  useEffect(() => {
    const checkUserAlreadyAssigned = assignedUserList?.filter(
      (item: any) => item?.profileId === clickedUser?._id,
    );
    if (checkUserAlreadyAssigned?.length > 0) {
      setToolTipClickEnable(false);
    } else {
      setToolTipClickEnable(true);
    }
  }, [clickedUser, assignedUserList]);

  useEffect(() => {
    if (assignOrUnassignDataUpdate.status === 200) {
      setAssignApiStatus(true);
    }
  }, [assignOrUnassignDataUpdate.loading]);

  useEffect(() => {
    if (resourceAssignApiStatus && updatedAssignedArray) {
      const updatedArray = [...updatedAssignedArray];
      setAssignedUserList(updatedArray);
      setOpen(false);
      setStartDate(null);
      setEndDate(null);
    }
  }, [resourceAssignApiStatus, updatedAssignedArray]);

  const addUserHandler = () => {
    dispatch(assignOrUnAssignResourceAction.reset());
    setAssignApiStatus(false);
    const tempArray: WsAssignedUser[] = [...assignedUserList];
    const roleDetails = jobDescriptionList?.find(
      (item: any) => item?.roleName === clickedUser?.designation,
    );
    const resourceData: WsAssignedUser = {
      startDate,
      endDate,
      profileId: clickedUser?._id,
      fullName: clickedUser?.name,
      role: clickedUser?.designation,
      experience: clickedUser?.experience,
      isOwner: false,
      roleId: roleDetails?.roleId,
    };
    tempArray.unshift(resourceData);
    setBeforeApiAssignedList(tempArray);
    assignUnassignResourceHandler(resourceData, "assign");
  };

  return (
    <UploadHolder>
      {profileList?.length > 0 ? (
        profileList?.map((item: any) => (
          <Tooltip
            position="top"
            trigger="click"
            arrow
            interactive
            theme="light"
            key={item?._id}
            open={
              !!(
                matchProfileId?.includes(item._id) &&
                open &&
                clickedUser?._id === item?._id &&
                toolTipClickEnable
              )
            }
            onRequestClose={() => {
              setOpen(false);
            }}
            html={
              <TooltipContent
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                Name={item?.name}
                ProfessionName={item?.designation}
                ExperienceCount={item?.experience}
                addUserHandler={addUserHandler}
                wrkStreamStartDate={wrkStreamStartDate}
                wrkStreamEndDate={wrkStreamEndDate}
              />
            }
          >
            <StyledNode
              key={item?._id}
              style={{
                border: matchProfileId?.includes(item._id)
                  ? "1px solid #4182F1"
                  : "1px solid #DEE5EB",
                backgroundColor: matchProfileId?.includes(item._id)
                  ? "white"
                  : "#F5F6FD",
                pointerEvents: matchProfileId?.includes(item._id)
                  ? "auto"
                  : "none",
                opacity: matchProfileId?.includes(item._id) ? 1 : 0.7,
              }}
              onClick={() => {
                setClickedUser(item);
                if (matchProfileId?.includes(item._id)) {
                  setOpen(true);
                }
              }}
            >
              <DesignationContainer>
                <DesignationDetails>{item?.designation}</DesignationDetails>
              </DesignationContainer>
              <MembersDetailsContainer>
                <MembersDetails style={{ marginBottom: "5px" }}>
                  {item?.name}
                </MembersDetails>
                {item?.experience && item?.experience !== "" ? (
                  <MembersDetails>{item?.experience} Years</MembersDetails>
                ) : null}
              </MembersDetailsContainer>
            </StyledNode>
          </Tooltip>
        ))
      ) : (
        <p> User is not available</p>
      )}
    </UploadHolder>
  );
}

export { OrgStructure };

// ....... commented only for mvp .....
/* import React, { ReactElement, useEffect, useState } from "react";
import styled from "styled-components";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import Scrollbars from "react-custom-scrollbars-2";
import { Button, ButtonGroup } from "react-bootstrap";
import Icons from "../../../icons";
import OrganizationStructure from "../../../../pages/MainPage/TOM/TOMView/HRView/Components/OrganizationStructure";
import EmployeeStructure from "./EmployeeStructure";
import { EmpDataListOrg } from "../../../../model/wsRoadMap";

type OrgStructuretype = {
  empOrgListStore: EmpDataListOrg;
};

function OrgStructure({ empOrgListStore }: OrgStructuretype): ReactElement {
  return (
    <>
      <TransformWrapper
        initialScale={1}
        initialPositionX={1}
        initialPositionY={1}
        minScale={-1}
        maxScale={3}
        disabled
      >
        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
          <React.Fragment>
            <div className="tools">
              <pre>
                <ButtonGroup aria-label="Basic example">
                  <Button variant="primary" onClick={() => zoomOut()}>
                    <Icons.ZoomOutIcon />
                  </Button>
                  <Button variant="primary" onClick={() => zoomIn()}>
                    <Icons.ZoomInIcon />
                  </Button>
                </ButtonGroup>
              </pre>
            </div>
            <Scrollbars autoHide className="Scrollbar">
              <TransformComponent>
                <EmployeeStructure empOrgListStore={empOrgListStore} />
              </TransformComponent>
            </Scrollbars>
          </React.Fragment>
        )}
      </TransformWrapper>
    </>
  );
}

export { OrgStructure }; */
