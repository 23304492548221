import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { useSelector } from "react-redux";
import HomePage from "../pages/LoginPage";
import { MainPage } from "../pages/MainPage";
import ErrorPage, { Error401Page } from "../pages/UtilPages/ErrorPage";
import { addAccessTokenInterceptor } from "../utils/http.service";
import { ProtectedRoute } from "./ProtectedRoute";
import { getTokenPermissionAction } from "../pages/LoginPage/getTokenPermissionSlice";
import { useAppDispatch } from "../store/hooks";
import { RootState } from "../store/store";
import Admin from "../pages/Admin";
import LoaderPage from "../pages/UtilPages/LoaderPage";

const DashboardPage = React.lazy(
  () => import("../pages/MainPage/DashBoardTab"),
);
const MaturityAssessment = React.lazy(
  () => import("../pages/MaturityAssessment"),
);
const AssessmentResult = React.lazy(
  () => import("../pages/MainPage/AssessmentResult"),
);
const NewAssessmentPage = React.lazy(() => import("../pages/NewAssessment"));

const ImplementationOverView = React.lazy(
  () => import("../pages/MainPage/Implementation/ImplementationOverview"),
);
const ImplementationRoadMap = React.lazy(
  () => import("../pages/MainPage/RoadMap"),
);
const Organization = React.lazy(() => import("../pages/MainPage/Organization"));
const DemographicsPage = React.lazy(
  () => import("../pages/MainPage/Demographics"),
);
const TOM = React.lazy(() => import("../pages/MainPage/TOM"));

const StrategyPage = React.lazy(() => import("../pages/MainPage/Strategy"));
const AuditReviewPage = React.lazy(() => import("../pages/AuditReview"));
const AuditOrganization = React.lazy(
  () => import("../pages/MainPage/OrganizationAuditor"),
);
const ExcelReviewTable = React.lazy(() => import("../pages/Excel"));

const ProfileBuilder = React.lazy(() => import("../pages/ProfileBuilder"));
const ManageRoles = React.lazy(() => import("../pages/Admin/ManageRoles"));
const ManageUsers = React.lazy(() => import("../pages/Admin/ManageUsers"));

function RouterPage() {
  const dispatch = useAppDispatch();
  const { isLoading, getAccessTokenSilently, isAuthenticated } = useAuth0();
  const permissions = useSelector(
    (state: RootState) => state.getTokenPermission,
  );

  const getToken = async () => {
    const token = await getAccessTokenSilently();
    const decoded: any = jwt_decode(token);
    const Roles: string[] = decoded["https://cybrizehorizon/roles"];
    // const userPermissions: string[] =
    //   decoded["https://cybrizehorizon/permissions"];
    // dispatch(getTokenPermissionAction.tokenSave(userPermissions));
    dispatch(getTokenPermissionAction.tokenSave(decoded.permissions));
    dispatch(getTokenPermissionAction.setRole(Roles[0]));
    dispatch(getTokenPermissionAction.setUserRolesArray(Roles));
  };

  useEffect(() => {
    if (isAuthenticated === true) {
      getToken();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    addAccessTokenInterceptor(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  /// ////////// ------------------------- Permission Setting -----------------////////////////

  const [permissionUpdate, setPermissionUpdate] = useState(false);

  useEffect(() => {
    if (!permissions.loader) {
      setPermissionUpdate(true);
      console.log(permissionUpdate, permissions);
    }
  }, [permissions]);
  return (
    <Routes>
      <Route path="" element={<HomePage />} />
      <Route path="ErrorPage" element={<ErrorPage />} />
      <Route path="401" element={<Error401Page />} />

      {permissions.role !== "" && permissions.role !== undefined ? (
        <Route
          path="Dashboard"
          element={<ProtectedRoute component={MainPage} />}
        >
          <Route
            path="Home"
            element={
              <React.Suspense fallback={<>...</>}>
                <ProtectedRoute component={DashboardPage} />
              </React.Suspense>
            }
          />
          <Route
            path="Assessment"
            element={
              <React.Suspense fallback={<>...</>}>
                <ProtectedRoute component={AssessmentResult} />
              </React.Suspense>
            }
          />
          <Route
            path="Audit"
            element={
              <React.Suspense fallback={<>...</>}>
                <ProtectedRoute component={AuditOrganization} />
              </React.Suspense>
            }
          />
          <Route
            path="Strategy"
            element={
              <React.Suspense fallback={<>...</>}>
                <ProtectedRoute component={StrategyPage} />
              </React.Suspense>
            }
          />

          <Route
            path="Roadmap"
            element={
              <React.Suspense fallback={<>...</>}>
                <ProtectedRoute component={ImplementationRoadMap} />
              </React.Suspense>
            }
          />
          <Route
            path="TOM"
            element={
              <React.Suspense fallback={<>...</>}>
                <ProtectedRoute component={TOM} />
              </React.Suspense>
            }
          />
          <Route
            path="Organization"
            element={
              <React.Suspense fallback={<>...</>}>
                <ProtectedRoute component={Organization} />
              </React.Suspense>
            }
          />
          <Route
            path="Team"
            element={
              <React.Suspense fallback={<>...</>}>
                <ProtectedRoute component={DemographicsPage} />
              </React.Suspense>
            }
          />
          <Route
            path="Implementation"
            element={
              <React.Suspense fallback={<>...</>}>
                <ProtectedRoute component={ImplementationOverView} />
                {/*  <ImplementationOverView /> */}
              </React.Suspense>
            }
          />
        </Route>
      ) : null}

      {/* {true ? ( */}
      {permissions.permissions?.includes("edit:ProfileBuilder") &&
      permissions.role !== "" &&
      permissions.role !== undefined &&
      permissionUpdate ? (
        <Route
          path="ProfileBuilder"
          element={
            <React.Suspense fallback={<>...</>}>
              <ProtectedRoute component={ProfileBuilder} />
            </React.Suspense>
          }
        />
      ) : (
        <Route path="error-404" element={<ErrorPage />} />
      )}
      {/* {true ? ( */}
      {permissions.role !== "" &&
      permissions.role !== undefined &&
      permissions.role !== "Auditor" ? (
        <Route
          path="MaturityAssessment"
          element={
            <React.Suspense fallback={<>...</>}>
              <ProtectedRoute component={MaturityAssessment} />
            </React.Suspense>
          }
        />
      ) : (
        <Route path="error-404" element={<ErrorPage />} />
      )}

      {permissions.role !== "" &&
      permissions.role !== undefined &&
      permissions.role !== "Auditor" ? (
        <Route
          path="MaturityAssessment/CustomFramework"
          element={
            <React.Suspense fallback={<>...</>}>
              <ProtectedRoute component={NewAssessmentPage} />
            </React.Suspense>
          }
        />
      ) : (
        <Route path="error-404" element={<ErrorPage />} />
      )}

      {permissions.role !== "" &&
      permissions.role !== undefined &&
      permissions.role === "Auditor" ? (
        <Route
          path="MaturityAssessment/audit/custom-framework/upload-excel"
          element={
            <React.Suspense fallback={<>...</>}>
              <ProtectedRoute component={ExcelReviewTable} />
            </React.Suspense>
          }
        />
      ) : (
        <Route path="error-404" element={<ErrorPage />} />
      )}

      {permissions.role !== "" &&
      permissions.role !== undefined &&
      permissions.role === "Auditor" ? (
        <Route
          path="MaturityAssessment/audit/custom-framework/take-assessment"
          element={
            <React.Suspense fallback={<>...</>}>
              <ProtectedRoute component={AuditReviewPage} />
            </React.Suspense>
          }
        />
      ) : (
        <Route path="error-404" element={<ErrorPage />} />
      )}

      {/* end */}
      {permissions.role !== "" &&
      permissions.role !== undefined &&
      permissions.role === "Admin" ? (
        <Route path="Admin" element={<ProtectedRoute component={Admin} />}>
          <Route
            path="Users"
            element={
              <React.Suspense fallback={<>...</>}>
                <ProtectedRoute component={ManageUsers} />
              </React.Suspense>
            }
          />
          <Route
            path="Roles"
            element={
              <React.Suspense fallback={<>...</>}>
                <ProtectedRoute component={ManageRoles} />
              </React.Suspense>
            }
          />
        </Route>
      ) : (
        <Route path="error-404" element={<ErrorPage />} />
      )}

      {isLoading ? (
        <Route path="*" element={<LoaderPage />} />
      ) : (
        <Route path="*" element={<ErrorPage msg="NF" />} />
      )}
    </Routes>
  );
}

export default RouterPage;
