import { takeLatest, put, fork, call } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { PayloadAction } from "@reduxjs/toolkit";
import http from "../../../../../utils/http.service";
import config from "../../../../../utils/request.config";
import {
  functionView,
  functionViewbulkUpdate,
  functionViewDelete,
  functionViewProfileDetails,
  getOrgProfiles,
} from "../../../../../utils/serviceurl";
import { APIResponse, ErrorType } from "../../../../../model/common";
import { funcUnassignedFetchAction } from "./FunctionUnassignedProfilesSlice";
import { funcBucketGetDataAction } from "./BucketFunctionProfilesGetSlice ";
import { funcBucketUdpateDataAction } from "./UpdateBucketFuncProfiles";
import {
  FunctionCard,
  FunctionNameUdpate,
} from "../../../../../model/functionViewModel";
import { newBucketFuncCreationAction } from "./NewBucketFuncCreation";
import { FunctionUpdateNameAction } from "./FunctionUpdateName";
import { funcProfileGetDataAction } from "./FunctionViewProfileFetchSlice";

/// //////////////// --------------------------- Getting Data FunctionviewData --------------//////////////
export const fetchFunBucketData = async () =>
  http.GET(config.getConfig("", functionView, ""));

function* onfetchfunctionBucketData() {
  try {
    const response: AxiosResponse<APIResponse> = yield call(fetchFunBucketData);
    if (response.status === 200) {
      yield put(
        funcBucketGetDataAction.funcBuncketFetchSuccess({
          data: response.data.data,
          status: response.status,
        }),
      );
    }
  } catch (error) {
    yield put(
      funcBucketGetDataAction.funcBuncketFetchFailure({
        error: error as ErrorType,
      }),
    );
  }
}

function* onfetchFunBucketData() {
  yield takeLatest(
    funcBucketGetDataAction.funcBuncketFetchRequest,
    onfetchfunctionBucketData,
  );
}

/// //////////////// --------------------------- Getting Data From Unassigned Profiles --------------//////////////////
export const fetchOrgProfilesDataAsync = async () =>
  http.GET(config.getConfig("", getOrgProfiles, ""));

function* onfetchOrgDatailsUnassignedAsync() {
  try {
    const response: AxiosResponse<APIResponse> = yield call(
      fetchOrgProfilesDataAsync,
    );
    if (response.status === 200) {
      yield put(
        funcUnassignedFetchAction.funcUnassignedProfileFetchSuccess({
          data: response.data.data,
          status: response.status,
        }),
      );
    }
  } catch (error) {
    yield put(
      funcUnassignedFetchAction.funcUnassignedProfileFetchFailure({
        error: error as ErrorType,
      }),
    );
  }
}

function* onfetchOrgData() {
  yield takeLatest(
    funcUnassignedFetchAction.funcUnassignedProfileFetchRequest,
    onfetchOrgDatailsUnassignedAsync,
  );
}

/// //////////////// --------------------------- Udpateing FunctionView Data --------------//////////////
export const UdpateFunBucketData = async (data: FunctionCard[]) =>
  http.PUT(config.postConfig(data, "", functionViewbulkUpdate, ""));

function* onUpdatefunctionBucketData(
  action: PayloadAction<{ data: FunctionCard[] }>,
) {
  try {
    const response: AxiosResponse<APIResponse> = yield call(
      UdpateFunBucketData,
      action.payload.data,
    );
    if (response.status === 200) {
      yield put(
        funcBucketUdpateDataAction.funcBuncketUpdateSuccess({
          data: response.data.data,
          status: response.status,
        }),
      );
    }
  } catch (error) {
    yield put(
      funcBucketUdpateDataAction.funcBuncketUpdateFailure({
        error: error as ErrorType,
      }),
    );
  }
}

function* onUdpateFunBucketData() {
  yield takeLatest(
    funcBucketUdpateDataAction.funcBuncketUpdateRequest,
    onUpdatefunctionBucketData,
  );
}

/// //////////////// --------------------------- Creating New Function Bucket --------------//////////////
export const CreateNewFunctionBucket = async (Data: {
  orgFunctionName: string;
}) => http.POST(config.postConfig(Data, "", functionView, ""));

function* onCreateNewFunctionBucket(
  action: PayloadAction<{ orgFunctionName: string }>,
) {
  try {
    const response: AxiosResponse<APIResponse> = yield call(
      CreateNewFunctionBucket,
      action.payload,
    );
    if (response.status === 200) {
      yield put(
        newBucketFuncCreationAction.newBucketFuncCreationSuccess({
          status: response.status,
        }),
      );
    }
  } catch (error) {
    yield put(
      newBucketFuncCreationAction.newBucketFuncCreationFailure({
        error: error as ErrorType,
      }),
    );
  }
}

function* onCreateNewFunctionBucketData() {
  yield takeLatest(
    newBucketFuncCreationAction.newBucketFuncCreationRequest,
    onCreateNewFunctionBucket,
  );
}

/// //////////////// --------------------------- Updating FunctionName -----------------------//////////////
export const UpdateFunctionName = async (data: FunctionNameUdpate) =>
  http.PUT(
    config.postConfig(
      data.orgFunctionName,
      "",
      `${functionView}/${data.id}`,
      "",
    ),
  );

function* onUpdateFunctionName(
  action: PayloadAction<{ data: FunctionNameUdpate }>,
) {
  try {
    const response: AxiosResponse<APIResponse> = yield call(
      UpdateFunctionName,
      action.payload.data,
    );
    if (response.status === 200) {
      yield put(
        FunctionUpdateNameAction.FunctionUpdatenameSuccess({
          status: response.status,
          data: response.data.data,
        }),
      );
    }
  } catch (error) {
    yield put(
      FunctionUpdateNameAction.FunctionUpdatenameFailure({
        error: error as ErrorType,
      }),
    );
  }
}

function* onUpdateFunctionNameData() {
  yield takeLatest(
    FunctionUpdateNameAction.FunctionUpdatenameRequest,
    onUpdateFunctionName,
  );
}

/// //////////////// --------------------------- Getting Profile Data --------------/////////////////
export const fetchProfileData = async (data: string) =>
  http.GET(config.getConfig("", `${functionViewProfileDetails}/${data}`, ""));

function* onfetchProfileFuncData(action: PayloadAction<string>) {
  try {
    const response: AxiosResponse<APIResponse> = yield call(
      fetchProfileData,
      action.payload,
    );
    if (response.status === 200) {
      yield put(
        funcProfileGetDataAction.funcProfileFetchSuccess({
          data: response.data.data,
          status: response.status,
        }),
      );
    }
  } catch (error) {
    yield put(
      funcProfileGetDataAction.funcProfileFetchFailure({
        error: error as ErrorType,
      }),
    );
  }
}

function* onfetchProfileData() {
  yield takeLatest(
    funcProfileGetDataAction.funcProfileFetchRequest,
    onfetchProfileFuncData,
  );
}

/// / -------------------- On Deleting Profiles -------------------------////////////////

export const DeleteFuncion = async (data: string) =>
  http.DELETE(config.getConfig("", `${functionViewDelete}/${data}`, ""));

function* onDeleteFunctionDat(action: PayloadAction<string>) {
  try {
    const response: AxiosResponse<APIResponse> = yield call(
      DeleteFuncion,
      action.payload,
    );
    if (response.status === 200) {
      yield put(
        funcBucketUdpateDataAction.funcBuncketUpdateSuccess({
          data: response.data.data,
          status: response.status,
        }),
      );
    }
  } catch (error) {
    yield put(
      funcBucketUdpateDataAction.funcBuncketUpdateFailure({
        error: error as ErrorType,
      }),
    );
  }
}

function* onDeleteFuncion() {
  yield takeLatest(
    funcBucketUdpateDataAction.funBuncketDeleteRequest,
    onDeleteFunctionDat,
  );
}

export const functionViewSaga = [
  fork(onfetchOrgData),
  fork(onfetchFunBucketData),
  fork(onUdpateFunBucketData),
  fork(onCreateNewFunctionBucketData),
  fork(onUpdateFunctionNameData),
  fork(onfetchProfileData),
  fork(onDeleteFuncion),
];
