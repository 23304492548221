import { createGlobalStyle } from "styled-components";
import AileronUltralightEot from "../fonts/Aileron/aileron-ultralight-webfont.eot";
import AileronUltralightSvg from "../fonts/Aileron/aileron-ultralight-webfont.svg";
import AileronUltralightWoff from "../fonts/Aileron/aileron-ultralight-webfont.woff";
import AileronUltralightTtf from "../fonts/Aileron/aileron-ultralight-webfont.ttf";
import AileronThinEot from "../fonts/Aileron/aileron-thin-webfont.eot";
import AileronThinSvg from "../fonts/Aileron/aileron-thin-webfont.svg";
import AileronThinWoff from "../fonts/Aileron/aileron-thin-webfont.woff";
import AileronThinTtf from "../fonts/Aileron/aileron-thin-webfont.ttf";
import AileronLightEot from "../fonts/Aileron/aileron-light-webfont.eot";
import AileronLightSvg from "../fonts/Aileron/aileron-light-webfont.svg";
import AileronLightWoff from "../fonts/Aileron/aileron-light-webfont.woff";
import AileronLightTtf from "../fonts/Aileron/aileron-light-webfont.ttf";
import AileronRegularEot from "../fonts/Aileron/aileron-regular-webfont.eot";
import AileronRegularSvg from "../fonts/Aileron/aileron-regular-webfont.svg";
import AileronRegularWoff from "../fonts/Aileron/aileron-regular-webfont.woff";
import AileronRegularTtf from "../fonts/Aileron/aileron-regular-webfont.ttf";
import AileronSemiBoldEot from "../fonts/Aileron/aileron-semibold-webfont.eot";
import AileronSemiBoldSvg from "../fonts/Aileron/aileron-semibold-webfont.svg";
import AileronSemiBoldWoff from "../fonts/Aileron/aileron-semibold-webfont.woff";
import AileronSemiBoldTtf from "../fonts/Aileron/aileron-semibold-webfont.ttf";
import AileronBoldEot from "../fonts/Aileron/aileron-bold-webfont.eot";
import AileronBoldSvg from "../fonts/Aileron/aileron-bold-webfont.svg";
import AileronBoldWoff from "../fonts/Aileron/aileron-bold-webfont.woff";
import AileronBoldTtf from "../fonts/Aileron/aileron-bold-webfont.ttf";
import AileronBlackEot from "../fonts/Aileron/aileron-black-webfont.eot";
import AileronBlackSvg from "../fonts/Aileron/aileron-black-webfont.svg";
import AileronBlackWoff from "../fonts/Aileron/aileron-black-webfont.woff";
import AileronBlackTtf from "../fonts/Aileron/aileron-black-webfont.ttf";
import backArrow1 from "../images/backArrow1.svg";
import theme from "./theme";

type ThemeType = typeof theme;
const GlobalStyles = createGlobalStyle<{ theme: ThemeType }>`
@font-face {
    font-family: 'AileronUltraLight';
    src: local('☺'), local('Aileron-UltraLight.eot'),
    url(${AileronUltralightEot}) format('eot'),
    url(${AileronUltralightSvg}) format('svg'),
    url(${AileronUltralightTtf}) format('truetype'),
    url(${AileronUltralightWoff}) format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'AileronThin';
    src: local('☺'), local('Aileron-Thin.eot'),
    url(${AileronThinEot}) format('eot'),
    url(${AileronThinSvg}) format('svg'),
    url(${AileronThinTtf}) format('truetype'),
    url(${AileronThinWoff}) format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'AileronLight';
    src: local('☺'), local('Aileron-Light.eot'),
    url(${AileronLightEot}) format('eot'),
    url(${AileronLightSvg}) format('svg'),
    url(${AileronLightTtf}) format('truetype'),
    url(${AileronLightWoff}) format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'AileronRegular';
    src: local('☺'), local('Aileron-Regular.eot'),
    url(${AileronRegularEot}) format('eot'),
    url(${AileronRegularSvg}) format('svg'),
    url(${AileronRegularTtf}) format('truetype'),
    url(${AileronRegularWoff}) format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'AileronSemiBold';
    src: local('☺'), local('Aileron-Semi-Bold.eot'),
    url(${AileronSemiBoldEot}) format('eot'),
    url(${AileronSemiBoldSvg}) format('svg'),
    url(${AileronSemiBoldTtf}) format('truetype'),
    url(${AileronSemiBoldWoff}) format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'AileronBold';
    src: local('☺'), local('Aileron-Bold.eot'),
    url(${AileronBoldEot}) format('eot'),
    url(${AileronBoldSvg}) format('svg'),
    url(${AileronBoldTtf}) format('truetype'),
    url(${AileronBoldWoff}) format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'AileronBlack';
    src: local('☺'), local('Aileron-Black.eot'),
    url(${AileronBlackEot}) format('eot'),
    url(${AileronBlackSvg}) format('svg'),
    url(${AileronBlackTtf}) format('truetype'),
    url(${AileronBlackWoff}) format('woff');
    font-weight: normal;
    font-style: normal;
}
*,
*::before,
*::after {
    box-sizing: border-box;
}
ul[class],
ol[class] {
    padding: 0;
}
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
    margin: 0;
}
body {
min-height: 100vh;
scroll-behavior: smooth;
    line-height: 1.5;
}
ul[class],
ol[class] {
    list-style: none;
}
a:not([class]) {
    text-decoration-skip-ink: auto;
}
img {
max-width: 100%;
    display: block;
}
article > * + * {
    margin-top: 1em;
}
input,
button,
textarea,
select {
    font: inherit;
}
@media (prefers-reduced-motion: reduce) {
    * {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}
/* ---------------------------------- */
/* ------------  Config  ------------ */
/* ---------------------------------- */
/* ---------------------------------- */
/* --------   Global Styles  -------- */
/* ---------------------------------- */
html {
    font-size: 16px;
}
body {
    font-family: 'AileronRegular';
}
#root {
    min-height: 100vh;
    scroll-behavior: smooth;
    display: flex;
    flex-direction: column;
}
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.scroll-shadow {
    --bgRGB: 255, 255, 255;
    --bg: rgb(var(--bgRGB));
    --bgTrans: rgba(var(--bgRGB), 0);
    --shadow: rgba(0, 0, 0, 0.2);
    background: linear-gradient(var(--bg) 30%, var(--bgTrans)) center top,
        linear-gradient(var(--bgTrans), var(--bg) 70%) center bottom,
        radial-gradient(farthest-side at 50% 0, var(--shadow), rgba(0, 0, 0, 0)) center top,
        radial-gradient(farthest-side at 50% 100%, var(--shadow), rgba(0, 0, 0, 0)) center bottom;
    background-repeat: no-repeat;
    background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
    background-attachment: local, local, scroll, scroll;
}
button,a{
    cursor: pointer;
}
button{
    border-radius: 6px;
    transition: ease-in all 200ms;
}
.btn-primary{
    background:${({ theme }) => theme.colors.bg.tealishBlue};
    border-color:${({ theme }) => theme.colors.bg.tealishBlue};
    padding:12px 24px;
    &:hover{
        background:${({ theme }) => theme.colors.bg.tralishBlue1};
        border-color:${({ theme }) => theme.colors.bg.tralishBlue1};
    }
    &:focus{
        background:${({ theme }) => theme.colors.bg.blue};
        border-color:${({ theme }) => theme.colors.bg.blue};
    }
    &[disabled]{
        opacity: 0.4;
    }
}
.btn-outline-primary{
    border-color:${({ theme }) => theme.colors.bg.tealishBlue};
    color:${({ theme }) => theme.colors.bg.tealishBlue};
    background:none;
    &:hover{
        background:transparent ;
        border-color:${({ theme }) => theme.colors.bg.tralishBlue1};       
        color:${({ theme }) => theme.colors.bg.tralishBlue1};
    }
    &:focus{
        background:transparent ;
        border-color:${({ theme }) => theme.colors.bg.blue};
        color:${({ theme }) => theme.colors.bg.blue};;
    }
}
.btn-tertiary{
    border-color:${({ theme }) => theme.colors.bg.white7}!important;
    background:${({ theme }) => theme.colors.bg.white7}!important;
    color:${({ theme }) => theme.colors.text.lightGrey1}!important;
    &:hover,
    &:focus{
        background:${({ theme }) => theme.colors.bg.white3}!important;
        border-color:${({ theme }) => theme.colors.bg.white3}!important;     
        color: ${({ theme }) => theme.colors.text.lightGrey1}!important;
    }
}

.custom-modal,
.maturity-assessment-modal,
.identify-function,.maturity-assessment-approval-modal{  
    width:calc(100% - 133px);
    right:0;
    left:auto;
    overflow:visible ;
    padding-right:0!important;
    @media (max-width: 1200px) {
        width:calc(100% - 83px);
    }
    .modal-dialog{
        width:100%;
        .modal-body{
            padding:25px 50px 0;
            display:flex;
            flex-direction:column;
        }
    }
    .modal-header{
        padding: 0;
        position: absolute;
        border: none;
        z-index: 1;
        top: 16px;
        left: -19px;
        height: 38px;
        button{
            width:38px;
            height:38px;
            border-radius:50%;
            background-color:${({ theme }) => theme.colors.bg.tealishBlue};
            background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fillRule='evenodd' clipRule='evenodd' d='M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L6 4.58579L10.2929 0.292893C10.6834 -0.0976311 11.3166 -0.0976311 11.7071 0.292893C12.0976 0.683417 12.0976 1.31658 11.7071 1.70711L7.41421 6L11.7071 10.2929C12.0976 10.6834 12.0976 11.3166 11.7071 11.7071C11.3166 12.0976 10.6834 12.0976 10.2929 11.7071L6 7.41421L1.70711 11.7071C1.31658 12.0976 0.683417 12.0976 0.292893 11.7071C-0.0976311 11.3166 -0.0976311 10.6834 0.292893 10.2929L4.58579 6L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z' fill='white'/%3e%3c/svg%3e ");
            opacity: 1;
            padding: 0;
            margin:0;
            &:hover{
                background-color:${({ theme }) => theme.baseColors.primary};
            }
        }
    }
    .modal-footer{
        border:none;
        padding:0 50px 32px;
        form{
            width:100%;
            display:flex;
        }
        button,form{
            margin:0 ;
        }
        button{
            font-family: ${({ theme }) => theme.fontFamily.bold};
            font-size: ${({ theme }) => theme.fontSizes[3]};
            line-height: 1.6875rem;
            font-weight: normal;
            text-transform: none;
            min-height:60px;
            padding:16px 40px;
            transition: background-color 1s;
            &.btn-secondary{
                background:${({ theme }) => theme.baseColors.greyLight};
                border-color:${({ theme }) => theme.baseColors.greyLight};
                color:${({ theme }) => theme.colors.text.darkGrey} ;
                &:hover{
                background:${({ theme }) => theme.colors.bg.darkGrey};
                border-color:${({ theme }) => theme.colors.bg.darkGrey};
                color:${({ theme }) => theme.colors.text.white} ;
                }
            }
            &+button{
                margin-left:16px;
            }
        }
    }
}
.maturity-assessment-approval-modal{
       .modal-dialog{
        width:100%;
        .modal-body{
            padding:0;
        }
        .modal-footer{
            margin-top:auto;
            padding:40px 0 0 0 ;
        }
    }
}
.maturity-assessment-modal{
    width:900px;
    .modal-dialog{
        width:100%;
        .modal-body{
            padding:0;
        }
        .modal-footer{
            margin-top:auto;
            padding:40px 0 0 0 ;
        }
    }
}
.maturity-assessment-modal.audit-review-modal{
    width: 90% !important;
}
.identify-function{
    width:600px;
    .modal-dialog{
        width:100%;
        .modal-body{
            padding:0;
        }
        .modal-footer{
            margin-top:auto;
            padding:40px 0 0 0 ;
        }
    }
}
.customscrollbarhorizontal{
    &::-webkit-scrollbar {
        width: 5px;
        height: 6px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        box-shadow: inset 0 0 6px rgba(#000, 0.3);
        background-color: ${({ theme }) => theme.colors.text.mediumGrey1};
    }
}
select {
    -webkit-appearance: none;
      -moz-appearance: none;
      -o-appearance: none;
      appearance: none;
      &::-ms-expand {
      display: none;
    }
  }
  .react-calendar__tile--now {
    color: white;
    background: #2b388f!important;
}
  .react-date-picker__calendar {
    width: 346px;
  }
  .react-calendar__tile--now:enabled:hover, .react-calendar__tile--now:enabled:focus {
    background: #2b388f;
}
  .react-calendar__month-view__weekdays__weekday,
  .iGfWIb .react-date-picker .react-calendar .react-calendar__tile {
    padding:3px 0;
    flex: 0 0 42px!important;
    margin:0 2px;
}
.iGfWIb .react-date-picker .react-calendar .react-calendar__tile {
    min-height:42px;
    padding:0px ;
}
.react-date-picker .react-calendar .react-calendar__tile--active {
    background: #4182f1;
}
.react-date-picker .react-calendar .react-calendar__tile{
    flex: 0 0 42px!important;
    margin:2px 2px;
    border-radius: 3px;
}
.react-date-picker .react-calendar .react-calendar__tile--active {
    background: #4182f1!important;
}

.noDataMatch{
    z-index: 9999;
    .modal-dialog{
        width:100%;
        @media (min-width: 576px){
    max-width: 400px;
}
        .modal-body{
            padding:50px;
            display:flex;
            flex-direction:column;
            text-align:center;
            justify-content:center;
            align-items: center;
            img{
                width:75px ;
                margin-bottom:10px;
            }
        }
    }
    .modal-header{
        padding: 0;
        position: absolute;
        border: none;
        z-index: 1;
        top: 16px;
        right: 20px;
        height:26px;
        button{
            width:26px;
            height:26px;
            border-radius:50%;
            background-color:${({ theme }) => theme.colors.bg.tealishBlue};
            background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fillRule='evenodd' clipRule='evenodd' d='M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L6 4.58579L10.2929 0.292893C10.6834 -0.0976311 11.3166 -0.0976311 11.7071 0.292893C12.0976 0.683417 12.0976 1.31658 11.7071 1.70711L7.41421 6L11.7071 10.2929C12.0976 10.6834 12.0976 11.3166 11.7071 11.7071C11.3166 12.0976 10.6834 12.0976 10.2929 11.7071L6 7.41421L1.70711 11.7071C1.31658 12.0976 0.683417 12.0976 0.292893 11.7071C-0.0976311 11.3166 -0.0976311 10.6834 0.292893 10.2929L4.58579 6L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z' fill='white'/%3e%3c/svg%3e ");
            background-size:10px;
            opacity: 1;
            padding: 0;
            margin:0;
            &:hover{
                background-color:${({ theme }) => theme.baseColors.primary};
                
            }
        }
    }
}
.custom-modal + .modal-backdrop{
    
    z-index: 9999;

}
.custom-modal + .modal-backdrop{
    
    z-index: 9999;

}
/* .custom-modal.assessment-modal{
    transform: translateX(100%);
  -webkit-transform: translateX(100%);
} */
.custom-modal.assessment-modal.left{
     animation: slide-in  1s forwards;
     /* animation-direction: reverse; */
  -webkit-animation: slide-in 1s forwards;
}
.custom-modal.assessment-modal.right{

     animation: slide-out 1s forwards;
  -webkit-animation: slide-out 1s forwards;
}
@keyframes slide-in {
  0% {
    -webkit-transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0%);
  }
}

@-webkit-keyframes slide-in {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}

@-webkit-keyframes slide-out {
  0% {
    -webkit-transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(100%);
  }
}
.custom-modal.assessment-modal .modal-header{
    top:45%;
    button{
        background-image:url(${backArrow1});
        transform: rotate(180deg);
    }
}
.tippy-tooltip.dark-theme{
    padding:0px;
        border: 1px solid rgb(222, 229, 235);
        background-color:#ffffff;
        right:40px;
    .enter{
        background-color:#ffffff!important;
    }
} 


input::placeholder {
font-family: ${({ theme }) => theme.fontFamily.regular};
font-weight: 400 !important;

}
.workstream-modal{

    width:602px;

    z-index:1200;

    .modal-body{

        padding: 40px 50px 30px !important;

        overflow:hidden;

    }

}

.centered-modal{
    overflow:visible ;   
    border-radius:9px;
    .modal-dialog-centered{
        max-width:610px;
    }
    .modal-body{
        padding:42px 86px;
    }
    .modal-header{
        padding: 0;
        position: absolute;
        border: none;
        z-index: 1;
        top: -19px;
        right: -19px;
        height: 38px;
        button{
            width:38px;
            height:38px;
            border-radius:50%;
            background-color:${({ theme }) => theme.colors.bg.tealishBlue};
            background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fillRule='evenodd' clipRule='evenodd' d='M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L6 4.58579L10.2929 0.292893C10.6834 -0.0976311 11.3166 -0.0976311 11.7071 0.292893C12.0976 0.683417 12.0976 1.31658 11.7071 1.70711L7.41421 6L11.7071 10.2929C12.0976 10.6834 12.0976 11.3166 11.7071 11.7071C11.3166 12.0976 10.6834 12.0976 10.2929 11.7071L6 7.41421L1.70711 11.7071C1.31658 12.0976 0.683417 12.0976 0.292893 11.7071C-0.0976311 11.3166 -0.0976311 10.6834 0.292893 10.2929L4.58579 6L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z' fill='white'/%3e%3c/svg%3e ");
            opacity: 1;
            padding: 0;
            margin:0;
            &:hover{
                background-color:${({ theme }) => theme.baseColors.primary};
                
            }
        }
    }
}
    .centered-modal_v2{
    background:rgba(12 12 12 / 51%);
    .modal-body {
    padding: 40px !important;
}
`;
export default GlobalStyles;
