import styled from "styled-components";
import whiteBackground from "../../ui/images/homeimg.jpg";

const MainContainer = styled.div`
  height: 100vh;
  background-image: url(${whiteBackground});
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  /* .background-image {
    position: absolute;
    right: 0;
    height: 100vh;
    bottom: 0;
    top: 0;
    z-index: 9;
    @media screen and (max-width: 1600px) {
      width: 800px;
    }
    @media screen and (max-width: 1200px) {
      width: 600px;
    }
  } */
`;
const InnerContainer = styled.div`
  padding-left: 100px;
  padding-top: 57px;
  @media screen and (max-width: 1200px) {
    padding-left: 50px;
  }
  .cybrize-logo {
    padding-bottom: 113px;
  }
`;
const InnerTextContainer = styled.div`
  width: 50%;
  z-index: 5;
  position: relative;
`;
const InnerTextHeading1 = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.black};
  font-size: 48px;
  line-height: 69px;
  color: ${({ theme }) => theme.colors.text.white};

  @media screen and (max-width: 1450px) {
    font-size: 40px;
    line-height: 59px;
  }
  @media screen and (max-width: 1200px) {
    font-size: 30px;
    line-height: 49px;
  }
  span {
    background-color: ${({ theme }) => theme.colors.bg.tealishBlue};
    padding-left: 9px;
    padding-right: 9px;
  }
`;
const InnerTextHeading2 = styled.h1`
  font-family: ${({ theme }) => theme.fontFamily.black};
  font-size: 48px;
  line-height: 69px;
  color: #0f0f54;
  margin-bottom: 8px;
  @media screen and (max-width: 1450px) {
    font-size: 40px;
    line-height: 59px;
  }
  @media screen and (max-width: 1200px) {
    font-size: 30px;
    line-height: 49px;
  }
`;
const InnerTextParagraph = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: ${({ theme }) => theme.fontSizes[4]};
  line-height: 33px;
  color: #0f0f54;
  margin-bottom: 58px;
  @media screen and (max-width: 1450px) {
    font-size: ${({ theme }) => theme.fontSizes[2]};
    line-height: 25px;
  }
  @media screen and (max-width: 1200px) {
    font-size: ${({ theme }) => theme.fontSizes[1]};
    line-height: 22px;
  }
`;

const InnerTextAccount = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: ${({ theme }) => theme.fontSizes[4]};
  line-height: 33px;
  color: #0f0f54;
  margin-bottom: 58px;
  margin-top: 20px;
  @media screen and (max-width: 1450px) {
    font-size: ${({ theme }) => theme.fontSizes[2]};
    line-height: 25px;
  }
  @media screen and (max-width: 1200px) {
    font-size: ${({ theme }) => theme.fontSizes[1]};
    line-height: 22px;
  }
`;
const HomePageButtonContainer = styled.div`
  .signup-button {
    background-color: ${({ theme }) => theme.colors.bg.white};
  }
  button {
    font-family: ${({ theme }) => theme.fontFamily.bold};
    font-size: ${({ theme }) => theme.fontSizes[4]};
    line-height: 27px;
    text-transform: none;
    min-height: 59px;
    padding: 16px 48px;
    transition: background-color 1s;

    &.btn-secondary {
      background: ${({ theme }) => theme.baseColors.greyLight};
      border-color: ${({ theme }) => theme.baseColors.greyLight};
      color: ${({ theme }) => theme.colors.text.darkGrey};
      &:hover {
        background: ${({ theme }) => theme.colors.bg.darkGrey};
        border-color: ${({ theme }) => theme.colors.bg.darkGrey};
        color: ${({ theme }) => theme.colors.text.white};
      }
    }
    & + button {
      margin-left: 22px;
    }
    @media screen and (max-width: 1200px) {
      padding: 8px 36px;
      min-height: 49px;
    }
  }
`;
const ButtonText = styled.a`
  text-decoration: underline;
  font-family: ${({ theme }) => theme.fontFamily.bold};
  margin-left: 5px;
`;
export {
  MainContainer,
  InnerContainer,
  InnerTextContainer,
  InnerTextHeading1,
  InnerTextHeading2,
  InnerTextParagraph,
  HomePageButtonContainer,
  ButtonText,
  InnerTextAccount,
};
