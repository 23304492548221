import { takeLatest, put, fork, call } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import http from "../../utils/http.service";
import config from "../../utils/request.config";
import { ErrorType } from "../../model/common";
import { userProfileGet } from "../../utils/serviceurl";
import { getTokenPermissionAction } from "./getTokenPermissionSlice";

export const fetchUserProfileData = async () =>
  http.GET(config.getConfig("", userProfileGet, ""));

function* onFetchUserProfileAsync() {
  try {
    const response: AxiosResponse<any> = yield call(fetchUserProfileData);
    if (response.status === 200) {
      // console.log(response, "response-onFetchUserProfileAsync");
      yield put(
        getTokenPermissionAction.getProfileSuccess({
          data: response.data,
          status: response.status,
        }),
      );
    }
  } catch (error) {
    yield put(
      getTokenPermissionAction.getProfileFailure({
        error: error as ErrorType,
      }),
    );
  }
}

function* onFetchUserProfileDataList() {
  yield takeLatest(
    getTokenPermissionAction.getProfile,
    onFetchUserProfileAsync,
  );
}

export const profileUserNameSaga = [fork(onFetchUserProfileDataList)];
