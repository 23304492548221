import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorType } from "../../../../model/common";
import { GetImplementaionList } from "../../../../model/Implementaion";

export const initialState: GetImplementaionList = {
  loading: false,
  implementationDetails: [],
  status: 0,
  error: false,
};

const implementaionFetchSlice = createSlice({
  name: "implementaionFetchSlice",
  initialState,
  reducers: {
    reset: () => initialState,
    implementaionFetchRequest(
      state: GetImplementaionList,
      action: PayloadAction<{ assesmentid: string }>,
    ) {
      state.loading = true;
    },
    implemenationFetchSuccess(
      state: GetImplementaionList,
      action: PayloadAction<{ data: any; status: number }>,
    ) {
      state.loading = false;
      state.implementationDetails = action.payload.data;
      state.status = action.payload.status;
    },
    implemenationFetchFailure(
      state: GetImplementaionList,
      action: PayloadAction<{ error: ErrorType }>,
    ) {
      state.loading = false;
      state.error = true;
      state.status = action.payload.error.response.status;
    },
  },
});

export const implementationFetchAction = implementaionFetchSlice.actions;
export const implementationFetchReducer = implementaionFetchSlice.reducer;

export default implementationFetchReducer;
