import React from "react";
import { Line } from "react-chartjs-2";
import styled from "styled-components";
import moment from "moment";

import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle,
} from "chart.js";

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle,
);
const StyledLineDetails = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.border.default};
  margin: 0;
  border-radius: 10px;
  overflow: hidden;
  padding: 28px 18px 20px;
  min-height: 500px;
  background: ${({ theme }) => theme.colors.bg.white}; ;
`;
const Heading = styled.div`
  display: flex;
  padding: 0 0 21px 0;
  font-family: ${({ theme }) => theme.fontFamily.bold};
  font-size: ${({ theme }) => theme.fontSizes[2]};
  line-height: ${({ theme }) => theme.lineHeight[3]};
  font-weight: normal;
  color: ${({ theme }) => theme.colors.text.black};
`;
const LineChartHolder = styled.div`
  width: 100%;
  height: 360px;
`;
const SelectGrp = styled.div`
  position: relative;
  display: flex;
  align-items: Center;
  border: 1px solid ${({ theme }) => theme.colors.border.bluishCyan};
  height: 40px;
  font-family: ${({ theme }) => theme.fontFamily.bold};
  font-size: ${({ theme }) => theme.fontSizes[0]};
  color: ${({ theme }) => theme.colors.text.black};
  padding: 0 12px;
  border-radius: 4px;
  .BlueDot,
  .GreenDot,
  .BlueDot1 {
    width: 6px;
    height: 6px;
    border-radius: 50px;
    background: ${({ theme }) => theme.colors.bg.blue6};
    margin-right: 10px;
  }
  .GreenDot {
    background: ${({ theme }) => theme.colors.border.green01};
  }
  .BlueDot1 {
    background: ${({ theme }) => theme.colors.bg.blue5};
  }
`;
const FilterGroup = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  padding-bottom: 13px;
  justify-content: flex-end;
  select {
    box-shadow: none !important;
    border: 1px solid ${({ theme }) => theme.colors.border.bluishCyan};
    height: 40px;
    padding: 0 0 0 0;
    background-position: right 16px center;
    font-size: ${({ theme }) => theme.fontSizes[0]};
    text-indent: 27px;
    font-family: ${({ theme }) => theme.fontFamily.bold};

    &:focus {
      border-color: ${({ theme }) => theme.colors.border.bluishCyan};
    }
  }
`;
function LineChart({ assessmentTrends }: any) {
  type DateArrayType = {
    value: string;
    label: string;
  };

  type ValueArrayType = {
    label: string;
    data: [];
    backgroundColor: [];
    borderColor: [];
    borderWidth: number;
  };
  const dateArray: DateArrayType[] = assessmentTrends?.map(
    (items: any, index: any) => {
      return { value: index, label: items.date };
    },
  );

  const valueArray: ValueArrayType[] = assessmentTrends?.map(
    (items: any, index: any) => {
      return {
        label: " Value",
        data: items.values,
        backgroundColor: "#ffffff",
        borderColor:
          index === 1 ? "#0AB062" : index === 2 ? "#9A4AFF" : "#4286F9",
        borderWidth: 2.5,
      };
    },
  );

  return (
    <StyledLineDetails>
      {assessmentTrends?.length > 0 && (
        <>
          <Heading>Assessment Trends</Heading>
          <FilterGroup>
            {dateArray.map((item: any, index: any) => {
              return (
                <SelectGrp>
                  <div
                    className={
                      index === 1
                        ? "GreenDot"
                        : index === 2
                        ? "BlueDot"
                        : "BlueDot1"
                    }
                  />
                  <div>{moment(item.label).format("DD MMM YYYY")}</div>
                </SelectGrp>
              );
            })}
          </FilterGroup>
          <LineChartHolder>
            <Line
              data={{
                labels: assessmentTrends[0].labels,
                datasets: valueArray,
              }}
              options={{
                maintainAspectRatio: false,

                scales: {
                  y: {
                    min: "-1.3",
                    max: "5",
                    display: false,
                  },
                },
                plugins: {
                  legend: {
                    display: false,
                    labels: {
                      color: "rgb(104, 255, 99)",
                    },
                  },
                },
              }}
            />
          </LineChartHolder>
        </>
      )}
    </StyledLineDetails>
  );
}

export { LineChart };
