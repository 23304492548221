const colors = {
  // Primary Colors
  blue: "#2B388F",
  bluishCyan: "#DEE5EB",
  bluishCyan1: "#DDE5E9",
  tealishBlue: "#4182F1",
  tralishBlue1: "#9197C0",
  ghostWhite: "#F6F8FF",
  blue2: "#4354C5",
  blue3: "#3377ff",
  tralishBlue2: "#F1F3FF",

  // Secondary Color
  white: "#FFFFFF",
  white1: "#FAFBFC",
  white2: "#F2F2F5",
  white3: "#F5F6FD",
  white4: "#FFFDFD",
  white5: "#FDFCFF",
  white6: "#FBFCFF",
  white7: "#ECECED",

  // Black
  black: "#000000",
  black2: "#4F4F4F",
  black3: "#090808",
  black4: "#787486",
  // Red
  red: "#FF0000",
  red2: "#FEEBE9",
  red3: "#EE786B",
  red4: "#FFF4F3",
  // Grey
  darkGrey: "#3A3A3C",
  darkGrey1: "#B5BABE",
  darkGrey2: "#404142",
  lightGrey: "#F2F2F5",
  lightGrey1: "#505A65",
  mediumGrey1: "#ADB1B7",
  darkGreyShade: "#C7C9D9",
  mediumGrey: "rgba(80, 90, 101, 0.47);",
  lightGrey2: "#ECECED",
  grey3: "#C4C6D0",
  grey4: "#505a65",

  // Green
  green: "#21C276",
  green01: "#0AB062",
  green2: "#E3F9EE",

  // blue Shade
  blueShade: "#E3EDFF",
  blue4: "#F4EDFE",
  blue5: "#4286F9",
  blue6: "#9A4AFF",

  // purple
  purpleShade: "#B5C2D5",
  purple: "#9A4AFF",
  purple1: "#F4ECFF",
};

export default colors;
