import { ReactElement } from "react";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import Select from "react-select";

const durationOptions = [
  { value: "Quarterly", label: "Quarterly" },
  { value: "Yearly", label: "Yearly" },
];

const RoadMapHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 11px;
  padding-top: 19px;
`;
const RoadMapHeading = styled.h1`
  font-family: ${({ theme }) => theme.fontFamily.bold};
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes[4]};
  line-height: ${({ theme }) => theme.lineHeight[5]};
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.text.black};
`;
const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  button {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 24px;
    background: ${({ theme }) => theme.colors.bg.tealishBlue};
    border-radius: 6px;
    font-family: ${({ theme }) => theme.fontFamily.bold};
    font-style: normal;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: ${({ theme }) => theme.fontSizes[1]};
    line-height: ${({ theme }) => theme.lineHeight[17]};
    letter-spacing: 0.005em;
    color: ${({ theme }) => theme.colors.text.white};
    &:focus {
      box-shadow: none;
    }
  }
`;
const DropdownSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15.5px 8px 15.5px 16px;
  min-width: 159px;
  height: 45px;
  border: 1px solid ${({ theme }) => theme.colors.border.mediumGrey1};
  border-radius: 6px;
  margin-right: 16px;
  .form-select {
    border: unset;
    padding: 0 32px 0 0;
    font-family: ${({ theme }) => theme.fontFamily.bold};
    font-style: normal;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: ${({ theme }) => theme.fontSizes[0]};
    line-height: ${({ theme }) => theme.lineHeight[0]};
    color: ${({ theme }) => theme.colors.text.black};
    cursor: pointer;
  }
  .form-select:focus {
    box-shadow: none;
  }
`;
const DropdownHeading = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-size: ${({ theme }) => theme.fontSizes[0]};
  line-height: ${({ theme }) => theme.lineHeight[0]};
  color: ${({ theme }) => theme.colors.text.lightGrey1};
  margin-right: 6px;
`;
const CustomSelect = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.text.black};
  font-size: ${({ theme }) => theme.fontSizes[1]};

  [class*="-control"] {
    font-family: ${({ theme }) => theme.fontFamily.bold};
    font-style: normal;
    font-size: ${({ theme }) => theme.fontSizes[0]};
    line-height: ${({ theme }) => theme.lineHeight[0]};
    color: ${({ theme }) => theme.colors.text.black};
    cursor: pointer;
    box-shadow: none !important;
    border: unset;
    &:hover {
      border-color: ${({ theme }) => theme.baseColors.primary};
      border: unset;
    }
  }
  [class*="-ValueContainer"] {
    padding: 0;
    div {
      padding: 0;
      margin: 0;
    }
  }
  [class*="-Input"] {
    position: absolute;
  }
  [class*="-indicatorContainer"] {
    padding: 0;
    svg {
      width: 18px;
      height: 18px;
      path {
        fill: ${({ theme }) => theme.baseColors.black};
      }
    }
  }
  .css-b62m3t-container {
    display: grid !important;
    width: 100%;
    .css-1okebmr-indicatorSeparator {
      display: none;
    }
  }
  .css-26l3qy-menu {
    z-index: 10;
    font-family: ${({ theme }) => theme.fontFamily.bold};
    font-style: normal;
    font-size: ${({ theme }) => theme.fontSizes[15]};
    line-height: ${({ theme }) => theme.lineHeight[0]};
    color: ${({ theme }) => theme.colors.text.black};
    margin: none;
    padding: 0px;
  }
`;
type HeaderProps = {
  title: string;
  dropDown: boolean;
  buttonText: string;
  durationTimeline: string;
  setDurationTimeline: (data: string) => void;
};
function HeaderInnerPages({
  title,
  dropDown,
  buttonText,
  durationTimeline,
  setDurationTimeline,
}: HeaderProps): ReactElement {
  return (
    <RoadMapHeader>
      <RoadMapHeading>{title}</RoadMapHeading>
      <ButtonContainer>
        {dropDown ? (
          <DropdownSection>
            <DropdownHeading>Duration</DropdownHeading>
            <CustomSelect>
              <Select
                onChange={(ev: any) => {
                  setDurationTimeline(ev.value);
                }}
                options={durationOptions}
                getOptionLabel={(e: any) => (e ? e.label : "")}
                getOptionValue={(e: any) => (e ? e.value : "")}
                placeholder={durationTimeline}
              />
            </CustomSelect>
          </DropdownSection>
        ) : (
          []
        )}
        <Button variant="primary" type="submit" disabled>
          {buttonText}
        </Button>
      </ButtonContainer>
    </RoadMapHeader>
  );
}

export { HeaderInnerPages };
