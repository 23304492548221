import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Modal } from "react-bootstrap";
import { Button, Image } from "rebass";
import styled from "styled-components";
import SessionTimeout from "../../images/session-timeout.svg";

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    margin-bottom: 37px;
  }
  h2 {
    text-align: center;
    font-family: ${({ theme }) => theme.fontFamily.bold};
    font-style: normal;
    font-weight: normal;
    font-size: ${({ theme }) => theme.fontSizes[18]};
    line-height: ${({ theme }) => theme.lineHeight[5]};
    color: #000000;
    margin-bottom: 10px;
  }
  h3 {
    text-align: center;
    font-family: ${({ theme }) => theme.fontFamily.regular};
    font-style: normal;
    font-weight: normal;
    font-size: ${({ theme }) => theme.fontSizes[16]};
    line-height: ${({ theme }) => theme.lineHeight[3]};
    color: #000000;
    margin-bottom: 40px;
  }
`;
const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  .loginButton {
    padding: 16px 48px;
    height: 59px;
    border-radius: 6px;
    background: ${({ theme }) => theme.colors.text.tealishBlue};
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: ${({ theme }) => theme.fontFamily.bold};
    font-style: normal;
    font-size: ${({ theme }) => theme.fontSizes[3]};
    line-height: ${({ theme }) => theme.lineHeight[3]};
    color: ${({ theme }) => theme.colors.text.white};
    &:hover {
      background: ${({ theme }) => theme.colors.bg.tralishBlue1};
      border-color: ${({ theme }) => theme.colors.bg.tralishBlue1};
    }
    &:focus {
      background: ${({ theme }) => theme.colors.bg.blue};
      border-color: ${({ theme }) => theme.colors.bg.blue};
    }
  }
  button:focus {
    box-shadow: none;
  }
`;
function SessionExpiredModal({ show, handleClose }: any) {
  const { loginWithRedirect } = useAuth0();
  return (
    <>
      <Modal
        show={show}
        closeTimeoutMS={500}
        onHide={handleClose}
        centered
        className="centered-modal"
      >
        {/* Popup close */}
        {/* <Modal.Header closeButton /> */}
        <Modal.Body>
          <MainContainer>
            <Image src={SessionTimeout} alt="session timeout" />
            <h2>SESSION HAS EXPIRED</h2>
            <h3>
              The page you are looking for might have been removed, had its name
              changed or is temporarily unavailable
            </h3>
            <ButtonsContainer>
              <Button
                variant="primary"
                className="loginButton"
                onClick={() => {
                  sessionStorage.removeItem("modalStatus");
                  loginWithRedirect({ appState: { returnTo: "" } });
                }}
              >
                Login
              </Button>
            </ButtonsContainer>
          </MainContainer>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SessionExpiredModal;
