import { ReactElement, useEffect } from "react";
import styled, { css } from "styled-components";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { TechnologyWSModel } from "../../../../model/wsTechnology";

const StyledTechnologyRecommendationList = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 20px;
  row-gap: 20px;
  width: 100%;
`;
type TechnologyRecommendationListHolderProps = {
  active?: boolean;
};
const TechnologyRecommendationListHolder = styled.section<TechnologyRecommendationListHolderProps>`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.border.bluishCyan};
  border-radius: 8px;
  padding: 16px;
  cursor: pointer;
  transition: ease-in all 200ms;
  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.bg.tealishBlue};
  }
  ${({ active }) =>
    active &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.bg.tealishBlue};
    `}
`;
const Heading = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.medium};
  font-size: ${({ theme }) => theme.fontSizes[1]};
  line-height: ${({ theme }) => theme.lineHeight[2]};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.baseColors.black};
  margin-bottom: 8px;
`;
const RecommendationWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 8px;
  row-gap: 8px;
`;
const RecommendationItem = styled.div`
  display: flex;
  background: ${({ theme }) => theme.colors.bg.tralishBlue2};
  color: ${({ theme }) => theme.baseColors.primary};
  padding: 0 6px;
  font-size: ${({ theme }) => theme.fontSizes[0]};
  line-height: ${({ theme }) => theme.lineHeight[2]};
`;
const RecommendationList = styled.div`
  display: flex;
  line-height: ${({ theme }) => theme.lineHeight[2]};
  font-family: ${({ theme }) => theme.fontFamily.bold};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  padding-left: 2px;
`;

const RecommendationDescription = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.text.darkGrey2};
  font-family: ${({ theme }) => theme.fontFamily.light};
  font-size: ${({ theme }) => theme.fontSizes[1]};
  line-height: ${({ theme }) => theme.lineHeight[3]};
  font-weight: ${({ theme }) => theme.fontWeights.light};
  margin-top: 14px;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
type TechnologyRecommendationListProps = {
  technologyRecommendationList: TechnologyWSModel[] | undefined;
  setSelectedId: any;
  selectedId: string;
  // setSelectedTec: any;
  setRecommendationList: any;
};

function TechnologyRecommendationList({
  technologyRecommendationList,
  setSelectedId,
  selectedId,
  setRecommendationList,
}: // setSelectedTec,
TechnologyRecommendationListProps): ReactElement {
  const TechSubmit = useSelector((state: RootState) => state.workStreamTech);

  useEffect(() => {
    setRecommendationList(TechSubmit.data);
  }, [TechSubmit.data]);

  const updateOnClick = (_id: string) => {
    const tempArray = technologyRecommendationList?.map((item: any) => {
      if (item._id === _id) {
        return { ...item, isSelected: true };
      }
      return { ...item, isSelected: false };
    });
    setRecommendationList(tempArray);
  };

  useEffect(() => {
    if (technologyRecommendationList?.length === 1) {
      const technologyData: any = technologyRecommendationList;
      setSelectedId(technologyData?._id);
      // setSelectedTec(technologyData);
    }
  }, [technologyRecommendationList]);

  return (
    <StyledTechnologyRecommendationList>
      {technologyRecommendationList
        ? technologyRecommendationList.map((item: any, index: number) => {
            return (
              <TechnologyRecommendationListHolder
                key={item._id}
                onClick={() => {
                  updateOnClick(item._id);
                  setSelectedId(item._id);
                  // setSelectedTec(item.technologies);
                }}
                active={item._id === selectedId || item.isSelected}
              >
                {/* Heading */}
                <Heading>Option Available {index + 1}</Heading>
                <RecommendationWrapper>
                  <RecommendationItem>
                    Domain |
                    <RecommendationList>{item?.domain}</RecommendationList>
                    {/* <RecommendationList>{item.technologies}</RecommendationList>
                <Count>+{item.technologyCount}</Count> */}
                  </RecommendationItem>
                  {/* Technology type */}
                  <RecommendationItem>
                    Technology |
                    <RecommendationList>
                      {item?.otherTechnology && item?.otherTechnology !== ""
                        ? item?.otherTechnology
                        : item?.technologyName}
                    </RecommendationList>
                    {/* <RecommendationList>{item.technologies}</RecommendationList>
                <Count>+{item.technologyCount}</Count> */}
                  </RecommendationItem>

                  {/* Resources type */}
                  {item?.reference && item?.reference !== "" ? (
                    <RecommendationItem>
                      References |
                      <RecommendationList>{item?.reference}</RecommendationList>
                      {/* {item.resources && item.resources.length > 2 ? (
                    <>
                      <RecommendationList>
                        {item.resources[0]} , {item.resources[1]}
                      </RecommendationList>
                      <Count>, +{item.resources.length - 2}</Count>
                    </>
                  ) : item.resources && item.resources.length === 2 ? (
                    <RecommendationList>
                      {item.resources[0]} , {item.resources[1]}
                    </RecommendationList>
                  ) : (
                    <RecommendationList> {item.resources}</RecommendationList>
                  )} */}
                    </RecommendationItem>
                  ) : null}
                </RecommendationWrapper>

                {/* Description */}
                <RecommendationDescription>
                  {item?.description}
                </RecommendationDescription>
              </TechnologyRecommendationListHolder>
            );
          })
        : null}
    </StyledTechnologyRecommendationList>
  );
}

export { TechnologyRecommendationList };
