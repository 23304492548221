import { takeLatest, put, fork, call } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import http from "../../../utils/http.service";
import config from "../../../utils/request.config";
import { ErrorType } from "../../../model/common";
import { assessmentTypeFrameworkGetApi } from "../../../utils/serviceurl";
import { assessmentTypeFetchAction } from "./assesmenttypeslice";

export const fetchAssesmentTypeDetails = async () =>
  http.GET(config.getConfig("", assessmentTypeFrameworkGetApi, ""));

function* onFetchAssessmentTypeFrameworkListAsync() {
  try {
    const response: AxiosResponse<any> = yield call(fetchAssesmentTypeDetails);
    if (response.status === 200) {
      yield put(
        assessmentTypeFetchAction.assessmentTypeFetchSuccess({
          data: response.data,
          status: response.status,
        }),
      );
    }
  } catch (error) {
    yield put(
      assessmentTypeFetchAction.assessmentTypeFetchFailure({
        error: error as ErrorType,
      }),
    );
  }
}

function* onAssessmentTypeFrameworkDataList() {
  yield takeLatest(
    assessmentTypeFetchAction.assessmentTypeFetchRequest,
    onFetchAssessmentTypeFrameworkListAsync,
  );
}

export const assessmentTypeFrameworkSaga = [
  fork(onAssessmentTypeFrameworkDataList),
];
