import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorType } from "../../model/common";

interface UserInfo {
  image: object;
  nickname: string;
  name: string;
  picture: string;
  updated_at: string;
  email: string;
  email_verified: boolean;
  sub: string;
  org_id: string;
}

export interface GetAbout {
  loader: boolean;
  permissions: string | undefined;
  profileName: string | undefined | null;
  error: boolean;
  status: number;
  role: string;
  userRolesArray: string[];
  userInfoArray: UserInfo | null; // Update this to be a single object
}

export const initialState: GetAbout = {
  loader: true,
  permissions: undefined,
  profileName: null,
  error: false,
  status: 0,
  role: "",
  userRolesArray: [],
  userInfoArray: null, // Initialize with null or a default UserInfo object if preferred
};

type Profile = {
  profileName: string;
};

const getTokenPermissionSlice = createSlice({
  name: "getTokenPermission",
  initialState,
  reducers: {
    reset: () => initialState,
    tokenSave(state: GetAbout, action: PayloadAction<any>) {
      state.loader = false;
      state.permissions = action.payload;
    },
    getProfile(state: GetAbout) {
      state.loader = false;
    },
    getProfileSuccess(
      state: GetAbout,
      action: PayloadAction<{ data: Profile; status: number }>,
    ) {
      state.loader = false;
      state.profileName = action.payload.data.profileName;
      state.status = action.payload.status;
    },
    getProfileFailure(
      state: GetAbout,
      action: PayloadAction<{ error: ErrorType }>,
    ) {
      state.loader = false;
      state.error = true;
      state.status = action.payload.error.response.status;
    },
    setRole(state: GetAbout, action: PayloadAction<string>) {
      state.role = action.payload;
    },
    setUserRolesArray(state: GetAbout, action: PayloadAction<any>) {
      state.userRolesArray = action.payload;
    },
    setUserInfoArray(state: GetAbout, action: PayloadAction<UserInfo>) {
      // Update to UserInfo type
      state.userInfoArray = action.payload;
    },
  },
});

export const getTokenPermissionAction = getTokenPermissionSlice.actions;
export const getTokenPermissionReducer = getTokenPermissionSlice.reducer;

export default getTokenPermissionReducer;
