import { takeLatest, put, fork, call } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { PayloadAction } from "@reduxjs/toolkit";
import http from "../../utils/http.service";
import config from "../../utils/request.config";
import { maturityAssessmentTypeAction } from "./maturityAssessmentTypeSlice";
import {
  maturityAssessmentFileUploadAction,
  maturityAssessmentSubmitAction,
} from "./maturityAssessmentSubmitSlice";
import {
  maturityAssessmentType,
  maturityAssessmentSubmit,
  assessmentFileUpload,
} from "../../utils/serviceurl";

export const fetchMaturityAssessment = async (data: any) =>
  http.POST(config.postConfig(data, "", maturityAssessmentType, ""));

function* onMaturityAssessmentTypeAsync(action: PayloadAction<any>) {
  try {
    const response: AxiosResponse<any> = yield call(
      fetchMaturityAssessment,
      action.payload,
    );
    if (response.status === 200) {
      yield put(
        maturityAssessmentTypeAction.maturityAssessmentSuccess({
          data: response.data.data,
          status: response.status,
        }),
      );
    }
  } catch (error) {
    // console.log(error);
  }
}

function* onMaturityAssessmentType() {
  yield takeLatest(
    maturityAssessmentTypeAction.maturityAssessmentRequest,
    onMaturityAssessmentTypeAsync,
  );
}

export const fetchMaturityAssessmentSubmit = async (data: any) =>
  http.POST(config.postConfig(data, "", maturityAssessmentSubmit, ""));

function* onMaturityAssessmentSubmitAsync(action: PayloadAction<any>) {
  try {
    const response: AxiosResponse<any> = yield call(
      fetchMaturityAssessmentSubmit,
      action.payload,
    );
    if (response.status === 200) {
      yield put(
        maturityAssessmentSubmitAction.maturityAssessmentSubmitSuccess({
          data: response.data.data,
          status: response.status,
        }),
      );
    }
  } catch (error) {
    // console.log(error);
  }
}

function* onMaturityAssessmentSubmit() {
  yield takeLatest(
    maturityAssessmentSubmitAction.maturityAssessmentSubmitRequest,
    onMaturityAssessmentSubmitAsync,
  );
}

export const fetchMaturityAssessmentFileUpload = async (data: any) =>
  http.POST(config.postConfig(data, "", assessmentFileUpload, ""));

function* onMaturityAssessmentFileUploadAsync(action: PayloadAction<any>) {
  try {
    const response: AxiosResponse<any> = yield call(
      fetchMaturityAssessmentFileUpload,
      action.payload,
    );
    if (response.status === 200) {
      yield put(
        maturityAssessmentFileUploadAction.maturityAssessmentFileUploadSuccess({
          data: response.data.data,
          status: response.status,
        }),
      );
    }
  } catch (error) {
    // console.log(error);
  }
}

function* onMaturityAssessmentFileUpload() {
  yield takeLatest(
    maturityAssessmentFileUploadAction.maturityAssessmentFileUploadRequest,
    onMaturityAssessmentFileUploadAsync,
  );
}

export const maturityAssessmentSaga = [
  fork(onMaturityAssessmentType),
  fork(onMaturityAssessmentSubmit),
  fork(onMaturityAssessmentFileUpload),
];
