import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorType, JobDesc, OrganizationProfile } from "../../../model/common";
import { GetJobDesc } from "../../../model/wSTalent";

export interface JobDesProps {
  loading: boolean;
  data: JobDesc[];
  status: number;
  error: boolean;
}

export interface OrgProfile {
  loading: boolean;
  data: OrganizationProfile[];
  status: number;
  error: boolean;
}
export const initialState = {
  loading: false,
  data: [],
  status: 0,
  error: false,
};

const workStreamJobDescriptionSlice = createSlice({
  name: "workStreamJobDescriptionDetails",
  initialState,
  reducers: {
    reset: () => initialState,
    workStreamJobDescriptionRequest(
      state: JobDesProps,
      action: PayloadAction<GetJobDesc>,
    ) {
      state.loading = true;
    },
    workStreamJobDescriptionSuccess(
      state: JobDesProps,
      action: PayloadAction<{ data: JobDesc[]; status: number }>,
    ) {
      state.loading = false;
      state.data = action.payload.data;
      state.status = action.payload.status;
    },
    workStreamJobDescriptionFailure(
      state: JobDesProps,
      action: PayloadAction<{ error: ErrorType }>,
    ) {
      state.loading = false;
      state.error = true;
      state.status = action.payload.error.response.status;
    },
  },
});

const orgChartUserListSlice = createSlice({
  name: "orgProfiles",
  initialState,
  reducers: {
    reset: () => initialState,
    getProfilesRequest(state: OrgProfile) {
      state.loading = true;
    },
    orgProfileListSuccess(
      state: OrgProfile,
      action: PayloadAction<{ data: OrganizationProfile[]; status: number }>,
    ) {
      state.loading = false;
      state.data = action.payload.data;
      state.status = action.payload.status;
    },
    orgProfileListFailure(
      state: OrgProfile,
      action: PayloadAction<{ error: ErrorType }>,
    ) {
      state.loading = false;
      state.error = true;
      state.status = action.payload.error.response.status;
    },
  },
});
const orgMatchedProfileSlice = createSlice({
  name: "orgMatchedProfiles",
  initialState,
  reducers: {
    reset: () => initialState,
    getMatchedProfilesRequest(state: any, action: PayloadAction<any>) {
      state.loading = true;
    },
    orgMatchedProfileListSuccess(
      state: OrgProfile,
      action: PayloadAction<{ data: any; status: number }>,
    ) {
      state.loading = false;
      state.data = action.payload.data;
      state.status = action.payload.status;
    },
    orgMatchedProfileListFailure(
      state: OrgProfile,
      action: PayloadAction<{ error: ErrorType }>,
    ) {
      state.loading = false;
      state.error = true;
      state.status = action.payload.error.response.status;
    },
  },
});

export const orgMatchedProfileListAction = orgMatchedProfileSlice.actions;
export const orgMatchedProfileReducer = orgMatchedProfileSlice.reducer;

export const workStreamJobDescriptionAction =
  workStreamJobDescriptionSlice.actions;
export const orgChartUserListAction = orgChartUserListSlice.actions;
export const workStreamJobDescriptionDetailsReducer =
  workStreamJobDescriptionSlice.reducer;
export const orgProfilesReducer = orgChartUserListSlice.reducer;

export default {
  workStreamJobDescriptionDetailsReducer,
  orgProfilesReducer,
  orgMatchedProfileReducer,
};
