const fontFamilies = {
  thin: "AileronThin",
  light: "AileronLight",
  medium: "AileronSemiBold",
  regular: "AileronRegular",
  bold: "AileronBold",
  black: "AileronBlack",
};

export default fontFamilies;
