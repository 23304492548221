/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorType } from "../../../model/common";
import { AcitiviesType } from "../../../model/statergyWS";

export interface GetwsUpdateStrategyList {
  loading: boolean;
  data: AcitiviesType[] | null;
  status: number;
  error: boolean;
}

export const initialState: GetwsUpdateStrategyList = {
  loading: false,
  data: null,
  status: 0,
  error: false,
};

const strategyUnActivityUpdateSlice = createSlice({
  name: "strategyUnActivityUpdateSlice",
  initialState,
  reducers: {
    reset: () => initialState,
    strategyUnActivityUpdateRequest(
      state: GetwsUpdateStrategyList,
      action: PayloadAction<{
        data: { ungroupedActivities: AcitiviesType[] };
        assesmentid: string;
      }>,
    ) {
      state.loading = true;
    },
    strategyUnActivityUpdateSuccess(
      state: GetwsUpdateStrategyList,
      action: PayloadAction<{ data: AcitiviesType[]; status: number }>,
    ) {
      state.loading = false;
      state.data = action.payload.data;
      state.status = action.payload.status;
    },
    strategyUnActivityUpdateFailure(
      state: GetwsUpdateStrategyList,
      action: PayloadAction<{ error: ErrorType }>,
    ) {
      state.loading = false;
      state.error = true;
      state.status = action.payload.error.response.status;
    },
  },
});

export const strategyUnActivityUpdateAction =
  strategyUnActivityUpdateSlice.actions;
export const strategyUnActivityUpdateReducer =
  strategyUnActivityUpdateSlice.reducer;

export default strategyUnActivityUpdateReducer;
