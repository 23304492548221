import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ProfileDetailsProp {
  loading: boolean;
  data: [];
  status: number;
  // error:null| string
}

export const initialState: ProfileDetailsProp = {
  loading: false,
  data: [],
  status: 0,
  // error:null
};

const designationListSlice = createSlice({
  name: "designationList",
  initialState,
  reducers: {
    designationListRequest(state: ProfileDetailsProp) {
      state.loading = true;
    },
    designationListSuccess(
      state: ProfileDetailsProp,
      action: PayloadAction<{ data: any; status: number }>,
    ) {
      state.loading = false;
      state.data = action.payload.data;
      state.status = action.payload.status;
    },
    designationListFailure(state: ProfileDetailsProp) {
      state.loading = false;
      // state.error = action.payload;
    },
  },
});

const departmentListSlice = createSlice({
  name: "departmentList",
  initialState,
  reducers: {
    departmentListRequest(state: ProfileDetailsProp) {
      state.loading = true;
    },
    departmentListSuccess(
      state: ProfileDetailsProp,
      action: PayloadAction<{ data: any; status: number }>,
    ) {
      state.loading = false;
      state.data = action.payload.data;
      state.status = action.payload.status;
    },
    departmentListFailure(state: ProfileDetailsProp) {
      state.loading = false;
      // state.error = action.payload;
    },
  },
});

const managerListSlice = createSlice({
  name: "managerList",
  initialState,
  reducers: {
    managerListRequest(state: ProfileDetailsProp) {
      state.loading = true;
    },
    managerListSuccess(
      state: ProfileDetailsProp,
      action: PayloadAction<{ data: any; status: number }>,
    ) {
      state.loading = false;
      state.data = action.payload.data;
      state.status = action.payload.status;
    },
    managerListFailure(state: ProfileDetailsProp) {
      state.loading = false;
      // state.error = action.payload;
    },
  },
});

const saveProfileSlice = createSlice({
  name: "resumeBuilder",
  initialState,
  reducers: {
    saveProfileRequest(state: ProfileDetailsProp, action: PayloadAction<any>) {
      state.loading = true;
    },
    saveProfileSuccess(state: ProfileDetailsProp, action: PayloadAction<any>) {
      state.loading = false;
      state.data = action.payload.data;
      state.status = action.payload.status;
    },
    basicDetailSubmitFailure(state: ProfileDetailsProp) {
      state.loading = false;
      // state.error = action.payload;
    },
  },
});

const profileImageUploadSlice = createSlice({
  name: "profileImageUpload",
  initialState,
  reducers: {
    reset: () => initialState,
    profileImageUploadRequest(
      state: ProfileDetailsProp,
      action: PayloadAction<any>,
    ) {
      state.loading = true;
    },
    profileImageUploadSuccess(
      state: ProfileDetailsProp,
      action: PayloadAction<any>,
    ) {
      state.loading = false;
      state.data = action.payload.data;
      state.status = action.payload.status;
    },
    profileImageUploadSuccessFailure(state: ProfileDetailsProp) {
      state.loading = false;
      // state.error = action.payload;
    },
  },
});

const pBGetProfileDetailsSlice = createSlice({
  name: "getProfileBuilderDetails",
  initialState,
  reducers: {
    reset: () => initialState,
    profileDetailsRequest(state: ProfileDetailsProp) {
      state.loading = true;
    },
    profileDetailsSuccess(
      state: ProfileDetailsProp,
      action: PayloadAction<{ data: any; status: number }>,
    ) {
      state.loading = false;
      state.data = action.payload.data;
      state.status = action.payload.status;
    },
    profileDetailsFailure(state: ProfileDetailsProp) {
      state.loading = false;
      // state.error = action.payload;
    },
  },
});

export const departmentListAction = departmentListSlice.actions;
export const designationListAction = designationListSlice.actions;
export const saveProfileAction = saveProfileSlice.actions;
export const profileImageUploadAction = profileImageUploadSlice.actions;
export const profileBuilderGetProfileDetailsAction =
  pBGetProfileDetailsSlice.actions;
export const managerListAction = managerListSlice.actions;
export const profileBuilderGetProfileDetailsReducer =
  pBGetProfileDetailsSlice.reducer;
export const departmentListReducer = departmentListSlice.reducer;
export const designationListReducer = designationListSlice.reducer;
export const resumeBuilderReducer = saveProfileSlice.reducer;
export const profileImageUploadReducer = profileImageUploadSlice.reducer;
export const managerListReducer = managerListSlice.reducer;
export default {
  designationListReducer,
  departmentListReducer,
  managerListReducer,
  profileImageUploadReducer,
};
