import styled, { css } from "styled-components";
import {
  SpaceProps,
  space,
  LayoutProps,
  layout,
  FlexboxProps,
  flexbox,
  position,
  PositionProps,
} from "styled-system";

type SsProps = SpaceProps & LayoutProps & FlexboxProps & PositionProps;

type FormGroupProps = SsProps & {
  className?: string;
  style?: React.CSSProperties;
  formGroupBlock?: boolean;
};

const FormGroup = styled.div<FormGroupProps>`
  ${space}
  ${layout}
	${flexbox}
	${position}

	${({ formGroupBlock }) =>
    formGroupBlock &&
    css`
      padding-bottom: 27px;
      .form-label {
        font-size: ${({ theme }) => theme.fontSizes[1]};
        line-height: 16.8px;
        color: ${({ theme }) => theme.colors.text.lightGrey1};
        margin-bottom: 9px;
        width: 100%;
      }
      .form-control {
        min-height: 60px;
        border-radius: 6px;
        padding: 10px 20px;
        border: 1px solid ${({ theme }) => theme.colors.border.bluishCyan1};
        font-family: ${({ theme }) => theme.fontFamily.medium};
        font-size: ${({ theme }) => theme.fontSizes[1]};
        line-height: 16.8px;
        font-weight: ${({ theme }) => theme.fontWeights.medium};
        color: ${({ theme }) => theme.colors.text.black};
        transition: ease-in all 200ms;
        @media (max-width: 1400px) {
          min-height: 45px;
        }
        &:focus {
          box-shadow: none;
          border-color: ${({ theme }) => theme.baseColors.primary};
        }
        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: ${({ theme }) => theme.colors.text.darkGrey1};
        }
        &::-moz-placeholder {
          /* Firefox 19+ */
          color: ${({ theme }) => theme.colors.text.darkGrey1};
        }
        &:-ms-input-placeholder {
          /* IE 10+ */
          color: ${({ theme }) => theme.colors.text.darkGrey1};
        }
        &:-moz-placeholder {
          /* Firefox 18- */
          color: ${({ theme }) => theme.colors.text.darkGrey1};
        }
      }
    `}
`;

export { FormGroup };
