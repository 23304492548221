import { ReactElement, useEffect, useState } from "react";
import { Modal, ListGroup, ProgressBar } from "react-bootstrap";
import styled from "styled-components";

import { Scrollbars } from "react-custom-scrollbars-2";
import _ from "lodash";
import icons from "../../icons";
import ModalAssessment from "../../../pages/MainPage/AssessmentResult/AssessmentModal";
import { assessmentDetialsAction } from "./AssesmentDetailsSlice";
import { useAppDispatch } from "../../../store/hooks";

const StyledAssessmentPriority = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.border.default};
  margin: 0;
  border-radius: 10px;
  overflow: hidden;
  min-height: 78px;
  display: flex;
  min-height: 500px;
  padding: 28px 28px 20px;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.bg.white};
  .scrollbar {
    max-height: calc(100% - 40px);
  }
  ul {
    li {
      border: 1px solid ${({ theme }) => theme.colors.bg.bluishCyan};
      box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.03);
      padding: 23px 20px;
      border-radius: 8px !important;
      display: flex;
      align-items: center;
      border-width: 1px !important;
      & + li {
        margin-top: 10px;
      }
    }
  }
`;
const AssessmentName = styled.div`
  display: flex;
  align-items: center;
  max-width: 250px;
  flex: 0 0 250px;
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: ${({ theme }) => theme.fontSizes[1]};
  line-height: ${({ theme }) => theme.lineHeight[1]};
  font-weight: normal;
  color: ${({ theme }) => theme.colors.text.black};
  @media screen and (max-width: 1300px) {
    max-width: 180px;
    flex: 0 0 180px;
  }
  span {
    font-weight: normal;
  }
  .iconInfo {
    display: inline;
    margin-right: 10px;
    cursor: pointer;
  }
`;
const ProgressBarWrapper = styled.div`
  width: 100%;
  position: relative;
  .progress {
    height: 10px;
    border-radius: 50px;
  }
`;
const ProgressBreakPoint = styled.div`
  position: absolute;
  top: -11px;
  margin-left: -6px;
`;
const Heading = styled.div`
  display: flex;
  padding-bottom: 16px;
  span {
    font-family: ${({ theme }) => theme.fontFamily.medium};
    font-size: ${({ theme }) => theme.fontSizes[1]};
    line-height: ${({ theme }) => theme.lineHeight[1]};
    font-weight: normal;
    color: ${({ theme }) => theme.colors.text.black};
    width: 270px;
  }
`;
const Legends = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${({ theme }) => theme.fontFamily.medium};
  font-size: ${({ theme }) => theme.fontSizes[1]};
  line-height: ${({ theme }) => theme.lineHeight[1]};
  font-weight: normal;
  color: ${({ theme }) => theme.colors.text.black};
  span {
    display: flex;
    align-items: center;
    & + span {
      margin-left: 20px;
    }
    div {
      width: 24px;
      height: 8px;
      border-radius: 50px;
      margin-right: 10px;
      background-color: ${({ theme }) => theme.colors.bg.tealishBlue};
    }
    .pr-icon {
      margin-right: 6px;
    }
  }
`;
function AssessmentPriority({
  assessmentPriority,
  assessmentId,
}: any): ReactElement {
  const dispatch = useAppDispatch();
  const [show, setShow] = useState(false);
  const [classs, setClass] = useState(false);
  const [functionName, setFilterFunction] = useState("");
  const handleClose = () => {
    setClass(false);
    setTimeout(() => setShow(false), 1000);
  };

  useEffect(() => {
    if (show) {
      dispatch(
        assessmentDetialsAction.getAssessmentDetailViewRequest(assessmentId),
      );
    } else {
      dispatch(assessmentDetialsAction.reset());
    }
  }, [show]);
  return (
    <StyledAssessmentPriority>
      <Heading>
        <span>Assessment</span>
        <span>Maturity Level</span>
      </Heading>
      <Scrollbars autoHide className="scrollbar">
        <ListGroup as="ul">
          {assessmentPriority?.length > 0 &&
            assessmentPriority.map((item: any) => (
              <ListGroup.Item as="li" key={item.function}>
                <AssessmentName>
                  {item.current >= item.target ? (
                    <div
                      className="iconInfo"
                      onClick={() => {
                        setClass(true);
                        setShow(true);
                        setFilterFunction(functionName as string);
                      }}
                      role="presentation"
                    >
                      <icons.Tick3 />
                    </div>
                  ) : (
                    <div
                      className="iconInfo"
                      onClick={() => {
                        setClass(true);
                        setShow(true);
                        setFilterFunction(item.function as string);
                      }}
                      role="presentation"
                    >
                      <icons.info1 />
                    </div>
                  )}
                  {_.capitalize(item.function)}
                  {item.issues === 0 ? (
                    <span
                      style={{
                        marginLeft: "3px",
                        fontWeight: "normal",
                        // color: "blue",
                      }}
                    >
                      (0)
                    </span>
                  ) : (
                    <span
                      style={{
                        marginLeft: "3px",
                        fontWeight: "normal",
                        // color: "blue",
                      }}
                    >
                      ( {item.issues} Items)
                    </span>
                  )}
                </AssessmentName>
                <ProgressBarWrapper>
                  <ProgressBreakPoint
                    style={{ left: `${(item.target / 5) * 100}%` }}
                  >
                    <icons.ProgressStrokeIcon />
                  </ProgressBreakPoint>

                  <ProgressBreakPoint
                    style={{ left: `${(item.current / 5) * 100}%` }}
                  >
                    <icons.ProgressBreakICon />
                  </ProgressBreakPoint>

                  <ProgressBar>
                    <ProgressBar
                      style={{ backgroundColor: "#4182F1" }}
                      now={(item.current / 5) * 100}
                      key={1}
                    />

                    <ProgressBar
                      style={
                        item.priority === "High Priority"
                          ? { backgroundColor: "#ff0000" }
                          : item.priority === "Medium Priority"
                          ? { backgroundColor: "#FFA500" }
                          : { backgroundColor: "#008000" }
                      }
                      now={((item.target - item.current) / 5) * 100}
                      key={2}
                    />
                  </ProgressBar>
                </ProgressBarWrapper>
                {/* <ProgressBarWrapper>
                  <ProgressBreakPoint
                    style={{ left: `${(item.target / 5) * 100}%` }}
                  >
                    <icons.ProgressBreakICon />
                  </ProgressBreakPoint>
                 
                  <ProgressBar animated now={(item.current / 5) * 100} />
                </ProgressBarWrapper> */}
              </ListGroup.Item>
            ))}
        </ListGroup>
      </Scrollbars>
      <Legends>
        {/* <span>
          <div></div>Current
        </span> */}
        <span>
          {" "}
          <icons.ProgressBreakICon /> Current Level
        </span>
        <span>
          <icons.ProgressStrokeIcon /> Target Level
        </span>
      </Legends>

      <Modal
        show={show}
        onHide={handleClose}
        fullscreen
        className={`custom-modal assessment-modal ${classs ? "left" : "right"}`}
      >
        {/* Popup close */}
        <Modal.Header closeButton />
        <Modal.Body>
          <ModalAssessment functionName={functionName} />
        </Modal.Body>
      </Modal>
    </StyledAssessmentPriority>
  );
}

export { AssessmentPriority };
