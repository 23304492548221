/**
 * Library used for handling HTTP request : axios
 * GIT: https://github.com/axios/axios
 * NPM: https://www.npmjs.com/package/axios
 */

import axios, { AxiosPromise, AxiosRequestConfig } from "axios";
import env from "./constants";
//  import constants from './constants';
// default SERVICE_URL will go here.
const API_URL = env.baseURL();

export const addAccessTokenInterceptor = (getAccessTokenSilently: any) => {
  axios.interceptors.request.use(async (config) => {
    const token = await getAccessTokenSilently();
    if (config.headers !== undefined) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      return config;
    }
    return config;
  });
};

const axiosConfig: AxiosRequestConfig = {
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
};
function getMainConfig(headers = {} as any, config = {} as any) {
  const mainConfig: AxiosRequestConfig = {
    ...config,
    headers: {
      ...axiosConfig.headers,
      ...headers,
    },
  };
  return mainConfig;
}

function getApiConfig({ headers = {} as any }) {
  const config = axiosConfig;
  return getMainConfig(headers, config);
}

const ApiCall = (ajaxParams: any): AxiosPromise => axios(ajaxParams);

function GET({
  url = "",
  params = {},
  headers = {},
}: AxiosRequestConfig): AxiosPromise {
  const config = getApiConfig({ headers });

  const ajaxParams = {
    ...config,
    url,
    params,
    method: "GET",
  };

  return ApiCall(ajaxParams);
}

function POST({
  url = "",
  params = {},
  data = {},
  headers = {},
}: AxiosRequestConfig): AxiosPromise {
  const config = getApiConfig({ headers });

  const ajaxParams = {
    ...config,
    url,
    data,
    params,
    method: "POST",
  };

  return ApiCall(ajaxParams);
}

function PUT({
  url = "",
  params = {},
  data = {},
  headers = {},
}: AxiosRequestConfig): AxiosPromise {
  const config = getApiConfig({ headers });

  const ajaxParams = {
    ...config,
    url,
    data,
    params,
    method: "PUT",
  };

  return ApiCall(ajaxParams);
}

function PATCH({
  url = "",
  params = {},
  data = {},
  headers = {},
}: AxiosRequestConfig): AxiosPromise {
  const config = getApiConfig({ headers });

  const ajaxParams = {
    ...config,
    url,
    data,
    params,
    method: "PATCH",
  };

  return ApiCall(ajaxParams);
}

function DELETE({
  url = "",
  params = {},
  data = {},
  headers = {},
}: AxiosRequestConfig): AxiosPromise {
  const config = getApiConfig({ headers });

  const ajaxParams = {
    ...config,
    url,
    data,
    params,
    method: "DELETE",
  };

  return ApiCall(ajaxParams);
}

export default {
  GET,
  POST,
  PUT,
  PATCH,
  DELETE,
};
