import React, { ReactElement } from "react";
import { Nav } from "react-bootstrap";
import { NavLink, Outlet } from "react-router-dom";

import styled from "styled-components";
import { Header } from "../../ui/patterns";
// import { SessionExpiryCheck } from "../../utils/SessionExpiryCheck";

const MainContainer = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  padding-top: 112px;
`;
const HeaderMenu = styled.div`
  .nav {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 8;
    border-bottom: unset;
    height: 48px;
    margin-top: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.bg.white3};
    border-bottom: 1px solid ${({ theme }) => theme.colors.border.default};
    .nav-link {
      margin-bottom: unset;
      height: 100%;
      padding: 15px 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: ${({ theme }) => theme.fontFamily.medium};
      font-style: normal;
      font-weight: normal;
      font-size: ${({ theme }) => theme.fontSizes[1]};
      line-height: ${({ theme }) => theme.lineHeight[14]};
      color: #000000 !important;
      border-left: unset;
      border-right: unset;
      border-top: unset;
      border-radius: unset;
      transition: color 0.25s;
      transition: all 0.25s ease;
      @media (max-width: 960px) {
        padding: 15px 20px;
      }
      &:hover {
        color: ${({ theme }) => theme.colors.text.tealishBlue} !important;
      }

      &.active {
        color: ${({ theme }) => theme.colors.text.tealishBlue} !important;
        background: unset;
        border-radius: unset;
        position: relative;
        font-family: ${({ theme }) => theme.fontFamily.bold};
        font-weight: normal;
        &:after {
          content: "";
          height: 4px;
          width: 100%;
          position: absolute;
          bottom: -1px;
          background: ${({ theme }) => theme.colors.border.tealishBlue};
          box-shadow: 0px 2px 5px 0px #5c97fba3;
        }
      }
    }
  }
`;
function Admin(): ReactElement {
  // SessionExpiryCheck();
  return (
    <>
      <Header />
      <MainContainer>
        <HeaderMenu>
          <Nav activeKey="Users">
            <Nav.Item>
              <Nav.Link as={NavLink} to="Users">
                Manage Users
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={NavLink} to="Roles">
                Manage Roles
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                style={{ pointerEvents: "none", opacity: "0.4" }}
                as={NavLink}
                to="Integration"
              >
                Integration
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </HeaderMenu>
        <Outlet />
      </MainContainer>
    </>
  );
}

export default Admin;
