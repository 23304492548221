import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorType } from "../../../../../model/common";
import { UpdateDateRoadMap } from "../../../../../model/wsRoadMap";
// For Token
export interface GetwsRoadMapUpdate {
  loading: boolean;
  status: number;
  error: boolean;
}

export const initialState: GetwsRoadMapUpdate = {
  loading: false,
  status: 0,
  error: false,
};

const roadMapUpdateDateSlice = createSlice({
  name: "roadMapUdpateDateSlice",
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    roadMapUdpateDateRequest(
      state: GetwsRoadMapUpdate,
      action: PayloadAction<UpdateDateRoadMap>,
    ) {
      state.loading = true;
    },
    roadMapUdpateDateSuccess(
      state: GetwsRoadMapUpdate,
      action: PayloadAction<{ status: number }>,
    ) {
      state.loading = false;
      state.status = action.payload.status;
    },
    roadMapUpdateDateFailure(
      state: GetwsRoadMapUpdate,
      action: PayloadAction<{ error: ErrorType }>,
    ) {
      state.loading = false;
      state.error = true;
      state.status = action.payload.error.response.status;
    },
  },
});

export const roadMapUpdateDateAction = roadMapUpdateDateSlice.actions;
export const roadMapUpdateDateReducer = roadMapUpdateDateSlice.reducer;

export default roadMapUpdateDateReducer;
