import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GetwsRoadMapList } from "../../../model/wsRoadMap";

export const initialState: GetwsRoadMapList = {
  loading: false,
  wsRoadMpDetails: [],
  status: 0,
};

const roadMapFetchSlice = createSlice({
  name: "roadMapFetchSlice",
  initialState,
  reducers: {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    roadMapFetchRequest(
      state: GetwsRoadMapList,
      action: PayloadAction<string>,
    ) {
      state.loading = true;
    },
    roadMapFetchSuccess(
      state: GetwsRoadMapList,
      action: PayloadAction<{ data: any; status: number }>,
    ) {
      state.loading = false;
      state.wsRoadMpDetails = action.payload.data;
      state.status = action.payload.status;
    },
    roadMapFetchFailure(state: GetwsRoadMapList) {
      state.loading = false;
      // state.error = action.payload;
    },
  },
});

export const roadMapFetchAction = roadMapFetchSlice.actions;
export const roadMapFetchReducer = roadMapFetchSlice.reducer;

export default roadMapFetchReducer;
