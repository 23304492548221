import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorType } from "../../../model/common";
import { DashboardDetailViewProp } from "../../../model/dashboardDetailView";

export interface GetAssessmentDetailsProp {
  loading: boolean;
  data: DashboardDetailViewProp[] | undefined;
  status: number | null;
  error: boolean;
}

export const initialState: GetAssessmentDetailsProp = {
  loading: false,
  data: undefined,
  status: 0,
  error: false,
};

const getAssessmentDetailsSlice = createSlice({
  name: "getAssessmentDetails",
  initialState,
  reducers: {
    reset: () => initialState,
    getAssessmentDetailViewRequest(
      state: GetAssessmentDetailsProp,
      action: PayloadAction<string>,
    ) {
      state.loading = true;
    },
    getAssessmentDetailViewSuccess(
      state: GetAssessmentDetailsProp,
      action: PayloadAction<{
        data: DashboardDetailViewProp[];
        status: number;
      }>,
    ) {
      state.loading = false;
      state.data = action.payload.data;
      state.status = action.payload.status;
    },
    getAssessmentDetailViewFailure(
      state: GetAssessmentDetailsProp,
      action: PayloadAction<{ error: ErrorType }>,
    ) {
      state.loading = false;
      state.status = action.payload.error.response.status;
      state.error = true;
    },
  },
});

export const assessmentDetialsAction = getAssessmentDetailsSlice.actions;
export const assessmentDetialsReducer = getAssessmentDetailsSlice.reducer;

export default assessmentDetialsReducer;
