import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorType } from "../../../model/common";
import { GetAssignUser } from "../../../model/wsAddUser";

type GetAssignedUserList = {
  loading: boolean;
  data: any;
  status: number;
  error: boolean;
};

export const initialState: GetAssignedUserList = {
  loading: false,
  data: [],
  status: 0,
  error: false,
};

const getAssignedUsersSlice = createSlice({
  name: "getAssignedUserList",
  initialState,
  reducers: {
    reset: () => initialState,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    assignedUsersFetchRequest(
      state: GetAssignedUserList,
      action: PayloadAction<GetAssignUser>,
    ) {
      state.loading = true;
    },
    assignedUsersFetchSuccess(
      state: GetAssignedUserList,
      action: PayloadAction<{ data: any; status: number }>,
    ) {
      state.loading = false;
      state.data = action.payload.data;
      state.status = action.payload.status;
    },
    assignedUsersFetchFailure(
      state: GetAssignedUserList,
      action: PayloadAction<{ error: ErrorType }>,
    ) {
      state.loading = false;
      state.error = true;
      state.status = action.payload.error.response.status;
    },
  },
});

export const assignedUsersFetchAction = getAssignedUsersSlice.actions;
export const assignedUsersFetchReducer = getAssignedUsersSlice.reducer;

const assignOwnerSlice = createSlice({
  name: "assignOwner",
  initialState,
  reducers: {
    reset: () => initialState,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    assignOwnerSubmitRequest(
      state: GetAssignedUserList,
      action: PayloadAction<{
        data: { isOwner: boolean };
        userId: string;
      }>,
    ) {
      state.loading = true;
    },
    assignOwnerSubmitSuccess(
      state: GetAssignedUserList,
      action: PayloadAction<{ status: number }>,
    ) {
      state.loading = false;
      state.status = action.payload.status;
    },
    assignOwnerSubmitFailure(
      state: GetAssignedUserList,
      action: PayloadAction<{ error: ErrorType }>,
    ) {
      state.loading = false;
      state.error = true;
      state.status = action.payload.error.response.status;
    },
  },
});

export const assignOwnerAction = assignOwnerSlice.actions;
export const assignOwnerReducer = assignOwnerSlice.reducer;

export default { assignedUsersFetchReducer, assignOwnerReducer };
