// import { APPURL,APPURLQA,APPURLDEV } from "./serviceurl";
// export const APP_TOKEN = process.env.REACT_APP_APP_TOKEN;
export const APPURL = process.env.REACT_APP_API_SERVER_URL;
const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const audience: string | undefined = process.env.REACT_APP_AUTH0_AUDIENCE;

const isEnvValid = domain && clientId && audience && APPURL;

if (!isEnvValid) {
  throw new Error("Missing environment variables.");
}

export const auth0paraGet = () => {
  return { domain, clientId, audience };
};

export default {
  baseURL(): string {
    if (APPURL !== undefined) {
      return APPURL;
    }
    return "";
  },
};
