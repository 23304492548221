import { ReactElement } from "react";
import styled, { css } from "styled-components";
import { Label, Checkbox } from "@rebass/forms";

const StyledProfileBuilderTable = styled.tbody`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 20px;
  row-gap: 20px;
  width: 100%;
  padding: 0 20px 0 18px;
`;
type ProfileBuilderTableHolderProps = {
  active?: boolean;
};
const ProfileBuilderTableHolder = styled.td<ProfileBuilderTableHolderProps>`
  display: flex;
  border: 1px solid ${({ theme }) => theme.colors.border.default};
  border-radius: 0 8px 8px 0;
  padding: 16px 20px;
  cursor: pointer;
  transition: ease-in all 200ms;
  overflow: hidden;
  border-left: 4px solid ${({ theme }) => theme.colors.border.default};

  &:hover {
    border-color: ${({ theme }) => theme.colors.bg.tealishBlue};
    box-shadow: 0px 4px 10px 0px rgba(31, 56, 98, 0.129);
  }

  ${({ active }) =>
    !active &&
    css`
      border-color: ${({ theme }) => theme.colors.bg.tealishBlue};
      box-shadow: 0px 4px 10px 0px rgba(31, 56, 98, 0.129);

      .accordion-button {
        border-bottom: 1px solid ${({ theme }) => theme.colors.border.default};
      }
      .collapse:not(.show) {
        display: block;
      }
      .accordion-button::after {
        transform: rotate(-180deg);
      }
    `}
`;
const Description = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.bold};
  font-size: ${({ theme }) => theme.fontSizes[1]};
  line-height: ${({ theme }) => theme.lineHeight[1]};
  font-weight: normal;
  color: ${({ theme }) => theme.baseColors.black};
  padding-right: 20px;
`;
const Details = styled.div`
  width: 118px;
  flex: 0 0 118px;
  margin-left: auto;
  margin-right: 74px;
  color: ${({ theme }) => theme.colors.text.black};
  font-family: ${({ theme }) => theme.fontFamily.bold};
  font-size: ${({ theme }) => theme.fontSizes[1]};
  line-height: ${({ theme }) => theme.lineHeight[1]};
  font-weight: normal;
`;
const Select = styled.div`
  width: 95px;
  flex: 0 0 95px;
  label {
    display: flex;
    align-items: center;
    white-space: nowrap;
    cursor: pointer;
    div {
      margin-right: 18px;
    }
  }
  input:focus ~ svg {
    background-color: transparent;
  }
  svg {
    width: 18px;
    height: 18px;
    margin: 0;
  }
  svg {
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect x='0.375' y='0.375' width='17.25' height='17.25' rx='2.625' fill='%23F5F6FD' stroke='%23ADB1B7' stroke-width='0.75'/%3e%3c/svg%3e ");
    path {
      opacity: 0;
    }
  }
  input:checked ~ svg {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='18' height='18' rx='3' fill='%234182F1'/%3e%3cpath d='M5.625 9.56263L7.7142 11.8125L12.9375 6.1875' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e ");
  }
`;
type ProfileBuilderTableContentProps = {
  formik?: any;
  profileBuilderTableContent: Array<List>;
  tab?: string;
  setSelectedArray: any;
  selectedDataArray: any;
  setAllchecked: any;
};
type List = {
  _id?: string | number;
  ksaDescription: string;
  ksaId: string;
  active?: boolean;
  taskDescription: string;
  taskId: string;
};

function ProfileBuilderTableContent({
  profileBuilderTableContent,
  formik,
  tab,
  setSelectedArray,
  selectedDataArray,
  setAllchecked,
}: ProfileBuilderTableContentProps): ReactElement {
  const onCheckBoxChange = (id: any) => {
    const profileBuilderList: any = profileBuilderTableContent;
    if (profileBuilderList !== null) {
      const index = profileBuilderList.findIndex(
        (item: any) => item._id === id,
      );
      if (profileBuilderList[index].active) {
        profileBuilderList[index].active = false;
      } else {
        profileBuilderList[index].active = true;
      }
    }
    switch (tab) {
      case "knowledge":
        formik.setFieldValue("knowledgeList", profileBuilderList);
        break;
      case "skills":
        formik.setFieldValue("skillsList", profileBuilderList);
        break;
      case "abliities":
        formik.setFieldValue("abliities", profileBuilderList);
        break;
      case "responsiblities":
        formik.setFieldValue("responsiblities", profileBuilderList);
        break;
      default:
        break;
    }
    const checkedDatas = profileBuilderList?.filter(
      (element: any) => element.active !== false,
    );
    const allSelectCheck = profileBuilderList.find(
      ({ active }: any) => !active,
    );
    if (allSelectCheck && Object.keys(allSelectCheck).length !== 0) {
      setAllchecked(false);
    }
    const tmpData = selectedDataArray.concat(checkedDatas);
    setSelectedArray(tmpData);
  };
  return (
    <StyledProfileBuilderTable>
      {profileBuilderTableContent?.length > 0 ? (
        profileBuilderTableContent.map((item) => (
          <ProfileBuilderTableHolder key={item._id} active={!item.active}>
            <Description>{item.ksaDescription}</Description>
            <Details>{item.ksaId}</Details>
            <Select>
              <Label>
                <Checkbox
                  id={item._id?.toString()}
                  name={item?.active ? item._id?.toString() : "selectall"}
                  checked={!!item?.active}
                  onChange={() => onCheckBoxChange(item._id)}
                />
              </Label>
            </Select>
          </ProfileBuilderTableHolder>
        ))
      ) : (
        <p style={{ textAlign: "center", marginTop: "20px" }}>
          Please select NICE job role for relevant{" "}
          {tab === "knowledge"
            ? "knowledge"
            : tab === "skills"
            ? "skill"
            : tab === "abliities"
            ? "abilities"
            : "responsibilities"}
        </p>
      )}
    </StyledProfileBuilderTable>
  );
}

export { ProfileBuilderTableContent };
