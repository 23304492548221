import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface MaturityProp {
  loading: boolean;
  data: [];
  status: number;
  error: null | string;
}

export const initialState: MaturityProp = {
  loading: false,
  data: [],
  status: 0,
  error: null,
};

const maturityAssessmentSubmitSlice = createSlice({
  name: "maturityAssessmentSubmit",
  initialState,
  reducers: {
    reset: () => initialState,
    maturityAssessmentSubmitRequest(
      state: MaturityProp,
      action: PayloadAction<any>,
    ) {
      state.loading = true;
    },
    maturityAssessmentSubmitSuccess(
      state: MaturityProp,
      action: PayloadAction<any>,
    ) {
      state.loading = false;
      state.data = action.payload.data;
      state.status = action.payload.status;
    },
    maturityAssessmentSubmitFailure(
      state: MaturityProp,
      action: PayloadAction<{ error: any }>,
    ) {
      state.loading = false;
      state.error = action.payload.error;
    },
  },
});

const maturityAssessmentFileUploadSlice = createSlice({
  name: "maturityAssessmentFileUpload",
  initialState,
  reducers: {
    reset: () => initialState,
    maturityAssessmentFileUploadRequest(
      state: MaturityProp,
      action: PayloadAction<any>,
    ) {
      state.loading = true;
    },
    maturityAssessmentFileUploadSuccess(
      state: MaturityProp,
      action: PayloadAction<any>,
    ) {
      state.loading = false;
      state.data = action.payload.data;
      state.status = action.payload.status;
    },
    maturityAssessmentFileUploadFailure(state: MaturityProp) {
      state.loading = false;
      // state.error = action.payload;
    },
  },
});

export const maturityAssessmentSubmitAction =
  maturityAssessmentSubmitSlice.actions;
export const maturityAssessmentSubmitReducer =
  maturityAssessmentSubmitSlice.reducer;
export const maturityAssessmentFileUploadAction =
  maturityAssessmentFileUploadSlice.actions;
export const maturityAssessmentFileUploadReducer =
  maturityAssessmentFileUploadSlice.reducer;
export default {
  maturityAssessmentSubmitReducer,
  maturityAssessmentFileUploadReducer,
};
