import { ReactElement, useEffect, useState } from "react";
import styled from "styled-components";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone, { defaultClassNames, Layout } from "react-dropzone-uploader";

import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { PreviewComponent } from "../previewElement";
import icons from "../../../icons";
import { RootState } from "../../../../store/store";
import errorHandling from "../../../../utils/errorHandling";
import { SelectDomainVendor } from "../selectDomainVendor";
import { workSteramTecAction } from "../workStreamTechnologySlice";
import { useAppDispatch } from "../../../../store/hooks";

// interface IPreviewProps {
//   setFileUploading: () => any;
//   isDropdown: boolean;
// }
const UploadHolder = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.border.default};
  margin-top: 30px;
  padding-top: 30px;
`;
const SubHeading = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.medium};
  font-size: ${({ theme }) => theme.fontSizes[1]};
  line-height: ${({ theme }) => theme.lineHeight[2]};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.baseColors.black};
  margin-bottom: 20px;
`;
const DropzoneWrapper = styled.div`
  position: relative;
  width: calc(100% - 1px);
  .dzu-dropzone {
    overflow: visible;
    min-height: 74px;
    border: none;
    .dzu-inputLabel {
      border: 1px solid ${({ theme }) => theme.colors.border.default};
      font-family: ${({ theme }) => theme.fontFamily.medium};
      font-size: ${({ theme }) => theme.fontSizes[1]};
      line-height: ${({ theme }) => theme.lineHeight[2]};
      font-weight: ${({ theme }) => theme.fontWeights.medium};
      color: ${({ theme }) => theme.colors.text.darkGrey1};
      right: auto;
      left: 0;
      width: 100%;
      text-align: left;
      justify-content: flex-start;
      border-radius: 8px;
      padding-left: 20px;
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='20' height='16' viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fillRule='evenodd' clipRule='evenodd' d='M7.91732 0.0834961C8.16466 0.0834961 8.3992 0.19335 8.55749 0.383342L10.391 2.5835H15.834C16.7544 2.5835 17.5007 3.3297 17.5007 4.25016V5.91683H18.334C18.5837 5.91683 18.8202 6.02875 18.9784 6.22183C19.1367 6.41491 19.2001 6.66879 19.1512 6.91358L17.4845 15.2469C17.4066 15.6365 17.0646 15.9168 16.6673 15.9168H1.67741C1.65215 15.9171 1.62669 15.9163 1.6011 15.9142C1.57397 15.912 1.54707 15.9086 1.52048 15.9038C1.35602 15.8745 1.2095 15.7977 1.0945 15.6888C1.07573 15.671 1.05781 15.6524 1.04077 15.6329C0.91151 15.4855 0.833992 15.2927 0.833992 15.0835V1.75016C0.833992 0.829688 1.58018 0.0834961 2.50066 0.0834961H7.91732ZM10.0007 4.25016C9.75332 4.25016 9.51878 4.14033 9.36049 3.95033L7.52703 1.75016H2.50066V8.31422L2.9422 6.54804C3.03495 6.17708 3.36827 5.91683 3.75066 5.91683H15.834V4.25016H10.0007ZM2.73464 14.2502L4.40131 7.5835H17.3175L15.9841 14.2502H2.73464Z' fill='black'/%3e%3c/svg%3e ");
      background-repeat: no-repeat;
      background-position: center right 20px;
    }
    .dzu-previewContainer {
      border: 1px solid ${({ theme }) => theme.colors.border.default};
      background: ${({ theme }) => theme.baseColors.white};
      overflow: hidden;
      border-radius: 8px;
      padding: 0 20px;
      min-height: 74px;
      & + .dzu-inputLabel {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
      }
      .dzu-previewImage {
        width: 36px;
        max-height: max-content;
        max-width: 36px;
        border-radius: 4px;
        object-fit: contain;
      }
      .dzu-previewStatusContainer {
        display: none;
      }
      .dzu-previewFileName {
        overflow: hidden;
      }
      .dzu-previewStatusContainer {
        .dzu-previewButton {
          display: none;
        }
      }
    }
    .dzu-submitButtonContainer {
      margin: 0;
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      width: 100%;
      padding-top: 25px;
      display: none;
    }
  }
`;
const InfoNoteWrapper = styled.div`
  margin-top: 10px;
  min-height: 15px;
`;
const InfoNote = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.text.tealishBlue};
  font-size: ${({ theme }) => theme.fontSizes[0]};
  line-height: ${({ theme }) => theme.lineHeight[0]};
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-weight: ${({ theme }) => theme.fontWeights.regular};

  svg {
    margin-right: 6px;
  }
`;

function TechnologyAdd({
  assessmentId,
  setRecommendationList,
  recommendationDataList,
  workstreamid,
  domain,
  setDomain,
  domainTechnology,
  setDomainTechnology,
  msspTechName,
  domainList,
  setCustomTechnologyArray,
  isDropdown,
  isExcelUpload,
  setIsExcelUpload,
  setIsDropdown,
  customTechName,
  setCustomName,
}: any): ReactElement {
  const [fileupload, setFileUploading] = useState(false);
  // eslint-disable-next-line no-empty-pattern
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [ErrorMsg, setErrorMsg] = useState("");
  const TechSubmit = useSelector((state: RootState) => state.workStreamTech);

  useEffect(() => {
    if (isExcelUpload) {
      setDomain({});
      setDomainTechnology(null);
      setCustomName("");
      setCustomTechnologyArray([]);
    } else if (isDropdown) {
      setFileUploading(false);
    }
  }, [isExcelUpload, isDropdown]);

  const handleChangeStatus = ({ meta }: any) => {
    setRecommendationList([]);
    dispatch(workSteramTecAction.reset());
    if (
      meta.name.lastIndexOf(".csv") === -1 &&
      meta.name.lastIndexOf(".xlsx") === -1
    ) {
      setFileUploading(false);
      setErrorMsg("Invalid format");
      setIsDropdown(true);
      setIsExcelUpload(false);
    } else {
      setErrorMsg("");
      setIsExcelUpload(true);
      setIsDropdown(false);
      if (meta.percent !== 0) {
        setFileUploading(true);
      }
    }
  };

  const handleSubmit = (files: any[], allFiles: any[]) => {
    allFiles.forEach((f: { remove: () => any }) => f.remove());
  };

  /// ////// ---------------- Error Handling-------------//////////
  useEffect(() => {
    if (TechSubmit.error) {
      if (TechSubmit.status === 400) {
        setErrorMsg("The upload file not inline with the provided format");
      } else {
        errorHandling(TechSubmit.status, navigate);
      }
    }
  }, [TechSubmit.error]);

  // function Preview(props: IPreviewProps) {
  //   return (
  //     <PreviewComponent
  //       {...props}
  //       assessmentId={assessmentId}
  //       workstreamid={workstreamid}
  //       setPreviewElement={setPreviewElement}
  //       setRecommendationList={setRecommendationList}
  //       recommendationDataList={recommendationDataList}
  //     />
  //   );
  // }
  return (
    <UploadHolder>
      <SubHeading>Upload file</SubHeading>
      <DropzoneWrapper>
        {/* File upload and drag */}

        <Dropzone
          LayoutComponent={Layout}
          PreviewComponent={(props) => (
            <PreviewComponent
              // {...props}
              meta={props.meta}
              fileWithMeta={props.fileWithMeta}
              files={props.files}
              setFileUploading={setFileUploading}
              assessmentId={assessmentId}
              workstreamid={workstreamid}
              setRecommendationList={setRecommendationList}
              recommendationDataList={recommendationDataList}
              isUpload={false}
              canCancel={false}
              canRemove={false}
              canRestart={false}
              extra={props.extra}
            />
          )}
          onSubmit={handleSubmit}
          maxFiles={1}
          onChangeStatus={handleChangeStatus}
          classNames={{ inputLabelWithFiles: defaultClassNames.inputLabel }}
          inputContent="Drag and drop/click to upload file from PC"
          submitButtonContent="Upload"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        />
        {/* File upload progress, file size and name */}
        {/* <ProgressBarHolder>
          <FileINfo>
            <FileName></FileName>
            <FileSize>1.24 MB</FileSize>
          </FileINfo>
          <ProgressBar animated now={45} />
        </ProgressBarHolder> */}

        {/* File info */}
        <Link to="/files/Technology_Excel_Upload.xlsx" target="_blank" download>
          <p style={{ fontSize: "14px", paddingTop: "10px" }}>
            Download Sample
          </p>
        </Link>
        {!fileupload && (
          <InfoNoteWrapper>
            {/* Upload file type info */}
            <InfoNote>
              <icons.Info />
              Supported file include excel, .xls
            </InfoNote>
          </InfoNoteWrapper>
        )}

        {ErrorMsg !== "" || (TechSubmit.error && TechSubmit.status === 400) ? (
          <InfoNoteWrapper>
            {/* Upload file type info */}
            <InfoNote>
              <icons.AlertIcon />
              {ErrorMsg}
            </InfoNote>
          </InfoNoteWrapper>
        ) : null}
        {/* Action buttons */}
        {/* <ActionButtons>
          <Button variant="outline-primary">Remove File</Button>
          <Button variant="outline-primary">Cancel</Button>
          <Button variant="primary" disabled>
            Upload
          </Button>
        </ActionButtons> */}
      </DropzoneWrapper>
      <SelectDomainVendor
        domain={domain}
        setDomain={setDomain}
        domainTechnology={domainTechnology}
        msspTechName={msspTechName}
        setDomainTechnology={setDomainTechnology}
        domainList={domainList}
        setCustomTechnologyArray={setCustomTechnologyArray}
        setIsExcelUpload={setIsExcelUpload}
        setIsDropdown={setIsDropdown}
        customTechName={customTechName}
        setCustomName={setCustomName}
        recommendationDataList={recommendationDataList}
      />
    </UploadHolder>
  );
}

export { TechnologyAdd };
